import { ICity, IState } from "core/interfaces/base";

export const nevadaCities: ICity[] = [
  { name: 'Alamo, NV', path: '/trailer-rentals/nevada/alamo', lat: 37.364961 , lng: -115.164461 },
  { name: 'Amargosa Valley, NV', path: '/trailer-rentals/nevada/amargosa-valley', lat: 36.643784 , lng: -116.400281 },
  { name: 'Battle Mountain, NV', path: '/trailer-rentals/nevada/battle-mountain', lat: 40.642133 , lng: -116.934267 },
  { name: 'Beatty, NV', path: '/trailer-rentals/nevada/beatty', lat: 36.908557 , lng: -116.759226 },
  { name: 'Boulder City, NV', path: '/trailer-rentals/nevada/boulder-city', lat: 35.978222 , lng: -114.834512 },
  { name: 'Blue Diamond, NV', path: '/trailer-rentals/nevada/blue-diamond', lat: 36.04636 , lng: -115.403892 },
  { name: 'Caliente, NV', path: '/trailer-rentals/nevada/caliente', lat: 37.614965 , lng: -114.511938 },
  { name: 'Carlin, NV', path: '/trailer-rentals/nevada/carlin', lat: 40.713807 , lng: -116.103966 },
  { name: 'Carson City, NV', path: '/trailer-rentals/nevada/carson-city', lat: 39.163798 , lng: -119.767403, active: true, showNewVersion: true },
  { name: 'Cold Springs, NV', path: '/trailer-rentals/nevada/cold-springs', lat: 39.680186 , lng: -119.976588 },
  { name: 'Crystal Bay, NV', path: '/trailer-rentals/nevada/crystal-bay', lat: 39.227827 , lng: -120.004709 },
  { name: 'Dyer, NV', path: '/trailer-rentals/nevada/dyer', lat: 37.667315 , lng: -118.066143 },
  { name: 'Elko, NV', path: '/trailer-rentals/nevada/elko', lat: 40.832421 , lng: -115.763123 },
  { name: 'Ely, NV', path: '/trailer-rentals/nevada/ely', lat: 39.253333 , lng: -114.874248 },
  { name: 'Enterprise, NV', path: '/trailer-rentals/nevada/enterprise', lat: 36.02525 , lng: -115.241942 },
  { name: 'Fallon, NV', path: '/trailer-rentals/nevada/fallon', lat: 39.474869 , lng: -118.777041 },
  { name: 'Fernley, NV', path: '/trailer-rentals/nevada/fernley', lat: 39.607968 , lng: -119.251835 },
  { name: 'Gabbs, NV', path: '/trailer-rentals/nevada/gabbs', lat: 38.869056 , lng: -117.922882 },
  { name: 'Gardnerville Ranchos, NV', path: '/trailer-rentals/nevada/gardnerville-ranchos', lat: 38.88824 , lng: -119.741287 },
  { name: 'Genoa, NV', path: '/trailer-rentals/nevada/genoa', lat: 39.004057 , lng: -119.847215 },
  { name: 'Goodsprings, NV', path: '/trailer-rentals/nevada/goodsprings', lat: 35.832477 , lng: -115.434166 },
  { name: 'Goldfield, NV', path: '/trailer-rentals/nevada/goldfield', lat: 37.708448 , lng: -117.235713 },
  { name: 'Hawthorne, NV', path: '/trailer-rentals/nevada/hawthorne', lat: 38.524644 , lng: -118.624578 },
  { name: 'Henderson, NV', path: '/trailer-rentals/nevada/henderson', lat: 36.039525 , lng: -114.981721, active: true, showNewVersion: true  },
  { name: 'Indian Hills, NV', path: '/trailer-rentals/nevada/indian-hills', lat: 39.08602 , lng: -119.784069 },
  { name: 'Indian Springs, NV', path: '/trailer-rentals/nevada/indian-springs', lat: 36.569683 , lng: -115.670576 },
  { name: 'Incline Village, NV', path: '/trailer-rentals/nevada/incline-village', lat: 39.249683 , lng: -119.952685 },
  { name: 'Imlay, NV', path: '/trailer-rentals/nevada/imlay', lat: 40.660739 , lng: -118.150421 },
  { name: 'Jackpot, NV', path: '/trailer-rentals/nevada/jackpot', lat: 41.98414 , lng: -114.672406 },
  { name: 'Lamoille, NV', path: '/trailer-rentals/nevada/lamoille', lat: 40.727987 , lng: -115.47842 },
  { name: 'Laughlin, NV', path: '/trailer-rentals/nevada/laughlin', lat: 35.167777 , lng: -114.573021 },
  { name: 'Las Vegas, NV', path: '/trailer-rentals/nevada/las-vegas', lat: 36.171563 , lng: -115.139101, active: true, showNewVersion: true },
  { name: 'Lovelock, NV', path: '/trailer-rentals/nevada/lovelock', lat: 40.179354 , lng: -118.473481 },
  { name: 'Luning, NV', path: '/trailer-rentals/nevada/luning', lat: 38.505418 , lng: -118.178505 },
  { name: 'Manhattan, NV', path: '/trailer-rentals/nevada/manhattan', lat: 38.538872 , lng: -117.07475 },
  { name: 'McGill, NV', path: '/trailer-rentals/nevada/mcGill', lat: 39.404938 , lng: -114.778628 },
  { name: 'Mercury, NV', path: '/trailer-rentals/nevada/mercury', lat: 36.660511 , lng: -115.994475 },
  { name: 'Mesquite, NV', path: '/trailer-rentals/nevada/mesquite', lat: 36.805531 , lng: -114.06719 },
  { name: 'Mina, NV', path: '/trailer-rentals/nevada/mina', lat: 38.390587 , lng: -118.108725 },
  { name: 'Moapa Valley, NV', path: '/trailer-rentals/nevada/moapa-valley', lat: 36.58053 , lng: -114.470259 },
  { name: 'Montello, NV', path: '/trailer-rentals/nevada/Montello', lat: 41.261313 , lng: -114.194183 },
  { name: 'Mount Charleston, NV', path: '/trailer-rentals/nevada/mount-charleston', lat: 36.257186 , lng: -115.642795 },
  { name: 'Nellis AFB, NV', path: '/trailer-rentals/nevada/nellis-afb', lat: 36.24063 , lng: -115.054276 },
  { name: 'North Las Vegas, NV', path: '/trailer-rentals/nevada/north-las-vegas', lat: 36.198859 , lng: -115.117501 },
  { name: 'Orovada, NV', path: '/trailer-rentals/nevada/orovada', lat: 41.57 , lng: -117.783889 },
  { name: 'Pahrump, NV', path: '/trailer-rentals/nevada/pahrump', lat: 36.208294 , lng: -115.983915 },
  { name: 'Panaca, NV', path: '/trailer-rentals/nevada/panaca', lat: 37.789106 , lng: -114.384653 },
  { name: 'Paradise, NV', path: '/trailer-rentals/nevada/paradise', lat: 36.097195 , lng: -115.146665, active: true, showNewVersion: true  },
  { name: 'Pioche, NV', path: '/trailer-rentals/nevada/pioche', lat: 37.929874 , lng: -114.45162 },
  { name: 'Reno, NV', path: '/trailer-rentals/nevada/reno', lat: 39.529919 , lng: -119.814269, active: true, showNewVersion: true},
  { name: 'Round Mountain, NV', path: '/trailer-rentals/nevada/round-mountain', lat: 38.711043 , lng: -117.067584 },
  { name: 'Ruth, NV', path: '/trailer-rentals/nevada/ruth', lat: 39.278219 , lng: -114.989438 },
  { name: 'Sandy Valley, NV', path: '/trailer-rentals/nevada/sandy-valley', lat: 35.816919 , lng: -115.632228 },
  { name: 'Searchlight, NV', path: '/trailer-rentals/nevada/searchlight', lat: 35.465269 , lng: -114.9197 },
  { name: 'Silver Peak, NV', path: '/trailer-rentals/nevada/silver-peak', lat: 37.75394 , lng: -117.635033 },
  { name: 'Silver Springs, NV', path: '/trailer-rentals/nevada/silver-springs', lat: 39.415471 , lng: -119.224607 },
  { name: 'Sloan, NV', path: '/trailer-rentals/nevada/sloan', lat: 35.943586 , lng: -115.217217 },
  { name: 'Sparks, NV', path: '/trailer-rentals/nevada/sparks', lat: 39.534911 , lng: -119.752689 },
  { name: 'Spring Creek, NV', path: '/trailer-rentals/nevada/spring-creek', lat: 40.726593 , lng: -115.585895 },
  { name: 'Spring Valley, NV', path: '/trailer-rentals/nevada/spring-valley', lat: 36.108026 , lng: -115.245001 },
  { name: 'Stateline, NV', path: '/trailer-rentals/nevada/stateline', lat: 38.962408 , lng: -119.939903 },
  { name: 'Summerlin South, NV', path: '/trailer-rentals/nevada/summerlin-south', lat: 36.134905 , lng: -115.328002 },
  { name: 'Sun Valley, NV', path: '/trailer-rentals/nevada/sun-valley', lat: 39.596299 , lng: -119.776023 },
  { name: 'Sunrise Manor, NV', path: '/trailer-rentals/nevada/sunrise-manor', lat: 36.211082 , lng: -115.073056 },
  { name: 'Tonopah, NV', path: '/trailer-rentals/nevada/tonopah', lat: 38.069211 , lng: -117.230586 },
  { name: 'Verdi, NV', path: '/trailer-rentals/nevada/verdi', lat: 39.518311 , lng: -119.988767 },
  { name: 'Virginia City, NV', path: '/trailer-rentals/nevada/virginia-city', lat: 39.309514 , lng: -119.649979 },
  { name: 'Wadsworth, NV', path: '/trailer-rentals/nevada/wadsworth', lat: 39.633801 , lng: -119.285448 },
  { name: 'Walker Lake, NV', path: '/trailer-rentals/nevada/walker-lake', lat: 38.648254 , lng: -118.755139 },
  { name: 'Warm Springs, NV', path: '/trailer-rentals/nevada/warm-springs', lat: 38.19049 , lng: -116.370054 },
  { name: 'Wellington, NV', path: '/trailer-rentals/nevada/wellington', lat: 38.793087 , lng: -119.388908 },
  { name: 'Wells, NV', path: '/trailer-rentals/nevada/wells', lat: 41.111589 , lng: -114.96449 },
  { name: 'West Washoe Valley, NV', path: '/trailer-rentals/nevada/west-washoe-valley', lat: 39.296329 , lng: -119.776122 },
  { name: 'West Wendover, NV', path: '/trailer-rentals/nevada/west-wendover', lat: 40.739097 , lng: -114.073345 },
  { name: 'Whitney, NV', path: '/trailer-rentals/nevada/whitney', lat: 36.09669 , lng: -115.040741 },
  { name: 'Winchester, NV', path: '/trailer-rentals/nevada/winchester', lat: 36.137026 , lng: -115.118582 },
  { name: 'Winnemucca, NV', path: '/trailer-rentals/nevada/winnemucca', lat: 40.972827 , lng: -117.735735 },
  { name: 'Yerington, NV', path: '/trailer-rentals/nevada/yerington', lat: 38.985751 , lng: -119.162931 },
  { name: 'Zephyr Cove, NV', path: '/trailer-rentals/nevada/zephyr-cove', lat: 39.000362 , lng: -119.952569 },
];

export const californiaCities: ICity[] = [
  { name: 'Azusa, CA', path: '/trailer-rentals/california/azusa', lat: 34.130657,lng: -117.906869, active: true, showNewVersion: true },
  { name: 'Alameda, CA', path: '/trailer-rentals/california/alameda', lat: 37.779872 , lng: -122.282185 },
  { name: 'Anaheim, CA', path: '/trailer-rentals/california/anaheim', lat:33.836593  , lng: -117.914301 },
  { name: 'Antioch, CA', path: '/trailer-rentals/california/antioch', lat:38.004921  , lng: -121.805789 },
  { name: 'Bakersfield, CA', path: '/trailer-rentals/california/bakersfield', lat: 35.373292 , lng: -119.018712 },
  { name: 'Berkeley, CA', path: '/trailer-rentals/california/berkeley', lat: 37.871523 , lng: -122.273042  },
  { name: 'Burbank, CA', path: '/trailer-rentals/california/burbank', lat:34.180839  , lng: -118.308966  },
  { name: 'Bloomington, CA', path: '/trailer-rentals/california/bloomington', lat: 34.068621 , lng: -117.396156, active: true, showNewVersion: true },
  { name: 'Carlsbad, CA', path: '/trailer-rentals/california/carlsbad', lat: 33.158093 , lng: -117.350594  },
  { name: 'Chula Vista, CA', path: '/trailer-rentals/california/chula-vista', lat: 32.640054  , lng: -117.084195  },
  { name: 'Clovis, CA', path: '/trailer-rentals/california/clovis', lat: 36.825228 , lng: -119.702919  },
  { name: 'Concord, CA', path: '/trailer-rentals/california/concord', lat: 37.977978 , lng: -122.031073  },
  { name: 'Chico, CA', path: '/trailer-rentals/california/chico', lat: 39.728494, lng: -121.837478, active: true, showNewVersion: true  },
  { name: 'Corona, CA', path: '/trailer-rentals/california/corona', lat: 33.875294 , lng: -117.566438  },
  { name: 'Castroville, CA', path: '/trailer-rentals/california/castroville', lat: 36.785197 , lng: -121.762497, active: true, showNewVersion: true },
  { name: 'Daly City, CA', path: '/trailer-rentals/california/daly-city', lat: 37.687924 , lng: -122.470208  },
  { name: 'Davis, CA', path: '/trailer-rentals/california/davis', lat: 38.544907  , lng: -121.740517  },
  { name: 'El Monte, CA', path: '/trailer-rentals/california/el-monte', lat: 34.068621  , lng: -118.027567 },
  { name: 'Elk Grove, CA', path: '/trailer-rentals/california/elk-grove', lat: 38.408799 , lng: -121.371618 },
  { name: 'Escondido, CA', path: '/trailer-rentals/california/escondido', lat: 33.119207 , lng: -117.086421 },
  { name: 'Fairfield, CA', path: '/trailer-rentals/california/fairfield', lat: 38.249196 , lng: -122.040515 },
  { name: 'Fontana, CA', path: '/trailer-rentals/california/fontana', lat: 34.092234 , lng: -117.435048, active: true, showNewVersion: true  },
  { name: 'Fremont, CA', path: '/trailer-rentals/california/fremont', lat: 37.54854 , lng: -121.988583 },
  { name: 'Fresno, CA', path: '/trailer-rentals/california/fresno', lat: 36.737798 , lng: -119.787125  },
  { name: 'Garden Grove, CA', path: '/trailer-rentals/california/garden-grove', lat: 33.774269 , lng: -117.937995 },
  { name: 'Glendale, CA', path: '/trailer-rentals/california/glendale', lat: 34.142508 , lng: -118.255075 },
  { name: 'Hawthorne, CA', path: '/trailer-rentals/california/hawthorne', lat: 33.916403 , lng: -118.352575 },
  { name: 'Hayward, CA', path: '/trailer-rentals/california/hayward', lat:37.668767 , lng: -122.080996 },
  { name: 'Huntington Beach, CA', path: '/trailer-rentals/california/huntington-beach', lat:33.659484  , lng: -117.998803 },
  { name: 'Indio, CA', path: '/trailer-rentals/california/indio', lat: 33.720577 , lng: -116.215562 },
  { name: 'Inglewood, CA', path: '/trailer-rentals/california/inglewood', lat: 33.96168 , lng: -118.353131  },
  { name: 'Irvine, CA', path: '/trailer-rentals/california/irvine', lat: 33.684567 , lng: -117.826505  },
  { name: 'Jurupa Valley, CA', path: '/trailer-rentals/california/jurupa-valley', lat: 33.994274 , lng: -117.497161  },
  { name: 'Lancaster, CA', path: '/trailer-rentals/california/lancaster', lat: 34.703947 , lng: -118.148102  },
  { name: 'Livermore, CA', path: '/trailer-rentals/california/livermore', lat: 37.681869 , lng: -121.768473  },
  { name: 'Long Beach, CA', path: '/trailer-rentals/california/long-beach', lat: 33.77005 , lng: -118.193739  },
  { name: 'Los Angeles, CA', path: '/trailer-rentals/california/los-angeles', lat: 34.054908 , lng: -118.242643,  active: true, showNewVersion: true },
  { name: 'Merced, CA', path: '/trailer-rentals/california/merced', lat: 37.302163 , lng: -120.482968  },
  { name: 'Mission Viejo, CA', path: '/trailer-rentals/california/mission-viejo', lat: 33.596891 , lng: -117.658156  },
  { name: 'Modesto, CA', path: '/trailer-rentals/california/modesto', lat: 37.63926 , lng: -120.997001 },
  { name: 'Moreno Valley, CA', path: '/trailer-rentals/california/moreno-valley', lat: 33.942466 , lng: -117.229672  },
  { name: 'Mountain View, CA', path: '/trailer-rentals/california/mountain-view', lat: 37.386052 , lng: -122.083851  },
  { name: 'Napa, CA', path: '/trailer-rentals/california/napa', lat: 38.297538 , lng: -122.286865  },
  { name: 'Newport Beach, CA', path: '/trailer-rentals/california/newport-beach', lat: 33.618883 , lng: -117.929849  },
  { name: 'Norwalk, CA', path: '/trailer-rentals/california/norwalk', lat: 33.902237 , lng: -118.081733  },
  { name: 'Oakland, CA', path: '/trailer-rentals/california/oakland', lat: 37.804351 , lng: -122.271164  },
  { name: 'Oceanside, CA', path: '/trailer-rentals/california/oceanside', lat: 33.19587 , lng: -117.379483  },
  { name: 'Ontario, CA', path: '/trailer-rentals/california/ontario', lat: 34.063344 , lng: -117.650888  },
  { name: 'Oxnard, CA', path: '/trailer-rentals/california/oxnard', lat: 34.197505 , lng: -119.177052  },
  { name: 'Palmdale, CA', path: '/trailer-rentals/california/palmdale', lat: 34.579434 , lng: -118.116461  },
  { name: 'Pasadena, CA', path: '/trailer-rentals/california/pasadena', lat: 34.147785 , lng: -118.144515  },
  { name: 'Pomona, CA', path: '/trailer-rentals/california/pomona', lat: 34.055103 , lng: -117.749991  },
  { name: 'Rancho Cucamonga, CA', path: '/trailer-rentals/california/rancho-cucamonga', lat: 34.106399 , lng: -117.593108  },
  { name: 'Redding, CA', path: '/trailer-rentals/california/redding', lat: 40.58654 , lng: -122.391675  },
  { name: 'Redwood City, CA', path: '/trailer-rentals/california/redwood-city', lat: 37.484796 , lng: -122.228141  },
  { name: 'Richmond, CA', path: '/trailer-rentals/california/richmond', lat: 37.935758 , lng: -122.347749  },
  { name: 'Riverside, CA', path: '/trailer-rentals/california/riverside', lat:33.980601 , lng: -117.375494 },
  { name: 'Roseville, CA', path: '/trailer-rentals/california/roseville', lat:38.752124  , lng: -121.288006  },
  { name: 'Sacramento, CA', path: '/trailer-rentals/california/sacramento', lat: 38.581572 , lng: -121.4944, active: true, showNewVersion: true },
  { name: 'Salinas, CA', path: '/trailer-rentals/california/salinas', lat: 36.677737 , lng: -121.655501, active: true, showNewVersion: true  },
  { name: 'San Bernardino, CA', path: '/trailer-rentals/california/san-bernardino', lat: 34.108345 , lng: -117.289765  },
  { name: 'San Diego, CA', path: '/trailer-rentals/california/san-diego', lat: 32.715738 , lng: -117.161084  },
  { name: 'San Francisco, CA', path: '/trailer-rentals/california/san-francisco', lat: 37.77493 , lng: -122.419415  },
  { name: 'San Jose, CA', path: '/trailer-rentals/california/san-jose', lat: 37.33874 , lng: -121.885252, active: true, showNewVersion: true },
  { name: 'San Leandro, CA', path: '/trailer-rentals/california/san-leandro', lat: 37.725766 , lng: -122.156855  },
  { name: 'San Marcos, CA', path: '/trailer-rentals/california/san-marcos', lat: 33.143372 , lng: -117.166145  },
  { name: 'San Mateo, CA', path: '/trailer-rentals/california/san-mateo', lat: 37.562992 , lng: -122.325525  },
  { name: 'Santa Ana, CA', path: '/trailer-rentals/california/santa-ana', lat: 33.745473 , lng: -117.867653  },
  { name: 'Santa Barbara, CA', path: '/trailer-rentals/california/santa-barbara', lat: 34.420831 , lng: -119.69819  },
  { name: 'Santa Clara, CA', path: '/trailer-rentals/california/santa-clara', lat: 37.354108 , lng: -121.955236  },
  { name: 'Santa Clarita, CA', path: '/trailer-rentals/california/santa-clarita', lat: 34.391664 , lng: -118.542586  },
  { name: 'Simi Valley, CA', path: '/trailer-rentals/california/simi-valley', lat: 34.269447 , lng: -118.781482, active: true, showNewVersion: true },
  { name: 'South Gate, CA', path: '/trailer-rentals/california/south-gate', lat: 33.954737 , lng: -118.212016  },
  { name: 'Stockton, CA', path: '/trailer-rentals/california/stockton', lat: 37.957702 , lng: -121.29078  },
  { name: 'Sunnyvale, CA', path: '/trailer-rentals/california/sunnyvale', lat: 37.36883 , lng: -122.03635  },
  { name: 'Temecula, CA', path: '/trailer-rentals/california/temecula', lat: 33.493424 , lng: -117.148816  },
  { name: 'Thousand Oaks, CA', path: '/trailer-rentals/california/thousand-oaks', lat: 34.176113  , lng: -118.848779 },
  { name: 'Torrance, CA', path: '/trailer-rentals/california/torrance', lat: 33.835849 , lng: -118.340629 },
  { name: 'Turlock, CA', path: '/trailer-rentals/california/turlock', lat: 37.494656 , lng: -120.846594, active: true, showNewVersion: true },
  { name: 'Vacaville, CA', path: '/trailer-rentals/california/vacaville', lat: 38.356577 , lng: -121.987744 },
  { name: 'Vallejo, CA', path: '/trailer-rentals/california/vallejo', lat: 38.104086  , lng: -122.256637  },
  { name: 'Ventura, CA', path: '/trailer-rentals/california/ventura', lat: 34.280492 , lng: -119.29452  },
  { name: 'Visalia, CA', path: '/trailer-rentals/california/visalia', lat: 36.330133 , lng: -119.296637  },
  { name: 'Whittier, CA', path: '/trailer-rentals/california/whittier', lat: 33.9754, lng: -118.0327, active: true, showNewVersion: true}
];

export const maineCities: ICity[] = [
  { name: 'Biddeford, ME', path: '/trailer-rentals/maine/biddeford', lat:43.492584  , lng: -70.453384  },
  { name: 'Kennebunk, ME', path: '/trailer-rentals/maine/kennebunk', lat: 43.384531 , lng: -70.54399 },
  { name: 'Kittery, ME', path: '/trailer-rentals/maine/kittery', lat: 43.088126 , lng: -70.736137 },
  { name: 'Portland, ME', path: '/trailer-rentals/maine/portland', lat: 43.659099 , lng: -70.256819 },
  { name: 'Saco, ME', path: '/trailer-rentals/maine/saco', lat: 43.500918 , lng: -70.442829 },
  { name: 'Sanford, ME', path: '/trailer-rentals/maine/sanford', lat: 43.439163 , lng: -70.77428  },
  { name: 'South Portland, ME', path: '/trailer-rentals/maine/south-portland', lat:43.641472  , lng: -70.240881 },
  { name: 'Wells, ME', path: '/trailer-rentals/maine/wells', lat: 43.322233  , lng: -70.580521  },
  { name: 'Westbrook, ME', path: '/trailer-rentals/maine/westbrook', lat:43.677025  , lng: -70.371162  },
  { name: 'York, ME', path: '/trailer-rentals/maine/york', lat: 43.161748 , lng: -70.648258  },
  { name: 'Auburn, ME', path: '/trailer-rentals/maine/auburn', lat: 44.097850, lng: -70.231165 },
  { name: 'Augusta, ME', path: '/trailer-rentals/maine/augusta', lat: 44.310624, lng: -69.779490 },
  { name: 'Bangor, ME', path: '/trailer-rentals/maine/bangor', lat: 44.801182, lng: -68.777813 },
  { name: 'Bar Harbor, ME', path: '/trailer-rentals/maine/bar-harbor', lat: 44.387611, lng: -68.203912 },
  { name: 'Bath, ME', path: '/trailer-rentals/maine/bath', lat: 43.910635, lng: -69.820602 },
  { name: 'Belfast, ME', path: '/trailer-rentals/maine/belfast', lat: 44.425908, lng: -69.006424 },
  { name: 'Berwick, ME', path: '/trailer-rentals/maine/berwick', lat: 43.271468, lng: -70.860062 },
  { name: 'Bethel, ME', path: '/trailer-rentals/maine/bethel', lat: 44.404231, lng: -70.790067 },
  { name: 'Boothbay Harbor, ME', path: '/trailer-rentals/maine/boothbay-harbor', lat: 43.850080, lng: -69.628656 },
  { name: 'Bridgton, ME', path: '/trailer-rentals/maine/bridgton', lat: 44.054238, lng: -70.712842 },
  { name: 'Brunswick, ME', path: '/trailer-rentals/maine/brunswick', lat: 43.914017, lng: -69.965325 },
  { name: 'Buckfield, ME', path: '/trailer-rentals/maine/buckfield', lat: 44.287299, lng: -70.368396 },
  { name: 'Calais, ME', path: '/trailer-rentals/maine/calais', lat: 45.188410, lng: -67.278333 },
  { name: 'Camden, ME', path: '/trailer-rentals/maine/camden', lat: 44.209792, lng: -69.064783 },
  { name: 'Cape Elizabeth, ME', path: '/trailer-rentals/maine/cape-elizabeth', lat: 43.563434, lng: -70.200276 },
  { name: 'Castine, ME', path: '/trailer-rentals/maine/castine', lat: 44.388692, lng: -68.798908 },
  { name: 'China, ME', path: '/trailer-rentals/maine/china', lat: 44.477298, lng: -69.522268 },
  { name: 'Corinna, ME', path: '/trailer-rentals/maine/corinna', lat: 44.919236, lng: -69.263659 },
  { name: 'Corinth, ME', path: '/trailer-rentals/maine/corinth', lat: 44.991738, lng: -69.007822 },
  { name: 'Cumberland, ME', path: '/trailer-rentals/maine/cumberland', lat: 43.797019, lng: -70.253388 },
  { name: 'Damariscotta, ME', path: '/trailer-rentals/maine/damariscotta', lat: 44.034528, lng: -69.518656 },
  { name: 'Dexter, ME', path: '/trailer-rentals/maine/dexter', lat: 45.026741, lng: -69.289217 },
  { name: 'Dover-Foxcroft, ME', path: '/trailer-rentals/maine/dover-foxcroft', lat: 45.183398, lng: -69.225328 },
  { name: 'Ellsworth, ME', path: '/trailer-rentals/maine/ellsworth', lat: 44.543409, lng: -68.419464 },
  { name: 'Falmouth, ME', path: '/trailer-rentals/maine/falmouth', lat: 43.722027, lng: -70.238106 },
  { name: 'Fairfield, ME', path: '/trailer-rentals/maine/fairfield', lat: 44.588398, lng: -69.598106 },
  { name: 'Farmington, ME', path: '/trailer-rentals/maine/farmington', lat: 44.670342, lng: -70.151453 },
  { name: 'Fort Fairfield, ME', path: '/trailer-rentals/maine/fort-fairfield', lat: 46.772276, lng: -67.833106 },
  { name: 'Fort Kent, ME', path: '/trailer-rentals/maine/fort-kent', lat: 47.258398, lng: -68.589106 },
  { name: 'Freeport, ME', path: '/trailer-rentals/maine/freeport', lat: 43.857398, lng: -70.101106 },
  { name: 'Fryeburg, ME', path: '/trailer-rentals/maine/fryeburg', lat: 44.016398, lng: -70.978106 },
  { name: 'Gardiner, ME', path: '/trailer-rentals/maine/gardiner', lat: 44.230398, lng: -69.775106 },
  { name: 'Gorham, ME', path: '/trailer-rentals/maine/gorham', lat: 43.679398, lng: -70.444106 },
  { name: 'Gray, ME', path: '/trailer-rentals/maine/gray', lat: 43.885398, lng: -70.330106 },
  { name: 'Greenville, ME', path: '/trailer-rentals/maine/greenville', lat: 45.459398, lng: -69.590106 },
  { name: 'Hallowell, ME', path: '/trailer-rentals/maine/hallowell', lat: 44.285398, lng: -69.790106 },
  { name: 'Hampden, ME', path: '/trailer-rentals/maine/hampden', lat: 44.744398, lng: -68.835106 },
  { name: 'Hermon, ME', path: '/trailer-rentals/maine/hermon', lat: 44.811398, lng: -68.911106 },
  { name: 'Houlton, ME', path: '/trailer-rentals/maine/houlton', lat: 46.125398, lng: -67.839106 },
  { name: 'Jay, ME', path: '/trailer-rentals/maine/jay', lat: 44.506398, lng: -70.208106 },
  { name: 'Kingfield, ME', path: '/trailer-rentals/maine/kingfield', lat: 44.959398, lng: -70.157106 },
  { name: 'Lewiston, ME', path: '/trailer-rentals/maine/lewiston', lat: 44.100398, lng: -70.214106 },
  { name: 'Liberty, ME', path: '/trailer-rentals/maine/liberty', lat: 44.366398, lng: -69.391106 },
  { name: 'Lincoln, ME', path: '/trailer-rentals/maine/lincoln', lat: 45.362398, lng: -68.504106 },
  { name: 'Lisbon, ME', path: '/trailer-rentals/maine/lisbon', lat: 44.031398, lng: -70.104106 },
  { name: 'Livermore Falls, ME', path: '/trailer-rentals/maine/livermore-falls', lat: 44.474398, lng: -70.187106 },
  { name: 'Machias, ME', path: '/trailer-rentals/maine/machias', lat: 44.715398, lng: -67.461106 },
  { name: 'Madison, ME', path: '/trailer-rentals/maine/madison', lat: 44.795398, lng: -69.879106 },
  { name: 'Millinocket, ME', path: '/trailer-rentals/maine/millinocket', lat: 45.657398, lng: -68.709106 },
  { name: 'Monmouth, ME', path: '/trailer-rentals/maine/monmouth', lat: 44.236398, lng: -70.037106 },
  { name: 'Naples, ME', path: '/trailer-rentals/maine/naples', lat: 43.973398, lng: -70.609106 },
  { name: 'Newcastle, ME', path: '/trailer-rentals/maine/newcastle', lat: 44.034398, lng: -69.533106 },
  { name: 'North Berwick, ME', path: '/trailer-rentals/maine/north-berwick', lat: 43.303398, lng: -70.734106 },
  { name: 'Norway, ME', path: '/trailer-rentals/maine/norway', lat: 44.212398, lng: -70.545106 },
  { name: 'Oakland, ME', path: '/trailer-rentals/maine/oakland', lat: 44.543398, lng: -69.721106 },
  { name: 'Old Orchard Beach, ME', path: '/trailer-rentals/maine/old-orchard-beach', lat: 43.517398, lng: -70.377106 },
  { name: 'Old Town, ME', path: '/trailer-rentals/maine/old-town', lat: 44.934398, lng: -68.646106 },
  { name: 'Orono, ME', path: '/trailer-rentals/maine/orono', lat: 44.883398, lng: -68.672106 },
  { name: 'Oxford, ME', path: '/trailer-rentals/maine/oxford', lat: 44.131398, lng: -70.493106 },
  { name: 'Paris, ME', path: '/trailer-rentals/maine/paris', lat: 44.258398, lng: -70.512106 },
  { name: 'Pittsfield, ME', path: '/trailer-rentals/maine/pittsfield', lat: 44.782398, lng: -69.379106 },
  { name: 'Poland, ME', path: '/trailer-rentals/maine/poland', lat: 44.060398, lng: -70.391106 },
  { name: 'Presque Isle, ME', path: '/trailer-rentals/maine/presque-isle', lat: 46.681398, lng: -68.016106 },
  { name: 'Rangeley, ME', path: '/trailer-rentals/maine/rangeley', lat: 44.966398, lng: -70.644106 },
  { name: 'Rockland, ME', path: '/trailer-rentals/maine/rockland', lat: 44.103398, lng: -69.108106 },
  { name: 'Rockport, ME', path: '/trailer-rentals/maine/rockport', lat: 44.187398, lng: -69.071106 },
  { name: 'Rumford, ME', path: '/trailer-rentals/maine/rumford', lat: 44.551398, lng: -70.544106 },
  { name: 'Scarborough, ME', path: '/trailer-rentals/maine/scarborough', lat: 43.578398, lng: -70.321106 },
  { name: 'Skowhegan, ME', path: '/trailer-rentals/maine/skowhegan', lat: 44.765398, lng: -69.719106 },
  { name: 'South Berwick, ME', path: '/trailer-rentals/maine/south-berwick', lat: 43.235398, lng: -70.808106 },
  { name: 'South Paris, ME', path: '/trailer-rentals/maine/south-paris', lat: 44.224398, lng: -70.514106 },
  { name: 'Stonington, ME', path: '/trailer-rentals/maine/stonington', lat: 44.156398, lng: -68.665106 },
  { name: 'Topsham, ME', path: '/trailer-rentals/maine/topsham', lat: 43.927398, lng: -69.975106 },
  { name: 'Turner, ME', path: '/trailer-rentals/maine/turner', lat: 44.257398, lng: -70.256106 },
  { name: 'Van Buren, ME', path: '/trailer-rentals/maine/van-buren', lat: 47.154398, lng: -67.944106 },
  { name: 'Vassalboro, ME', path: '/trailer-rentals/maine/vassalboro', lat: 44.451398, lng: -69.674106 },
  { name: 'Waldoboro, ME', path: '/trailer-rentals/maine/waldoboro', lat: 44.097398, lng: -69.375106 },
  { name: 'Waterville, ME', path: '/trailer-rentals/maine/waterville', lat: 44.552398, lng: -69.631106 },
  { name: 'Wilton, ME', path: '/trailer-rentals/maine/wilton', lat: 44.592398, lng: -70.229106 },
  { name: 'Windham, ME', path: '/trailer-rentals/maine/windham', lat: 43.800398, lng: -70.407106 },
  { name: 'Winslow, ME', path: '/trailer-rentals/maine/winslow', lat: 44.547398, lng: -69.608106 },
  { name: 'Winthrop, ME', path: '/trailer-rentals/maine/winthrop', lat: 44.309398, lng: -69.973106 },
  { name: 'Yarmouth, ME', path: '/trailer-rentals/maine/yarmouth', lat: 43.800398, lng: -70.186106 },
];

export const massachusettsCities: ICity[] = [
  { name: 'Amesbury, MA', path: '/trailer-rentals/massachusetts/amesbury', lat:42.858393  , lng: -70.930038 },
  { name: 'Andover, MA', path: '/trailer-rentals/massachusetts/andover', lat: 42.658336 , lng: -71.136795 },
  { name: 'Beverly, MA', path: '/trailer-rentals/massachusetts/beverly', lat: 42.558428 , lng: -70.880049 },
  { name: 'Chelmsford, MA', path: '/trailer-rentals/massachusetts/chelmsford', lat: 42.599814 , lng: -71.367284 },
  { name: 'Danvers, MA', path: '/trailer-rentals/massachusetts/danvers', lat: 42.575001 , lng: -70.932122 },
  { name: 'Georgetown, MA', path: '/trailer-rentals/massachusetts/georgetown', lat: 42.725092 , lng: -70.991166  },
  { name: 'Gloucester, MA', path: '/trailer-rentals/massachusetts/gloucester', lat:42.615929  , lng: -70.661989 },
  { name: 'Haverhill, MA', path: '/trailer-rentals/massachusetts/haverhill', lat: 42.776202  , lng: -71.07728  },
  { name: 'Lawrence, MA', path: '/trailer-rentals/massachusetts/lawrence', lat:42.707035  , lng: -71.163114  },
  { name: 'Lynn, MA', path: '/trailer-rentals/massachusetts/lynn', lat: 42.466763 , lng: -70.949494  },
  { name: 'Methuen, MA', path: '/trailer-rentals/massachusetts/methuen', lat: 42.726202 , lng: -71.190892  },
  { name: 'Newburyport, MA', path: '/trailer-rentals/massachusetts/newburyport', lat: 42.812591 , lng: -70.877275  },
  { name: 'North Andover, MA', path: '/trailer-rentals/massachusetts/north-andover', lat: 42.698702 , lng: -71.135057  },
  { name: 'Peabody, MA', path: '/trailer-rentals/massachusetts/peabody', lat: 42.527873 , lng: -70.928661  },
  { name: 'Salem, MA', path: '/trailer-rentals/massachusetts/salem', lat: 42.519747 , lng: -70.895463  },
  { name: 'Salisbury, MA', path: '/trailer-rentals/massachusetts/salisbury', lat: 42.841723 , lng: -70.860598  },
  { name: 'Tewksbury, MA', path: '/trailer-rentals/massachusetts/tewksbury', lat: 42.610648 , lng: -71.234225  },
  { name: 'Wakefield, MA', path: '/trailer-rentals/massachusetts/wakefield', lat: 42.501831 , lng: -71.075049  },
  { name: 'West Newbury, MA', path: '/trailer-rentals/massachusetts/west-newbury', lat: 42.80148 , lng: -70.989778  },
  { name: 'Wilmington, MA', path: '/trailer-rentals/massachusetts/wilmington', lat: 42.548171 , lng: -71.172447, active: true, showNewVersion: true  },
  { name: 'Abington, MA', path: '/trailer-rentals/massachusetts/abington', lat: 42.1045, lng: -70.9453 },
  { name: 'Acton, MA', path: '/trailer-rentals/massachusetts/acton', lat: 42.4851, lng: -71.4328 },
  { name: 'Agawam, MA', path: '/trailer-rentals/massachusetts/agawam', lat: 42.0695, lng: -72.6143 },
  { name: 'Amherst, MA', path: '/trailer-rentals/massachusetts/amherst', lat: 42.3732, lng: -72.5199 },
  { name: 'Arlington, MA', path: '/trailer-rentals/massachusetts/arlington', lat: 42.4154, lng: -71.1564 },
  { name: 'Ashland, MA', path: '/trailer-rentals/massachusetts/ashland', lat: 42.2612, lng: -71.4637 },
  { name: 'Attleboro, MA', path: '/trailer-rentals/massachusetts/attleboro', lat: 41.9445, lng: -71.2856 },
  { name: 'Barnstable, MA', path: '/trailer-rentals/massachusetts/barnstable', lat: 41.7003, lng: -70.3002 },
  { name: 'Bedford, MA', path: '/trailer-rentals/massachusetts/bedford', lat: 42.4918, lng: -71.2765 },
  { name: 'Belchertown, MA', path: '/trailer-rentals/massachusetts/belchertown', lat: 42.2776, lng: -72.4101 },
  { name: 'Bellingham, MA', path: '/trailer-rentals/massachusetts/bellingham', lat: 42.0987, lng: -71.4745 },
  { name: 'Belmont, MA', path: '/trailer-rentals/massachusetts/belmont', lat: 42.3956, lng: -71.1787 },
  { name: 'Billerica, MA', path: '/trailer-rentals/massachusetts/billerica', lat: 42.5584, lng: -71.2689 },
  { name: 'Boston, MA', path: '/trailer-rentals/massachusetts/boston', lat: 42.3601, lng: -71.0589 },
  { name: 'Braintree, MA', path: '/trailer-rentals/massachusetts/braintree', lat: 42.207, lng: -71.0014 },
  { name: 'Bridgewater, MA', path: '/trailer-rentals/massachusetts/bridgewater', lat: 41.9904, lng: -70.9754 },
  { name: 'Brockton, MA', path: '/trailer-rentals/massachusetts/brockton', lat: 42.0834, lng: -71.0184 },
  { name: 'Brookline, MA', path: '/trailer-rentals/massachusetts/brookline', lat: 42.3318, lng: -71.1212 },
  { name: 'Burlington, MA', path: '/trailer-rentals/massachusetts/burlington', lat: 42.5048, lng: -71.1956 },
  { name: 'Cambridge, MA', path: '/trailer-rentals/massachusetts/cambridge', lat: 42.3736, lng: -71.1097 },
  { name: 'Canton, MA', path: '/trailer-rentals/massachusetts/canton', lat: 42.1584, lng: -71.1448 },
  { name: 'Charlton, MA', path: '/trailer-rentals/massachusetts/charlton', lat: 42.1334, lng: -71.9695 },
  { name: 'Chelsea, MA', path: '/trailer-rentals/massachusetts/chelsea', lat: 42.3918, lng: -71.0328 },
  { name: 'Chicopee, MA', path: '/trailer-rentals/massachusetts/chicopee', lat: 42.1487, lng: -72.6079 },
  { name: 'Clinton, MA', path: '/trailer-rentals/massachusetts/clinton', lat: 42.4168, lng: -71.6828 },
  { name: 'Dartmouth, MA', path: '/trailer-rentals/massachusetts/dartmouth', lat: 41.6134, lng: -70.9708 },
  { name: 'Dedham, MA', path: '/trailer-rentals/massachusetts/dedham', lat: 42.2434, lng: -71.165 },
  { name: 'Dracut, MA', path: '/trailer-rentals/massachusetts/dracut', lat: 42.6709, lng: -71.3028 },
  { name: 'East Longmeadow, MA', path: '/trailer-rentals/massachusetts/east-longmeadow', lat: 42.0645, lng: -72.5123 },
  { name: 'Easthampton, MA', path: '/trailer-rentals/massachusetts/easthampton', lat: 42.2668, lng: -72.6684 },
  { name: 'Everett, MA', path: '/trailer-rentals/massachusetts/everett', lat: 42.4084, lng: -71.0537 },
  { name: 'Fairhaven, MA', path: '/trailer-rentals/massachusetts/fairhaven', lat: 41.6376, lng: -70.9034 },
  { name: 'Fall River, MA', path: '/trailer-rentals/massachusetts/fall-river', lat: 41.7015, lng: -71.155 },
  { name: 'Fitchburg, MA', path: '/trailer-rentals/massachusetts/fitchburg', lat: 42.5834, lng: -71.8023 },
  { name: 'Framingham, MA', path: '/trailer-rentals/massachusetts/framingham', lat: 42.2793, lng: -71.4162 },
  { name: 'Franklin, MA', path: '/trailer-rentals/massachusetts/franklin', lat: 42.0834, lng: -71.3967 },
  { name: 'Gardner, MA', path: '/trailer-rentals/massachusetts/gardner', lat: 42.5751, lng: -71.9981 },
  { name: 'Greenfield, MA', path: '/trailer-rentals/massachusetts/greenfield', lat: 42.5876, lng: -72.5995 },
  { name: 'Holyoke, MA', path: '/trailer-rentals/massachusetts/holyoke', lat: 42.2043, lng: -72.6162 },
  { name: 'Hopkinton, MA', path: '/trailer-rentals/massachusetts/hopkinton', lat: 42.2287, lng: -71.5223 },
  { name: 'Ipswich, MA', path: '/trailer-rentals/massachusetts/ipswich', lat: 42.6793, lng: -70.8412 },
  { name: 'Leominster, MA', path: '/trailer-rentals/massachusetts/leominster', lat: 42.5251, lng: -71.7598 },
  { name: 'Lexington, MA', path: '/trailer-rentals/massachusetts/lexington', lat: 42.4473, lng: -71.2245 },
  { name: 'Lowell, MA', path: '/trailer-rentals/massachusetts/lowell', lat: 42.6334, lng: -71.3162 },
  { name: 'Ludlow, MA', path: '/trailer-rentals/massachusetts/ludlow', lat: 42.1601, lng: -72.4756 },
  { name: 'Malden, MA', path: '/trailer-rentals/massachusetts/malden', lat: 42.4251, lng: -71.0662 },
  { name: 'Mansfield, MA', path: '/trailer-rentals/massachusetts/mansfield', lat: 42.0334, lng: -71.2184 },
  { name: 'Marblehead, MA', path: '/trailer-rentals/massachusetts/marblehead', lat: 42.5001, lng: -70.8578 },
  { name: 'Marlborough, MA', path: '/trailer-rentals/massachusetts/marlborough', lat: 42.3451, lng: -71.5523 },
  { name: 'Marshfield, MA', path: '/trailer-rentals/massachusetts/marshfield', lat: 42.0918, lng: -70.7056 },
  { name: 'Medford, MA', path: '/trailer-rentals/massachusetts/medford', lat: 42.4184, lng: -71.1062 },
  { name: 'Melrose, MA', path: '/trailer-rentals/massachusetts/melrose', lat: 42.4587, lng: -71.0662 },
  { name: 'Middleborough, MA', path: '/trailer-rentals/massachusetts/middleborough', lat: 41.8934, lng: -70.9112 },
  { name: 'Milford, MA', path: '/trailer-rentals/massachusetts/milford', lat: 42.1395, lng: -71.5162 },
  { name: 'Milton, MA', path: '/trailer-rentals/massachusetts/milton', lat: 42.2495, lng: -71.0662 },
  { name: 'Natick, MA', path: '/trailer-rentals/massachusetts/natick', lat: 42.2834, lng: -71.3495 },
  { name: 'Needham, MA', path: '/trailer-rentals/massachusetts/needham', lat: 42.2834, lng: -71.2334 },
  { name: 'New Bedford, MA', path: '/trailer-rentals/massachusetts/new-bedford', lat: 41.6362, lng: -70.9342 },
  { name: 'Newton, MA', path: '/trailer-rentals/massachusetts/newton', lat: 42.337, lng: -71.2092 },
  { name: 'Northampton, MA', path: '/trailer-rentals/massachusetts/northampton', lat: 42.3251, lng: -72.6412 },
  { name: 'Norwood, MA', path: '/trailer-rentals/massachusetts/norwood', lat: 42.1945, lng: -71.1995 },
  { name: 'Pittsfield, MA', path: '/trailer-rentals/massachusetts/pittsfield', lat: 42.4501, lng: -73.2454 },
  { name: 'Plymouth, MA', path: '/trailer-rentals/massachusetts/plymouth', lat: 41.9584, lng: -70.6678 },
  { name: 'Quincy, MA', path: '/trailer-rentals/massachusetts/quincy', lat: 42.2529, lng: -71.0023 },
  { name: 'Randolph, MA', path: '/trailer-rentals/massachusetts/randolph', lat: 42.1626, lng: -71.0412 },
  { name: 'Reading, MA', path: '/trailer-rentals/massachusetts/reading', lat: 42.5251, lng: -71.0956 },
  { name: 'Revere, MA', path: '/trailer-rentals/massachusetts/revere', lat: 42.4084, lng: -71.0112 },
  { name: 'Saugus, MA', path: '/trailer-rentals/massachusetts/saugus', lat: 42.4645, lng: -71.0101 },
];

export const newHampshireCities: ICity[] = [
  { name: 'Amherst, NH', path: '/trailer-rentals/new-hampshire/amherst', lat: 42.861475 , lng: -71.625349 },
  { name: 'Bedford, NH', path: '/trailer-rentals/new-hampshire/bedford', lat: 42.946329 , lng: -71.513201 },
  { name: 'Brentwood, NH', path: '/trailer-rentals/new-hampshire/brentwood', lat: 42.978025 , lng: -71.07355, active: true, showNewVersion: true },
  { name: 'Concord, NH', path: '/trailer-rentals/new-hampshire/concord', lat: 43.208137 , lng: -71.537572 },
  { name: 'Derry, NH', path: '/trailer-rentals/new-hampshire/derry', lat: 42.880644 , lng: -71.327335 },
  { name: 'Dover, NH', path: '/trailer-rentals/new-hampshire/dover', lat: 43.197862 , lng: -70.87367 },
  { name: 'Durham, NH', path: '/trailer-rentals/new-hampshire/durham', lat: 43.133955 , lng: -70.926439 },
  { name: 'Epping, NH', path: '/trailer-rentals/new-hampshire/epping', lat: 43.053484 , lng: -71.073548 },
  { name: 'Exeter, NH', path: '/trailer-rentals/new-hampshire/exeter', lat: 42.981429 , lng: -70.947755 },
  { name: 'Goffstown, NH', path: '/trailer-rentals/new-hampshire/goffstown', lat: 43.020405 , lng: -71.600222 },
  { name: 'Hudson, NH', path: '/trailer-rentals/new-hampshire/hudson', lat: 42.764776 , lng: -71.439807 },
  { name: 'Kingston, NH', path: '/trailer-rentals/new-hampshire/kingston', lat: 42.936477 , lng: -71.053392 },
  { name: 'Laconia, NH', path: '/trailer-rentals/new-hampshire/laconia', lat: 43.527855 , lng: -71.470351 },
  { name: 'Londonderry, NH', path: '/trailer-rentals/new-hampshire/londonderry', lat: 42.86513 , lng: -71.373927 },
  { name: 'Manchester, NH', path: '/trailer-rentals/new-hampshire/manchester', lat: 42.99564 , lng: -71.454789, active: true, showNewVersion: true },
  { name: 'Merrimack, NH', path: '/trailer-rentals/new-hampshire/merrimack', lat: 42.867869 , lng: -71.494832 },
  { name: 'Milford, NH', path: '/trailer-rentals/new-hampshire/milford', lat: 42.835362 , lng: -71.648984 },
  { name: 'Milton, NH', path: '/trailer-rentals/new-hampshire/milton', lat: 43.444095 , lng: -71.0092 },
  { name: 'Nashua, NH', path: '/trailer-rentals/new-hampshire/nashua', lat: 42.765366 , lng: -71.467566 },
  { name: 'Newmarket, NH', path: '/trailer-rentals/new-hampshire/newmarket', lat: 43.082855 , lng: -70.935038 },
  { name: 'North Hampton, NH', path: '/trailer-rentals/new-hampshire/north-hampton', lat: 42.973762 , lng: -70.835179 },
  { name: 'Pelham, NH', path: '/trailer-rentals/new-hampshire/pelham', lat: 42.734534 , lng: -71.324507 },
  { name: 'Plaistow, NH', path: '/trailer-rentals/new-hampshire/plaistow', lat: 42.836478 , lng: -71.09478 },
  { name: 'Portsmouth, NH', path: '/trailer-rentals/new-hampshire/portsmouth', lat: 43.071755 , lng: -70.762553 },
  { name: 'Raymond, NH', path: '/trailer-rentals/new-hampshire/raymond', lat: 43.036209 , lng: -71.183386 },
  { name: 'Rochester, NH', path: '/trailer-rentals/new-hampshire/rochester', lat: 43.304526 , lng: -70.975619 },
  { name: 'Salem, NH', path: '/trailer-rentals/new-hampshire/salem', lat: 42.788555 , lng: -71.200891 },
  { name: 'Seabrook, NH', path: '/trailer-rentals/new-hampshire/seabrook', lat: 42.890962 , lng: -70.865507 },
  { name: 'Somersworth, NH', path: '/trailer-rentals/new-hampshire/somersworth', lat: 43.26175 , lng: -70.865337 },
  { name: 'Stratham, NH', path: '/trailer-rentals/new-hampshire/stratham', lat: 43.022868 , lng: -70.916387 },
  { name: 'Windham, NH', path: '/trailer-rentals/new-hampshire/windham', lat: 42.800644 , lng: -71.304229 },
  { name: 'Atkinson, NH', path: '/trailer-rentals/new-hampshire/atkinson', lat: 42.837037, lng: -71.161168 },
  { name: 'Barrington, NH', path: '/trailer-rentals/new-hampshire/barrington', lat: 43.220634, lng: -71.049225 },
  { name: 'Belmont, NH', path: '/trailer-rentals/new-hampshire/belmont', lat: 43.444525, lng: -71.477295 },
  { name: 'Berlin, NH', path: '/trailer-rentals/new-hampshire/berlin', lat: 44.46836, lng: -71.185077 },
  { name: 'Bow, NH', path: '/trailer-rentals/new-hampshire/bow', lat: 43.131747, lng: -71.537295 },
  { name: 'Bristol, NH', path: '/trailer-rentals/new-hampshire/bristol', lat: 43.591186, lng: -71.736195 },
  { name: 'Brookline, NH', path: '/trailer-rentals/new-hampshire/brookline', lat: 42.734256, lng: -71.656195 },
  { name: 'Candia, NH', path: '/trailer-rentals/new-hampshire/candia', lat: 43.069525, lng: -71.314225 },
  { name: 'Chester, NH', path: '/trailer-rentals/new-hampshire/chester', lat: 42.957037, lng: -71.253168 },
  { name: 'Claremont, NH', path: '/trailer-rentals/new-hampshire/claremont', lat: 43.374525, lng: -72.337295 },
  { name: 'Conway, NH', path: '/trailer-rentals/new-hampshire/conway', lat: 43.979525, lng: -71.128225 },
  { name: 'East Kingston, NH', path: '/trailer-rentals/new-hampshire/east-kingston', lat: 42.911186, lng: -71.015195 },
  { name: 'Farmington, NH', path: '/trailer-rentals/new-hampshire/farmington', lat: 43.389525, lng: -71.066195 },
  { name: 'Franklin, NH', path: '/trailer-rentals/new-hampshire/franklin', lat: 43.444525, lng: -71.657295 },
  { name: 'Gilford, NH', path: '/trailer-rentals/new-hampshire/gilford', lat: 43.547037, lng: -71.407168 },
  { name: 'Gorham, NH', path: '/trailer-rentals/new-hampshire/gorham', lat: 44.387037, lng: -71.187168 },
  { name: 'Greenland, NH', path: '/trailer-rentals/new-hampshire/greenland', lat: 43.037037, lng: -70.837168 },
  { name: 'Hampstead, NH', path: '/trailer-rentals/new-hampshire/hampstead', lat: 42.874525, lng: -71.177295 },
  { name: 'Hanover, NH', path: '/trailer-rentals/new-hampshire/hanover', lat: 43.707037, lng: -72.287168 },
  { name: 'Henniker, NH', path: '/trailer-rentals/new-hampshire/henniker', lat: 43.177037, lng: -71.827168 },
  { name: 'Hillsborough, NH', path: '/trailer-rentals/new-hampshire/hillsborough', lat: 43.117037, lng: -71.917168 },
  { name: 'Hinsdale, NH', path: '/trailer-rentals/new-hampshire/hinsdale', lat: 42.787037, lng: -72.487168 },
  { name: 'Hooksett, NH', path: '/trailer-rentals/new-hampshire/hooksett', lat: 43.087037, lng: -71.467168, active: true, showNewVersion: true  },
  { name: 'Jaffrey, NH', path: '/trailer-rentals/new-hampshire/jaffrey', lat: 42.817037, lng: -72.017168 },
  { name: 'Keene, NH', path: '/trailer-rentals/new-hampshire/keene', lat: 42.947037, lng: -72.287168 },
  { name: 'Lancaster, NH', path: '/trailer-rentals/new-hampshire/lancaster', lat: 44.487037, lng: -71.567168 },
  { name: 'Lebanon, NH', path: '/trailer-rentals/new-hampshire/lebanon', lat: 43.647037, lng: -72.287168 },
  { name: 'Lee, NH', path: '/trailer-rentals/new-hampshire/lee', lat: 43.117037, lng: -71.017168 },
  { name: 'Litchfield, NH', path: '/trailer-rentals/new-hampshire/litchfield', lat: 42.847037, lng: -71.467168 },
  { name: 'Loudon, NH', path: '/trailer-rentals/new-hampshire/loudon', lat: 43.307037, lng: -71.467168 },
  { name: 'Madison, NH', path: '/trailer-rentals/new-hampshire/madison', lat: 43.887037, lng: -71.187168 },
  { name: 'Marlborough, NH', path: '/trailer-rentals/new-hampshire/marlborough', lat: 42.907037, lng: -72.217168 },
  { name: 'Meredith, NH', path: '/trailer-rentals/new-hampshire/meredith', lat: 43.657037, lng: -71.487168 },
  { name: 'New Boston, NH', path: '/trailer-rentals/new-hampshire/new-boston', lat: 42.987037, lng: -71.687168 },
  { name: 'New Ipswich, NH', path: '/trailer-rentals/new-hampshire/new-ipswich', lat: 42.747037, lng: -71.817168 },
  { name: 'New London, NH', path: '/trailer-rentals/new-hampshire/new-london', lat: 43.417037, lng: -71.987168 },
  { name: 'Newport, NH', path: '/trailer-rentals/new-hampshire/newport', lat: 43.367037, lng: -72.187168 },
  { name: 'Northfield, NH', path: '/trailer-rentals/new-hampshire/northfield', lat: 43.447037, lng: -71.567168 },
  { name: 'Pembroke, NH', path: '/trailer-rentals/new-hampshire/pembroke', lat: 43.137037, lng: -71.467168 },
  { name: 'Peterborough, NH', path: '/trailer-rentals/new-hampshire/peterborough', lat: 42.877037, lng: -71.967168 },
  { name: 'Pinardville, NH', path: '/trailer-rentals/new-hampshire/pinardville', lat: 43.007037, lng: -71.517168 },
  { name: 'Pittsfield, NH', path: '/trailer-rentals/new-hampshire/pittsfield', lat: 43.307037, lng: -71.327168 },
  { name: 'Plymouth, NH', path: '/trailer-rentals/new-hampshire/plymouth', lat: 43.757037, lng: -71.687168 },
  { name: 'Rindge, NH', path: '/trailer-rentals/new-hampshire/rindge', lat: 42.747037, lng: -72.017168 },
  { name: 'Rollinsford, NH', path: '/trailer-rentals/new-hampshire/rollinsford', lat: 43.237037, lng: -70.837168 },
  { name: 'South Hooksett, NH', path: '/trailer-rentals/new-hampshire/south-hooksett', lat: 43.037037, lng: -71.467168 },
  { name: 'Suncook, NH', path: '/trailer-rentals/new-hampshire/suncook', lat: 43.137037, lng: -71.447168 },
  { name: 'Swanzey, NH', path: '/trailer-rentals/new-hampshire/swanzey', lat: 42.867037, lng: -72.287168 },
  { name: 'Tilton, NH', path: '/trailer-rentals/new-hampshire/tilton', lat: 43.447037, lng: -71.587168 },
  { name: 'Wakefield, NH', path: '/trailer-rentals/new-hampshire/wakefield', lat: 43.567037, lng: -71.037168 },
  { name: 'Walpole, NH', path: '/trailer-rentals/new-hampshire/walpole', lat: 43.087037, lng: -72.417168 },
  { name: 'Weare, NH', path: '/trailer-rentals/new-hampshire/weare', lat: 43.087037, lng: -71.717168 },
  { name: 'West Swanzey, NH', path: '/trailer-rentals/new-hampshire/west-swanzey', lat: 42.867037, lng: -72.317168 },
  { name: 'Wilton, NH', path: '/trailer-rentals/new-hampshire/wilton', lat: 42.847037, lng: -71.737168 },
  { name: 'Winchester, NH', path: '/trailer-rentals/new-hampshire/winchester', lat: 42.787037, lng: -72.387168 },
  { name: 'Wolfeboro, NH', path: '/trailer-rentals/new-hampshire/wolfeboro', lat: 43.587037, lng: -71.207168 }
];

export const northernTexasCities: ICity[] = [
  { name: "Addison, TX", path: "/trailer-rentals/texas/addison", lat: 32.961791, lng: -96.829169 },
  { name: "Arlington, TX", path: "/trailer-rentals/texas/arlington", lat: 32.735687, lng: -97.108066 },
  { name: "Carrollton, TX", path: "/trailer-rentals/texas/carrollton", lat: 32.975642, lng: -96.889964 },
  { name: "Cedar Hill, TX", path: "/trailer-rentals/texas/cedar-hill", lat: 32.589721, lng: -96.959799, active: true, showNewVersion: true },
  { name: "Dallas, TX", path: "/trailer-rentals/texas/dallas", lat: 32.776664, lng: -96.796988, active: true, showNewVersion: true },
  { name: "Denton, TX", path: "/trailer-rentals/texas/denton", lat: 33.214841, lng: -97.133068 },
  { name: "Duncanville, TX", path: "/trailer-rentals/texas/duncanville", lat: 32.6518, lng: -96.908336 },
  { name: 'Ennis, TX', path: '/trailer-rentals/texas/ennis', lat: 32.3347, lng: -96.6335 },
  { name: "Flower Mound, TX", path: "/trailer-rentals/texas/flower-mound", lat: 33.014567, lng: -97.096955 },
  { name: "Fort Worth, TX", path: "/trailer-rentals/texas/fort-worth", lat: 32.755488, lng: -97.330766, active: true, showNewVersion: true },
  { name: "Frisco, TX", path: "/trailer-rentals/texas/frisco", lat: 33.150674, lng: -96.823612 },
  { name: "Garland, TX", path: "/trailer-rentals/texas/garland", lat: 32.912624, lng: -96.638883 },
  { name: "Grand Prairie, TX", path: "/trailer-rentals/texas/grand-prairie", lat: 32.745965, lng: -96.997785, active: true, showNewVersion: true  },
  { name: "Grapevine, TX", path: "/trailer-rentals/texas/grapevine", lat: 32.934292, lng: -97.078065 },
  { name: "Irving, TX", path: "/trailer-rentals/texas/irving", lat: 32.814018, lng: -96.948894 },
  { name: "Mansfield, TX", path: "/trailer-rentals/texas/mansfield", lat: 32.563192, lng: -97.141677, active: true, showNewVersion: true },
  { name: 'Mesquite, TX', path: '/trailer-rentals/texas/mesquite', lat: 32.7668, lng: -96.5992, active: true, showNewVersion: true },
  { name: "Plano, TX", path: "/trailer-rentals/texas/plano", lat: 33.019843, lng: -96.698886 },
  { name: "Richardson, TX", path: "/trailer-rentals/texas/richardson", lat: 32.948333, lng: -96.729852 },
  { name: "Rockwall, TX", path: "/trailer-rentals/texas/rockwall", lat: 32.909074, lng: -96.450062 },
  { name: "Southlake, TX", path: "/trailer-rentals/texas/southlake", lat: 32.941236, lng: -97.134178 },
  { name: "Weatherford, TX", path: "/trailer-rentals/texas/weatherford", lat: 32.759296, lng: -97.797254 },
];

export const centralTexasCities: ICity[] = [
  { name: "Austin, TX", path: "/trailer-rentals/texas/austin", lat: 30.267153, lng: -97.743057, active: true, showNewVersion: true },
  { name: "Katy, TX", path: "/trailer-rentals/texas/katy", lat: 29.7858, lng: -95.8235, active: true, showNewVersion: true }, 
  { name: "Bastrop, TX", path: "/trailer-rentals/texas/bastrop", lat: 30.1388, lng: -97.2921 },
  { name: "Belton, TX", path: "/trailer-rentals/texas/belton", lat: 31.0723, lng: -97.472 },
  { name: "Boerne, TX", path: "/trailer-rentals/texas/boerne", lat: 29.794664, lng: -98.73197 },
  { name: "Bryan, TX", path: "/trailer-rentals/texas/bryan", lat: 30.674364, lng: -96.369963 },
  { name: "Buda, TX", path: "/trailer-rentals/texas/buda", lat: 32.542082, lng: -97.320849 },
  { name: "Cedar Park, TX", path: "/trailer-rentals/texas/cedar-park", lat: 30.505198, lng: -97.820288 },
  { name: "College Station, TX", path: "/trailer-rentals/texas/college-station", lat: 30.627977, lng: -96.334407 },
  { name: "Copperas Cove, TX", path: "/trailer-rentals/texas/copperas-cove", lat: 31.124695, lng: -97.903471 },
  { name: "Dripping Springs, TX", path: "/trailer-rentals/texas/dripping-springs", lat: 30.2268, lng: -98.1029 },
  { name: "Elgin, TX", path: "/trailer-rentals/texas/elgin", lat: 30.3231, lng: -97.3737 },
  { name: "Fredericksburg, TX", path: "/trailer-rentals/texas/fredericksburg", lat: 30.2752, lng: -98.871984 },
  { name: "Georgetown, TX", path: "/trailer-rentals/texas/georgetown", lat: 30.633261, lng: -97.677984 },
  { name: "Hutto, TX", path: "/trailer-rentals/texas/hutto", lat: 30.5257, lng: -97.5672 },
  { name: "Killeen, TX", path: "/trailer-rentals/texas/killeen", lat: 31.117119, lng: -97.727796 },
  { name: "Kyle, TX", path: "/trailer-rentals/texas/kyle", lat: 29.989105, lng: -97.877227 },
  { name: "Leander, TX", path: "/trailer-rentals/texas/leander", lat: 30.449, lng: -97.9669 },
  { name: "Lockhart, TX", path: "/trailer-rentals/texas/lockhart", lat: 29.884944, lng: -97.67 },
  { name: "Pflugerville, TX", path: "/trailer-rentals/texas/pflugerville", lat: 30.43937, lng: -97.620004 },
  { name: "Round Rock, TX", path: "/trailer-rentals/texas/round-rock", lat: 30.508255, lng: -97.678896 },
  { name: "San Marcos, TX", path: "/trailer-rentals/texas/san-marcos", lat: 29.883274, lng: -97.941394 },
  { name: "Schertz, TX", path: "/trailer-rentals/texas/schertz", lat: 29.579, lng: -98.2778 },
  { name: "Seguin, TX", path: "/trailer-rentals/texas/seguin", lat: 29.568841, lng: -97.964726 },
  { name: "San Antonio, TX", path: "/trailer-rentals/texas/san-antonio", lat: 29.424122, lng: -98.493629, active: true, showNewVersion: true },
  { name: "Taylor, TX", path: "/trailer-rentals/texas/taylor", lat: 30.5708, lng: -97.4094 },
  { name: "Temple, TX", path: "/trailer-rentals/texas/temple", lat: 31.098234, lng: -97.342782 },
  { name: "Waco, TX", path: "/trailer-rentals/texas/waco", lat: 31.549333, lng: -97.146669 },
  { name: "Woodway, TX", path: "/trailer-rentals/texas/woodway", lat: 31.4988, lng: -97.3166, active: true, showNewVersion: true },
];

export const easternTexasCities: ICity[] = [
  { name: 'Athens, TX', path: '/trailer-rentals/texas/athens', lat: 32.1935, lng: -95.8432 },
  { name: "Beaumont, TX", path: "/trailer-rentals/texas/beaumont", lat: 30.080174, lng: -94.126556 },
  { name: "Cleburne, TX", path: "/trailer-rentals/texas/cleburne", lat: 32.347648, lng: -97.386653 },
  { name: "Corsicana, TX", path: "/trailer-rentals/texas/corsicana", lat: 32.09543, lng: -96.468872 },
  { name: "Ennis, TX", path: "/trailer-rentals/texas/ennis", lat: 32.3347, lng: -96.6335, active: true, showNewVersion: true  },
  { name: 'Greenville, TX', path: '/trailer-rentals/texas/greenville', lat: 33.1854, lng: -96.1304 },
  { name: "Henderson, TX", path: "/trailer-rentals/texas/henderson", lat: 32.2131, lng: -94.7834 },
  { name: "Huntsville, TX", path: "/trailer-rentals/texas/huntsville", lat: 30.723526, lng: -95.550777 },
  { name: "Jacksonville, TX", path: "/trailer-rentals/texas/jacksonville", lat: 31.9618, lng: -95.2703 },
  { name: "Kilgore, TX", path: "/trailer-rentals/texas/kilgore", lat: 32.3836, lng: -94.8653 },
  { name: "Livingston, TX", path: "/trailer-rentals/texas/livingston", lat: 30.6829, lng: -94.8976 },
  { name: "Longview, TX", path: "/trailer-rentals/texas/longview", lat: 32.500703, lng: -94.740489 },
  { name: "Lufkin, TX", path: "/trailer-rentals/texas/lufkin", lat: 31.3489, lng: -94.8327 },
  { name: "Marshall, TX", path: "/trailer-rentals/texas/marshall", lat: 32.544871, lng: -94.367418 },
  { name: "Mount Pleasant, TX", path: "/trailer-rentals/texas/mount-pleasant", lat: 33.1733, lng: -94.9695 },
  { name: "Nacogdoches, TX", path: "/trailer-rentals/texas/nacogdoches", lat: 31.603512, lng: -94.655487 },
  { name: "Palestine, TX", path: "/trailer-rentals/texas/palestine", lat: 31.7942, lng: -95.662 },
  { name: "Paris, TX", path: "/trailer-rentals/texas/paris", lat: 33.660938, lng: -95.555513 },
  { name: "Stephenville, TX", path: "/trailer-rentals/texas/stephenville", lat: 32.220695, lng: -98.202349 },
  { name: "Sulphur Springs, TX", path: "/trailer-rentals/texas/sulphur-springs", lat: 33.1345, lng: -95.5922 },
  { name: "Splendora, TX", path: "/trailer-rentals/texas/splendora", lat: 33.1345, lng: -95.5922, active: true, showNewVersion: true },
  { name: "Texarkana, TX", path: "/trailer-rentals/texas/texarkana", lat: 33.425125, lng: -94.047688 },
  { name: "Tyler, TX", path: "/trailer-rentals/texas/tyler", lat: 32.35126, lng: -95.301062 },
];

export const southernTexasCities: ICity[] = [
  { name: "Abilene, TX", path: "/trailer-rentals/texas/abilene", lat: 32.448736, lng: -99.733144 },
  { name: "Alpine, TX", path: "/trailer-rentals/texas/alpine", lat: 30.358492, lng: -103.661011 },
  { name: "Alamo, TX", path: "/trailer-rentals/texas/alamo", lat: 26.1906, lng: -98.1164 },
  { name: "Alice, TX", path: "/trailer-rentals/texas/alice", lat: 27.7522, lng: -98.0697 },
  { name: "Amarillo, TX", path: "/trailer-rentals/texas/amarillo", lat: 35.221997, lng: -101.831297 },
  { name: "Baytown, TX", path: "/trailer-rentals/texas/baytown", lat: 29.735504, lng: -94.977427 },
  { name: "Beeville, TX", path: "/trailer-rentals/texas/beeville", lat: 28.3931, lng: -97.776 },
  { name: "Big Spring, TX", path: "/trailer-rentals/texas/big-spring", lat: 32.2733, lng: -101.374 },
  { name: "Bonham, TX", path: "/trailer-rentals/texas/bonham", lat: 33.577863, lng: -96.178373 },
  { name: "Brenham, TX", path: "/trailer-rentals/texas/brenham", lat: 30.166883, lng: -96.397744 },
  { name: "Brownsville, TX", path: "/trailer-rentals/texas/brownsville", lat: 25.901747, lng: -97.497484 },
  { name: "Brownwood, TX", path: "/trailer-rentals/texas/brownwood", lat: 31.709319, lng: -98.991161 },
  { name: "Canyon, TX", path: "/trailer-rentals/texas/canyon", lat: 34.980334, lng: -101.918802 },
  { name: "Clute, TX", path: "/trailer-rentals/texas/clute", lat: 29.023858, lng: -95.399473 },
  { name: "Conroe, TX", path: "/trailer-rentals/texas/conroe", lat: 30.311876, lng: -95.456051 },
  { name: "Corpus Christi, TX", path: "/trailer-rentals/texas/corpus-christi", lat: 27.800583, lng: -97.396381 },
  { name: "Del Rio, TX", path: "/trailer-rentals/texas/del-rio", lat: 29.362169, lng: -100.896879 },
  { name: "Eagle Lake, TX", path: "/trailer-rentals/texas/eagle-lake", lat: 29.588228, lng: -96.333333 },
  { name: "Eagle Pass, TX", path: "/trailer-rentals/texas/eagle-pass", lat: 28.709141, lng: -100.499521 },
  { name: "Edinburg, TX", path: "/trailer-rentals/texas/edinburg", lat: 26.301737, lng: -98.163343 },
  { name: "El Paso, TX", path: "/trailer-rentals/texas/el-paso", lat: 31.761878, lng: -106.485022 },
  { name: "Galveston, TX", path: "/trailer-rentals/texas/galveston", lat: 29.301348, lng: -94.797695 },
  { name: "Graham, TX", path: "/trailer-rentals/texas/graham", lat: 33.106567, lng: -98.589434 },
  { name: "Harlingen, TX", path: "/trailer-rentals/texas/harlingen", lat: 26.190631, lng: -97.696103 },
  { name: "Hereford, TX", path: "/trailer-rentals/texas/hereford", lat: 34.815821, lng: -102.397609 },
  { name: "Houston, TX", path: "/trailer-rentals/texas/houston", lat: 29.760427, lng: -95.369803, active: true, showNewVersion: true },
  { name: 'Justin, TX', path: '/trailer-rentals/texas/justin', lat: 33.0848, lng: -97.2961, active: true, showNewVersion: true },
  { name: "Kerrville, TX", path: "/trailer-rentals/texas/kerrville", lat: 30.047433, lng: -99.140318 },
  { name: "Kingsville, TX", path: "/trailer-rentals/texas/kingsville", lat: 27.515882, lng: -97.855099 },
  { name: "Lake Jackson, TX", path: "/trailer-rentals/texas/lake-jackson", lat: 29.043477, lng: -95.452502 },
  { name: "La Porte, TX", path: "/trailer-rentals/texas/la-porte", lat: 29.6658, lng: -95.0194 },
  { name: "Laredo, TX", path: "/trailer-rentals/texas/laredo", lat: 27.506407, lng: -99.507542 },
  { name: "League City, TX", path: "/trailer-rentals/texas/league-city", lat: 29.507453, lng: -95.09493 },
  { name: "Levelland, TX", path: "/trailer-rentals/texas/levelland", lat: 33.587521, lng: -102.378247 },
  { name: "Lubbock, TX", path: "/trailer-rentals/texas/lubbock", lat: 33.577863, lng: -101.855166, active: true, showNewVersion: true },
  { name: "Mercedes, TX", path: "/trailer-rentals/texas/mercedes", lat: 26.1513, lng: -97.9185 },
  { name: "McAllen, TX", path: "/trailer-rentals/texas/mcallen", lat: 26.203407, lng: -98.230012 },
  { name: "Midland, TX", path: "/trailer-rentals/texas/midland", lat: 31.997345, lng: -102.077915, active: true, showNewVersion: true  },
  { name: "Mineral Wells, TX", path: "/trailer-rentals/texas/mineral-wells", lat: 32.808468, lng: -98.112822 },
  { name: "Mission, TX", path: "/trailer-rentals/texas/mission", lat: 26.215906, lng: -98.325293 },
  { name: "Missouri City, TX", path: "/trailer-rentals/texas/missouri-city", lat: 29.618566, lng: -95.537721 },
  { name: "New Braunfels, TX", path: "/trailer-rentals/texas/new-braunfels", lat: 29.703002, lng: -98.124453 },
  { name: "Odessa, TX", path: "/trailer-rentals/texas/odessa", lat: 31.845682, lng: -102.367643 },
  { name: "Pasadena, TX", path: "/trailer-rentals/texas/pasadena", lat: 29.691063, lng: -95.2091 },
  { name: "Pearland, TX", path: "/trailer-rentals/texas/pearland", lat: 29.563566, lng: -95.286047 },
  { name: "Pecos, TX", path: "/trailer-rentals/texas/pecos", lat: 31.4229, lng: -103.493217 },
  { name: "Plainview, TX", path: "/trailer-rentals/texas/plainview", lat: 34.184423, lng: -101.707313 },
  { name: "Port Arthur, TX", path: "/trailer-rentals/texas/port-arthur", lat: 29.88495, lng: -93.939947 },
  { name: "Port Lavaca, TX", path: "/trailer-rentals/texas/port-lavaca", lat: 28.615802, lng: -96.626115 },
  { name: "Port Isabel, TX", path: "/trailer-rentals/texas/port-isabel", lat: 26.0539, lng: -97.3125 },
  { name: "Rio Grande City, TX", path: "/trailer-rentals/texas/rio-grande-city", lat: 26.379787, lng: -98.820334 },
  { name: "Rockport, TX", path: "/trailer-rentals/texas/rockport", lat: 28.020573, lng: -97.054434 },
  { name: "Rosenberg, TX", path: "/trailer-rentals/texas/rosenberg", lat: 29.557182, lng: -95.808181 },
  { name: "San Angelo, TX", path: "/trailer-rentals/texas/san-angelo", lat: 31.463772, lng: -100.437037 },
  { name: "San Benito, TX", path: "/trailer-rentals/texas/san-benito", lat: 26.1337, lng: -97.6447 },
  { name: "Sealy, TX", path: "/trailer-rentals/texas/sealy", lat: 29.774265, lng: -96.157373 },
  { name: "Snyder, TX", path: "/trailer-rentals/texas/snyder", lat: 32.717499, lng: -100.917091 },
  { name: "South Padre Island, TX", path: "/trailer-rentals/texas/south-padre-island", lat: 26.11184, lng: -97.168926 },
  { name: "Sugar Land, TX", path: "/trailer-rentals/texas/sugar-land", lat: 29.619678, lng: -95.634946 },
  { name: "Terrell, TX", path: "/trailer-rentals/texas/terrell", lat: 32.735962, lng: -96.275256 },
  { name: "Texas City, TX", path: "/trailer-rentals/texas/texas-city", lat: 29.383845, lng: -94.9027 },
  { name: "The Woodlands, TX", path: "/trailer-rentals/texas/the-woodlands", lat: 30.16582, lng: -95.461262 },
  { name: "Tomball, TX", path: "/trailer-rentals/texas/tomball", lat: 30.0615, lng: -95.6821 },
  { name: "Uvalde, TX", path: "/trailer-rentals/texas/uvalde", lat: 29.20968, lng: -99.786872 },
  { name: "Van Horn, TX", path: "/trailer-rentals/texas/van-horn", lat: 31.042671, lng: -104.831919 },
  { name: "Victoria, TX", path: "/trailer-rentals/texas/victoria", lat: 28.805267, lng: -97.003598 },
  { name: "Weslaco, TX", path: "/trailer-rentals/texas/weslaco", lat: 26.1595, lng: -97.9908 },
  { name: "Wichita Falls, TX", path: "/trailer-rentals/texas/wichita-falls", lat: 33.913708, lng: -98.493387 },
  { name: "Wolfforth, TX", path: "/trailer-rentals/texas/wolfforth", lat: 33.5059, lng: -102.0091 }
];

export const idahoCities: ICity[] = [
  { name: 'Boise, ID', path: '/trailer-rentals/idaho/boise', lat: 43.615019 , lng: -116.202314 },
  { name: 'Caldwell, ID', path: '/trailer-rentals/idaho/caldwell', lat: 43.662938 , lng: -116.68736 },
  { name: 'Cambridge, ID', path: '/trailer-rentals/idaho/cambridge', lat: 44.572662 , lng: -116.675992 },
  { name: 'Cascade, ID', path: '/trailer-rentals/idaho/cascade', lat: 44.516282 , lng: -116.041798 },
  { name: 'Eagle, ID', path: '/trailer-rentals/idaho/eagle', lat: 43.695442 , lng: -116.354014 },
  { name: 'Emmett, ID', path: '/trailer-rentals/idaho/emmett', lat: 43.873498 , lng: -116.499301 },
  { name: 'Fruitland, ID', path: '/trailer-rentals/idaho/fruitland', lat: 44.007664 , lng: -116.916546 },
  { name: 'Garden City, ID', path: '/trailer-rentals/idaho/garden-city', lat: 43.622111 , lng: -116.238174 },
  { name: 'Glenns Ferry, ID', path: '/trailer-rentals/idaho/glenns-ferry', lat: 42.954899 , lng: -115.300904 },
  { name: 'Greenleaf, ID', path: '/trailer-rentals/idaho/greenleaf', lat: 43.671271 , lng: -116.816255 },
  { name: 'Hammett, ID', path: '/trailer-rentals/idaho/hammett', lat: 42.945731 , lng: -115.466186 },
  { name: 'Homedale, ID', path: '/trailer-rentals/idaho/homedale', lat: 43.617658 , lng: -116.93376 },
  { name: 'Horseshoe Bend, ID', path: '/trailer-rentals/idaho/horseshoe-bend', lat: 43.914612 , lng: -116.197902 },
  { name: 'Idaho City, ID', path: '/trailer-rentals/idaho/idaho-city', lat: 43.828505 , lng: -115.834554 },
  { name: 'Kuna, ID', path: '/trailer-rentals/idaho/kuna', lat: 43.491831 , lng: -116.420122 },
  { name: 'Marsing, ID', path: '/trailer-rentals/idaho/marsing', lat: 43.545436 , lng: -116.813196 },
  { name: 'Melba, ID', path: '/trailer-rentals/idaho/melba', lat: 43.375441 , lng: -116.529012 },
  { name: 'Meridian, ID', path: '/trailer-rentals/idaho/meridian', lat: 43.612109 , lng: -116.391513 },
  { name: 'Middleton, ID', path: '/trailer-rentals/idaho/middleton', lat: 43.706828 , lng: -116.620136 },
  { name: 'Mountain Home, ID', path: '/trailer-rentals/idaho/mountain-home', lat: 43.13295 , lng: -115.691197 },
  { name: 'Nampa, ID', path: '/trailer-rentals/idaho/nampa', lat: 43.578818 , lng: -116.55978 },
  { name: 'New Meadows, ID', path: '/trailer-rentals/idaho/new-meadows', lat: 44.971281 , lng: -116.284018 },
  { name: 'New Plymouth, ID', path: '/trailer-rentals/idaho/new-plymouth', lat: 43.969886 , lng: -116.81904 },
  { name: 'Notus, ID', path: '/trailer-rentals/idaho/notus', lat: 43.725439 , lng: -116.801256 },
  { name: 'Parma, ID', path: '/trailer-rentals/idaho/parma', lat: 43.785162 , lng: -116.943209 },
  { name: 'Payette, ID', path: '/trailer-rentals/idaho/payette', lat: 44.07822 , lng: -116.933771 },
  { name: 'Star, ID', path: '/trailer-rentals/idaho/star', lat: 43.692107 , lng: -116.493463 },
  { name: 'Twin Falls, ID', path: '/trailer-rentals/idaho/twin-falls', lat: 42.555838 , lng: -114.470052 },
  { name: 'Weiser, ID', path: '/trailer-rentals/idaho/weiser', lat: 44.250998 , lng: -116.969333 },
  { name: 'Wilder, ID', path: '/trailer-rentals/idaho/wilder', lat: 43.676549 , lng: -116.911816 },
  { name: 'Challis, ID', path: '/trailer-rentals/idaho/challis', lat: 44.5047, lng: -114.2314 },
  { name: 'Coeur dAlene, ID', path: '/trailer-rentals/idaho/coeur-dalene', lat: 47.6777, lng: -116.7805 },
  { name: 'Cottonwood, ID', path: '/trailer-rentals/idaho/cottonwood', lat: 46.0457, lng: -116.3493 },
  { name: 'Council, ID', path: '/trailer-rentals/idaho/council', lat: 44.7291, lng: -116.4382 },
  { name: 'Craigmont, ID', path: '/trailer-rentals/idaho/craigmont', lat: 46.2388, lng: -116.4682 },
  { name: 'Dalton Gardens, ID', path: '/trailer-rentals/idaho/dalton-gardens', lat: 47.7266, lng: -116.7694 },
  { name: 'Driggs, ID', path: '/trailer-rentals/idaho/driggs', lat: 43.7238, lng: -111.1113 },
  { name: 'Fairfield, ID', path: '/trailer-rentals/idaho/fairfield', lat: 43.3463, lng: -114.7917 },
  { name: 'Gooding, ID', path: '/trailer-rentals/idaho/gooding', lat: 42.9388, lng: -114.7131 },
  { name: 'Grangeville, ID', path: '/trailer-rentals/idaho/grangeville', lat: 45.9263, lng: -116.1229 },
  { name: 'Hailey, ID', path: '/trailer-rentals/idaho/hailey', lat: 43.5196, lng: -114.3153 },
  { name: 'Harrison, ID', path: '/trailer-rentals/idaho/harrison', lat: 47.4544, lng: -116.7802 },
  { name: 'Hayden, ID', path: '/trailer-rentals/idaho/hayden', lat: 47.7666, lng: -116.7863 },
  { name: 'Idaho Falls, ID', path: '/trailer-rentals/idaho/idaho-falls', lat: 43.4917, lng: -112.0339, active: true, showNewVersion: true  },
  { name: 'Inkom, ID', path: '/trailer-rentals/idaho/inkom', lat: 42.7991, lng: -112.2533 },
  { name: 'Jerome, ID', path: '/trailer-rentals/idaho/jerome', lat: 42.7246, lng: -114.5186 },
  { name: 'Kamiah, ID', path: '/trailer-rentals/idaho/kamiah', lat: 46.2271, lng: -116.0282 },
  { name: 'Kellogg, ID', path: '/trailer-rentals/idaho/kellogg', lat: 47.5388, lng: -116.1293 },
  { name: 'Ketchum, ID', path: '/trailer-rentals/idaho/ketchum', lat: 43.6805, lng: -114.3633 },
  { name: 'Kimberly, ID', path: '/trailer-rentals/idaho/kimberly', lat: 42.5338, lng: -114.3642 },
  { name: 'Lapwai, ID', path: '/trailer-rentals/idaho/lapwai', lat: 46.4032, lng: -116.8043 },
  { name: 'Lewiston, ID', path: '/trailer-rentals/idaho/lewiston', lat: 46.4165, lng: -117.0177 },
  { name: 'McCall, ID', path: '/trailer-rentals/idaho/mccall', lat: 44.9116, lng: -116.0987 },
  { name: 'Malad City, ID', path: '/trailer-rentals/idaho/malad-city', lat: 42.1916, lng: -112.2508 },
  { name: 'Montpelier, ID', path: '/trailer-rentals/idaho/montpelier', lat: 42.3227, lng: -111.2977 },
  { name: 'Moscow, ID', path: '/trailer-rentals/idaho/moscow', lat: 46.7324, lng: -117.0002 },
  { name: 'Orofino, ID', path: '/trailer-rentals/idaho/orofino', lat: 46.4791, lng: -116.2554 },
  { name: 'Paris, ID', path: '/trailer-rentals/idaho/paris', lat: 42.2277, lng: -111.3997 },
  { name: 'Pinehurst, ID', path: '/trailer-rentals/idaho/pinehurst', lat: 47.5388, lng: -116.2343 },
  { name: 'Plummer, ID', path: '/trailer-rentals/idaho/plummer', lat: 47.3344, lng: -116.8883 },
  { name: 'Pocatello, ID', path: '/trailer-rentals/idaho/pocatello', lat: 42.8713, lng: -112.4455 },
  { name: 'Post Falls, ID', path: '/trailer-rentals/idaho/post-falls', lat: 47.7171, lng: -116.9516 },
  { name: 'Preston, ID', path: '/trailer-rentals/idaho/preston', lat: 42.0966, lng: -111.8766 },
  { name: 'Priest River, ID', path: '/trailer-rentals/idaho/priest-river', lat: 48.1838, lng: -116.9108 },
  { name: 'Rathdrum, ID', path: '/trailer-rentals/idaho/rathdrum', lat: 47.8127, lng: -116.8963 },
  { name: 'Rigby, ID', path: '/trailer-rentals/idaho/rigby', lat: 43.6727, lng: -111.9144, active: true, showNewVersion: true  },
  { name: 'Riggins, ID', path: '/trailer-rentals/idaho/riggins', lat: 45.4207, lng: -116.3179 },
  { name: 'Rupert, ID', path: '/trailer-rentals/idaho/rupert', lat: 42.6207, lng: -113.6772 },
  { name: 'Salmon, ID', path: '/trailer-rentals/idaho/salmon', lat: 45.1755, lng: -113.8958 },
  { name: 'Sandpoint, ID', path: '/trailer-rentals/idaho/sandpoint', lat: 48.2766, lng: -116.5533 },
  { name: 'Shelley, ID', path: '/trailer-rentals/idaho/shelley', lat: 43.3816, lng: -112.1233 },
  { name: 'Shoshone, ID', path: '/trailer-rentals/idaho/shoshone', lat: 42.9366, lng: -114.4044 },
  { name: 'Spirit Lake, ID', path: '/trailer-rentals/idaho/spirit-lake', lat: 47.9666, lng: -116.8683 },
  { name: 'St. Anthony, ID', path: '/trailer-rentals/idaho/st-anthony', lat: 43.9655, lng: -111.6827 },
  { name: 'St. Maries, ID', path: '/trailer-rentals/idaho/st-maries', lat: 47.3155, lng: -116.5627 },
  { name: 'Sun Valley, ID', path: '/trailer-rentals/idaho/sun-valley', lat: 43.6977, lng: -114.3533 },
  { name: 'Swan Valley, ID', path: '/trailer-rentals/idaho/swan-valley', lat: 43.4566, lng: -111.3366 },
  { name: 'Teton, ID', path: '/trailer-rentals/idaho/teton', lat: 43.8877, lng: -111.6783 },
  { name: 'Troy, ID', path: '/trailer-rentals/idaho/troy', lat: 46.7377, lng: -116.7694 },
  { name: 'Ucon, ID', path: '/trailer-rentals/idaho/ucon', lat: 43.5966, lng: -111.9655 },
  { name: 'Wendell, ID', path: '/trailer-rentals/idaho/wendell', lat: 42.7766, lng: -114.7011 },
];

export const alabamaCities: ICity[] = [
  { name: 'Alabaster, AL', path: '/trailer-rentals/alabama/alabaster', lat: 33.244281, lng: -86.816377, active: true, showNewVersion: true },
  { name: 'Alexander City, AL', path: '/trailer-rentals/alabama/alexander-city', lat: 32.944008, lng: -85.953853 },
  { name: 'Andalusia, AL', path: '/trailer-rentals/alabama/andalusia', lat: 31.308503, lng: -86.483292 },
  { name: 'Anniston, AL', path: '/trailer-rentals/alabama/anniston', lat: 33.659825, lng: -85.831631 },
  { name: 'Arab, AL', path: '/trailer-rentals/alabama/arab', lat: 34.318147, lng: -86.495823 },
  { name: 'Athens, AL', path: '/trailer-rentals/alabama/athens', lat: 34.802867, lng: -86.971674 },
  { name: 'Atmore, AL', path: '/trailer-rentals/alabama/atmore', lat: 31.023237, lng: -87.493876 },
  { name: 'Auburn, AL', path: '/trailer-rentals/alabama/auburn', lat: 32.609857, lng: -85.480782 },
  { name: 'Bay Minette, AL', path: '/trailer-rentals/alabama/bay-minette', lat: 30.882963, lng: -87.773048 },
  { name: 'Bessemer, AL', path: '/trailer-rentals/alabama/bessemer', lat: 33.401778, lng: -86.954437 },
  { name: 'Birmingham, AL', path: '/trailer-rentals/alabama/birmingham', lat: 33.518589, lng: -86.810356, active: true, showNewVersion: true },
  { name: 'Boaz, AL', path: '/trailer-rentals/alabama/boaz', lat: 34.200645, lng: -86.152191 },
  { name: 'Brewton, AL', path: '/trailer-rentals/alabama/brewton', lat: 31.105179, lng: -87.072191 },
  { name: 'Calera, AL', path: '/trailer-rentals/alabama/calera', lat: 33.102896, lng: -86.753598 },
  { name: 'Center Point, AL', path: '/trailer-rentals/alabama/center-point', lat: 33.645661, lng: -86.683599 },
  { name: 'Centre, AL', path: '/trailer-rentals/alabama/centre', lat: 34.152038, lng: -85.678573 },
  { name: 'Chelsea, AL', path: '/trailer-rentals/alabama/chelsea', lat: 33.340939, lng: -86.630819 },
  { name: 'Clanton, AL', path: '/trailer-rentals/alabama/clanton', lat: 32.838737, lng: -86.629434 },
  { name: 'Clay, AL', path: '/trailer-rentals/alabama/clay', lat: 33.702049, lng: -86.599434 },
  { name: 'Cullman, AL', path: '/trailer-rentals/alabama/cullman', lat: 34.174820, lng: -86.843612 },
  { name: 'Daphne, AL', path: '/trailer-rentals/alabama/daphne', lat: 30.603527, lng: -87.903604 },
  { name: 'Decatur, AL', path: '/trailer-rentals/alabama/decatur', lat: 34.605925, lng: -86.983341 },
  { name: 'Demopolis, AL', path: '/trailer-rentals/alabama/demopolis', lat: 32.517637, lng: -87.836964 },
  { name: 'Dothan, AL', path: '/trailer-rentals/alabama/dothan', lat: 31.223231, lng: -85.390488 },
  { name: 'Enterprise, AL', path: '/trailer-rentals/alabama/enterprise', lat: 31.315170, lng: -85.855216 },
  { name: 'Eufaula, AL', path: '/trailer-rentals/alabama/eufaula', lat: 31.891271, lng: -85.145488 },
  { name: 'Fairfield, AL', path: '/trailer-rentals/alabama/fairfield', lat: 33.485938, lng: -86.919713 },
  { name: 'Fairhope, AL', path: '/trailer-rentals/alabama/fairhope', lat: 30.522973, lng: -87.903326 },
  { name: 'Fultondale, AL', path: '/trailer-rentals/alabama/fultondale', lat: 33.606773, lng: -86.793601 },
  { name: 'Gadsden, AL', path: '/trailer-rentals/alabama/gadsden', lat: 34.014264, lng: -86.006638 },
  { name: 'Gardendale, AL', path: '/trailer-rentals/alabama/gardendale', lat: 33.660936, lng: -86.812215 },
  { name: 'Gulf Shores, AL', path: '/trailer-rentals/alabama/gulf-shores', lat: 30.246036, lng: -87.700819 },
  { name: 'Guntersville, AL', path: '/trailer-rentals/alabama/guntersville', lat: 34.358147, lng: -86.294693 },
  { name: 'Hartselle, AL', path: '/trailer-rentals/alabama/hartselle', lat: 34.443703, lng: -86.935837 },
  { name: 'Helena, AL', path: '/trailer-rentals/alabama/helena', lat: 33.296779, lng: -86.843322 },
  { name: 'Homewood, AL', path: '/trailer-rentals/alabama/homewood', lat: 33.471772, lng: -86.800822 },
  { name: 'Hoover, AL', path: '/trailer-rentals/alabama/hoover', lat: 33.405386, lng: -86.811378 },
  { name: 'Hueytown, AL', path: '/trailer-rentals/alabama/hueytown', lat: 33.451218, lng: -86.996104 },
  { name: 'Huntsville, AL', path: '/trailer-rentals/alabama/huntsville', lat: 34.730369, lng: -86.586104 },
  { name: 'Irondale, AL', path: '/trailer-rentals/alabama/irondale', lat: 33.538158, lng: -86.707766 },
  { name: 'Jacksonville, AL', path: '/trailer-rentals/alabama/jacksonville', lat: 33.813713, lng: -85.761352 },
  { name: 'Jasper, AL', path: '/trailer-rentals/alabama/jasper', lat: 33.831222, lng: -87.277505 },
  { name: 'Leeds, AL', path: '/trailer-rentals/alabama/leeds', lat: 33.548995, lng: -86.544711 },
  { name: 'Lincoln, AL', path: '/trailer-rentals/alabama/lincoln', lat: 33.594268, lng: -86.118864 },
  { name: 'Madison, AL', path: '/trailer-rentals/alabama/madison', lat: 34.699257, lng: -86.748332 },
  { name: 'Meadowbrook, AL', path: '/trailer-rentals/alabama/meadowbrook', lat: 33.414274, lng: -86.696934 },
  { name: 'Millbrook, AL', path: '/trailer-rentals/alabama/millbrook', lat: 32.479302, lng: -86.361917 },
  { name: 'Mobile, AL', path: '/trailer-rentals/alabama/mobile', lat: 30.695366, lng: -88.039891 },
  { name: 'Montevallo, AL', path: '/trailer-rentals/alabama/montevallo', lat: 33.100940, lng: -86.864157 },
  { name: 'Montgomery, AL', path: '/trailer-rentals/alabama/montgomery', lat: 32.366805, lng: -86.299969 },
  { name: 'Moody, AL', path: '/trailer-rentals/alabama/moody', lat: 33.590936, lng: -86.490823 },
  { name: 'Mountain Brook, AL', path: '/trailer-rentals/alabama/mountain-brook', lat: 33.500941, lng: -86.752576 },
  { name: 'Muscle Shoals, AL', path: '/trailer-rentals/alabama/muscle-shoals', lat: 34.745891, lng: -87.667529 },
  { name: 'Northport, AL', path: '/trailer-rentals/alabama/northport', lat: 33.229007, lng: -87.577228 },
  { name: 'Opelika, AL', path: '/trailer-rentals/alabama/opelika', lat: 32.645411, lng: -85.378279 },
  { name: 'Orange Beach, AL', path: '/trailer-rentals/alabama/orange-beach', lat: 30.269703, lng: -87.586757 },
  { name: 'Oxford, AL', path: '/trailer-rentals/alabama/oxford', lat: 33.614274, lng: -85.834438 },
  { name: 'Ozark, AL', path: '/trailer-rentals/alabama/ozark', lat: 31.459336, lng: -85.640219 },
  { name: 'Pelham, AL', path: '/trailer-rentals/alabama/pelham', lat: 33.285671, lng: -86.809988 },
  { name: 'Pell City, AL', path: '/trailer-rentals/alabama/pell-city', lat: 33.586217, lng: -86.286088 },
  { name: 'Phenix City, AL', path: '/trailer-rentals/alabama/phenix-city', lat: 32.470976, lng: -85.000765 },
  { name: 'Pike Road, AL', path: '/trailer-rentals/alabama/pike-road', lat: 32.275146, lng: -86.103292 },
  { name: 'Pinson, AL', path: '/trailer-rentals/alabama/pinson', lat: 33.688157, lng: -86.682492 },
  { name: 'Pleasant Grove, AL', path: '/trailer-rentals/alabama/pleasant-grove', lat: 33.490936, lng: -86.973601 },
  { name: 'Prattville, AL', path: '/trailer-rentals/alabama/prattville', lat: 32.464024, lng: -86.459696 },
  { name: 'Prichard, AL', path: '/trailer-rentals/alabama/prichard', lat: 30.738518, lng: -88.079791 },
  { name: 'Rainbow City, AL', path: '/trailer-rentals/alabama/rainbow-city', lat: 33.937037, lng: -86.041086 },
  { name: 'Robertsdale, AL', path: '/trailer-rentals/alabama/robertsdale', lat: 30.553803, lng: -87.711103 },
  { name: 'Russellville, AL', path: '/trailer-rentals/alabama/russellville', lat: 34.507591, lng: -87.728642 },
  { name: 'Saraland, AL', path: '/trailer-rentals/alabama/saraland', lat: 30.820741, lng: -88.070556 },
  { name: 'Satsuma, AL', path: '/trailer-rentals/alabama/satsuma', lat: 30.852130, lng: -88.065556 },
  { name: 'Scottsboro, AL', path: '/trailer-rentals/alabama/scottsboro', lat: 34.672306, lng: -86.034146 },
  { name: 'Selma, AL', path: '/trailer-rentals/alabama/selma', lat: 32.407358, lng: -87.021101 },
  { name: 'Sheffield, AL', path: '/trailer-rentals/alabama/sheffield', lat: 34.765646, lng: -87.698636 },
  { name: 'Southside, AL', path: '/trailer-rentals/alabama/southside', lat: 33.924268, lng: -86.022191 },
  { name: 'Sylacauga, AL', path: '/trailer-rentals/alabama/sylacauga', lat: 33.173993, lng: -86.251636 },
  { name: 'Talladega, AL', path: '/trailer-rentals/alabama/talladega', lat: 33.435941, lng: -86.105804 },
  { name: 'Troy, AL', path: '/trailer-rentals/alabama/troy', lat: 31.808767, lng: -85.969951 },
  { name: 'Trussville, AL', path: '/trailer-rentals/alabama/trussville', lat: 33.619847, lng: -86.608882 },
  { name: 'Tuscaloosa, AL', path: '/trailer-rentals/alabama/tuscaloosa', lat: 33.209841, lng: -87.569173 },
  { name: 'Albertville, AL', path: '/trailer-rentals/alabama/albertville', lat: 34.267593, lng: -86.208867 },
  { name: 'Bayou La Batre, AL', path: '/trailer-rentals/alabama/bayou-la-batre', lat: 30.403892, lng: -88.248344 },
  { name: 'Chickasaw, AL', path: '/trailer-rentals/alabama/chickasaw', lat: 30.771218, lng: -88.079791 },
  { name: 'Childersburg, AL', path: '/trailer-rentals/alabama/childersburg', lat: 33.278768, lng: -86.354171 },
  { name: 'Citronelle, AL', path: '/trailer-rentals/alabama/citronelle', lat: 31.091218, lng: -88.230556 },
  { name: 'Daleville, AL', path: '/trailer-rentals/alabama/daleville', lat: 31.308768, lng: -85.712495 },
  { name: 'Fort Payne, AL', path: '/trailer-rentals/alabama/fort-payne', lat: 34.444268, lng: -85.719689 },
  { name: 'Foley, AL', path: '/trailer-rentals/alabama/foley', lat: 30.406218, lng: -87.683604 },
  { name: 'Hokes Bluff, AL', path: '/trailer-rentals/alabama/hokes-bluff', lat: 33.998768, lng: -85.867495 },
  { name: 'Monroeville, AL', path: '/trailer-rentals/alabama/monroeville', lat: 31.527593, lng: -87.324171 },
  { name: 'Oneonta, AL', path: '/trailer-rentals/alabama/oneonta', lat: 33.942593, lng: -86.474171 },
  { name: 'Rainsville, AL', path: '/trailer-rentals/alabama/rainsville', lat: 34.494268, lng: -85.847495 },
  { name: 'Roanoke, AL', path: '/trailer-rentals/alabama/roanoke', lat: 33.150940, lng: -85.372495 },
  { name: 'Spanish Fort, AL', path: '/trailer-rentals/alabama/spanish-fort', lat: 30.674268, lng: -87.915819 },
  { name: 'Tallassee, AL', path : '/trailer-rentals/alabama/tallassee', lat: 32.547593, lng: -85.893604 },
  { name: 'Tuscumbia, AL', path: '/trailer-rentals/alabama/tuscumbia', lat: 34.731218, lng: -87.702495 },
  { name: 'Union Springs, AL', path: '/trailer-rentals/alabama/union-springs', lat: 32.143768, lng: -85.715819 },
  { name: 'Valley, AL', path: '/trailer-rentals/alabama/valley', lat: 32.814268, lng: -85.177495 },
  { name: 'Vernon, AL', path: '/trailer-rentals/alabama/vernon', lat: 33.755940, lng: -88.110819 },
  { name: 'Winfield, AL', path: '/trailer-rentals/alabama/winfield', lat: 33.930940, lng: -87.819171 },
  { name: 'York, AL', path: '/trailer-rentals/alabama/york', lat: 32.486218, lng: -88.297495 },
];

export const arkansasCities: ICity[] = [
  { name: 'Alma, AR', path: '/trailer-rentals/arkansas/alma', lat: 35.477, lng: -94.221 },
  { name: 'Arkadelphia, AR', path: '/trailer-rentals/arkansas/arkadelphia', lat: 34.120, lng: -93.053 },
  { name: 'Ashdown, AR', path: '/trailer-rentals/arkansas/ashdown', lat: 33.674, lng: -94.131 },
  { name: 'Austin, AR', path: '/trailer-rentals/arkansas/austin', lat: 35.011, lng: -91.984 },
  { name: 'Batesville, AR', path: '/trailer-rentals/arkansas/batesville', lat: 35.769, lng: -91.640 },
  { name: 'Bella Vista, AR', path: '/trailer-rentals/arkansas/bella-vista', lat: 36.480, lng: -94.274 },
  { name: 'Benton, AR', path: '/trailer-rentals/arkansas/benton', lat: 34.564, lng: -92.586 },
  { name: 'Bentonville, AR', path: '/trailer-rentals/arkansas/bentonville', lat: 36.372, lng: -94.208 },
  { name: 'Berryville, AR', path: '/trailer-rentals/arkansas/berryville', lat: 36.364, lng: -93.567 },
  { name: 'Blytheville, AR', path: '/trailer-rentals/arkansas/blytheville', lat: 35.927, lng: -89.918 },
  { name: 'Booneville, AR', path: '/trailer-rentals/arkansas/booneville', lat: 35.139, lng: -93.921 },
  { name: 'Brinkley, AR', path: '/trailer-rentals/arkansas/brinkley', lat: 34.888, lng: -91.191 },
  { name: 'Bryant, AR', path: '/trailer-rentals/arkansas/bryant', lat: 34.595, lng: -92.489 },
  { name: 'Cabot, AR', path: '/trailer-rentals/arkansas/cabot', lat: 34.974, lng: -92.016 },
  { name: 'Camden, AR', path: '/trailer-rentals/arkansas/camden', lat: 33.584, lng: -92.834 },
  { name: 'Carlisle, AR', path: '/trailer-rentals/arkansas/carlisle', lat: 34.784, lng: -91.744 },
  { name: 'Centerton, AR', path: '/trailer-rentals/arkansas/centerton', lat: 36.359, lng: -94.284 },
  { name: 'Charleston, AR', path: '/trailer-rentals/arkansas/charleston', lat: 35.297, lng: -94.038 },
  { name: 'Clarendon, AR', path: '/trailer-rentals/arkansas/clarendon', lat: 34.693, lng: -91.313 },
  { name: 'Clarksville, AR', path: '/trailer-rentals/arkansas/clarksville', lat: 35.471, lng: -93.466 },
  { name: 'Clinton, AR', path: '/trailer-rentals/arkansas/clinton', lat: 35.591, lng: -92.460 },
  { name: 'Conway, AR', path: '/trailer-rentals/arkansas/conway', lat: 35.088, lng: -92.442 },
  { name: 'Corning, AR', path: '/trailer-rentals/arkansas/corning', lat: 36.407, lng: -90.579 },
  { name: 'Crossett, AR', path: '/trailer-rentals/arkansas/crossett', lat: 33.128, lng: -91.961 },
  { name: 'Dardanelle, AR', path: '/trailer-rentals/arkansas/dardanelle', lat: 35.223, lng: -93.157 },
  { name: 'De Queen, AR', path: '/trailer-rentals/arkansas/de-queen', lat: 34.037, lng: -94.341 },
  { name: 'De Witt, AR', path: '/trailer-rentals/arkansas/de-witt', lat: 34.292, lng: -91.337 },
  { name: 'Des Arc, AR', path: '/trailer-rentals/arkansas/des-arc', lat: 34.977, lng: -91.498 },
  { name: 'El Dorado, AR', path: '/trailer-rentals/arkansas/el-dorado', lat: 33.207, lng: -92.666 },
  { name: 'England, AR', path: '/trailer-rentals/arkansas/england', lat: 34.544, lng: -91.969 },
  { name: 'Eureka Springs, AR', path: '/trailer-rentals/arkansas/eureka-springs', lat: 36.401, lng: -93.737 },
  { name: 'Fairfield Bay, AR', path: '/trailer-rentals/arkansas/fairfield-bay', lat: 35.597, lng: -92.278 },
  { name: 'Farmington, AR', path: '/trailer-rentals/arkansas/farmington', lat: 36.042, lng: -94.247 },
  { name: 'Fayetteville, AR', path: '/trailer-rentals/arkansas/fayetteville', lat: 36.062, lng: -94.157 },
  { name: 'Flippin, AR', path: '/trailer-rentals/arkansas/flippin', lat: 36.276, lng: -92.591 },
  { name: 'Fordyce, AR', path: '/trailer-rentals/arkansas/fordyce', lat: 33.813, lng: -92.412 },
  { name: 'Forrest City, AR', path: '/trailer-rentals/arkansas/forrest-city', lat: 35.008, lng: -90.789 },
  { name: 'Fort Smith, AR', path: '/trailer-rentals/arkansas/fort-smith', lat: 35.385, lng: -94.398 },
  { name: 'Gassville, AR', path: '/trailer-rentals/arkansas/gassville', lat: 36.283, lng: -92.491 },
  { name: 'Glenwood, AR', path: '/trailer-rentals/arkansas/glenwood', lat: 34.327, lng: -93.548 },
  { name: 'Greenwood, AR', path: '/trailer-rentals/arkansas/greenwood', lat: 35.215, lng: -94.255 },
  { name: 'Gurdon, AR', path: '/trailer-rentals/arkansas/gurdon', lat: 33.917, lng: -93.154 },
  { name: 'Hamburg, AR', path: '/trailer-rentals/arkansas/hamburg', lat: 33.227, lng: -91.797 },
  { name: 'Hampton, AR', path: '/trailer-rentals/arkansas/hampton', lat: 33.537, lng: -92.469 },
  { name: 'Harrison, AR', path: '/trailer-rentals/arkansas/harrison', lat: 36.229, lng: -93.107 },
  { name: 'Heber Springs, AR', path: '/trailer-rentals/arkansas/heber-springs', lat: 35.491, lng: -92.031 },
  { name: 'Helena-West Helena, AR', path: '/trailer-rentals/arkansas/helena-west-helena', lat: 34.529, lng: -90.591 },
  { name: 'Hermitage, AR', path: '/trailer-rentals/arkansas/hermitage', lat: 33.448, lng: -92.171 },
  { name: 'Hope, AR', path: '/trailer-rentals/arkansas/hope', lat: 33.667, lng: -93.591 },
  { name: 'Horseshoe Bend, AR', path: '/trailer-rentals/arkansas/horseshoe-bend', lat: 36.224, lng: -91.750 },
  { name: 'Hot Springs, AR', path: '/trailer-rentals/arkansas/hot-springs', lat: 34.503, lng: -93.055 },
  { name: 'Hoxie, AR', path: '/trailer-rentals/arkansas/hoxie', lat: 36.048, lng: -90.977 },
  { name: 'Huntsville, AR', path: '/trailer-rentals/arkansas/huntsville', lat: 36.086, lng: -93.741 },
  { name: 'Jacksonville, AR', path: '/trailer-rentals/arkansas/jacksonville', lat: 34.866, lng: -92.110 },
  { name: 'Jasper, AR', path: '/trailer-rentals/arkansas/jasper', lat: 36.008, lng: -93.186 },
  { name: 'Jonesboro, AR', path: '/trailer-rentals/arkansas/jonesboro', lat: 35.842, lng: -90.704 },
  { name: 'Lake City, AR', path: '/trailer-rentals/arkansas/lake-city', lat: 35.817, lng: -90.440 },
  { name: 'Lake Village, AR', path: '/trailer-rentals/arkansas/lake-village', lat: 33.331, lng: -91.283 },
  { name: 'Lavaca, AR', path: '/trailer-rentals/arkansas/lavaca', lat: 35.336, lng: -94.178 },
  { name: 'Leachville, AR', path: '/trailer-rentals/arkansas/leachville', lat: 35.935, lng: -90.257 },
  { name: 'Little Rock, AR', path: '/trailer-rentals/arkansas/little-rock', lat: 34.746, lng: -92.290 },
  { name: 'Lonoke, AR', path: '/trailer-rentals/arkansas/lonoke', lat: 34.783, lng: -91.899 },
  { name: 'Magnolia, AR', path: '/trailer-rentals/arkansas/magnolia', lat: 33.267, lng: -93.239 },
  { name: 'Malvern, AR', path: '/trailer-rentals/arkansas/malvern', lat: 34.362, lng: -92.812 },
  { name: 'Mammoth Spring, AR', path: '/trailer-rentals/arkansas/mammoth-spring', lat: 36.496, lng: -91.539 },
  { name: 'Manila, AR', path: '/trailer-rentals/arkansas/manila', lat: 35.879, lng: -90.167 },
  { name: 'Mansfield, AR', path: '/trailer-rentals/arkansas/mansfield', lat: 35.060, lng: -94.246 },
  { name: 'Marianna, AR', path: '/trailer-rentals/arkansas/marianna', lat: 34.773, lng: -90.757 },
  { name: 'Marion, AR', path: '/trailer-rentals/arkansas/marion', lat: 35.214, lng: -90.196 },
  { name: 'Marked Tree, AR', path: '/trailer-rentals/arkansas/marked-tree', lat: 35.532, lng: -90.420 },
  { name: 'McCrory, AR', path: '/trailer-rentals/arkansas/mccrory', lat: 35.256, lng: -91.197 },
  { name: 'McGehee, AR', path: '/trailer-rentals/arkansas/mcgehee', lat: 33.628, lng: -91.394 },
  { name: 'Melbourne, AR', path: '/trailer-rentals/arkansas/melbourne', lat: 36.059, lng: -91.906 },
  { name: 'Mena, AR', path: '/trailer-rentals/arkansas/mena', lat: 34.586, lng: -94.239 },
  { name: 'Monticello, AR', path: '/trailer-rentals/arkansas/monticello', lat: 33.628, lng: -91.790 },
  { name: 'Morrilton, AR', path: '/trailer-rentals/arkansas/morrilton', lat: 35.150, lng: -92.744 },
  { name: 'Mountain Home, AR', path: '/trailer-rentals/arkansas/mountain-home', lat: 36.335, lng: -92.385 },
  { name: 'Mountain View, AR', path: '/trailer-rentals/arkansas/mountain-view', lat: 35.868, lng: -92.117 },
  { name: 'Nashville, AR', path: '/trailer-rentals/arkansas/nashville', lat: 33.945, lng: -93.847 },
  { name: 'Newport, AR', path: '/trailer-rentals/arkansas/newport', lat: 35.605, lng: -91.271 },
];

export const northernColoradoCities: ICity[] = [
  { name: 'Akron, CO', path: '/trailer-rentals/colorado/akron', lat: 40.1803, lng: -103.2259 },
  { name: 'Ault, CO', path: '/trailer-rentals/colorado/ault', lat: 40.5938, lng: -104.7356 },
  { name: 'Arvada, CO', path: '/trailer-rentals/colorado/arvada', lat: 39.802, lng: -105.087 },
  { name: 'Berthoud, CO', path: '/trailer-rentals/colorado/berthoud', lat: 40.308, lng: -105.081 },
  { name: 'Boulder, CO', path: '/trailer-rentals/colorado/boulder', lat: 40.015, lng: -105.270 },
  { name: 'Broomfield, CO', path: '/trailer-rentals/colorado/broomfield', lat: 39.920, lng: -105.086 },
  { name: 'Brighton, CO', path: '/trailer-rentals/colorado/brighton', lat: 39.9636, lng: -104.9072 },
  { name: 'Castle Rock, CO', path: '/trailer-rentals/colorado/castle-rock', lat: 39.372, lng: -104.856 },
  { name: 'Centennial, CO', path: '/trailer-rentals/colorado/centennial', lat: 39.580, lng: -104.877 },
  { name: 'Commerce City, CO', path: '/trailer-rentals/colorado/commerce-city', lat: 39.813, lng: -104.933 },
  { name: 'Denver, CO', path: '/trailer-rentals/colorado/denver', lat: 39.739, lng: -104.990, active: true, showNewVersion: true },
  { name: 'Eaton, CO', path: '/trailer-rentals/colorado/eaton', lat: 40.5273, lng: -104.7146 },
  { name: 'Erie, CO', path: '/trailer-rentals/colorado/erie', lat: 40.050, lng: -105.049 },
  { name: 'Evans, CO', path: '/trailer-rentals/colorado/evans', lat: 40.376, lng: -104.692 },
  { name: 'Federal Heights, CO', path: '/trailer-rentals/colorado/federal-heights', lat: 39.851, lng: -105.014 },
  { name: 'Greeley, CO', path: '/trailer-rentals/colorado/greeley', lat: 40.423, lng: -104.709 },
  { name: 'Johnstown, CO', path: '/trailer-rentals/colorado/johnstown', lat: 40.336, lng: -104.912 },
  { name: 'Lafayette, CO', path: '/trailer-rentals/colorado/lafayette', lat: 39.993, lng: -105.089 },
  { name: 'Lakewood, CO', path: '/trailer-rentals/colorado/lakewood', lat: 39.704, lng: -105.081 },
  { name: 'Longmont, CO', path: '/trailer-rentals/colorado/longmont', lat: 40.167, lng: -105.101 },
  { name: 'Loveland, CO', path: '/trailer-rentals/colorado/loveland', lat: 40.397, lng: -105.075 },
  { name: 'Louisville, CO', path: '/trailer-rentals/colorado/louisville', lat: 39.977, lng: -105.131 },
  { name: 'Mead, CO', path: '/trailer-rentals/colorado/mead', lat: 40.233, lng: -104.998 },
  { name: 'Milliken, CO', path: '/trailer-rentals/colorado/milliken', lat: 40.329, lng: -104.855 },
  { name: 'Northglenn, CO', path: '/trailer-rentals/colorado/northglenn', lat: 39.896, lng: -104.981 },
  { name: 'Thornton, CO', path: '/trailer-rentals/colorado/thornton', lat: 39.868, lng: -104.9719 },
  { name: 'Westminster, CO', path: '/trailer-rentals/colorado/westminster', lat: 39.8367, lng: -105.0372 },
];

export const centralColoradoCities: ICity[] = [
  { name: 'Alamosa, CO', path: '/trailer-rentals/colorado/alamosa', lat: 37.469, lng: -105.870 },
  { name: 'Aspen, CO', path: '/trailer-rentals/colorado/aspen', lat: 39.191, lng: -106.817 },
  { name: 'Aurora, CO', path: '/trailer-rentals/colorado/aurora', lat: 39.729, lng: -104.831 },
  { name: 'Avon, CO', path: '/trailer-rentals/colorado/avon', lat: 39.633, lng: -106.522 },
  { name: 'Basalt, CO', path: '/trailer-rentals/colorado/basalt', lat: 39.368, lng: -107.033 },
  { name: 'Breckenridge, CO', path: '/trailer-rentals/colorado/breckenridge', lat: 39.481, lng: -106.038 },
  { name: 'Buena Vista, CO', path: '/trailer-rentals/colorado/buena-vista', lat: 38.842, lng: -106.131 },
  { name: 'Canon City, CO', path: '/trailer-rentals/colorado/canon-city', lat: 38.441, lng: -105.242 },
  { name: 'Carbondale, CO', path: '/trailer-rentals/colorado/carbondale', lat: 39.402, lng: -107.211 },
  { name: 'Castle Pines, CO', path: '/trailer-rentals/colorado/castle-pines', lat: 39.462, lng: -104.896 },
  { name: 'Cherry Hills Village, CO', path: '/trailer-rentals/colorado/cherry-hills-village', lat: 39.640, lng: -104.959 },
  { name: 'Colorado Springs, CO', path: '/trailer-rentals/colorado/colorado-springs', lat: 38.833, lng: -104.821 },
  { name: 'Columbine, CO', path: '/trailer-rentals/colorado/columbine', lat: 39.587, lng: -105.070 },
  { name: 'Cortez, CO', path: '/trailer-rentals/colorado/cortez', lat: 37.349, lng: -108.585 },
  { name: 'Crested Butte, CO', path: '/trailer-rentals/colorado/crested-butte', lat: 38.869, lng: -106.987 },
  { name: 'Dillon, CO', path: '/trailer-rentals/colorado/dillon', lat: 39.630, lng: -106.043 },
  { name: 'Durango, CO', path: '/trailer-rentals/colorado/durango', lat: 37.275, lng: -107.880 },
  { name: 'Eagle, CO', path: '/trailer-rentals/colorado/eagle', lat: 39.655, lng: -106.828 },
  { name: 'Edgewater, CO', path: '/trailer-rentals/colorado/edgewater', lat: 39.753, lng: -105.064 },
  { name: 'Englewood, CO', path: '/trailer-rentals/colorado/englewood', lat: 39.647, lng: -104.987 },
  { name: 'Estes Park, CO', path: '/trailer-rentals/colorado/estes-park', lat: 40.377, lng: -105.521 },
  { name: 'Federal Heights, CO', path: '/trailer-rentals/colorado/federal-heights', lat: 39.851, lng: -105.014 },
  { name: 'Florence, CO', path: '/trailer-rentals/colorado/florence', lat: 38.390, lng: -105.118 },
  { name: 'Fort Collins, CO', path: '/trailer-rentals/colorado/fort-collins', lat: 40.585, lng: -105.084 },
  { name: 'Fountain, CO', path: '/trailer-rentals/colorado/fountain', lat: 38.682, lng: -104.700 },
  { name: 'Fraser, CO', path: '/trailer-rentals/colorado/fraser', lat: 39.574, lng: -105.817 },
  { name: 'Frederick, CO', path: '/trailer-rentals/colorado/frederick', lat: 40.099, lng: -104.937 },
  { name: 'Frisco, CO', path: '/trailer-rentals/colorado/frisco', lat: 39.574, lng: -106.097 },
  { name: 'Fruita, CO', path: '/trailer-rentals/colorado/fruita', lat: 39.158, lng: -108.728 },
  { name: 'Georgetown, CO', path: '/trailer-rentals/colorado/georgetown', lat: 39.706, lng: -105.697 },
  { name: 'Glendale, CO', path: '/trailer-rentals/colorado/glendale', lat: 39.704, lng: -104.933 },
  { name: 'Glenwood Springs, CO', path: '/trailer-rentals/colorado/glenwood-springs', lat: 39.550, lng: -107.324 },
  { name: 'Golden, CO', path: '/trailer-rentals/colorado/golden', lat: 39.755, lng: -105.221 },
  { name: 'Grand Junction, CO', path: '/trailer-rentals/colorado/grand-junction', lat: 39.063, lng: -108.550 },
  { name: 'Gunnison, CO', path: '/trailer-rentals/colorado/gunnison', lat: 38.545, lng: -106.925 },
  { name: 'Highlands Ranch, CO', path: '/trailer-rentals/colorado/highlands-ranch', lat: 39.553, lng: -104.970 },
  { name: 'La Salle, CO', path: '/trailer-rentals/colorado/la-salle', lat: 40.3211, lng: -104.7268 },
  { name: 'Littleton, CO', path: '/trailer-rentals/colorado/littleton', lat: 39.613, lng: -105.016 },
  { name: 'Lone Tree, CO', path: '/trailer-rentals/colorado/lone-tree', lat: 39.536, lng: -104.897 },
  { name: 'Monument, CO', path: '/trailer-rentals/colorado/monument', lat: 39.091, lng: -104.872 },
  { name: 'Nederland, CO', path: '/trailer-rentals/colorado/nederland', lat: 39.961, lng: -105.510 },
  { name: 'Parker, CO', path: '/trailer-rentals/colorado/parker', lat: 39.518, lng: -104.761 },
  { name: 'Pueblo, CO', path: '/trailer-rentals/colorado/pueblo', lat: 38.254, lng: -104.609 },
  { name: 'Rifle, CO', path: '/trailer-rentals/colorado/rifle', lat: 39.534, lng: -107.783 },
  { name: 'Silverthorne, CO', path: '/trailer-rentals/colorado/silverthorne', lat: 39.6411, lng: -106.108 },
  { name: 'Steamboat Springs, CO', path: '/trailer-rentals/colorado/steamboat-springs', lat: 40.485, lng: -106.8317 },
  { name: 'Vail, CO', path: '/trailer-rentals/colorado/vail', lat: 39.6242, lng: -106.4973 },
  { name: 'Windsor, CO', path: '/trailer-rentals/colorado/windsor', lat: 40.4775, lng: -104.9014 },
];

export const westernColoradoCities: ICity[] = [
  { name: 'Alamosa East, CO', path: '/trailer-rentals/colorado/alamosa-east', lat: 37.4729, lng: -105.8574 },
  { name: 'Bayfield, CO', path: '/trailer-rentals/colorado/bayfield', lat: 37.2603, lng: -107.6137 },
  { name: 'Black Forest, CO', path: '/trailer-rentals/colorado/black-forest', lat: 39.0130, lng: -104.7008 },
  { name: 'Black Hawk, CO', path: '/trailer-rentals/colorado/black-hawk', lat: 39.816, lng: -105.4753 },
  { name: 'Breen, CO', path: '/trailer-rentals/colorado/breen', lat: 37.1925, lng: -108.0779 },
  { name: 'Calhan, CO', path: '/trailer-rentals/colorado/calhan', lat: 38.9648, lng: -104.3553 },
  { name: 'Cedaredge, CO', path: '/trailer-rentals/colorado/cedaredge', lat: 38.9119, lng: -107.9268 },
  { name: 'Coal Creek, CO', path: '/trailer-rentals/colorado/coal-creek', lat: 38.3611, lng: -105.1483 },
  { name: 'Clifton, CO', path: '/trailer-rentals/colorado/clifton', lat: 39.0805, lng: -108.4496 },
  { name: 'Creede, CO', path: '/trailer-rentals/colorado/creede', lat: 37.8164, lng: -106.9277 },
  { name: 'Cripple Creek, CO', path: '/trailer-rentals/colorado/cripple-creek', lat: 38.8261, lng: -105.1499 },
  { name: 'Del Norte, CO', path: '/trailer-rentals/colorado/del-norte', lat: 37.6447, lng: -106.4073 },
  { name: 'Dove Valley, CO', path: '/trailer-rentals/colorado/dove-valley', lat: 39.5768, lng: -104.8305 },
  { name: 'Elk Springs, CO', path: '/trailer-rentals/colorado/elk-springs', lat: 40.3555, lng: -108.4484 },
  { name: 'Grand Junction, CO', path: '/trailer-rentals/colorado/grand-junction', lat: 39.063, lng: -108.550 },
  { name: 'Gunnison, CO', path: '/trailer-rentals/colorado/gunnison', lat: 38.545, lng: -106.925 },
  { name: 'Gypsum, CO', path: '/trailer-rentals/colorado/gypsum', lat: 39.646, lng: -106.951 },
  { name: 'Hayden, CO', path: '/trailer-rentals/colorado/highlands-ranch', lat: 40.4945, lng: -107.2571 },
  { name: 'Hotchkiss, CO', path: '/trailer-rentals/colorado/hotchkiss', lat: 38.8124, lng: -107.7472 },
  { name: 'Idaho Springs, CO', path: '/trailer-rentals/colorado/idaho-springs', lat: 39.7402, lng: -105.5983 },
  { name: 'Julesburg, CO', path: '/trailer-rentals/colorado/julesburg', lat: 40.9708, lng: -102.2575 },
  { name: 'Keenesburg, CO', path: '/trailer-rentals/colorado/keenesburg', lat: 40.0958, lng: -104.4464 },
  { name: 'Kremmling, CO', path: '/trailer-rentals/colorado/kremmling', lat: 40.0632, lng: -106.3955 },
  { name: 'Leadville, CO', path: '/trailer-rentals/colorado/leadville', lat: 39.2497, lng: -106.3015 },
  { name: 'Limon, CO', path: '/trailer-rentals/colorado/limon', lat: 39.2713, lng: -103.6856 },
  { name: 'Lochbuie, CO', path: '/trailer-rentals/colorado/lochbuie', lat: 39.9515, lng: -104.7746 },
  { name: 'Minturn, CO', path: '/trailer-rentals/colorado/minturn', lat: 39.5805, lng: -106.4176 },
  { name: 'Montrose, CO', path: '/trailer-rentals/colorado/montrose', lat: 38.478, lng: -107.876 },
  { name: 'New Castle, CO', path: '/trailer-rentals/colorado/new-castle', lat: 39.5709, lng: -107.5428 },
  { name: 'Orchard City, CO', path: '/trailer-rentals/colorado/orchard-city', lat: 38.8391, lng: -107.9649 },
  { name: 'Pagosa Springs, CO', path: '/trailer-rentals/colorado/pagosa-springs', lat: 37.269, lng: -107.009 },
  { name: 'Parachute, CO', path: '/trailer-rentals/colorado/parachute', lat: 39.4519, lng: -108.0529 },
  { name: 'Penrose, CO', path: '/trailer-rentals/colorado/penrose', lat: 38.4336, lng: -105.0113 },
  { name: 'Peyton, CO', path: '/trailer-rentals/colorado/peyton', lat: 38.9541, lng: -104.5472 },
  { name: 'Platteville, CO', path: '/trailer-rentals/colorado/platteville', lat: 40.2131, lng: -104.8028 },
  { name: 'Rangely, CO', path: '/trailer-rentals/colorado/rangely', lat: 40.0828, lng: -108.7991 },
  { name: 'Redlands, CO', path: '/trailer-rentals/colorado/redlands', lat: 39.1038, lng: -108.6829 },
  { name: 'Rocky Ford, CO', path: '/trailer-rentals/colorado/rocky-ford', lat: 38.049, lng: -103.7251 },
  { name: 'Saguache, CO', path: '/trailer-rentals/colorado/saguache', lat: 38.0977, lng: -106.1876 },
  { name: 'Salida, CO', path: '/trailer-rentals/colorado/salida', lat: 38.5259, lng: -105.9978 },
  { name: 'Security-Widefield, CO', path: '/trailer-rentals/colorado/security-widefield', lat: 38.7641, lng: -104.7404 },
  { name: 'Silverton, CO', path: '/trailer-rentals/colorado/silverton', lat: 37.809, lng: -107.6667 },
  { name: 'Snowmass Village, CO', path: '/trailer-rentals/colorado/snowmass-village', lat: 39.2212, lng: -106.932 },
  { name: 'Sterling, CO', path: '/trailer-rentals/colorado/sterling', lat: 40.6306, lng: -103.2212 },
  { name: 'Telluride, CO', path: '/trailer-rentals/colorado/telluride', lat: 37.94, lng: -107.8214 },
  { name: 'Timnath, CO', path: '/trailer-rentals/colorado/timnath', lat: 40.5291, lng: -104.9853 },
  { name: 'Trinidad, CO', path: '/trailer-rentals/colorado/trinidad', lat: 37.2691, lng: -104.5038 },
  { name: 'Woodland Park, CO', path: '/trailer-rentals/colorado/woodland-park', lat: 38.9969, lng: -105.0623 },
  { name: 'Yuma, CO', path: '/trailer-rentals/colorado/yuma', lat: 40.1301, lng: -102.7072 },
];

export const southernColoradoCities: ICity[] = [
  { name: 'Alamosa East, CO', path: '/trailer-rentals/colorado/alamosa-east', lat: 37.4729, lng: -105.8574 },
  { name: 'Lamar, CO', path: '/trailer-rentals/colorado/lamar', lat: 38.087, lng: -102.620 },
  { name: 'La Junta, CO', path: '/trailer-rentals/colorado/la-junta', lat: 37.9546, lng: -103.6644 },
  { name: 'Larkspur, CO', path: '/trailer-rentals/colorado/larkspur', lat: 37.9341, lng: -122.5353 },
  { name: 'La Salle, CO', path: '/trailer-rentals/colorado/la-salle', lat: 40.3211, lng: -104.7268 },
  { name: 'Pueblo, CO', path: '/trailer-rentals/colorado/pueblo', lat: 38.254, lng: -104.609 },
  { name: 'Penrose, CO', path: '/trailer-rentals/colorado/penrose', lat: 38.4336, lng: -105.0113 },
  { name: 'Rangely, CO', path: '/trailer-rentals/colorado/rangely', lat: 40.0828, lng: -108.7991 },
  { name: 'Wray, CO', path: '/trailer-rentals/colorado/wray', lat: 40.0685, lng: -102.393 },
];

export const connecticutCities: ICity[] = [
  { name: 'Ansonia, CT', path: '/trailer-rentals/connecticut/ansonia', lat: 41.346, lng: -73.079 },
  { name: 'Avon, CT', path: '/trailer-rentals/connecticut/avon', lat: 41.809, lng: -72.830 },
  { name: 'Bethel, CT', path: '/trailer-rentals/connecticut/bethel', lat: 41.371, lng: -73.414 },
  { name: 'Bloomfield, CT', path: '/trailer-rentals/connecticut/bloomfield', lat: 41.826, lng: -72.730 },
  { name: 'Bolton, CT', path: '/trailer-rentals/connecticut/bolton', lat: 41.768, lng: -72.435 },
  { name: 'Branford, CT', path: '/trailer-rentals/connecticut/branford', lat: 41.279, lng: -72.815 },
  { name: 'Bridgeport, CT', path: '/trailer-rentals/connecticut/bridgeport', lat: 41.186, lng: -73.195 },
  { name: 'Bristol, CT', path: '/trailer-rentals/connecticut/bristol', lat: 41.671, lng: -72.949 },
  { name: 'Brookfield, CT', path: '/trailer-rentals/connecticut/brookfield', lat: 41.482, lng: -73.409 },
  { name: 'Burlington, CT', path: '/trailer-rentals/connecticut/burlington', lat: 41.769, lng: -72.964 },
  { name: 'Canton, CT', path: '/trailer-rentals/connecticut/canton', lat: 41.824, lng: -72.892 },
  { name: 'Cheshire, CT', path: '/trailer-rentals/connecticut/cheshire', lat: 41.498, lng: -72.900 },
  { name: 'Clinton, CT', path: '/trailer-rentals/connecticut/clinton', lat: 41.278, lng: -72.528 },
  { name: 'Colchester, CT', path: '/trailer-rentals/connecticut/colchester', lat: 41.575, lng: -72.332 },
  { name: 'Coventry, CT', path: '/trailer-rentals/connecticut/coventry', lat: 41.784, lng: -72.336 },
  { name: 'Cromwell, CT', path: '/trailer-rentals/connecticut/cromwell', lat: 41.595, lng: -72.645 },
  { name: 'Danbury, CT', path: '/trailer-rentals/connecticut/danbury', lat: 41.394, lng: -73.454 },
  { name: 'Darien, CT', path: '/trailer-rentals/connecticut/darien', lat: 41.078, lng: -73.469 },
  { name: 'Derby, CT', path: '/trailer-rentals/connecticut/derby', lat: 41.320, lng: -73.089 },
  { name: 'Durham, CT', path: '/trailer-rentals/connecticut/durham', lat: 41.475, lng: -72.682 },
  { name: 'East Granby, CT', path: '/trailer-rentals/connecticut/east-granby', lat: 41.941, lng: -72.726 },
  { name: 'East Haddam, CT', path: '/trailer-rentals/connecticut/east-haddam', lat: 41.453, lng: -72.460 },
  { name: 'East Hampton, CT', path: '/trailer-rentals/connecticut/east-hampton', lat: 41.575, lng: -72.502 },
  { name: 'East Hartford, CT', path: '/trailer-rentals/connecticut/east-hartford', lat: 41.782, lng: -72.612 },
  { name: 'East Haven, CT', path: '/trailer-rentals/connecticut/east-haven', lat: 41.276, lng: -72.868 },
  { name: 'East Lyme, CT', path: '/trailer-rentals/connecticut/east-lyme', lat: 41.353, lng: -72.226 },
  { name: 'East Windsor, CT', path: '/trailer-rentals/connecticut/east-windsor', lat: 41.907, lng: -72.620 },
  { name: 'Enfield, CT', path: '/trailer-rentals/connecticut/enfield', lat: 41.976, lng: -72.591 },
  { name: 'Essex, CT', path: '/trailer-rentals/connecticut/essex', lat: 41.352, lng: -72.392 },
  { name: 'Fairfield, CT', path: '/trailer-rentals/connecticut/fairfield', lat: 41.141, lng: -73.263 },
  { name: 'Farmington, CT', path: '/trailer-rentals/connecticut/farmington', lat: 41.719, lng: -72.832 },
  { name: 'Glastonbury, CT', path: '/trailer-rentals/connecticut/glastonbury', lat: 41.712, lng: -72.608 },
  { name: 'Granby, CT', path: '/trailer-rentals/connecticut/granby', lat: 41.957, lng: -72.791 },
  { name: 'Greenwich, CT', path: '/trailer-rentals/connecticut/greenwich', lat: 41.027, lng: -73.628 },
  { name: 'Griswold, CT', path: '/trailer-rentals/connecticut/griswold', lat: 41.606, lng: -71.978 },
  { name: 'Groton, CT', path: '/trailer-rentals/connecticut/groton', lat: 41.349, lng: -72.079 },
  { name: 'Guilford, CT', path: '/trailer-rentals/connecticut/guilford', lat: 41.288, lng: -72.683 },
  { name: 'Haddam, CT', path: '/trailer-rentals/connecticut/haddam', lat: 41.481, lng: -72.503 },
  { name: 'Hamden, CT', path: '/trailer-rentals/connecticut/hamden', lat: 41.395, lng: -72.896 },
  { name: 'Hartford, CT', path: '/trailer-rentals/connecticut/hartford', lat: 41.763, lng: -72.685 },
  { name: 'Hebron, CT', path: '/trailer-rentals/connecticut/hebron', lat: 41.661, lng: -72.366 },
  { name: 'Kent, CT', path: '/trailer-rentals/connecticut/kent', lat: 41.724, lng: -73.477 },
  { name: 'Killingly, CT', path: '/trailer-rentals/connecticut/killingly', lat: 41.841, lng: -71.887 },
  { name: 'Killingworth, CT', path: '/trailer-rentals/connecticut/killingworth', lat: 41.358, lng: -72.566 },
  { name: 'Ledyard, CT', path: '/trailer-rentals/connecticut/ledyard', lat: 41.439, lng: -72.010 },
  { name: 'Litchfield, CT', path: '/trailer-rentals/connecticut/litchfield', lat: 41.747, lng: -73.189 },
  { name: 'Madison, CT', path: '/trailer-rentals/connecticut/madison', lat: 41.279, lng: -72.598 },
  { name: 'Manchester, CT', path: '/trailer-rentals/connecticut/manchester', lat: 41.775, lng: -72.523  },
  { name: 'Mansfield, CT', path: '/trailer-rentals/connecticut/mansfield', lat: 41.765, lng: -72.217 },
  { name: 'Meriden, CT', path: '/trailer-rentals/connecticut/meriden', lat: 41.538, lng: -72.807 },
  { name: 'Middlebury, CT', path: '/trailer-rentals/connecticut/middlebury', lat: 41.528, lng: -73.121 },
  { name: 'Middlefield, CT', path: '/trailer-rentals/connecticut/middlefield', lat: 41.515, lng: -72.718 },
  { name: 'Middletown, CT', path: '/trailer-rentals/connecticut/middletown', lat: 41.562, lng: -72.650 },
  { name: 'Milford, CT', path: '/trailer-rentals/connecticut/milford', lat: 41.222, lng: -73.056 },
  { name: 'Monroe, CT', path: '/trailer-rentals/connecticut/monroe', lat: 41.332, lng: -73.207 },
  { name: 'Montville, CT', path: '/trailer-rentals/connecticut/montville', lat: 41.462, lng: -72.150 },
  { name: 'Naugatuck, CT', path: '/trailer-rentals/connecticut/naugatuck', lat: 41.485, lng: -73.050 },
  { name: 'New Britain, CT', path: '/trailer-rentals/connecticut/new-britain', lat: 41.661, lng: -72.779 },
  { name: 'New Canaan, CT', path: '/trailer-rentals/connecticut/new-canaan', lat: 41.146, lng: -73.494 },
  { name: 'New Fairfield, CT', path: '/trailer-rentals/connecticut/new-fairfield', lat: 41.467, lng: -73.485 },
  { name: 'New Hartford, CT', path: '/trailer-rentals/connecticut/new-hartford', lat: 41.878, lng: -73.016 },
  { name: 'New Haven, CT', path: '/trailer-rentals/connecticut/new-haven', lat: 41.308, lng: -72.928 },
  { name: 'New London, CT', path: '/trailer-rentals/connecticut/new-london', lat: 41.355, lng: -72.100 },
  { name: 'New Milford, CT', path: '/trailer-rentals/connecticut/new-milford', lat: 41.577, lng: -73.408 },
  { name: 'Newington, CT', path: '/trailer-rentals/connecticut/newington', lat: 41.697, lng: -72.724 },
  { name: 'Newtown, CT', path: '/trailer-rentals/connecticut/newtown', lat: 41.414, lng: -73.303 },
  { name: 'North Branford, CT', path: '/trailer-rentals/connecticut/north-branford', lat: 41.327, lng: -72.769 },
  { name: 'North Haven, CT', path: '/trailer-rentals/connecticut/north-haven', lat: 41.390, lng: -72.859 },
  { name: 'Norwalk, CT', path: '/trailer-rentals/connecticut/norwalk', lat: 41.117, lng: -73.408 },
  { name: 'Norwich, CT', path: '/trailer-rentals/connecticut/norwich', lat: 41.524, lng: -72.075 },
  { name: 'Old Lyme, CT', path: '/trailer-rentals/connecticut/old-lyme', lat: 41.315, lng: -72.327 },
  { name: 'Old Saybrook, CT', path: '/trailer-rentals/connecticut/old-saybrook', lat: 41.291, lng: -72.375 },
  { name: 'Orange, CT', path: '/trailer-rentals/connecticut/orange', lat: 41.278, lng: -73.026 },
  { name: 'Plainfield, CT', path: '/trailer-rentals/connecticut/plainfield', lat: 41.678, lng: -71.915 },
  { name: 'Plainville, CT', path: '/trailer-rentals/connecticut/plainville', lat: 41.674, lng: -72.863 },
  { name: 'Plymouth, CT', path: '/trailer-rentals/connecticut/plymouth', lat: 41.675, lng: -73.051 },
  { name: 'Portland, CT', path: '/trailer-rentals/connecticut/portland', lat: 41.588, lng: -72.630 },
  { name: 'Preston, CT', path: '/trailer-rentals/connecticut/preston', lat: 41.520, lng: -71.973 },
  { name: 'Prospect, CT', path: '/trailer-rentals/connecticut/prospect', lat: 41.502, lng: -72.978 },
  { name: 'Ridgefield, CT', path: '/trailer-rentals/connecticut/ridgefield', lat: 41.281, lng: -73.497 },
];

export const georgiaCities: ICity[] = [
  { name: 'Acworth, GA', path: '/trailer-rentals/georgia/acworth', lat: 34.070, lng: -84.664 },
  { name: 'Albany, GA', path: '/trailer-rentals/georgia/albany', lat: 31.578, lng: -84.155 },
  { name: 'Alpharetta, GA', path: '/trailer-rentals/georgia/alpharetta', lat: 34.075, lng: -84.294 },
  { name: 'Americus, GA', path: '/trailer-rentals/georgia/americus', lat: 32.066, lng: -84.222 },
  { name: 'Athens, GA', path: '/trailer-rentals/georgia/athens', lat: 33.951, lng: -83.357 },
  { name: 'Atlanta, GA', path: '/trailer-rentals/georgia/atlanta', lat: 33.749, lng: -84.388, active: true, showNewVersion: true },
  { name: 'Augusta, GA', path: '/trailer-rentals/georgia/augusta', lat: 33.473, lng: -82.010 },
  { name: 'Bainbridge, GA', path: '/trailer-rentals/georgia/bainbridge', lat: 30.903, lng: -84.573 },
  { name: 'Barnesville, GA', path: '/trailer-rentals/georgia/barnesville', lat: 33.052, lng: -84.157 },
  { name: 'Blairsville, GA', path: '/trailer-rentals/georgia/blairsville', lat: 34.877, lng: -83.933 },
  { name: 'Brunswick, GA', path: '/trailer-rentals/georgia/brunswick', lat: 31.149, lng: -81.491 },
  { name: 'Buford, GA', path: '/trailer-rentals/georgia/buford', lat: 34.100, lng: -83.993 },
  { name: 'Calhoun, GA', path: '/trailer-rentals/georgia/calhoun', lat: 34.500, lng: -84.951 },
  { name: 'Canton, GA', path: '/trailer-rentals/georgia/canton', lat: 34.282, lng: -84.490 },
  { name: 'Carrollton, GA', path: '/trailer-rentals/georgia/carrollton', lat: 33.577, lng: -85.073 },
  { name: 'Cartersville, GA', path: '/trailer-rentals/georgia/cartersville', lat: 34.168, lng: -84.799, active: true, showNewVersion: true },
  { name: 'Cedartown, GA', path: '/trailer-rentals/georgia/cedartown', lat: 34.022, lng: -85.249 },
  { name: 'Chatsworth, GA', path: '/trailer-rentals/georgia/chatsworth', lat: 34.774, lng: -84.760 },
  { name: 'Chamblee, GA', path: '/trailer-rentals/georgia/chamblee', lat: 33.900, lng: -84.295 },
  { name: 'Clarkston, GA', path: '/trailer-rentals/georgia/clarkston', lat: 33.795, lng: -84.233 },
  { name: 'Claxton, GA', path: '/trailer-rentals/georgia/claxton', lat: 32.195, lng: -81.965 },
  { name: 'Cleveland, GA', path: '/trailer-rentals/georgia/cleveland', lat: 34.570, lng: -83.749 },
  { name: 'College Park, GA', path: '/trailer-rentals/georgia/college-park', lat: 33.653, lng: -84.454 },
  { name: 'Columbus, GA', path: '/trailer-rentals/georgia/columbus', lat: 32.460, lng: -84.987 },
  { name: 'Conyers, GA', path: '/trailer-rentals/georgia/conyers', lat: 33.678, lng: -84.002 },
  { name: 'Cordele, GA', path: '/trailer-rentals/georgia/cordele', lat: 31.978, lng: -83.764 },
  { name: 'Covington, GA', path: '/trailer-rentals/georgia/covington', lat: 33.596, lng: -83.861 },
  { name: 'Cumming, GA', path: '/trailer-rentals/georgia/cumming', lat: 34.207, lng: -84.140 },
  { name: 'Dallas, GA', path: '/trailer-rentals/georgia/dallas', lat: 33.923710, lng: -84.840770, active: true, showNewVersion: true  },
  { name: 'Dalton, GA', path: '/trailer-rentals/georgia/dalton', lat: 34.770, lng: -84.970 },
  { name: 'Decatur, GA', path: '/trailer-rentals/georgia/decatur', lat: 33.774, lng: -84.296 },
  { name: 'Doraville, GA', path: '/trailer-rentals/georgia/doraville', lat: 33.894, lng: -84.294 },
  { name: 'Douglas, GA', path: '/trailer-rentals/georgia/douglas', lat: 33.669, lng: -84.445 },
  { name: 'Dublin, GA', path: '/trailer-rentals/georgia/dublin', lat: 32.540, lng: -83.034 },
  { name: 'Duluth, GA', path: '/trailer-rentals/georgia/duluth', lat: 34.002, lng: -84.144 },
  { name: 'East Point, GA', path: '/trailer-rentals/georgia/east-point', lat: 33.685, lng: -84.434 },
  { name: 'Eatonton, GA', path: '/trailer-rentals/georgia/eatonton', lat: 33.120, lng: -83.400 },
  { name: 'Elberton, GA', path: '/trailer-rentals/georgia/elberton', lat: 34.112, lng: -82.860 },
  { name: 'Fairburn, GA', path: '/trailer-rentals/georgia/fairburn', lat: 33.579, lng: -84.589 },
  { name: 'Fayetteville, GA', path: '/trailer-rentals/georgia/fayetteville', lat: 33.448, lng: -84.461 },
  { name: 'Flowery Branch, GA', path: '/trailer-rentals/georgia/flowery-branch', lat: 34.182, lng: -83.918 },
  { name: 'Forest Park, GA', path: '/trailer-rentals/georgia/forest-park', lat: 33.650, lng: -84.365 },
  { name: 'Gainesville, GA', path: '/trailer-rentals/georgia/gainesville', lat: 34.303, lng: -83.824 },
  { name: 'Griffin, GA', path: '/trailer-rentals/georgia/griffin', lat: 33.246, lng: -84.261 },
  { name: 'Hapeville, GA', path: '/trailer-rentals/georgia/hapeville', lat: 33.653, lng: -84.387 },
  { name: 'Hinesville, GA', path: '/trailer-rentals/georgia/hinesville', lat: 31.825, lng: -81.599 },
  { name: 'Jackson, GA', path: '/trailer-rentals/georgia/jackson', lat: 33.295, lng: -83.965 },
  { name: 'Jasper, GA', path: '/trailer-rentals/georgia/jasper', lat: 34.468, lng: -84.478 },
  { name: 'Jefferson, GA', path: '/trailer-rentals/georgia/jefferson', lat: 34.127, lng: -83.579 },
  { name: 'Jonesboro, GA', path: '/trailer-rentals/georgia/jonesboro', lat: 33.533, lng: -84.358 },
  { name: 'Kennesaw, GA', path: '/trailer-rentals/georgia/kennesaw', lat: 34.024, lng: -84.620 },
  { name: 'Kingsland, GA', path: '/trailer-rentals/georgia/kingsland', lat: 30.895, lng: -81.697 },
  { name: 'LaGrange, GA', path: '/trailer-rentals/georgia/lagrange', lat: 33.037, lng: -85.031 },
  { name: 'Lawrenceville, GA', path: '/trailer-rentals/georgia/lawrenceville', lat: 33.956, lng: -83.987 },
  { name: 'Lilburn, GA', path: '/trailer-rentals/georgia/lilburn', lat: 33.887, lng: -84.147 },
  { name: 'Lithonia, GA', path: '/trailer-rentals/georgia/lithonia', lat: 33.725, lng: -84.096 },
  { name: 'Locust Grove, GA', path: '/trailer-rentals/georgia/locust-grove', lat: 33.344, lng: -84.100 },
  { name: 'Loganville, GA', path: '/trailer-rentals/georgia/loganville', lat: 33.815, lng: -83.899, active: true, showNewVersion: true  },
  { name: 'Mableton, GA', path: '/trailer-rentals/georgia/mableton', lat: 33.817, lng: -84.578 },
  { name: 'Macon, GA', path: '/trailer-rentals/georgia/macon', lat: 32.840, lng: -83.632 },
  { name: 'Madison, GA', path: '/trailer-rentals/georgia/madison', lat: 33.597, lng: -83.463 },
  { name: 'Marietta, GA', path: '/trailer-rentals/georgia/marietta', lat: 33.952, lng: -84.549 },
  { name: 'McDonough, GA', path: '/trailer-rentals/georgia/mcdonough', lat: 33.448, lng: -84.146 },
  { name: 'Milledgeville, GA', path: '/trailer-rentals/georgia/milledgeville', lat: 33.080, lng: -83.225 },
  { name: 'Monroe, GA', path: '/trailer-rentals/georgia/monroe', lat: 33.796, lng: -83.713 },
  { name: 'Moultrie, GA', path: '/trailer-rentals/georgia/moultrie', lat: 31.162, lng: -83.775 },
  { name: 'Newnan, GA', path: '/trailer-rentals/georgia/newnan', lat: 33.371, lng: -84.801 },
  { name: 'Norcross, GA', path: '/trailer-rentals/georgia/norcross', lat: 33.941, lng: -84.217 },
  { name: 'Peachtree City, GA', path: '/trailer-rentals/georgia/peachtree-city', lat: 33.396, lng: -84.590 },
  { name: 'Perry, GA', path: '/trailer-rentals/georgia/perry', lat: 32.457, lng: -83.726 },
  { name: 'Pooler, GA', path: '/trailer-rentals/georgia/pooler', lat: 32.099, lng: -81.227 },
  { name: 'Powder Springs, GA', path: '/trailer-rentals/georgia/powder-springs', lat: 33.857, lng: -84.654, active: true, showNewVersion: true  },
  { name: 'Riverdale, GA', path: '/trailer-rentals/georgia/riverdale', lat: 33.588, lng: -84.401 },
  { name: 'Rome, GA', path: '/trailer-rentals/georgia/rome', lat: 34.257, lng: -85.164 },
  { name: 'Roswell, GA', path: '/trailer-rentals/georgia/roswell', lat: 34.023, lng: -84.362 },
  { name: 'Sandy Springs, GA', path: '/trailer-rentals/georgia/sandy-springs', lat: 33.925, lng: -84.379 },
  { name: 'Savannah, GA', path: '/trailer-rentals/georgia/savannah', lat: 32.083, lng: -81.099 },
  { name: 'Smyrna, GA', path: '/trailer-rentals/georgia/smyrna', lat: 33.883, lng: -84.514 },
  { name: 'Snellville, GA', path: '/trailer-rentals/georgia/snellville', lat: 33.857, lng: -84.021 },
  { name: 'Statesboro, GA', path: '/trailer-rentals/georgia/statesboro', lat: 32.448, lng: -81.782 },
  { name: 'Stone Mountain, GA', path: '/trailer-rentals/georgia/stone-mountain', lat: 33.805, lng: -84.170 },
];

export const floridaCities: ICity[] = [
  { name: 'Alachua, FL', path: '/trailer-rentals/florida/alachua', lat: 29.751, lng: -82.424 },
  { name: 'Altamonte Springs, FL', path: '/trailer-rentals/florida/altamonte-springs', lat: 28.661, lng: -81.365 },
  { name: 'Apalachicola, FL', path: '/trailer-rentals/florida/apalachicola', lat: 29.725, lng: -84.983 },
  { name: 'Apopka, FL', path: '/trailer-rentals/florida/apopka', lat: 28.693, lng: -81.532 },
  { name: 'Atlantic Beach, FL', path: '/trailer-rentals/florida/atlantic-beach', lat: 30.334, lng: -81.398 },
  { name: 'Aventura, FL', path: '/trailer-rentals/florida/aventura', lat: 25.956, lng: -80.139 },
  { name: 'Bartow, FL', path: '/trailer-rentals/florida/bartow', lat: 27.896, lng: -81.843 },
  { name: 'Bay Harbor Islands, FL', path: '/trailer-rentals/florida/bay-harbor-islands', lat: 25.887, lng: -80.131 },
  { name: 'Boca Raton, FL', path: '/trailer-rentals/florida/boca-raton', lat: 26.358, lng: -80.083 },
  { name: 'Bonita Springs, FL', path: '/trailer-rentals/florida/bonita-springs', lat: 26.339, lng: -81.778 },
  { name: 'Boynton Beach, FL', path: '/trailer-rentals/florida/boynton-beach', lat: 26.531, lng: -80.090 },
  { name: 'Bradenton, FL', path: '/trailer-rentals/florida/bradenton', lat: 27.498, lng: -82.574 },
  { name: 'Cape Canaveral, FL', path: '/trailer-rentals/florida/cape-canaveral', lat: 28.392, lng: -80.607 },
  { name: 'Cape Coral, FL', path: '/trailer-rentals/florida/cape-coral', lat: 26.562, lng: -81.949 },
  { name: 'Casselberry, FL', path: '/trailer-rentals/florida/casselberry', lat: 28.677, lng: -81.327 },
  { name: 'Clearwater, FL', path: '/trailer-rentals/florida/clearwater', lat: 27.965, lng: -82.800 },
  { name: 'Clermont, FL', path: '/trailer-rentals/florida/clermont', lat: 28.549, lng: -81.772 },
  { name: 'Cocoa, FL', path: '/trailer-rentals/florida/cocoa', lat: 28.386, lng: -80.741 },
  { name: 'Cocoa Beach, FL', path: '/trailer-rentals/florida/cocoa-beach', lat: 28.320, lng: -80.607 },
  { name: 'Coral Gables, FL', path: '/trailer-rentals/florida/coral-gables', lat: 25.721, lng: -80.268 },
  { name: 'Coral Springs, FL', path: '/trailer-rentals/florida/coral-springs', lat: 26.271, lng: -80.270 },
  { name: 'Crystal River, FL', path: '/trailer-rentals/florida/crystal-river', lat: 28.902, lng: -82.592 },
  { name: 'Dania Beach, FL', path: '/trailer-rentals/florida/dania-beach', lat: 26.052, lng: -80.143 },
  { name: 'Davie, FL', path: '/trailer-rentals/florida/davie', lat: 26.062, lng: -80.233 },
  { name: 'Daytona Beach, FL', path: '/trailer-rentals/florida/daytona-beach', lat: 29.210, lng: -81.022 },
  { name: 'Deerfield Beach, FL', path: '/trailer-rentals/florida/deerfield-beach', lat: 26.318, lng: -80.099 },
  { name: 'Delray Beach, FL', path: '/trailer-rentals/florida/delray-beach', lat: 26.461, lng: -80.072 },
  { name: 'Destin, FL', path: '/trailer-rentals/florida/destin', lat: 30.393, lng: -86.495 },
  { name: 'Dover, FL', path: '/trailer-rentals/florida/dover', lat: 27.9947, lng: -82.2195, active: true, showNewVersion: true  },
  { name: 'Dunedin, FL', path: '/trailer-rentals/florida/dunedin', lat: 28.019, lng: -82.771 },
  { name: 'Edgewater, FL', path: '/trailer-rentals/florida/edgewater', lat: 28.988, lng: -80.902 },
  { name: 'Eustis, FL', path: '/trailer-rentals/florida/eustis', lat: 28.852, lng: -81.685 },
  { name: 'Fernandina Beach, FL', path: '/trailer-rentals/florida/fernandina-beach', lat: 30.669, lng: -81.462 },
  { name: 'Fort Lauderdale, FL', path: '/trailer-rentals/florida/fort-lauderdale', lat: 26.122, lng: -80.137 },
  { name: 'Fort Myers, FL', path: '/trailer-rentals/florida/fort-myers', lat: 26.640, lng: -81.872 },
  { name: 'Fort Pierce, FL', path: '/trailer-rentals/florida/fort-pierce', lat: 27.448, lng: -80.325 },
  { name: 'Gainesville, FL', path: '/trailer-rentals/florida/gainesville', lat: 29.651, lng: -82.324 },
  { name: 'Gulf Breeze, FL', path: '/trailer-rentals/florida/gulf-breeze', lat: 30.357, lng: -87.163 },
  { name: 'Hallandale Beach, FL', path: '/trailer-rentals/florida/hallandale-beach', lat: 25.981, lng: -80.148 },
  { name: 'Hialeah, FL', path: '/trailer-rentals/florida/hialeah', lat: 25.857, lng: -80.278 },
  { name: 'Hollywood, FL', path: '/trailer-rentals/florida/hollywood', lat: 26.011, lng: -80.149 },
  { name: 'Homestead, FL', path: '/trailer-rentals/florida/homestead', lat: 25.468, lng: -80.477 },
  { name: 'Jacksonville, FL', path: '/trailer-rentals/florida/jacksonville', lat: 30.332, lng: -81.655, active: true, showNewVersion: true  },
  { name: 'Jupiter, FL', path: '/trailer-rentals/florida/jupiter', lat: 26.934, lng: -80.094 },
  { name: 'Key West, FL', path: '/trailer-rentals/florida/key-west', lat: 24.555, lng: -81.782 },
  { name: 'Kissimmee, FL', path: '/trailer-rentals/florida/kissimmee', lat: 28.292, lng: -81.407 },
  { name: 'Lake City, FL', path: '/trailer-rentals/florida/lake-city', lat: 30.189, lng: -82.639 },
  { name: 'Lake Mary, FL', path: '/trailer-rentals/florida/lake-mary', lat: 28.758, lng: -81.317 },
  { name: 'Lakeland, FL', path: '/trailer-rentals/florida/lakeland', lat: 28.039, lng: -81.949 },
  { name: 'Largo, FL', path: '/trailer-rentals/florida/largo', lat: 27.909, lng: -82.788 },
  { name: 'Leesburg, FL', path: '/trailer-rentals/florida/leesburg', lat: 28.810, lng: -81.878 },
  { name: 'Longwood, FL', path: '/trailer-rentals/florida/longwood', lat: 28.703, lng: -81.339 },
  { name: 'Maitland, FL', path: '/trailer-rentals/florida/maitland', lat: 28.627, lng: -81.363 },
  { name: 'Marco Island, FL', path: '/trailer-rentals/florida/marco-island', lat: 25.939, lng: -81.718 },
  { name: 'Melbourne, FL', path: '/trailer-rentals/florida/melbourne', lat: 28.083, lng: -80.608 },
  { name: 'Miami, FL', path: '/trailer-rentals/florida/miami', lat: 25.761, lng: -80.191 },
  { name: 'Miami Beach, FL', path: '/trailer-rentals/florida/miami-beach', lat: 25.792, lng: -80.135 },
  { name: 'Miramar, FL', path: '/trailer-rentals/florida/miramar', lat: 25.987, lng: -80.232 },
  { name: 'Naples, FL', path: '/trailer-rentals/florida/naples', lat: 26.142, lng: -81.794 },
  { name: 'New Port Richey, FL', path: '/trailer-rentals/florida/new-port-richey', lat: 28.244, lng: -82.719 },
  { name: 'North Miami, FL', path: '/trailer-rentals/florida/north-miami', lat: 25.890, lng: -80.186 },
  { name: 'North Port, FL', path: '/trailer-rentals/florida/north-port', lat: 27.044, lng: -82.235 },
  { name: 'Ocala, FL', path: '/trailer-rentals/florida/ocala', lat: 29.187, lng: -82.140 },
  { name: 'Ocoee, FL', path: '/trailer-rentals/florida/ocoee', lat: 28.569, lng: -81.544 },
  { name: 'Orlando, FL', path: '/trailer-rentals/florida/orlando', lat: 28.538, lng: -81.379 },
  { name: 'Ormond Beach, FL', path: '/trailer-rentals/florida/ormond-beach', lat: 29.285, lng: -81.055 },
  { name: 'Palatka, FL', path: '/trailer-rentals/florida/palatka', lat: 29.648, lng: -81.637 },
  { name: 'Palm Bay, FL', path: '/trailer-rentals/florida/palm-bay', lat: 28.034, lng: -80.588 },
  { name: 'Palm Beach, FL', path: '/trailer-rentals/florida/palm-beach', lat: 26.705, lng: -80.036 },
  { name: 'Panama City, FL', path: '/trailer-rentals/florida/panama-city', lat: 30.158, lng: -85.660 },
  { name: 'Pensacola, FL', path: '/trailer-rentals/florida/pensacola', lat: 30.421, lng: -87.217 },
  { name: 'Plantation, FL', path: '/trailer-rentals/florida/plantation', lat: 26.127, lng: -80.233 },
  { name: 'Pompano Beach, FL', path: '/trailer-rentals/florida/pompano-beach', lat: 26.237, lng: -80.124 },
  { name: 'Port Orange, FL', path: '/trailer-rentals/florida/port-orange', lat: 29.138, lng: -80.995 },
  { name: 'Port St. Lucie, FL', path: '/trailer-rentals/florida/port-st-lucie', lat: 27.273, lng: -80.358 },
  { name: 'Punta Gorda, FL', path: '/trailer-rentals/florida/punta-gorda', lat: 26.929, lng: -82.045 },
  { name: 'Riverview, FL', path: '/trailer-rentals/florida/riverview', lat: 27.8661, lng: -82.3265, active: true, showNewVersion: true  },
  { name: 'Sanford, FL', path: '/trailer-rentals/florida/sanford', lat: 28.802, lng: -81.273 },
  { name: 'Sarasota, FL', path: '/trailer-rentals/florida/sarasota', lat: 27.336, lng: -82.530 },
  { name: 'Sebastian, FL', path: '/trailer-rentals/florida/sebastian', lat: 27.816, lng: -80.470 },
  { name: 'St. Augustine, FL', path: '/trailer-rentals/florida/st-augustine', lat: 29.901, lng: -81.312 },
  { name: 'Tallahassee, FL', path: '/trailer-rentals/florida/tallahassee', lat: 30.438, lng: -84.281 },
  { name: 'Tampa, FL', path: '/trailer-rentals/florida/tampa', lat: 27.947, lng: -82.458, active: true, showNewVersion: true  },
];

export const illinoisCities: ICity[] = [
  { name: 'Addison, IL', path: '/trailer-rentals/illinois/addison', lat: 41.931, lng: -88.002 },
  { name: 'Algonquin, IL', path: '/trailer-rentals/illinois/algonquin', lat: 42.165, lng: -88.294 },
  { name: 'Alton, IL', path: '/trailer-rentals/illinois/alton', lat: 38.890, lng: -90.184 },
  { name: 'Antioch, IL', path: '/trailer-rentals/illinois/antioch', lat: 42.477, lng: -88.096 },
  { name: 'Arlington Heights, IL', path: '/trailer-rentals/illinois/arlington-heights', lat: 42.088, lng: -87.980 },
  { name: 'Aurora, IL', path: '/trailer-rentals/illinois/aurora', lat: 41.760, lng: -88.320 },
  { name: 'Barrington, IL', path: '/trailer-rentals/illinois/barrington', lat: 42.153, lng: -88.136 },
  { name: 'Bartlett, IL', path: '/trailer-rentals/illinois/bartlett', lat: 41.995, lng: -88.185 },
  { name: 'Batavia, IL', path: '/trailer-rentals/illinois/batavia', lat: 41.850, lng: -88.312 },
  { name: 'Belleville, IL', path: '/trailer-rentals/illinois/belleville', lat: 38.520, lng: -89.984 },
  { name: 'Belvidere, IL', path: '/trailer-rentals/illinois/belvidere', lat: 42.263, lng: -88.844 },
  { name: 'Bensenville, IL', path: '/trailer-rentals/illinois/bensenville', lat: 41.955, lng: -87.940 },
  { name: 'Berwyn, IL', path: '/trailer-rentals/illinois/berwyn', lat: 41.850, lng: -87.793 },
  { name: 'Bloomington, IL', path: '/trailer-rentals/illinois/bloomington', lat: 40.484, lng: -88.993 },
  { name: 'Bolingbrook, IL', path: '/trailer-rentals/illinois/bolingbrook', lat: 41.698, lng: -88.068 },
  { name: 'Bourbonnais, IL', path: '/trailer-rentals/illinois/bourbonnais', lat: 41.141, lng: -87.875 },
  { name: 'Bradley, IL', path: '/trailer-rentals/illinois/bradley', lat: 41.142, lng: -87.861 },
  { name: 'Brookfield, IL', path: '/trailer-rentals/illinois/brookfield', lat: 41.823, lng: -87.851 },
  { name: 'Buffalo Grove, IL', path: '/trailer-rentals/illinois/buffalo-grove', lat: 42.166, lng: -87.963 },
  { name: 'Calumet City, IL', path: '/trailer-rentals/illinois/calumet-city', lat: 41.615, lng: -87.529 },
  { name: 'Carol Stream, IL', path: '/trailer-rentals/illinois/carol-stream', lat: 41.912, lng: -88.135 },
  { name: 'Carpentersville, IL', path: '/trailer-rentals/illinois/carpentersville', lat: 42.121, lng: -88.257 },
  { name: 'Champaign, IL', path: '/trailer-rentals/illinois/champaign', lat: 40.116, lng: -88.243 },
  { name: 'Chicago, IL', path: '/trailer-rentals/illinois/chicago', lat: 41.878, lng: -87.629 },
  { name: 'Chicago Heights, IL', path: '/trailer-rentals/illinois/chicago-heights', lat: 41.506, lng: -87.635 },
  { name: 'Cicero, IL', path: '/trailer-rentals/illinois/cicero', lat: 41.845, lng: -87.753 },
  { name: 'Collinsville, IL', path: '/trailer-rentals/illinois/collinsville', lat: 38.670, lng: -89.984 },
  { name: 'Crystal Lake, IL', path: '/trailer-rentals/illinois/crystal-lake', lat: 42.241, lng: -88.316 },
  { name: 'Danville, IL', path: '/trailer-rentals/illinois/danville', lat: 40.124, lng: -87.630 },
  { name: 'Darien, IL', path: '/trailer-rentals/illinois/darien', lat: 41.751, lng: -87.973 },
  { name: 'Decatur, IL', path: '/trailer-rentals/illinois/decatur', lat: 39.840, lng: -88.954 },
  { name: 'DeKalb, IL', path: '/trailer-rentals/illinois/dekalb', lat: 41.929, lng: -88.750 },
  { name: 'Des Plaines, IL', path: '/trailer-rentals/illinois/des-plaines', lat: 42.033, lng: -87.883 },
  { name: 'Dixon, IL', path: '/trailer-rentals/illinois/dixon', lat: 41.843, lng: -89.485 },
  { name: 'Downers Grove, IL', path: '/trailer-rentals/illinois/downers-grove', lat: 41.808, lng: -88.011 },
  { name: 'East Moline, IL', path: '/trailer-rentals/illinois/east-moline', lat: 41.514, lng: -90.444 },
  { name: 'East Peoria, IL', path: '/trailer-rentals/illinois/east-peoria', lat: 40.666, lng: -89.580 },
  { name: 'Edwardsville, IL', path: '/trailer-rentals/illinois/edwardsville', lat: 38.811, lng: -89.953 },
  { name: 'Elk Grove Village, IL', path: '/trailer-rentals/illinois/elk-grove-village', lat: 42.003, lng: -87.970 },
  { name: 'Elmhurst, IL', path: '/trailer-rentals/illinois/elmhurst', lat: 41.899, lng: -87.940 },
  { name: 'Elmwood Park, IL', path: '/trailer-rentals/illinois/elmwood-park', lat: 41.923, lng: -87.809 },
  { name: 'Evanston, IL', path: '/trailer-rentals/illinois/evanston', lat: 42.045, lng: -87.687 },
  { name: 'Fairview Heights, IL', path: '/trailer-rentals/illinois/fairview-heights', lat: 38.593, lng: -89.990 },
  { name: 'Freeport, IL', path: '/trailer-rentals/illinois/freeport', lat: 42.296, lng: -89.621 },
  { name: 'Galesburg, IL', path: '/trailer-rentals/illinois/galesburg', lat: 40.947, lng: -90.371 },
  { name: 'Glen Ellyn, IL', path: '/trailer-rentals/illinois/glen-ellyn', lat: 41.877, lng: -88.067 },
  { name: 'Glendale Heights, IL', path: '/trailer-rentals/illinois/glendale-heights', lat: 41.921, lng: -88.071 },
  { name: 'Glenview, IL', path: '/trailer-rentals/illinois/glenview', lat: 42.069, lng: -87.787 },
  { name: 'Granite City, IL', path: '/trailer-rentals/illinois/granite-city', lat: 38.701, lng: -90.148 },
  { name: 'Grayslake, IL', path: '/trailer-rentals/illinois/grayslake', lat: 42.344, lng: -88.041 },
  { name: 'Gurnee, IL', path: '/trailer-rentals/illinois/gurnee', lat: 42.370, lng: -87.902 },
  { name: 'Hanover Park, IL', path: '/trailer-rentals/illinois/hanover-park', lat: 41.999, lng: -88.145 },
  { name: 'Harvey, IL', path: '/trailer-rentals/illinois/harvey', lat: 41.610, lng: -87.646 },
  { name: 'Highland Park, IL', path: '/trailer-rentals/illinois/highland-park', lat: 42.182, lng: -87.800 },
  { name: 'Hoffman Estates, IL', path: '/trailer-rentals/illinois/hoffman-estates', lat: 42.042, lng: -88.079 },
  { name: 'Huntley, IL', path: '/trailer-rentals/illinois/huntley', lat: 42.168, lng: -88.428 },
  { name: 'Jacksonville, IL', path: '/trailer-rentals/illinois/jacksonville', lat: 39.733, lng: -90.229 },
  { name: 'Joliet, IL', path: '/trailer-rentals/illinois/joliet', lat: 41.525, lng: -88.081 },
  { name: 'Kankakee, IL', path: '/trailer-rentals/illinois/kankakee', lat: 41.120, lng: -87.861 },
  { name: 'Lake Forest, IL', path: '/trailer-rentals/illinois/lake-forest', lat: 42.258, lng: -87.840 },
  { name: 'Lake in the Hills, IL', path: '/trailer-rentals/illinois/lake-in-the-hills', lat: 42.181, lng: -88.330 },
  { name: 'Lake Zurich, IL', path: '/trailer-rentals/illinois/lake-zurich', lat: 42.196, lng: -88.093 },
  { name: 'Lansing, IL', path: '/trailer-rentals/illinois/lansing', lat: 41.564, lng: -87.538 },
  { name: 'Libertyville, IL', path: '/trailer-rentals/illinois/libertyville', lat: 42.283, lng: -87.953 },
  { name: 'Lincoln, IL', path: '/trailer-rentals/illinois/lincoln', lat: 40.148, lng: -89.364 },
  { name: 'Lincolnwood, IL', path: '/trailer-rentals/illinois/lincolnwood', lat: 42.005, lng: -87.733 },
  { name: 'Lindenhurst, IL', path: '/trailer-rentals/illinois/lindenhurst', lat: 42.415, lng: -88.030 },
  { name: 'Lisle, IL', path: '/trailer-rentals/illinois/lisle', lat: 41.801, lng: -88.074 },
  { name: 'Lockport, IL', path: '/trailer-rentals/illinois/lockport', lat: 41.589, lng: -88.057 },
  { name: 'Lombard, IL', path: '/trailer-rentals/illinois/lombard', lat: 41.880, lng: -88.007 },
  { name: 'Loves Park, IL', path: '/trailer-rentals/illinois/loves-park', lat: 42.320, lng: -89.058 },
  { name: 'Machesney Park, IL', path: '/trailer-rentals/illinois/machesney-park', lat: 42.347, lng: -89.039 },
  { name: 'Macomb, IL', path: '/trailer-rentals/illinois/macomb', lat: 40.459, lng: -90.671 },
  { name: 'Marion, IL', path: '/trailer-rentals/illinois/marion', lat: 37.730, lng: -88.933 },
  { name: 'Markham, IL', path: '/trailer-rentals/illinois/markham', lat: 41.596, lng: -87.694 },
  { name: 'Matteson, IL', path: '/trailer-rentals/illinois/matteson', lat: 41.509, lng: -87.713 },
  { name: 'Maywood, IL', path: '/trailer-rentals/illinois/maywood', lat: 41.879, lng: -87.843 },
  { name: 'McHenry, IL', path: '/trailer-rentals/illinois/mchenry', lat: 42.333, lng: -88.266 },
  { name: 'Melrose Park, IL', path: '/trailer-rentals/illinois/melrose-park', lat: 41.900, lng: -87.856 },
  { name: 'Moline, IL', path: '/trailer-rentals/illinois/moline', lat: 41.506, lng: -90.515 },
];

export const indianaCities: ICity[] = [
  { name: 'Alexandria, IN', path: '/trailer-rentals/indiana/alexandria', lat: 40.262, lng: -85.675 },
  { name: 'Anderson, IN', path: '/trailer-rentals/indiana/anderson', lat: 40.105, lng: -85.680 },
  { name: 'Angola, IN', path: '/trailer-rentals/indiana/angola', lat: 41.634, lng: -85.000 },
  { name: 'Attica, IN', path: '/trailer-rentals/indiana/attica', lat: 40.294, lng: -87.248 },
  { name: 'Auburn, IN', path: '/trailer-rentals/indiana/auburn', lat: 41.366, lng: -85.058 },
  { name: 'Avon, IN', path: '/trailer-rentals/indiana/avon', lat: 39.762, lng: -86.399 },
  { name: 'Bedford, IN', path: '/trailer-rentals/indiana/bedford', lat: 38.861, lng: -86.487 },
  { name: 'Beech Grove, IN', path: '/trailer-rentals/indiana/beech-grove', lat: 39.721, lng: -86.089 },
  { name: 'Berne, IN', path: '/trailer-rentals/indiana/berne', lat: 40.657, lng: -84.954 },
  { name: 'Bloomington, IN', path: '/trailer-rentals/indiana/bloomington', lat: 39.165, lng: -86.526 },
  { name: 'Bluffton, IN', path: '/trailer-rentals/indiana/bluffton', lat: 40.738, lng: -85.171 },
  { name: 'Boonville, IN', path: '/trailer-rentals/indiana/boonville', lat: 38.049, lng: -87.274 },
  { name: 'Brazil, IN', path: '/trailer-rentals/indiana/brazil', lat: 39.523, lng: -87.126 },
  { name: 'Bremen, IN', path: '/trailer-rentals/indiana/bremen', lat: 41.446, lng: -86.148 },
  { name: 'Brownsburg, IN', path: '/trailer-rentals/indiana/brownsburg', lat: 39.843, lng: -86.397 },
  { name: 'Carmel, IN', path: '/trailer-rentals/indiana/carmel', lat: 39.978, lng: -86.118 },
  { name: 'Cedar Lake, IN', path: '/trailer-rentals/indiana/cedar-lake', lat: 41.364, lng: -87.441 },
  { name: 'Charlestown, IN', path: '/trailer-rentals/indiana/charlestown', lat: 38.455, lng: -85.670 },
  { name: 'Chesterton, IN', path: '/trailer-rentals/indiana/chesterton', lat: 41.610, lng: -87.064 },
  { name: 'Clarksville, IN', path: '/trailer-rentals/indiana/clarksville', lat: 38.296, lng: -85.760 },
  { name: 'Columbia City, IN', path: '/trailer-rentals/indiana/columbia-city', lat: 41.157, lng: -85.488 },
  { name: 'Columbus, IN', path: '/trailer-rentals/indiana/columbus', lat: 39.201, lng: -85.921 },
  { name: 'Connersville, IN', path: '/trailer-rentals/indiana/connersville', lat: 39.641, lng: -85.141 },
  { name: 'Crawfordsville, IN', path: '/trailer-rentals/indiana/crawfordsville', lat: 40.041, lng: -86.874 },
  { name: 'Crown Point, IN', path: '/trailer-rentals/indiana/crown-point', lat: 41.418, lng: -87.365 },
  { name: 'Danville, IN', path: '/trailer-rentals/indiana/danville', lat: 39.760, lng: -86.526 },
  { name: 'Decatur, IN', path: '/trailer-rentals/indiana/decatur', lat: 40.830, lng: -84.929 },
  { name: 'Dyer, IN', path: '/trailer-rentals/indiana/dyer', lat: 41.494, lng: -87.521 },
  { name: 'East Chicago, IN', path: '/trailer-rentals/indiana/east-chicago', lat: 41.639, lng: -87.454 },
  { name: 'Edinburgh, IN', path: '/trailer-rentals/indiana/edinburgh', lat: 39.354, lng: -85.966 },
  { name: 'Elkhart, IN', path: '/trailer-rentals/indiana/elkhart', lat: 41.681, lng: -85.976 },
  { name: 'Elwood, IN', path: '/trailer-rentals/indiana/elwood', lat: 40.276, lng: -85.841 },
  { name: 'Evansville, IN', path: '/trailer-rentals/indiana/evansville', lat: 37.971, lng: -87.571 },
  { name: 'Fishers, IN', path: '/trailer-rentals/indiana/fishers', lat: 39.956, lng: -86.013 },
  { name: 'Fort Wayne, IN', path: '/trailer-rentals/indiana/fort-wayne', lat: 41.079, lng: -85.139 },
  { name: 'Frankfort, IN', path: '/trailer-rentals/indiana/frankfort', lat: 40.279, lng: -86.510 },
  { name: 'Franklin, IN', path: '/trailer-rentals/indiana/franklin', lat: 39.480, lng: -86.054 },
  { name: 'Garrett, IN', path: '/trailer-rentals/indiana/garrett', lat: 41.349, lng: -85.135 },
  { name: 'Gary, IN', path: '/trailer-rentals/indiana/gary', lat: 41.593, lng: -87.346 },
  { name: 'Goshen, IN', path: '/trailer-rentals/indiana/goshen', lat: 41.582, lng: -85.834 },
  { name: 'Greencastle, IN', path: '/trailer-rentals/indiana/greencastle', lat: 39.644, lng: -86.864 },
  { name: 'Greenfield, IN', path: '/trailer-rentals/indiana/greenfield', lat: 39.785, lng: -85.769 },
  { name: 'Greensburg, IN', path: '/trailer-rentals/indiana/greensburg', lat: 39.337, lng: -85.483 },
  { name: 'Greenwood, IN', path: '/trailer-rentals/indiana/greenwood', lat: 39.613, lng: -86.106 },
  { name: 'Griffith, IN', path: '/trailer-rentals/indiana/griffith', lat: 41.528, lng: -87.423 },
  { name: 'Hammond, IN', path: '/trailer-rentals/indiana/hammond', lat: 41.583, lng: -87.500 },
  { name: 'Hartford City, IN', path: '/trailer-rentals/indiana/hartford-city', lat: 40.451, lng: -85.369 },
  { name: 'Highland, IN', path: '/trailer-rentals/indiana/highland', lat: 41.553, lng: -87.453 },
  { name: 'Hobart, IN', path: '/trailer-rentals/indiana/hobart', lat: 41.532, lng: -87.255 },
  { name: 'Huntington, IN', path: '/trailer-rentals/indiana/huntington', lat: 40.883, lng: -85.497 },
  { name: 'Indianapolis, IN', path: '/trailer-rentals/indiana/indianapolis', lat: 39.768, lng: -86.158 },
  { name: 'Jasper, IN', path: '/trailer-rentals/indiana/jasper', lat: 38.391, lng: -86.931 },
  { name: 'Jeffersonville, IN', path: '/trailer-rentals/indiana/jeffersonville', lat: 38.277, lng: -85.737 },
  { name: 'Kendallville, IN', path: '/trailer-rentals/indiana/kendallville', lat: 41.441, lng: -85.264 },
  { name: 'Kokomo, IN', path: '/trailer-rentals/indiana/kokomo', lat: 40.486, lng: -86.133 },
  { name: 'La Porte, IN', path: '/trailer-rentals/indiana/la-porte', lat: 41.611, lng: -86.722 },
  { name: 'Laconia, IN', path: '/trailer-rentals/indiana/laconia', lat: 38.034, lng: -86.091 },
  { name: 'Lafayette, IN', path: '/trailer-rentals/indiana/lafayette', lat: 40.417, lng: -86.875 },
  { name: 'Lawrence, IN', path: '/trailer-rentals/indiana/lawrence', lat: 39.838, lng: -86.025 },
  { name: 'Lebanon, IN', path: '/trailer-rentals/indiana/lebanon', lat: 40.048, lng: -86.469 },
  { name: 'Linton, IN', path: '/trailer-rentals/indiana/linton', lat: 39.034, lng: -87.165 },
  { name: 'Logansport, IN', path: '/trailer-rentals/indiana/logansport', lat: 40.754, lng: -86.356 },
  { name: 'Madison, IN', path: '/trailer-rentals/indiana/madison', lat: 38.735, lng: -85.379 },
  { name: 'Marion, IN', path: '/trailer-rentals/indiana/marion', lat: 40.558, lng: -85.659 },
  { name: 'Martinsville, IN', path: '/trailer-rentals/indiana/martinsville', lat: 39.426, lng: -86.428 },
  { name: 'Merrillville, IN', path: '/trailer-rentals/indiana/merrillville', lat: 41.482, lng: -87.332 },
  { name: 'Michigan City, IN', path: '/trailer-rentals/indiana/michigan-city', lat: 41.707, lng: -86.895 },
  { name: 'Mishawaka, IN', path: '/trailer-rentals/indiana/mishawaka', lat: 41.661, lng: -86.158 },
  { name: 'Mitchell, IN', path: '/trailer-rentals/indiana/mitchell', lat: 38.732, lng: -86.473 },
  { name: 'Monticello, IN', path: '/trailer-rentals/indiana/monticello', lat: 40.745, lng: -86.764 },
  { name: 'Mooresville, IN', path: '/trailer-rentals/indiana/mooresville', lat: 39.612, lng: -86.374 },
  { name: 'Muncie, IN', path: '/trailer-rentals/indiana/muncie', lat: 40.193, lng: -85.386 },
  { name: 'Munster, IN', path: '/trailer-rentals/indiana/munster', lat: 41.564, lng: -87.512 },
  { name: 'Nappanee, IN', path: '/trailer-rentals/indiana/nappanee', lat: 41.442, lng: -86.001 },
  { name: 'New Albany, IN', path: '/trailer-rentals/indiana/new-albany', lat: 38.285, lng: -85.824 },
  { name: 'New Castle, IN', path: '/trailer-rentals/indiana/new-castle', lat: 39.928, lng: -85.370 },
  { name: 'New Haven, IN', path: '/trailer-rentals/indiana/new-haven', lat: 41.070, lng: -85.014 },
  { name: 'Noblesville, IN', path: '/trailer-rentals/indiana/noblesville', lat: 40.045, lng: -86.008 },
  { name: 'North Manchester, IN', path: '/trailer-rentals/indiana/north-manchester', lat: 41.000, lng: -85.769 },
  { name: 'North Vernon, IN', path: '/trailer-rentals/indiana/north-vernon', lat: 39.006, lng: -85.623 },
  { name: 'Peru, IN', path: '/trailer-rentals/indiana/peru', lat: 40.753, lng: -86.068 },
  { name: 'Plainfield, IN', path: '/trailer-rentals/indiana/plainfield', lat: 39.704, lng: -86.399 },
  { name: 'Plymouth, IN', path: '/trailer-rentals/indiana/plymouth', lat: 41.343, lng: -86.309 },
  { name: 'Portland, IN', path: '/trailer-rentals/indiana/portland', lat: 40.434, lng: -84.977 },
  { name: 'Princeton, IN', path: '/trailer-rentals/indiana/princeton', lat: 38.355, lng: -87.567 },
  { name: 'Rensselaer, IN', path: '/trailer-rentals/indiana/rensselaer', lat: 40.936, lng: -87.150 },
  { name: 'Richmond, IN', path: '/trailer-rentals/indiana/richmond', lat: 39.828, lng: -84.890 },
  { name: 'Rochester, IN', path: '/trailer-rentals/indiana/rochester', lat: 41.064, lng: -86.215 },
  { name: 'Rushville, IN', path: '/trailer-rentals/indiana/rushville', lat: 39.609, lng: -85.446 },
  { name: 'Salem, IN', path: '/trailer-rentals/indiana/salem', lat: 38.605, lng: -86.101 },
  { name: 'Schererville, IN', path: '/trailer-rentals/indiana/schererville', lat: 41.478, lng: -87.454 },
  { name: 'Scottsburg, IN', path: '/trailer-rentals/indiana/scottsburg', lat: 38.685, lng: -85.770 },
  { name: 'Seymour, IN', path: '/trailer-rentals/indiana/seymour', lat: 38.959, lng: -85.890 },
  { name: 'Shelbyville, IN', path: '/trailer-rentals/indiana/shelbyville', lat: 39.523, lng: -85.776 },
  { name: 'South Bend, IN', path: '/trailer-rentals/indiana/south-bend', lat: 41.676, lng: -86.252 },
  { name: 'Speedway, IN', path: '/trailer-rentals/indiana/speedway', lat: 39.792, lng: -86.247 },
  { name: 'Tell City, IN', path: '/trailer-rentals/indiana/tell-city', lat: 37.951, lng: -86.767 },
  { name: 'Terre Haute, IN', path: '/trailer-rentals/indiana/terre-haute', lat: 39.466, lng: -87.413 },
  { name: 'Tipton, IN', path: '/trailer-rentals/indiana/tipton', lat: 40.282, lng: -86.041 },
  { name: 'Valparaiso, IN', path: '/trailer-rentals/indiana/valparaiso', lat: 41.473, lng: -87.061 },
  { name: 'Vincennes, IN', path: '/trailer-rentals/indiana/vincennes', lat: 38.677, lng: -87.528 },
  { name: 'Wabash, IN', path: '/trailer-rentals/indiana/wabash', lat: 40.797, lng: -85.820 },
  { name: 'Warsaw, IN', path: '/trailer-rentals/indiana/warsaw', lat: 41.238, lng: -85.853 },
  { name: 'Washington, IN', path: '/trailer-rentals/indiana/washington', lat: 38.659, lng: -87.172 },
  { name: 'West Lafayette, IN', path: '/trailer-rentals/indiana/west-lafayette', lat: 40.425, lng: -86.908 },
  { name: 'Westfield, IN', path: '/trailer-rentals/indiana/westfield', lat: 40.042, lng: -86.127 },
  { name: 'Whiting, IN', path: '/trailer-rentals/indiana/whiting', lat: 41.679, lng: -87.494 },
  { name: 'Winchester, IN', path: '/trailer-rentals/indiana/winchester', lat: 40.172, lng: -84.981 },
  { name: 'Yorktown, IN', path: '/trailer-rentals/indiana/yorktown', lat: 40.171, lng: -85.493 },
  { name: 'Zionsville, IN', path: '/trailer-rentals/indiana/zionsville', lat: 39.950, lng: -86.261 }
];

export const iowaCities: ICity[] = [
  { name: 'Ames, IA', path: '/trailer-rentals/iowa/ames', lat: 42.0347, lng: -93.6204 },
  { name: 'Ankeny, IA', path: '/trailer-rentals/iowa/ankeny', lat: 41.7318, lng: -93.6001 },
  { name: 'Asbury, IA', path: '/trailer-rentals/iowa/asbury', lat: 42.5153, lng: -90.7407 },
  { name: 'Bettendorf, IA', path: '/trailer-rentals/iowa/bettendorf', lat: 41.5563, lng: -90.4743 },
  { name: 'Boone, IA', path: '/trailer-rentals/iowa/boone', lat: 42.0597, lng: -93.8802 },
  { name: 'Burlington, IA', path: '/trailer-rentals/iowa/burlington', lat: 40.8075, lng: -91.1129 },
  { name: 'Carroll, IA', path: '/trailer-rentals/iowa/carroll', lat: 42.0658, lng: -94.8672 },
  { name: 'Cedar Falls, IA', path: '/trailer-rentals/iowa/cedar-falls', lat: 42.5349, lng: -92.4453 },
  { name: 'Cedar Rapids, IA', path: '/trailer-rentals/iowa/cedar-rapids', lat: 41.9779, lng: -91.6656 },
  { name: 'Clinton, IA', path: '/trailer-rentals/iowa/clinton', lat: 41.8445, lng: -90.1887 },
  { name: 'Clive, IA', path: '/trailer-rentals/iowa/clive', lat: 41.6033, lng: -93.7244 },
  { name: 'Coralville, IA', path: '/trailer-rentals/iowa/coralville', lat: 41.6764, lng: -91.5804 },
  { name: 'Council Bluffs, IA', path: '/trailer-rentals/iowa/council-bluffs', lat: 41.2619, lng: -95.8608 },
  { name: 'Davenport, IA', path: '/trailer-rentals/iowa/davenport', lat: 41.5236, lng: -90.5776 },
  { name: 'Decorah, IA', path: '/trailer-rentals/iowa/decorah', lat: 43.3033, lng: -91.7859 },
  { name: 'Des Moines, IA', path: '/trailer-rentals/iowa/des-moines', lat: 41.5868, lng: -93.625 },
  { name: 'Dubuque, IA', path: '/trailer-rentals/iowa/dubuque', lat: 42.5006, lng: -90.6646 },
  { name: 'Eldridge, IA', path: '/trailer-rentals/iowa/eldridge', lat: 41.6586, lng: -90.5843 },
  { name: 'Estherville, IA', path: '/trailer-rentals/iowa/estherville', lat: 43.4041, lng: -94.8342 },
  { name: 'Fairfield, IA', path: '/trailer-rentals/iowa/fairfield', lat: 41.0086, lng: -91.9637 },
  { name: 'Fort Dodge, IA', path: '/trailer-rentals/iowa/fort-dodge', lat: 42.4975, lng: -94.168 },
  { name: 'Grimes, IA', path: '/trailer-rentals/iowa/grimes', lat: 41.6883, lng: -93.7916 },
  { name: 'Grinnell, IA', path: '/trailer-rentals/iowa/grinnell', lat: 41.7434, lng: -92.7226 },
  { name: 'Hiawatha, IA', path: '/trailer-rentals/iowa/hiawatha', lat: 42.045, lng: -91.6821 },
  { name: 'Indianola, IA', path: '/trailer-rentals/iowa/indianola', lat: 41.358, lng: -93.5578 },
  { name: 'Iowa City, IA', path: '/trailer-rentals/iowa/iowa-city', lat: 41.6611, lng: -91.5302 },
  { name: 'Johnston, IA', path: '/trailer-rentals/iowa/johnston', lat: 41.673, lng: -93.6975 },
  { name: 'Keokuk, IA', path: '/trailer-rentals/iowa/keokuk', lat: 40.3978, lng: -91.3843 },
  { name: 'Knoxville, IA', path: '/trailer-rentals/iowa/knoxville', lat: 41.3208, lng: -93.1013 },
  { name: 'Le Mars, IA', path: '/trailer-rentals/iowa/le-mars', lat: 42.7944, lng: -96.165 },
  { name: 'Marion, IA', path: '/trailer-rentals/iowa/marion', lat: 42.034, lng: -91.5971 },
  { name: 'Marshalltown, IA', path: '/trailer-rentals/iowa/marshalltown', lat: 42.0494, lng: -92.908 },
  { name: 'Mason City, IA', path: '/trailer-rentals/iowa/mason-city', lat: 43.1536, lng: -93.201 },
  { name: 'Mount Pleasant, IA', path: '/trailer-rentals/iowa/mount-pleasant', lat: 40.9631, lng: -91.5488 },
  { name: 'Muscatine, IA', path: '/trailer-rentals/iowa/muscatine', lat: 41.4245, lng: -91.0432 },
  { name: 'Newton, IA', path: '/trailer-rentals/iowa/newton', lat: 41.699, lng: -93.0474 },
  { name: 'North Liberty, IA', path: '/trailer-rentals/iowa/north-liberty', lat: 41.7497, lng: -91.5971 },
  { name: 'Norwalk, IA', path: '/trailer-rentals/iowa/norwalk', lat: 41.475, lng: -93.678 },
  { name: 'Oelwein, IA', path: '/trailer-rentals/iowa/oelwein', lat: 42.6811, lng: -91.9132 },
  { name: 'Orange City, IA', path: '/trailer-rentals/iowa/orange-city', lat: 43.0078, lng: -96.0584 },
  { name: 'Oskaloosa, IA', path: '/trailer-rentals/iowa/oskaloosa', lat: 41.2964, lng: -92.644 },
  { name: 'Ottumwa, IA', path: '/trailer-rentals/iowa/ottumwa', lat: 41.0209, lng: -92.411 },
  { name: 'Pella, IA', path: '/trailer-rentals/iowa/pella', lat: 41.4086, lng: -92.9165 },
  { name: 'Perry, IA', path: '/trailer-rentals/iowa/perry', lat: 41.838, lng: -94.1038 },
  { name: 'Pleasant Hill, IA', path: '/trailer-rentals/iowa/pleasant-hill', lat: 41.585, lng: -93.5155 },
  { name: 'Sioux Center, IA', path: '/trailer-rentals/iowa/sioux-center', lat: 43.079, lng: -96.1753 },
  { name: 'Sioux City, IA', path: '/trailer-rentals/iowa/sioux-city', lat: 42.499, lng: -96.4003 },
  { name: 'Spencer, IA', path: '/trailer-rentals/iowa/spencer', lat: 43.145, lng: -95.1447 },
  { name: 'Storm Lake, IA', path: '/trailer-rentals/iowa/storm-lake', lat: 42.6414, lng: -95.209 },
  { name: 'Urbandale, IA', path: '/trailer-rentals/iowa/urbandale', lat: 41.6267, lng: -93.7122 },
  { name: 'Vinton, IA', path: '/trailer-rentals/iowa/vinton', lat: 42.1642, lng: -92.0252 },
  { name: 'Waukee, IA', path: '/trailer-rentals/iowa/waukee', lat: 41.6119, lng: -93.8858 },
  { name: 'Waverly, IA', path: '/trailer-rentals/iowa/waverly', lat: 42.7275, lng: -92.4755 },
  { name: 'Webster City, IA', path: '/trailer-rentals/iowa/webster-city', lat: 42.4697, lng: -93.8166 },
  { name: 'West Des Moines, IA', path: '/trailer-rentals/iowa/west-des-moines', lat: 41.5772, lng: -93.7113 },
  { name: 'Windsor Heights, IA', path: '/trailer-rentals/iowa/windsor-heights', lat: 41.6017, lng: -93.7038 },
];

export const kansasCities: ICity[] = [
  { name: 'Abilene, KS', path: '/trailer-rentals/kansas/abilene', lat: 38.9172, lng: -97.2139 },
  { name: 'Andover, KS', path: '/trailer-rentals/kansas/andover', lat: 37.7139, lng: -97.1364 },
  { name: 'Arkansas City, KS', path: '/trailer-rentals/kansas/arkansas-city', lat: 37.0614, lng: -97.0386 },
  { name: 'Atchison, KS', path: '/trailer-rentals/kansas/atchison', lat: 39.5639, lng: -95.1214 },
  { name: 'Augusta, KS', path: '/trailer-rentals/kansas/augusta', lat: 37.6861, lng: -96.9764 },
  { name: 'Baldwin City, KS', path: '/trailer-rentals/kansas/baldwin-city', lat: 38.7756, lng: -95.1875 },
  { name: 'Basehor, KS', path: '/trailer-rentals/kansas/basehor', lat: 39.1408, lng: -94.9383 },
  { name: 'Baxter Springs, KS', path: '/trailer-rentals/kansas/baxter-springs', lat: 37.0231, lng: -94.7358 },
  { name: 'Belleville, KS', path: '/trailer-rentals/kansas/belleville', lat: 39.8242, lng: -97.6328 },
  { name: 'Beloit, KS', path: '/trailer-rentals/kansas/beloit', lat: 39.4561, lng: -98.1139 },
  { name: 'Bonner Springs, KS', path: '/trailer-rentals/kansas/bonner-springs', lat: 39.0592, lng: -94.8836 },
  { name: 'Burlington, KS', path: '/trailer-rentals/kansas/burlington', lat: 38.1942, lng: -95.7383 },
  { name: 'Chanute, KS', path: '/trailer-rentals/kansas/chanute', lat: 37.6792, lng: -95.4578 },
  { name: 'Cherryvale, KS', path: '/trailer-rentals/kansas/cherryvale', lat: 37.2703, lng: -95.5511 },
  { name: 'Clay Center, KS', path: '/trailer-rentals/kansas/clay-center', lat: 39.3792, lng: -97.1258 },
  { name: 'Clearwater, KS', path: '/trailer-rentals/kansas/clearwater', lat: 37.5042, lng: -97.5042 },
  { name: 'Coffeyville, KS', path: '/trailer-rentals/kansas/coffeyville', lat: 37.0386, lng: -95.6194 },
  { name: 'Colby, KS', path: '/trailer-rentals/kansas/colby', lat: 39.3958, lng: -101.0528 },
  { name: 'Columbus, KS', path: '/trailer-rentals/kansas/columbus', lat: 37.1692, lng: -94.8444 },
  { name: 'Concordia, KS', path: '/trailer-rentals/kansas/concordia', lat: 39.5708, lng: -97.6628 },
  { name: 'Council Grove, KS', path: '/trailer-rentals/kansas/council-grove', lat: 38.6611, lng: -96.4911 },
  { name: 'De Soto, KS', path: '/trailer-rentals/kansas/de-soto', lat: 38.9792, lng: -94.9683 },
  { name: 'Derby, KS', path: '/trailer-rentals/kansas/derby', lat: 37.5458, lng: -97.2683 },
  { name: 'Dodge City, KS', path: '/trailer-rentals/kansas/dodge-city', lat: 37.7528, lng: -100.0178 },
  { name: 'El Dorado, KS', path: '/trailer-rentals/kansas/el-dorado', lat: 37.8178, lng: -96.8628 },
  { name: 'Ellsworth, KS', path: '/trailer-rentals/kansas/ellsworth', lat: 38.7308, lng: -98.2283 },
  { name: 'Emporia, KS', path: '/trailer-rentals/kansas/emporia', lat: 38.4039, lng: -96.1811 },
  { name: 'Eudora, KS', path: '/trailer-rentals/kansas/eudora', lat: 38.9439, lng: -95.0983 },
  { name: 'Fort Scott, KS', path: '/trailer-rentals/kansas/fort-scott', lat: 37.8392, lng: -94.7083 },
  { name: 'Frontenac, KS', path: '/trailer-rentals/kansas/frontenac', lat: 37.4511, lng: -94.6883 },
  { name: 'Galena, KS', path: '/trailer-rentals/kansas/galena', lat: 37.0758, lng: -94.6358 },
  { name: 'Garden City, KS', path: '/trailer-rentals/kansas/garden-city', lat: 37.9714, lng: -100.8728 },
  { name: 'Gardner, KS', path: '/trailer-rentals/kansas/gardner', lat: 38.8108, lng: -94.9278 },
  { name: 'Garnett, KS', path: '/trailer-rentals/kansas/garnett', lat: 38.2808, lng: -95.2411 },
  { name: 'Goodland, KS', path: '/trailer-rentals/kansas/goodland', lat: 39.3508, lng: -101.7108 },
  { name: 'Great Bend, KS', path: '/trailer-rentals/kansas/great-bend', lat: 38.3642, lng: -98.7642 },
  { name: 'Greensburg, KS', path: '/trailer-rentals/kansas/greensburg', lat: 37.6042, lng: -99.2939 },
  { name: 'Hays, KS', path: '/trailer-rentals/kansas/hays', lat: 38.8792, lng: -99.3228 },
  { name: 'Haysville, KS', path: '/trailer-rentals/kansas/haysville', lat: 37.5642, lng: -97.3528 },
  { name: 'Hesston, KS', path: '/trailer-rentals/kansas/hesston', lat: 38.1411, lng: -97.4311 },
  { name: 'Hillsboro, KS', path: '/trailer-rentals/kansas/hillsboro', lat: 38.3511, lng: -97.2042 },
  { name: 'Hoisington, KS', path: '/trailer-rentals/kansas/hoisington', lat: 38.5183, lng: -98.7783 },
  { name: 'Holton, KS', path: '/trailer-rentals/kansas/holton', lat: 39.4642, lng: -95.7361 },
  { name: 'Horton, KS', path: '/trailer-rentals/kansas/horton', lat: 39.6608, lng: -95.5261 },
  { name: 'Hugoton, KS', path: '/trailer-rentals/kansas/hugoton', lat: 37.1758, lng: -101.3492 },
  { name: 'Humboldt, KS', path: '/trailer-rentals/kansas/humboldt', lat: 37.8111, lng: -95.4361 },
  { name: 'Hutchinson, KS', path: '/trailer-rentals/kansas/hutchinson', lat: 38.0608, lng: -97.9292 },
  { name: 'Independence, KS', path: '/trailer-rentals/kansas/independence', lat: 37.2242, lng: -95.7083 },
  { name: 'Iola, KS', path: '/trailer-rentals/kansas/iola', lat: 37.9242, lng: -95.3992 },
  { name: 'Junction City, KS', path: '/trailer-rentals/kansas/junction-city', lat: 39.0283, lng: -96.8311 },
  { name: 'Kansas City, KS', path: '/trailer-rentals/kansas/kansas-city', lat: 39.1142, lng: -94.6278 },
  { name: 'Kingman, KS', path: '/trailer-rentals/kansas/kingman', lat: 37.6458, lng: -98.1139 },
  { name: 'Lansing, KS', path: '/trailer-rentals/kansas/lansing', lat: 39.2483, lng: -94.9008 },
  { name: 'Larned, KS', path: '/trailer-rentals/kansas/larned', lat: 38.1808, lng: -99.0983 },
  { name: 'Lawrence, KS', path: '/trailer-rentals/kansas/lawrence', lat: 38.9717, lng: -95.2353 },
  { name: 'Leavenworth, KS', path: '/trailer-rentals/kansas/leavenworth', lat: 39.3111, lng: -94.9228 },
  { name: 'Leawood, KS', path: '/trailer-rentals/kansas/leawood', lat: 38.9667, lng: -94.6161 },
  { name: 'Lenexa, KS', path: '/trailer-rentals/kansas/lenexa', lat: 38.9536, lng: -94.7336 },
  { name: 'Liberal, KS', path: '/trailer-rentals/kansas/liberal', lat: 37.0436, lng: -100.9208 },
  { name: 'Lindsborg, KS', path: '/trailer-rentals/kansas/lindsborg', lat: 38.5736, lng: -97.6742 },
  { name: 'Louisburg, KS', path: '/trailer-rentals/kansas/louisburg', lat: 38.6192, lng: -94.6778 },
  { name: 'Manhattan, KS', path: '/trailer-rentals/kansas/manhattan', lat: 39.1836, lng: -96.5717 },
  { name: 'Marion, KS', path: '/trailer-rentals/kansas/marion', lat: 38.3486, lng: -97.0161 },
  { name: 'McPherson, KS', path: '/trailer-rentals/kansas/mcpherson', lat: 38.3708, lng: -97.6642 },
  { name: 'Merriam, KS', path: '/trailer-rentals/kansas/merriam', lat: 39.0158, lng: -94.6936 },
  { name: 'Mission, KS', path: '/trailer-rentals/kansas/mission', lat: 39.0278, lng: -94.6558 },
  { name: 'Mulvane, KS', path: '/trailer-rentals/kansas/mulvane', lat: 37.4742, lng: -97.2436 },
  { name: 'Neodesha, KS', path: '/trailer-rentals/kansas/neodesha', lat: 37.4186, lng: -95.6842 },
  { name: 'Newton, KS', path: '/trailer-rentals/kansas/newton', lat: 38.0461, lng: -97.3458 },
  { name: 'Norton, KS', path: '/trailer-rentals/kansas/norton', lat: 39.8336, lng: -99.8911 },
  { name: 'Oakley, KS', path: '/trailer-rentals/kansas/oakley', lat: 39.1278, lng: -100.8511 },
  { name: 'Olathe, KS', path: '/trailer-rentals/kansas/olathe', lat: 38.8814, lng: -94.8194 },
  { name: 'Osage City, KS', path: '/trailer-rentals/kansas/osage-city', lat: 38.6336, lng: -95.8258 },
  { name: 'Osawatomie, KS', path: '/trailer-rentals/kansas/osawatomie', lat: 38.4961, lng: -94.9511 },
  { name: 'Ottawa, KS', path: '/trailer-rentals/kansas/ottawa', lat: 38.6158, lng: -95.2678 },
  { name: 'Overland Park, KS', path: '/trailer-rentals/kansas/overland-park', lat: 38.9822, lng: -94.6708 },
  { name: 'Paola, KS', path: '/trailer-rentals/kansas/paola', lat: 38.5722, lng: -94.8792 },
  { name: 'Parsons, KS', path: '/trailer-rentals/kansas/parsons', lat: 37.3408, lng: -95.2611 },
  { name: 'Pittsburg, KS', path: '/trailer-rentals/kansas/pittsburg', lat: 37.4108, lng: -94.7042 },
  { name: 'Plainville, KS', path: '/trailer-rentals/kansas/plainville', lat: 39.2342, lng: -99.2983 },
  { name: 'Pratt, KS', path: '/trailer-rentals/kansas/pratt', lat: 37.6436, lng: -98.7378 },
  { name: 'Roeland Park, KS', path: '/trailer-rentals/kansas/roeland-park', lat: 39.0378, lng: -94.6328 },
  { name: 'Russell, KS', path: '/trailer-rentals/kansas/russell', lat: 38.8958, lng: -98.8578 },
  { name: 'Salina, KS', path: '/trailer-rentals/kansas/salina', lat: 38.8403, lng: -97.6114 },
  { name: 'Scott City, KS', path: '/trailer-rentals/kansas/scott-city', lat: 38.4828, lng: -100.9078 },
  { name: 'Shawnee, KS', path: '/trailer-rentals/kansas/shawnee', lat: 39.0228, lng: -94.7158 },
  { name: 'Spring Hill, KS', path: '/trailer-rentals/kansas/spring-hill', lat: 38.7436, lng: -94.8258 },
  { name: 'Sterling, KS', path: '/trailer-rentals/kansas/sterling', lat: 38.2108, lng: -98.2078 },
  { name: 'Tonganoxie, KS', path: '/trailer-rentals/kansas/tonganoxie', lat: 39.1083, lng: -95.0878 },
  { name: 'Topeka, KS', path: '/trailer-rentals/kansas/topeka', lat: 39.0558, lng: -95.6892 },
  { name: 'Ulysses, KS', path: '/trailer-rentals/kansas/ulysses', lat: 37.5811, lng: -101.3539 },
  { name: 'Valley Center, KS', path: '/trailer-rentals/kansas/valley-center', lat: 37.8342, lng: -97.3739 },
  { name: 'Wamego, KS', path: '/trailer-rentals/kansas/wamego', lat: 39.2028, lng: -96.3042 },
  { name: 'Wellington, KS', path: '/trailer-rentals/kansas/wellington', lat: 37.2658, lng: -97.3711 },
  { name: 'Wichita, KS', path: '/trailer-rentals/kansas/wichita', lat: 37.6872, lng: -97.3301 },
  { name: 'Winfield, KS', path: '/trailer-rentals/kansas/winfield', lat: 37.2392, lng: -96.9958 },
  { name: 'Yates Center, KS', path: '/trailer-rentals/kansas/yates-center', lat: 37.8836, lng: -95.7328 },
];

export const kentuckyCities: ICity[] = [
  { name: 'Alexandria, KY', path: '/trailer-rentals/kentucky/alexandria', lat: 38.9595, lng: -84.3877 },
  { name: 'Ashland, KY', path: '/trailer-rentals/kentucky/ashland', lat: 38.4784, lng: -82.6379 },
  { name: 'Barbourville, KY', path: '/trailer-rentals/kentucky/barbourville', lat: 36.8668, lng: -83.8888 },
  { name: 'Bardstown, KY', path: '/trailer-rentals/kentucky/bardstown', lat: 37.8092, lng: -85.4669 },
  { name: 'Beattyville, KY', path: '/trailer-rentals/kentucky/beattyville', lat: 37.5712, lng: -83.7069 },
  { name: 'Beaver Dam, KY', path: '/trailer-rentals/kentucky/beaver-dam', lat: 37.4014, lng: -86.8753 },
  { name: 'Benton, KY', path: '/trailer-rentals/kentucky/benton', lat: 36.8573, lng: -88.3503 },
  { name: 'Berea, KY', path: '/trailer-rentals/kentucky/berea', lat: 37.5689, lng: -84.2963 },
  { name: 'Bowling Green, KY', path: '/trailer-rentals/kentucky/bowling-green', lat: 36.9685, lng: -86.4808 },
  { name: 'Brandenburg, KY', path: '/trailer-rentals/kentucky/brandenburg', lat: 37.9984, lng: -86.1694 },
  { name: 'Brooksville, KY', path: '/trailer-rentals/kentucky/brooksville', lat: 38.6823, lng: -84.0655 },
  { name: 'Burkesville, KY', path: '/trailer-rentals/kentucky/burkesville', lat: 36.7909, lng: -85.3705 },
  { name: 'Cadiz, KY', path: '/trailer-rentals/kentucky/cadiz', lat: 36.8656, lng: -87.8353 },
  { name: 'Calvert City, KY', path: '/trailer-rentals/kentucky/calvert-city', lat: 37.0334, lng: -88.3503 },
  { name: 'Campbellsville, KY', path: '/trailer-rentals/kentucky/campbellsville', lat: 37.3434, lng: -85.3419 },
  { name: 'Carlisle, KY', path: '/trailer-rentals/kentucky/carlisle', lat: 38.3129, lng: -84.0274 },
  { name: 'Carrollton, KY', path: '/trailer-rentals/kentucky/carrollton', lat: 38.6801, lng: -85.1794 },
  { name: 'Catlettsburg, KY', path: '/trailer-rentals/kentucky/catlettsburg', lat: 38.4042, lng: -82.6004 },
  { name: 'Cave City, KY', path: '/trailer-rentals/kentucky/cave-city', lat: 37.1362, lng: -85.9561 },
  { name: 'Central City, KY', path: '/trailer-rentals/kentucky/central-city', lat: 37.2937, lng: -87.1236 },
  { name: 'Columbia, KY', path: '/trailer-rentals/kentucky/columbia', lat: 37.1023, lng: -85.3066 },
  { name: 'Corbin, KY', path: '/trailer-rentals/kentucky/corbin', lat: 36.9487, lng: -84.0963 },
  { name: 'Covington, KY', path: '/trailer-rentals/kentucky/covington', lat: 39.0837, lng: -84.5086 },
  { name: 'Cynthiana, KY', path: '/trailer-rentals/kentucky/cynthiana', lat: 38.3901, lng: -84.2941 },
  { name: 'Danville, KY', path: '/trailer-rentals/kentucky/danville', lat: 37.6456, lng: -84.7722 },
  { name: 'Dawson Springs, KY', path: '/trailer-rentals/kentucky/dawson-springs', lat: 37.1673, lng: -87.6922 },
  { name: 'Dayton, KY', path: '/trailer-rentals/kentucky/dayton', lat: 39.1128, lng: -84.4713 },
  { name: 'Dry Ridge, KY', path: '/trailer-rentals/kentucky/dry-ridge', lat: 38.6823, lng: -84.5916 },
  { name: 'Eddyville, KY', path: '/trailer-rentals/kentucky/eddyville', lat: 37.0945, lng: -88.0803 },
  { name: 'Edgewood, KY', path: '/trailer-rentals/kentucky/edgewood', lat: 39.0184, lng: -84.5755 },
  { name: 'Elizabethtown, KY', path: '/trailer-rentals/kentucky/elizabethtown', lat: 37.7031, lng: -85.8591 },
  { name: 'Elkton, KY', path: '/trailer-rentals/kentucky/elkton', lat: 36.8117, lng: -87.1575 },
  { name: 'Erlanger, KY', path: '/trailer-rentals/kentucky/erlanger', lat: 39.0167, lng: -84.6005 },
  { name: 'Falmouth, KY', path: '/trailer-rentals/kentucky/falmouth', lat: 38.6765, lng: -84.3305 },
  { name: 'Florence, KY', path: '/trailer-rentals/kentucky/florence', lat: 38.9989, lng: -84.6266 },
  { name: 'Fort Mitchell, KY', path: '/trailer-rentals/kentucky/fort-mitchell', lat: 39.0478, lng: -84.5591 },
  { name: 'Fort Thomas, KY', path: '/trailer-rentals/kentucky/fort-thomas', lat: 39.0789, lng: -84.4477 },
  { name: 'Frankfort, KY', path: '/trailer-rentals/kentucky/frankfort', lat: 38.2009, lng: -84.8733 },
  { name: 'Franklin, KY', path: '/trailer-rentals/kentucky/franklin', lat: 36.7223, lng: -86.5775 },
  { name: 'Fulton, KY', path: '/trailer-rentals/kentucky/fulton', lat: 36.5259, lng: -88.8753 },
  { name: 'Georgetown, KY', path: '/trailer-rentals/kentucky/georgetown', lat: 38.2098, lng: -84.5588 },
  { name: 'Glasgow, KY', path: '/trailer-rentals/kentucky/glasgow', lat: 36.9959, lng: -85.9119 },
  { name: 'Grayson, KY', path: '/trailer-rentals/kentucky/grayson', lat: 38.3323, lng: -82.9488 },
  { name: 'Greensburg, KY', path: '/trailer-rentals/kentucky/greensburg', lat: 37.2595, lng: -85.4986 },
  { name: 'Greenville, KY', path: '/trailer-rentals/kentucky/greenville', lat: 37.2014, lng: -87.1786 },
  { name: 'Harlan, KY', path: '/trailer-rentals/kentucky/harlan', lat: 36.8434, lng: -83.321 },
  { name: 'Harrodsburg, KY', path: '/trailer-rentals/kentucky/harrodsburg', lat: 37.7629, lng: -84.843 },
  { name: 'Hartford, KY', path: '/trailer-rentals/kentucky/hartford', lat: 37.4509, lng: -86.9075 },
  { name: 'Hazard, KY', path: '/trailer-rentals/kentucky/hazard', lat: 37.249, lng: -83.1932 },
  { name: 'Henderson, KY', path: '/trailer-rentals/kentucky/henderson', lat: 37.8362, lng: -87.590 },
  { name: 'Hickman, KY', path: '/trailer-rentals/kentucky/hickman', lat: 36.5717, lng: -89.1912 },
  { name: 'Highland Heights, KY', path: '/trailer-rentals/kentucky/highland-heights', lat: 39.0328, lng: -84.4555 },
  { name: 'Hopkinsville, KY', path: '/trailer-rentals/kentucky/hopkinsville', lat: 36.8656, lng: -87.4886 },
  { name: 'Horse Cave, KY', path: '/trailer-rentals/kentucky/horse-cave', lat: 37.1795, lng: -85.9069 },
  { name: 'Independence, KY', path: '/trailer-rentals/kentucky/independence', lat: 38.9431, lng: -84.5441 },
  { name: 'Irvine, KY', path: '/trailer-rentals/kentucky/irvine', lat: 37.6962, lng: -83.9738 },
  { name: 'Jackson, KY', path: '/trailer-rentals/kentucky/jackson', lat: 37.5537, lng: -83.3838 },
  { name: 'Jenkins, KY', path: '/trailer-rentals/kentucky/jenkins', lat: 37.1795, lng: -82.6313 },
  { name: 'La Grange, KY', path: '/trailer-rentals/kentucky/la-grange', lat: 38.4073, lng: -85.3788 },
  { name: 'Lawrenceburg, KY', path: '/trailer-rentals/kentucky/lawrenceburg', lat: 38.037, lng: -84.8966 },
  { name: 'Lebanon, KY', path: '/trailer-rentals/kentucky/lebanon', lat: 37.569, lng: -85.2527 },
  { name: 'Leitchfield, KY', path: '/trailer-rentals/kentucky/leitchfield', lat: 37.4806, lng: -86.2936 },
  { name: 'Lexington, KY', path: '/trailer-rentals/kentucky/lexington', lat: 38.0406, lng: -84.5037 },
  { name: 'Liberty, KY', path: '/trailer-rentals/kentucky/liberty', lat: 37.3184, lng: -84.933 },
  { name: 'London, KY', path: '/trailer-rentals/kentucky/london', lat: 37.1289, lng: -84.0833 },
  { name: 'Louisa, KY', path: '/trailer-rentals/kentucky/louisa', lat: 38.1145, lng: -82.6032 },
  { name: 'Louisville, KY', path: '/trailer-rentals/kentucky/louisville', lat: 38.2527, lng: -85.7585 },
  { name: 'Ludlow, KY', path: '/trailer-rentals/kentucky/ludlow', lat: 39.0914, lng: -84.5474 },
  { name: 'Madisonville, KY', path: '/trailer-rentals/kentucky/madisonville', lat: 37.3281, lng: -87.4989 },
  { name: 'Manchester, KY', path: '/trailer-rentals/kentucky/manchester', lat: 37.1537, lng: -83.7616 },
  { name: 'Marion, KY', path: '/trailer-rentals/kentucky/marion', lat: 37.332, lng: -88.0811 },
  { name: 'Mayfield, KY', path: '/trailer-rentals/kentucky/mayfield', lat: 36.7412, lng: -88.6364 },
  { name: 'Maysville, KY', path: '/trailer-rentals/kentucky/maysville', lat: 38.6412, lng: -83.7441 },
  { name: 'Middlesboro, KY', path: '/trailer-rentals/kentucky/middlesboro', lat: 36.6084, lng: -83.716 },
  { name: 'Monticello, KY', path: '/trailer-rentals/kentucky/monticello', lat: 36.8295, lng: -84.8494 },
  { name: 'Morehead, KY', path: '/trailer-rentals/kentucky/morehead', lat: 38.1837, lng: -83.4327 },
  { name: 'Morganfield, KY', path: '/trailer-rentals/kentucky/morganfield', lat: 37.6834, lng: -87.9164 },
  { name: 'Mount Sterling, KY', path: '/trailer-rentals/kentucky/mount-sterling', lat: 38.0565, lng: -83.943 },
  { name: 'Mount Vernon, KY', path: '/trailer-rentals/kentucky/mount-vernon', lat: 37.352, lng: -84.3405 },
  { name: 'Murray, KY', path: '/trailer-rentals/kentucky/murray', lat: 36.6103, lng: -88.3148 },
  { name: 'Newport, KY', path: '/trailer-rentals/kentucky/newport', lat: 39.0914, lng: -84.4958 },
  { name: 'Nicholasville, KY', path: '/trailer-rentals/kentucky/nicholasville', lat: 37.8806, lng: -84.572 },
  { name: 'Owensboro, KY', path: '/trailer-rentals/kentucky/owensboro', lat: 37.7719, lng: -87.1112 },
  { name: 'Owenton, KY', path: '/trailer-rentals/kentucky/owenton', lat: 38.5365, lng: -84.8397 },
  { name: 'Paducah, KY', path: '/trailer-rentals/kentucky/paducah', lat: 37.0834, lng: -88.600 },
  { name: 'Paintsville, KY', path: '/trailer-rentals/kentucky/paintsville', lat: 37.8145, lng: -82.8074 },
  { name: 'Paris, KY', path: '/trailer-rentals/kentucky/paris', lat: 38.2098, lng: -84.2527 },
  { name: 'Pikeville, KY', path: '/trailer-rentals/kentucky/pikeville', lat: 37.4793, lng: -82.5187 },
  { name: 'Pineville, KY', path: '/trailer-rentals/kentucky/pineville', lat: 36.7634, lng: -83.6941 },
  { name: 'Prestonsburg, KY', path: '/trailer-rentals/kentucky/prestonsburg', lat: 37.6653, lng: -82.7715 },
  { name: 'Princeton, KY', path: '/trailer-rentals/kentucky/princeton', lat: 37.1092, lng: -87.8811 },
  { name: 'Providence, KY', path: '/trailer-rentals/kentucky/providence', lat: 37.3987, lng: -87.7572 },
  { name: 'Radcliff, KY', path: '/trailer-rentals/kentucky/radcliff', lat: 37.8403, lng: -85.9491 },
  { name: 'Richmond, KY', path: '/trailer-rentals/kentucky/richmond', lat: 37.7479, lng: -84.2947 },
  { name: 'Russell, KY', path: '/trailer-rentals/kentucky/russell', lat: 38.5179, lng: -82.7013 },
  { name: 'Russellville, KY', path: '/trailer-rentals/kentucky/russellville', lat: 36.8459, lng: -86.8875 },
  { name: 'Shelbyville, KY', path: '/trailer-rentals/kentucky/shelbyville', lat: 38.212, lng: -85.223 },
  { name: 'Shepherdsville, KY', path: '/trailer-rentals/kentucky/shepherdsville', lat: 37.9884, lng: -85.7158 },
  { name: 'Shively, KY', path: '/trailer-rentals/kentucky/shively', lat: 38.2009, lng: -85.8227 },
  { name: 'Somerset, KY', path: '/trailer-rentals/kentucky/somerset', lat: 37.092, lng: -84.6041 },
  { name: 'Southgate, KY', path: '/trailer-rentals/kentucky/southgate', lat: 39.0651, lng: -84.4713 },
  { name: 'Springfield, KY', path: '/trailer-rentals/kentucky/springfield', lat: 37.6859, lng: -85.2222 },
  { name: 'Stanford, KY', path: '/trailer-rentals/kentucky/stanford', lat: 37.5315, lng: -84.6616 },
  { name: 'Stanton, KY', path: '/trailer-rentals/kentucky/stanton', lat: 37.8473, lng: -83.8588 },
  { name: 'Taylor Mill, KY', path: '/trailer-rentals/kentucky/taylor-mill', lat: 39.0101, lng: -84.4833 },
  { name: 'Tompkinsville, KY', path: '/trailer-rentals/kentucky/tompkinsville', lat: 36.7023, lng: -85.6936 },
  { name: 'Union, KY', path: '/trailer-rentals/kentucky/union', lat: 38.9459, lng: -84.6803 },
  { name: 'Versailles, KY', path: '/trailer-rentals/kentucky/versailles', lat: 38.0526, lng: -84.7316 },
  { name: 'Villa Hills, KY', path: '/trailer-rentals/kentucky/villa-hills', lat: 39.0451, lng: -84.5827 },
  { name: 'Walton, KY', path: '/trailer-rentals/kentucky/walton', lat: 38.8734, lng: -84.6105 },
  { name: 'West Liberty, KY', path: '/trailer-rentals/kentucky/west-liberty', lat: 37.9145, lng: -83.2638 },
  { name: 'Whitesburg, KY', path: '/trailer-rentals/kentucky/whitesburg', lat: 37.1184, lng: -82.8269 },
  { name: 'Williamsburg, KY', path: '/trailer-rentals/kentucky/williamsburg', lat: 36.7434, lng: -84.1593 },
  { name: 'Wilmore, KY', path: '/trailer-rentals/kentucky/wilmore', lat: 37.8629, lng: -84.6616 },
  { name: 'Winchester, KY', path: '/trailer-rentals/kentucky/winchester', lat: 37.992, lng: -84.1797 },
];

export const louisianaCities: ICity[] = [
  { name: 'Abbeville, LA', path: '/trailer-rentals/louisiana/abbeville', lat: 29.9741, lng: -92.1343 },
  { name: 'Addis, LA', path: '/trailer-rentals/louisiana/addis', lat: 30.3535, lng: -91.2662, active: true, showNewVersion: true  },
  { name: 'Alexandria, LA', path: '/trailer-rentals/louisiana/alexandria', lat: 31.3113, lng: -92.4451 },
  { name: 'Amite City, LA', path: '/trailer-rentals/louisiana/amite-city', lat: 30.7266, lng: -90.5084 },
  { name: 'Arcadia, LA', path: '/trailer-rentals/louisiana/arcadia', lat: 32.5526, lng: -92.9199 },
  { name: 'Baker, LA', path: '/trailer-rentals/louisiana/baker', lat: 30.5885, lng: -91.1685 },
  { name: 'Baldwin, LA', path: '/trailer-rentals/louisiana/baldwin', lat: 29.8377, lng: -91.5432 },
  { name: 'Bastrop, LA', path: '/trailer-rentals/louisiana/bastrop', lat: 32.7565, lng: -91.8721 },
  { name: 'Baton Rouge, LA', path: '/trailer-rentals/louisiana/baton-rouge', lat: 30.4515, lng: -91.1871 },
  { name: 'Berwick, LA', path: '/trailer-rentals/louisiana/berwick', lat: 29.6944, lng: -91.2384 },
  { name: 'Bogalusa, LA', path: '/trailer-rentals/louisiana/bogalusa', lat: 30.7916, lng: -89.8487 },
  { name: 'Bossier City, LA', path: '/trailer-rentals/louisiana/bossier-city', lat: 32.5150, lng: -93.7321 },
  { name: 'Breaux Bridge, LA', path: '/trailer-rentals/louisiana/breaux-bridge', lat: 30.2730, lng: -91.8998 },
  { name: 'Broussard, LA', path: '/trailer-rentals/louisiana/broussard', lat: 30.1474, lng: -91.9618 },
  { name: 'Bunkie, LA', path: '/trailer-rentals/louisiana/bunkie', lat: 30.9538, lng: -92.1821 },
  { name: 'Carencro, LA', path: '/trailer-rentals/louisiana/carencro', lat: 30.3202, lng: -92.0493 },
  { name: 'Church Point, LA', path: '/trailer-rentals/louisiana/church-point', lat: 30.4027, lng: -92.2171 },
  { name: 'Clinton, LA', path: '/trailer-rentals/louisiana/clinton', lat: 30.8638, lng: -91.0148 },
  { name: 'Covington, LA', path: '/trailer-rentals/louisiana/covington', lat: 30.4755, lng: -90.1009 },
  { name: 'Crowley, LA', path: '/trailer-rentals/louisiana/crowley', lat: 30.2141, lng: -92.3746 },
  { name: 'DeQuincy, LA', path: '/trailer-rentals/louisiana/dequincy', lat: 30.4502, lng: -93.4335 },
  { name: 'DeRidder, LA', path: '/trailer-rentals/louisiana/deridder', lat: 30.8460, lng: -93.2902 },
  { name: 'Donaldsonville, LA', path: '/trailer-rentals/louisiana/donaldsonville', lat: 30.1019, lng: -90.9920 },
  { name: 'Duson, LA', path: '/trailer-rentals/louisiana/duson', lat: 30.2360, lng: -92.1862 },
  { name: 'Elton, LA', path: '/trailer-rentals/louisiana/elton', lat: 30.4819, lng: -92.6968 },
  { name: 'Erath, LA', path: '/trailer-rentals/louisiana/erath', lat: 29.9588, lng: -92.0351 },
  { name: 'Eunice, LA', path: '/trailer-rentals/louisiana/eunice', lat: 30.4944, lng: -92.4171 },
  { name: 'Farmerville, LA', path: '/trailer-rentals/louisiana/farmerville', lat: 32.7735, lng: -92.4051 },
  { name: 'Ferriday, LA', path: '/trailer-rentals/louisiana/ferriday', lat: 31.6302, lng: -91.5551 },
  { name: 'Franklin, LA', path: '/trailer-rentals/louisiana/franklin', lat: 29.7960, lng: -91.5012 },
  { name: 'Glenmora, LA', path: '/trailer-rentals/louisiana/glenmora', lat: 30.9735, lng: -92.5821 },
  { name: 'Gonzales, LA', path: '/trailer-rentals/louisiana/gonzales', lat: 30.2385, lng: -90.9201 },
  { name: 'Grambling, LA', path: '/trailer-rentals/louisiana/grambling', lat: 32.5276, lng: -92.7143 },
  { name: 'Gramercy, LA', path: '/trailer-rentals/louisiana/gramercy', lat: 30.0460, lng: -90.6898 },
  { name: 'Grand Coteau, LA', path: '/trailer-rentals/louisiana/grand-coteau', lat: 30.4171, lng: -92.0485 },
  { name: 'Greensburg, LA', path: '/trailer-rentals/louisiana/greensburg', lat: 30.8302, lng: -90.6698 },
  { name: 'Greenwood, LA', path: '/trailer-rentals/louisiana/greenwood', lat: 32.4426, lng: -93.9735 },
  { name: 'Gretna, LA', path: '/trailer-rentals/louisiana/gretna', lat: 29.9144, lng: -90.0534 },
  { name: 'Hammond, LA', path: '/trailer-rentals/louisiana/hammond', lat: 30.5044, lng: -90.4612 },
  { name: 'Harahan, LA', path: '/trailer-rentals/louisiana/harahan', lat: 29.9402, lng: -90.2034 },
  { name: 'Harrisonburg, LA', path: '/trailer-rentals/louisiana/harrisonburg', lat: 31.7710, lng: -91.8212 },
  { name: 'Haughton, LA', path: '/trailer-rentals/louisiana/haughton', lat: 32.5326, lng: -93.5043 },
  { name: 'Homer, LA', path: '/trailer-rentals/louisiana/homer', lat: 32.7916, lng: -93.0551 },
  { name: 'Houma, LA', path: '/trailer-rentals/louisiana/houma', lat: 29.5958, lng: -90.7195 },
  { name: 'Independence, LA', path: '/trailer-rentals/louisiana/independence', lat: 30.6360, lng: -90.5043 },
  { name: 'Iowa, LA', path: '/trailer-rentals/louisiana/iowa', lat: 30.2360, lng: -93.0135 },
  { name: 'Jackson, LA', path: '/trailer-rentals/louisiana/jackson', lat: 30.8377, lng: -91.2112 },
  { name: 'Jeanerette, LA', path: '/trailer-rentals/louisiana/jeanerette', lat: 29.9177, lng: -91.6698 },
  { name: 'Jennings, LA', path: '/trailer-rentals/louisiana/jennings', lat: 30.2227, lng: -92.6571 },
  { name: 'Jena, LA', path: '/trailer-rentals/louisiana/jena', lat: 31.6835, lng: -92.1271 },
  { name: 'Kentwood, LA', path: '/trailer-rentals/louisiana/kentwood', lat: 30.9385, lng: -90.5101 },
  { name: 'Kinder, LA', path: '/trailer-rentals/louisiana/kinder', lat: 30.4858, lng: -92.8501 },
  { name: 'Lafayette, LA', path: '/trailer-rentals/louisiana/lafayette', lat: 30.2241, lng: -92.0198 },
  { name: 'Lake Charles, LA', path: '/trailer-rentals/louisiana/lake-charles', lat: 30.2266, lng: -93.2171 },
  { name: 'Leesville, LA', path: '/trailer-rentals/louisiana/leesville', lat: 31.1435, lng: -93.2612 },
  { name: 'Livonia, LA', path: '/trailer-rentals/louisiana/livonia', lat: 30.5610, lng: -91.5485 },
  { name: 'Lutcher, LA', path: '/trailer-rentals/louisiana/lutcher', lat: 30.0444, lng: -90.6998 },
  { name: 'Madisonville, LA', path: '/trailer-rentals/louisiana/madisonville', lat: 30.3971, lng: -90.1612 },
  { name: 'Mamou, LA', path: '/trailer-rentals/louisiana/mamou', lat: 30.6344, lng: -92.4185 },
  { name: 'Mandeville, LA', path: '/trailer-rentals/louisiana/mandeville', lat: 30.3585, lng: -90.0651 },
  { name: 'Mansfield, LA', path: '/trailer-rentals/louisiana/mansfield', lat: 32.0377, lng: -93.7012 },
  { name: 'Many, LA', path: '/trailer-rentals/louisiana/many', lat: 31.5660, lng: -93.4843 },
  { name: 'Marrero, LA', path: '/trailer-rentals/louisiana/marrero', lat: 29.8994, lng: -90.1009 },
  { name: 'Minden, LA', path: '/trailer-rentals/louisiana/minden', lat: 32.6158, lng: -93.2862 },
  { name: 'Monroe, LA', path: '/trailer-rentals/louisiana/monroe', lat: 32.5093, lng: -92.1193 },
  { name: 'Morgan City, LA', path: '/trailer-rentals/louisiana/morgan-city', lat: 29.6996, lng: -91.2068 },
  { name: 'Natchitoches, LA', path: '/trailer-rentals/louisiana/natchitoches', lat: 31.7607, lng: -93.0863 },
  { name: 'New Iberia, LA', path: '/trailer-rentals/louisiana/new-iberia', lat: 30.0035, lng: -91.8187 },
  { name: 'New Orleans, LA', path: '/trailer-rentals/louisiana/new-orleans', lat: 29.9511, lng: -90.0715 },
  { name: 'Oak Grove, LA', path: '/trailer-rentals/louisiana/oak-grove', lat: 32.8610, lng: -91.3862 },
  { name: 'Oakdale, LA', path: '/trailer-rentals/louisiana/oakdale', lat: 30.8160, lng: -92.6601 },
  { name: 'Opelousas, LA', path: '/trailer-rentals/louisiana/opelousas', lat: 30.5335, lng: -92.0812 },
  { name: 'Patterson, LA', path: '/trailer-rentals/louisiana/patterson', lat: 29.6935, lng: -91.3021 },
  { name: 'Pineville, LA', path: '/trailer-rentals/louisiana/pineville', lat: 31.3227, lng: -92.4343 },
  { name: 'Plaquemine, LA', path: '/trailer-rentals/louisiana/plaquemine', lat: 30.2896, lng: -91.2343 },
  { name: 'Ponchatoula, LA', path: '/trailer-rentals/louisiana/ponchatoula', lat: 30.4385, lng: -90.4412 },
  { name: 'Port Allen, LA', path: '/trailer-rentals/louisiana/port-allen', lat: 30.4527, lng: -91.2101 },
  { name: 'Rayne, LA', path: '/trailer-rentals/louisiana/rayne', lat: 30.2344, lng: -92.2685 },
  { name: 'Ruston, LA', path: '/trailer-rentals/louisiana/ruston', lat: 32.5293, lng: -92.6403 },
  { name: 'St. Gabriel, LA', path: '/trailer-rentals/louisiana/st-gabriel', lat: 30.2552, lng: -91.1012 },
  { name: 'St. Martinville, LA', path: '/trailer-rentals/louisiana/st-martinville', lat: 30.1252, lng: -91.8293 },
  { name: 'Shreveport, LA', path: '/trailer-rentals/louisiana/shreveport', lat: 32.5252, lng: -93.7502 },
  { name: 'Slidell, LA', path: '/trailer-rentals/louisiana/slidell', lat: 30.2752, lng: -89.7812 },
  { name: 'Springhill, LA', path: '/trailer-rentals/louisiana/springhill', lat: 33.0035, lng: -93.4643 },
  { name: 'Sulphur, LA', path: '/trailer-rentals/louisiana/sulphur', lat: 30.2360, lng: -93.3771 },
  { name: 'Tallulah, LA', path: '/trailer-rentals/louisiana/tallulah', lat: 32.4085, lng: -91.1862 },
  { name: 'Thibodaux, LA', path: '/trailer-rentals/louisiana/thibodaux', lat: 29.7958, lng: -90.8221 },
  { name: 'Vidalia, LA', path: '/trailer-rentals/louisiana/vidalia', lat: 31.5660, lng: -91.4251 },
  { name: 'Ville Platte, LA', path: '/trailer-rentals/louisiana/ville-platte', lat: 30.6871, lng: -92.2712 },
  { name: 'Walker, LA', path: '/trailer-rentals/louisiana/walker', lat: 30.4871, lng: -90.8612 },
  { name: 'Welsh, LA', path: '/trailer-rentals/louisiana/welsh', lat: 30.2360, lng: -92.8171 },
  { name: 'West Monroe, LA', path: '/trailer-rentals/louisiana/west-monroe', lat: 32.5185, lng: -92.1471 },
  { name: 'Westlake, LA', path: '/trailer-rentals/louisiana/westlake', lat: 30.2458, lng: -93.2671 },
  { name: 'Winnfield, LA', path: '/trailer-rentals/louisiana/winnfield', lat: 31.9258, lng: -92.6393 },
  { name: 'Winnsboro, LA', path: '/trailer-rentals/louisiana/winnsboro', lat: 32.1635, lng: -91.7212 },
  { name: 'Woodworth, LA', path: '/trailer-rentals/louisiana/woodworth', lat: 31.1658, lng: -92.4962 },
  { name: 'Zachary, LA', path: '/trailer-rentals/louisiana/zachary', lat: 30.6485, lng: -91.1562 },
];

export const marylandCities: ICity[] = [
  { name: 'Aberdeen, MD', path: '/trailer-rentals/maryland/aberdeen', lat: 39.5096, lng: -76.1641 },
  { name: 'Annapolis, MD', path: '/trailer-rentals/maryland/annapolis', lat: 38.9784, lng: -76.4922 },
  { name: 'Baltimore, MD', path: '/trailer-rentals/maryland/baltimore', lat: 39.2904, lng: -76.6122 },
  { name: 'Bel Air, MD', path: '/trailer-rentals/maryland/bel-air', lat: 39.5359, lng: -76.3483 },
  { name: 'Berlin, MD', path: '/trailer-rentals/maryland/berlin', lat: 38.3226, lng: -75.2177 },
  { name: 'Bethesda, MD', path: '/trailer-rentals/maryland/bethesda', lat: 38.9847, lng: -77.0947 },
  { name: 'Bowie, MD', path: '/trailer-rentals/maryland/bowie', lat: 39.0068, lng: -76.7791 },
  { name: 'Cambridge, MD', path: '/trailer-rentals/maryland/cambridge', lat: 38.5632, lng: -76.0788 },
  { name: 'Capitol Heights, MD', path: '/trailer-rentals/maryland/capitol-heights', lat: 38.8851, lng: -76.9155 },
  { name: 'Catonsville, MD', path: '/trailer-rentals/maryland/catonsville', lat: 39.2721, lng: -76.7319 },
  { name: 'College Park, MD', path: '/trailer-rentals/maryland/college-park', lat: 38.9897, lng: -76.9378 },
  { name: 'Columbia, MD', path: '/trailer-rentals/maryland/columbia', lat: 39.2037, lng: -76.8610 },
  { name: 'Cumberland, MD', path: '/trailer-rentals/maryland/cumberland', lat: 39.6529, lng: -78.7625 },
  { name: 'Damascus, MD', path: '/trailer-rentals/maryland/damascus', lat: 39.2884, lng: -77.2039 },
  { name: 'District Heights, MD', path: '/trailer-rentals/maryland/district-heights', lat: 38.8576, lng: -76.8894 },
  { name: 'Easton, MD', path: '/trailer-rentals/maryland/easton', lat: 38.7743, lng: -76.0763 },
  { name: 'Edgewood, MD', path: '/trailer-rentals/maryland/edgewood', lat: 39.4182, lng: -76.2941 },
  { name: 'Elkridge, MD', path: '/trailer-rentals/maryland/elkridge', lat: 39.2126, lng: -76.7136 },
  { name: 'Elkton, MD', path: '/trailer-rentals/maryland/elkton', lat: 39.6068, lng: -75.8333 },
  { name: 'Ellicott City, MD', path: '/trailer-rentals/maryland/ellicott-city', lat: 39.2673, lng: -76.7983 },
  { name: 'Essex, MD', path: '/trailer-rentals/maryland/essex', lat: 39.3093, lng: -76.4750 },
  { name: 'Frederick, MD', path: '/trailer-rentals/maryland/frederick', lat: 39.4143, lng: -77.4105 },
  { name: 'Gaithersburg, MD', path: '/trailer-rentals/maryland/gaithersburg', lat: 39.1434, lng: -77.2014 },
  { name: 'Germantown, MD', path: '/trailer-rentals/maryland/germantown', lat: 39.1732, lng: -77.2717 },
  { name: 'Glen Burnie, MD', path: '/trailer-rentals/maryland/glen-burnie', lat: 39.1626, lng: -76.6247 },
  { name: 'Greenbelt, MD', path: '/trailer-rentals/maryland/greenbelt', lat: 39.0046, lng: -76.8755 },
  { name: 'Hagerstown, MD', path: '/trailer-rentals/maryland/hagerstown', lat: 39.6418, lng: -77.7199 },
  { name: 'Hampstead, MD', path: '/trailer-rentals/maryland/hampstead', lat: 39.6043, lng: -76.8494 },
  { name: 'Hyattsville, MD', path: '/trailer-rentals/maryland/hyattsville', lat: 38.9559, lng: -76.9455 },
  { name: 'La Plata, MD', path: '/trailer-rentals/maryland/la-plata', lat: 38.5296, lng: -76.9753 },
  { name: 'Laurel, MD', path: '/trailer-rentals/maryland/laurel', lat: 39.0993, lng: -76.8483 },
  { name: 'Lutherville, MD', path: '/trailer-rentals/maryland/lutherville', lat: 39.4215, lng: -76.6264 },
  { name: 'Manchester, MD', path: '/trailer-rentals/maryland/manchester', lat: 39.6615, lng: -76.8844 },
  { name: 'Middle River, MD', path: '/trailer-rentals/maryland/middle-river', lat: 39.3343, lng: -76.4394 },
  { name: 'Mount Airy, MD', path: '/trailer-rentals/maryland/mount-airy', lat: 39.3765, lng: -77.1544 },
  { name: 'New Carrollton, MD', path: '/trailer-rentals/maryland/new-carrollton', lat: 38.9698, lng: -76.8794 },
  { name: 'North Bethesda, MD', path: '/trailer-rentals/maryland/north-bethesda', lat: 39.0446, lng: -77.1180 },
  { name: 'North Laurel, MD', path: '/trailer-rentals/maryland/north-laurel', lat: 39.1368, lng: -76.8597 },
  { name: 'Ocean City, MD', path: '/trailer-rentals/maryland/ocean-city', lat: 38.3365, lng: -75.0849 },
  { name: 'Odenton, MD', path: '/trailer-rentals/maryland/odenton', lat: 39.0840, lng: -76.7003 },
  { name: 'Owings Mills, MD', path: '/trailer-rentals/maryland/owings-mills', lat: 39.4196, lng: -76.7803 },
  { name: 'Oxon Hill, MD', path: '/trailer-rentals/maryland/oxon-hill', lat: 38.8032, lng: -76.9897 },
  { name: 'Parkville, MD', path: '/trailer-rentals/maryland/parkville', lat: 39.3776, lng: -76.5397 },
  { name: 'Pasadena, MD', path: '/trailer-rentals/maryland/pasadena', lat: 39.1070, lng: -76.5711 },
  { name: 'Perry Hall, MD', path: '/trailer-rentals/maryland/perry-hall', lat: 39.4126, lng: -76.4636 },
  { name: 'Pikesville, MD', path: '/trailer-rentals/maryland/pikesville', lat: 39.3743, lng: -76.7225 },
  { name: 'Potomac, MD', path: '/trailer-rentals/maryland/potomac', lat: 39.0182, lng: -77.2086 },
  { name: 'Randallstown, MD', path: '/trailer-rentals/maryland/randallstown', lat: 39.3673, lng: -76.7955 },
  { name: 'Reisterstown, MD', path: '/trailer-rentals/maryland/reisterstown', lat: 39.4696, lng: -76.8294 },
  { name: 'Rockville, MD', path: '/trailer-rentals/maryland/rockville', lat: 39.0839, lng: -77.1528 },
  { name: 'Salisbury, MD', path: '/trailer-rentals/maryland/salisbury', lat: 38.3607, lng: -75.5994 },
  { name: 'Seabrook, MD', path: '/trailer-rentals/maryland/seabrook', lat: 38.9676, lng: -76.8464 },
  { name: 'Severn, MD', path: '/trailer-rentals/maryland/severn', lat: 39.1376, lng: -76.6983 },
  { name: 'Severna Park, MD', path: '/trailer-rentals/maryland/severna-park', lat: 39.0704, lng: -76.5452 },
  { name: 'Silver Spring, MD', path: '/trailer-rentals/maryland/silver-spring', lat: 38.9907, lng: -77.0261 },
  { name: 'South Laurel, MD', path: '/trailer-rentals/maryland/south-laurel', lat: 39.0698, lng: -76.8503 },
  { name: 'St. Charles, MD', path: '/trailer-rentals/maryland/st-charles', lat: 38.6032, lng: -76.9383 },
  { name: 'Suitland, MD', path: '/trailer-rentals/maryland/suitland', lat: 38.8487, lng: -76.9233 },
  { name: 'Takoma Park, MD', path: '/trailer-rentals/maryland/takoma-park', lat: 38.9779, lng: -77.0075 },
  { name: 'Towson, MD', path: '/trailer-rentals/maryland/towson', lat: 39.4015, lng: -76.6019 },
  { name: 'University Park, MD', path: '/trailer-rentals/maryland/university-park', lat: 38.9671, lng: -76.9375 },
  { name: 'Waldorf, MD', path: '/trailer-rentals/maryland/waldorf', lat: 38.6246, lng: -76.9391 },
  { name: 'Westminster, MD', path: '/trailer-rentals/maryland/westminster', lat: 39.5754, lng: -76.9958 },
  { name: 'Wheaton, MD', path: '/trailer-rentals/maryland/wheaton', lat: 39.0398, lng: -77.0553 },
  { name: 'White Oak, MD', path: '/trailer-rentals/maryland/white-oak', lat: 39.0398, lng: -76.9936 },
];

export const michiganCities: ICity[] = [
  { name: 'Adrian, MI', path: '/trailer-rentals/michigan/adrian', lat: 41.8975, lng: -84.0372 },
  { name: 'Albion, MI', path: '/trailer-rentals/michigan/albion', lat: 42.2431, lng: -84.7530 },
  { name: 'Allegan, MI', path: '/trailer-rentals/michigan/allegan', lat: 42.5292, lng: -85.8553 },
  { name: 'Alma, MI', path: '/trailer-rentals/michigan/alma', lat: 43.3789, lng: -84.6591 },
  { name: 'Ann Arbor, MI', path: '/trailer-rentals/michigan/ann-arbor', lat: 42.2808, lng: -83.7430 },
  { name: 'Auburn Hills, MI', path: '/trailer-rentals/michigan/auburn-hills', lat: 42.6875, lng: -83.2341 },
  { name: 'Battle Creek, MI', path: '/trailer-rentals/michigan/battle-creek', lat: 42.3212, lng: -85.1797 },
  { name: 'Bay City, MI', path: '/trailer-rentals/michigan/bay-city', lat: 43.5945, lng: -83.8889 },
  { name: 'Benton Harbor, MI', path: '/trailer-rentals/michigan/benton-harbor', lat: 42.1167, lng: -86.4542 },
  { name: 'Birmingham, MI', path: '/trailer-rentals/michigan/birmingham', lat: 42.5467, lng: -83.2113 },
  { name: 'Cadillac, MI', path: '/trailer-rentals/michigan/cadillac', lat: 44.2511, lng: -85.4012 },
  { name: 'Center Line, MI', path: '/trailer-rentals/michigan/center-line', lat: 42.4859, lng: -83.0274 },
  { name: 'Chelsea, MI', path: '/trailer-rentals/michigan/chelsea', lat: 42.3181, lng: -84.0205 },
  { name: 'Clawson, MI', path: '/trailer-rentals/michigan/clawson', lat: 42.5334, lng: -83.1466 },
  { name: 'Coldwater, MI', path: '/trailer-rentals/michigan/coldwater', lat: 41.9403, lng: -85.0005 },
  { name: 'Dearborn, MI', path: '/trailer-rentals/michigan/dearborn', lat: 42.3223, lng: -83.1763 },
  { name: 'Dearborn Heights, MI', path: '/trailer-rentals/michigan/dearborn-heights', lat: 42.3360, lng: -83.2733 },
  { name: 'Detroit, MI', path: '/trailer-rentals/michigan/detroit', lat: 42.3314, lng: -83.0458, active: true, showNewVersion: true  },
  { name: 'Dowagiac, MI', path: '/trailer-rentals/michigan/dowagiac', lat: 41.9845, lng: -86.1086 },
  { name: 'East Grand Rapids, MI', path: '/trailer-rentals/michigan/east-grand-rapids', lat: 42.9417, lng: -85.6106 },
  { name: 'East Lansing, MI', path: '/trailer-rentals/michigan/east-lansing', lat: 42.7360, lng: -84.4839 },
  { name: 'Eastpointe, MI', path: '/trailer-rentals/michigan/eastpointe', lat: 42.4684, lng: -82.9555 },
  { name: 'Ecorse, MI', path: '/trailer-rentals/michigan/ecorse', lat: 42.2442, lng: -83.1452 },
  { name: 'Escanaba, MI', path: '/trailer-rentals/michigan/escanaba', lat: 45.7458, lng: -87.0646 },
  { name: 'Farmington Hills, MI', path: '/trailer-rentals/michigan/farmington-hills', lat: 42.4989, lng: -83.3677 },
  { name: 'Flint, MI', path: '/trailer-rentals/michigan/flint', lat: 43.0125, lng: -83.6875 },
  { name: 'Fraser, MI', path: '/trailer-rentals/michigan/fraser', lat: 42.5392, lng: -82.9491 },
  { name: 'Garden City, MI', path: '/trailer-rentals/michigan/garden-city', lat: 42.3256, lng: -83.3310 },
  { name: 'Gaylord, MI', path: '/trailer-rentals/michigan/gaylord', lat: 45.0278, lng: -84.6747 },
  { name: 'Grand Blanc, MI', path: '/trailer-rentals/michigan/grand-blanc', lat: 42.9275, lng: -83.6299 },
  { name: 'Grand Haven, MI', path: '/trailer-rentals/michigan/grand-haven', lat: 43.0631, lng: -86.2284 },
  { name: 'Grand Rapids, MI', path: '/trailer-rentals/michigan/grand-rapids', lat: 42.9634, lng: -85.6681 },
  { name: 'Grandville, MI', path: '/trailer-rentals/michigan/grandville', lat: 42.9095, lng: -85.7631 },
  { name: 'Grosse Pointe, MI', path: '/trailer-rentals/michigan/grosse-pointe', lat: 42.3862, lng: -82.9113 },
  { name: 'Hamtramck, MI', path: '/trailer-rentals/michigan/hamtramck', lat: 42.3928, lng: -83.0496 },
  { name: 'Harper Woods, MI', path: '/trailer-rentals/michigan/harper-woods', lat: 42.4334, lng: -82.9241 },
  { name: 'Hazel Park, MI', path: '/trailer-rentals/michigan/hazel-park', lat: 42.4628, lng: -83.1049 },
  { name: 'Highland Park, MI', path: '/trailer-rentals/michigan/highland-park', lat: 42.4059, lng: -83.0963 },
  { name: 'Holland, MI', path: '/trailer-rentals/michigan/holland', lat: 42.7875, lng: -86.1089 },
  { name: 'Howell, MI', path: '/trailer-rentals/michigan/howell', lat: 42.6073, lng: -83.9294 },
  { name: 'Ionia, MI', path: '/trailer-rentals/michigan/ionia', lat: 42.9875, lng: -85.0711 },
  { name: 'Iron Mountain, MI', path: '/trailer-rentals/michigan/iron-mountain', lat: 45.8200, lng: -88.0654 },
  { name: 'Ironwood, MI', path: '/trailer-rentals/michigan/ironwood', lat: 46.4547, lng: -90.1718 },
  { name: 'Ishpeming, MI', path: '/trailer-rentals/michigan/ishpeming', lat: 46.4880, lng: -87.6676 },
  { name: 'Jackson, MI', path: '/trailer-rentals/michigan/jackson', lat: 42.2459, lng: -84.4013 },
  { name: 'Kalamazoo, MI', path: '/trailer-rentals/michigan/kalamazoo', lat: 42.2917, lng: -85.5872 },
  { name: 'Kentwood, MI', path: '/trailer-rentals/michigan/kentwood', lat: 42.8695, lng: -85.6447 },
  { name: 'Lansing, MI', path: '/trailer-rentals/michigan/lansing', lat: 42.7325, lng: -84.5555 },
  { name: 'Lapeer, MI', path: '/trailer-rentals/michigan/lapeer', lat: 43.0514, lng: -83.3183 },
  { name: 'Lincoln Park, MI', path: '/trailer-rentals/michigan/lincoln-park', lat: 42.2506, lng: -83.1785 },
  { name: 'Livonia, MI', path: '/trailer-rentals/michigan/livonia', lat: 42.3684, lng: -83.3527 },
  { name: 'Ludington, MI', path: '/trailer-rentals/michigan/ludington', lat: 43.9553, lng: -86.4526 },
  { name: 'Madison Heights, MI', path: '/trailer-rentals/michigan/madison-heights', lat: 42.4859, lng: -83.1052 },
  { name: 'Manistee, MI', path: '/trailer-rentals/michigan/manistee', lat: 44.2447, lng: -86.3245 },
  { name: 'Marquette, MI', path: '/trailer-rentals/michigan/marquette', lat: 46.5476, lng: -87.3954 },
  { name: 'Marshall, MI', path: '/trailer-rentals/michigan/marshall', lat: 42.2728, lng: -84.9633 },
  { name: 'Marysville, MI', path: '/trailer-rentals/michigan/marysville', lat: 42.9125, lng: -82.4869 },
  { name: 'Midland, MI', path: '/trailer-rentals/michigan/midland', lat: 43.6156, lng: -84.2472 },
  { name: 'Monroe, MI', path: '/trailer-rentals/michigan/monroe', lat: 41.9164, lng: -83.3977 },
  { name: 'Mount Clemens, MI', path: '/trailer-rentals/michigan/mount-clemens', lat: 42.5973, lng: -82.8771 },
  { name: 'Mount Pleasant, MI', path: '/trailer-rentals/michigan/mount-pleasant', lat: 43.5978, lng: -84.7675 },
  { name: 'Muskegon, MI', path: '/trailer-rentals/michigan/muskegon', lat: 43.2342, lng: -86.2484 },
  { name: 'New Baltimore, MI', path: '/trailer-rentals/michigan/new-baltimore', lat: 42.6811, lng: -82.7369 },
  { name: 'Niles, MI', path: '/trailer-rentals/michigan/niles', lat: 41.8298, lng: -86.2542 },
  { name: 'Northville, MI', path: '/trailer-rentals/michigan/northville', lat: 42.4311, lng: -83.4833 },
  { name: 'Norton Shores, MI', path: '/trailer-rentals/michigan/norton-shores', lat: 43.1689, lng: -86.2634 },
  { name: 'Oak Park, MI', path: '/trailer-rentals/michigan/oak-park', lat: 42.4595, lng: -83.1827 },
  { name: 'Owosso, MI', path: '/trailer-rentals/michigan/owosso', lat: 42.9978, lng: -84.1766 },
  { name: 'Petoskey, MI', path: '/trailer-rentals/michigan/petoskey', lat: 45.3731, lng: -84.9553 },
  { name: 'Plymouth, MI', path: '/trailer-rentals/michigan/plymouth', lat: 42.3714, lng: -83.4702 },
  { name: 'Pontiac, MI', path: '/trailer-rentals/michigan/pontiac', lat: 42.6389, lng: -83.2910 },
  { name: 'Port Huron, MI', path: '/trailer-rentals/michigan/port-huron', lat: 42.9709, lng: -82.4249 },
  { name: 'Portage, MI', path: '/trailer-rentals/michigan/portage', lat: 42.2012, lng: -85.5800 },
  { name: 'Redford, MI', path: '/trailer-rentals/michigan/redford', lat: 42.3834, lng: -83.2966 },
  { name: 'Rochester, MI', path: '/trailer-rentals/michigan/rochester', lat: 42.6806, lng: -83.1338 },
  { name: 'Rockford, MI', path: '/trailer-rentals/michigan/rockford', lat: 43.1200, lng: -85.5603 },
  { name: 'Romulus, MI', path: '/trailer-rentals/michigan/romulus', lat: 42.2223, lng: -83.3966 },
  { name: 'Roseville, MI', path: '/trailer-rentals/michigan/roseville', lat: 42.4973, lng: -82.9371 },
  { name: 'Royal Oak, MI', path: '/trailer-rentals/michigan/royal-oak', lat: 42.4895, lng: -83.1446 },
  { name: 'Saginaw, MI', path: '/trailer-rentals/michigan/saginaw', lat: 43.4195, lng: -83.9508 },
  { name: 'Saint Clair Shores, MI', path: '/trailer-rentals/michigan/saint-clair-shores', lat: 42.4970, lng: -82.8964 },
  { name: 'Saint Joseph, MI', path: '/trailer-rentals/michigan/saint-joseph', lat: 42.1090, lng: -86.4790 },
  { name: 'Saline, MI', path: '/trailer-rentals/michigan/saline', lat: 42.1667, lng: -83.7816 },
  { name: 'South Lyon, MI', path: '/trailer-rentals/michigan/south-lyon', lat: 42.4609, lng: -83.6516 },
  { name: 'Southfield, MI', path: '/trailer-rentals/michigan/southfield', lat: 42.4734, lng: -83.2219 },
  { name: 'Southgate, MI', path: '/trailer-rentals/michigan/southgate', lat: 42.2134, lng: -83.1938 },
  { name: 'Sterling Heights, MI', path: '/trailer-rentals/michigan/sterling-heights', lat: 42.5803, lng: -83.0302 },
  { name: 'Sturgis, MI', path: '/trailer-rentals/michigan/sturgis', lat: 41.7992, lng: -85.4197 },
  { name: 'Taylor, MI', path: '/trailer-rentals/michigan/taylor', lat: 42.2409, lng: -83.2697 },
  { name: 'Three Rivers, MI', path: '/trailer-rentals/michigan/three-rivers', lat: 41.9437, lng: -85.6325 },
  { name: 'Traverse City, MI', path: '/trailer-rentals/michigan/traverse-city', lat: 44.7631, lng: -85.6206 },
  { name: 'Trenton, MI', path: '/trailer-rentals/michigan/trenton', lat: 42.1395, lng: -83.1780 },
  { name: 'Troy, MI', path: '/trailer-rentals/michigan/troy', lat: 42.6056, lng: -83.1499, active: true, showNewVersion: true  },
  { name: 'Utica, MI', path: '/trailer-rentals/michigan/utica', lat: 42.6295, lng: -83.0333 },
  { name: 'Walker, MI', path: '/trailer-rentals/michigan/walker', lat: 43.0014, lng: -85.7681 },
  { name: 'Warren, MI', path: '/trailer-rentals/michigan/warren', lat: 42.5145, lng: -83.0147 },
  { name: 'Wixom, MI', path: '/trailer-rentals/michigan/wixom', lat: 42.5242, lng: -83.5369 },
  { name: 'Wyandotte, MI', path: '/trailer-rentals/michigan/wyandotte', lat: 42.2142, lng: -83.1499 },
  { name: 'Wyoming, MI', path: '/trailer-rentals/michigan/wyoming', lat: 42.9134, lng: -85.7053 },
  { name: 'Ypsilanti, MI', path: '/trailer-rentals/michigan/ypsilanti', lat: 42.2411, lng: -83.6129 },
];

export const minnesotaCities: ICity[] = [
  { name: 'Ada, MN', path: '/trailer-rentals/minnesota/ada', lat: 47.2997, lng: -96.5128 },
  { name: 'Aitkin, MN', path: '/trailer-rentals/minnesota/aitkin', lat: 46.5333, lng: -93.7100 },
  { name: 'Albert Lea, MN', path: '/trailer-rentals/minnesota/albert-lea', lat: 43.6530, lng: -93.3687 },
  { name: 'Alexandria, MN', path: '/trailer-rentals/minnesota/alexandria', lat: 45.8848, lng: -95.3777 },
  { name: 'Annandale, MN', path: '/trailer-rentals/minnesota/annandale', lat: 45.2622, lng: -94.1244 },
  { name: 'Anoka, MN', path: '/trailer-rentals/minnesota/anoka', lat: 45.1977, lng: -93.3872 },
  { name: 'Apple Valley, MN', path: '/trailer-rentals/minnesota/apple-valley', lat: 44.7319, lng: -93.2177 },
  { name: 'Austin, MN', path: '/trailer-rentals/minnesota/austin', lat: 43.6666, lng: -92.9746 },
  { name: 'Avon, MN', path: '/trailer-rentals/minnesota/avon', lat: 45.6094, lng: -94.4528 },
  { name: 'Baudette, MN', path: '/trailer-rentals/minnesota/baudette', lat: 48.7122, lng: -94.5994 },
  { name: 'Baxter, MN', path: '/trailer-rentals/minnesota/baxter', lat: 46.3472, lng: -94.2700 },
  { name: 'Belle Plaine, MN', path: '/trailer-rentals/minnesota/belle-plaine', lat: 44.6233, lng: -93.7694 },
  { name: 'Bemidji, MN', path: '/trailer-rentals/minnesota/bemidji', lat: 47.4736, lng: -94.8803 },
  { name: 'Benson, MN', path: '/trailer-rentals/minnesota/benson', lat: 45.3144, lng: -95.6036 },
  { name: 'Big Lake, MN', path: '/trailer-rentals/minnesota/big-lake', lat: 45.3333, lng: -93.7494 },
  { name: 'Blue Earth, MN', path: '/trailer-rentals/minnesota/blue-earth', lat: 43.6372, lng: -94.1022 },
  { name: 'Brainerd, MN', path: '/trailer-rentals/minnesota/brainerd', lat: 46.3580, lng: -94.2008 },
  { name: 'Brooklyn Center, MN', path: '/trailer-rentals/minnesota/brooklyn-center', lat: 45.0761, lng: -93.3327 },
  { name: 'Brooklyn Park, MN', path: '/trailer-rentals/minnesota/brooklyn-park', lat: 45.0941, lng: -93.3563 },
  { name: 'Buffalo, MN', path: '/trailer-rentals/minnesota/buffalo', lat: 45.1719, lng: -93.8747 },
  { name: 'Burnsville, MN', path: '/trailer-rentals/minnesota/burnsville', lat: 44.7677, lng: -93.2777 },
  { name: 'Cambridge, MN', path: '/trailer-rentals/minnesota/cambridge', lat: 45.5722, lng: -93.2244 },
  { name: 'Cannon Falls, MN', path: '/trailer-rentals/minnesota/cannon-falls', lat: 44.5072, lng: -92.9058 },
  { name: 'Chanhassen, MN', path: '/trailer-rentals/minnesota/chanhassen', lat: 44.8611, lng: -93.5308 },
  { name: 'Chaska, MN', path: '/trailer-rentals/minnesota/chaska', lat: 44.7894, lng: -93.6019 },
  { name: 'Cloquet, MN', path: '/trailer-rentals/minnesota/cloquet', lat: 46.7216, lng: -92.4591 },
  { name: 'Cokato, MN', path: '/trailer-rentals/minnesota/cokato', lat: 45.0733, lng: -94.1894 },
  { name: 'Coon Rapids, MN', path: '/trailer-rentals/minnesota/coon-rapids', lat: 45.1736, lng: -93.3031 },
  { name: 'Cottage Grove, MN', path: '/trailer-rentals/minnesota/cottage-grove', lat: 44.8272, lng: -92.9433 },
  { name: 'Crookston, MN', path: '/trailer-rentals/minnesota/crookston', lat: 47.7744, lng: -96.6094 },
  { name: 'Crystal, MN', path: '/trailer-rentals/minnesota/crystal', lat: 45.0322, lng: -93.3608 },
  { name: 'Detroit Lakes, MN', path: '/trailer-rentals/minnesota/detroit-lakes', lat: 46.8172, lng: -95.8458 },
  { name: 'Duluth, MN', path: '/trailer-rentals/minnesota/duluth', lat: 46.7867, lng: -92.1005 },
  { name: 'Eagan, MN', path: '/trailer-rentals/minnesota/eagan', lat: 44.8041, lng: -93.1669 },
  { name: 'East Grand Forks, MN', path: '/trailer-rentals/minnesota/east-grand-forks', lat: 47.9250, lng: -97.0203 },
  { name: 'Eden Prairie, MN', path: '/trailer-rentals/minnesota/eden-prairie', lat: 44.8547, lng: -93.4708 },
  { name: 'Edina, MN', path: '/trailer-rentals/minnesota/edina', lat: 44.8897, lng: -93.3499 },
  { name: 'Elk River, MN', path: '/trailer-rentals/minnesota/elk-river', lat: 45.3030, lng: -93.5677 },
  { name: 'Ely, MN', path: '/trailer-rentals/minnesota/ely', lat: 47.9033, lng: -91.8672 },
  { name: 'Eveleth, MN', path: '/trailer-rentals/minnesota/eveleth', lat: 47.4622, lng: -92.5408 },
  { name: 'Fairmont, MN', path: '/trailer-rentals/minnesota/fairmont', lat: 43.6522, lng: -94.4619 },
  { name: 'Falcon Heights, MN', path: '/trailer-rentals/minnesota/falcon-heights', lat: 44.9916, lng: -93.1680 },
  { name: 'Faribault, MN', path: '/trailer-rentals/minnesota/faribault', lat: 44.2944, lng: -93.2686 },
  { name: 'Fergus Falls, MN', path: '/trailer-rentals/minnesota/fergus-falls', lat: 46.2833, lng: -96.0772 },
  { name: 'Forest Lake, MN', path: '/trailer-rentals/minnesota/forest-lake', lat: 45.2780, lng: -92.9858 },
  { name: 'Fridley, MN', path: '/trailer-rentals/minnesota/fridley', lat: 45.0861, lng: -93.2636 },
  { name: 'Glencoe, MN', path: '/trailer-rentals/minnesota/glencoe', lat: 44.7700, lng: -94.1519 },
  { name: 'Golden Valley, MN', path: '/trailer-rentals/minnesota/golden-valley', lat: 44.9911, lng: -93.3608 },
  { name: 'Grand Rapids, MN', path: '/trailer-rentals/minnesota/grand-rapids', lat: 47.2372, lng: -93.5308 },
  { name: 'Ham Lake, MN', path: '/trailer-rentals/minnesota/ham-lake', lat: 45.2500, lng: -93.2494 },
  { name: 'Hastings, MN', path: '/trailer-rentals/minnesota/hastings', lat: 44.7433, lng: -92.8522 },
  { name: 'Hibbing, MN', path: '/trailer-rentals/minnesota/hibbing', lat: 47.4272, lng: -92.9372 },
  { name: 'Hopkins, MN', path: '/trailer-rentals/minnesota/hopkins', lat: 44.9244, lng: -93.4086 },
  { name: 'Hugo, MN', path: '/trailer-rentals/minnesota/hugo', lat: 45.1594, lng: -92.9936 },
  { name: 'Hutchinson, MN', path: '/trailer-rentals/minnesota/hutchinson', lat: 44.8922, lng: -94.3694 },
  { name: 'International Falls, MN', path: '/trailer-rentals/minnesota/international-falls', lat: 48.6011, lng: -93.4108 },
  { name: 'Inver Grove Heights, MN', path: '/trailer-rentals/minnesota/inver-grove-heights', lat: 44.8486, lng: -93.0422 },
  { name: 'Isanti, MN', path: '/trailer-rentals/minnesota/isanti', lat: 45.4900, lng: -93.2472 },
  { name: 'Jackson, MN', path: '/trailer-rentals/minnesota/jackson', lat: 43.6200, lng: -94.9872 },
  { name: 'Jordan, MN', path: '/trailer-rentals/minnesota/jordan', lat: 44.6650, lng: -93.6272 },
  { name: 'Kasson, MN', path: '/trailer-rentals/minnesota/kasson', lat: 44.0319, lng: -92.7508 },
  { name: 'La Crescent, MN', path: '/trailer-rentals/minnesota/la-crescent', lat: 43.8286, lng: -91.3033 },
  { name: 'Lake City, MN', path: '/trailer-rentals/minnesota/lake-city', lat: 44.4500, lng: -92.2669 },
  { name: 'Lake Elmo, MN', path: '/trailer-rentals/minnesota/lake-elmo', lat: 44.9958, lng: -92.8794 },
  { name: 'Lakeville, MN', path: '/trailer-rentals/minnesota/lakeville', lat: 44.6497, lng: -93.2422 },
  { name: 'Litchfield, MN', path: '/trailer-rentals/minnesota/litchfield', lat: 45.1250, lng: -94.5269 },
  { name: 'Little Canada, MN', path: '/trailer-rentals/minnesota/little-canada', lat: 45.0261, lng: -93.0872 },
  { name: 'Little Falls, MN', path: '/trailer-rentals/minnesota/little-falls', lat: 45.9761, lng: -94.3622 },
  { name: 'Luverne, MN', path: '/trailer-rentals/minnesota/luverne', lat: 43.6544, lng: -96.2094 },
  { name: 'Mankato, MN', path: '/trailer-rentals/minnesota/mankato', lat: 44.1636, lng: -93.9994 },
  { name: 'Maple Grove, MN', path: '/trailer-rentals/minnesota/maple-grove', lat: 45.0722, lng: -93.4558 },
  { name: 'Maplewood, MN', path: '/trailer-rentals/minnesota/maplewood', lat: 44.9533, lng: -93.0308 },
  { name: 'Marshall, MN', path: '/trailer-rentals/minnesota/marshall', lat: 44.4461, lng: -95.7886 },
  { name: 'Mendota Heights, MN', path: '/trailer-rentals/minnesota/mendota-heights', lat: 44.8833, lng: -93.1569 },
  { name: 'Minneapolis, MN', path: '/trailer-rentals/minnesota/minneapolis', lat: 44.9778, lng: -93.2650, active: true, showNewVersion: true },
  { name: 'Minnetonka, MN', path: '/trailer-rentals/minnesota/minnetonka', lat: 44.9133, lng: -93.5033 },
  { name: 'Montevideo, MN', path: '/trailer-rentals/minnesota/montevideo', lat: 44.9422, lng: -95.7236 },
  { name: 'Monticello, MN', path: '/trailer-rentals/minnesota/monticello', lat: 45.3050, lng: -93.7944 },
  { name: 'Moorhead, MN', path: '/trailer-rentals/minnesota/moorhead', lat: 46.8739, lng: -96.7675 },
  { name: 'Morris, MN', path: '/trailer-rentals/minnesota/morris', lat: 45.5861, lng: -95.9136 },
  { name: 'New Brighton, MN', path: '/trailer-rentals/minnesota/new-brighton', lat: 45.0650, lng: -93.2019 },
  { name: 'New Hope, MN', path: '/trailer-rentals/minnesota/new-hope', lat: 45.0386, lng: -93.3869 },
  { name: 'New Prague, MN', path: '/trailer-rentals/minnesota/new-prague', lat: 44.5433, lng: -93.5769 },
  { name: 'New Ulm, MN', path: '/trailer-rentals/minnesota/new-ulm', lat: 44.3122, lng: -94.4608 },
  { name: 'North Branch, MN', path: '/trailer-rentals/minnesota/north-branch', lat: 45.5111, lng: -92.9786 },
  { name: 'North Mankato, MN', path: '/trailer-rentals/minnesota/north-mankato', lat: 44.1736, lng: -94.0358 },
  { name: 'North St. Paul, MN', path: '/trailer-rentals/minnesota/north-st-paul', lat: 45.0122, lng: -92.9919 },
  { name: 'Northfield, MN', path: '/trailer-rentals/minnesota/northfield', lat: 44.4583, lng: -93.1614 },
  { name: 'Oak Grove, MN', path: '/trailer-rentals/minnesota/oak-grove', lat: 45.3294, lng: -93.3472 },
  { name: 'Oakdale, MN', path: '/trailer-rentals/minnesota/oakdale', lat: 44.9633, lng: -92.9644 },
  { name: 'Orono, MN', path: '/trailer-rentals/minnesota/orono', lat: 44.9750, lng: -93.6044 },
  { name: 'Otsego, MN', path: '/trailer-rentals/minnesota/otsego', lat: 45.2744, lng: -93.5919 },
  { name: 'Owatonna, MN', path: '/trailer-rentals/minnesota/owatonna', lat: 44.0833, lng: -93.2269 },
  { name: 'Plymouth, MN', path: '/trailer-rentals/minnesota/plymouth', lat: 45.0106, lng: -93.4558 },
  { name: 'Princeton, MN', path: '/trailer-rentals/minnesota/princeton', lat: 45.5694, lng: -93.5819 },
  { name: 'Prior Lake, MN', path: '/trailer-rentals/minnesota/prior-lake', lat: 44.7133, lng: -93.4222 },
  { name: 'Ramsey, MN', path: '/trailer-rentals/minnesota/ramsey', lat: 45.2611, lng: -93.4508 },
  { name: 'Red Wing, MN', path: '/trailer-rentals/minnesota/red-wing', lat: 44.5667, lng: -92.5833 },
  { name: 'Redwood Falls, MN', path: '/trailer-rentals/minnesota/redwood-falls', lat: 44.5408, lng: -95.1169 },
  { name: 'Richfield, MN', path: '/trailer-rentals/minnesota/richfield', lat: 44.8833, lng: -93.2833 },
  { name: 'Robbinsdale, MN', path: '/trailer-rentals/minnesota/robbinsdale', lat: 45.0333, lng: -93.3333 },
  { name: 'Rochester, MN', path: '/trailer-rentals/minnesota/rochester', lat: 44.0216, lng: -92.4699 },
  { name: 'Rogers, MN', path: '/trailer-rentals/minnesota/rogers', lat: 45.1889, lng: -93.5533 },
  { name: 'Rosemount, MN', path: '/trailer-rentals/minnesota/rosemount', lat: 44.7394, lng: -93.1250 },
  { name: 'Roseville, MN', path: '/trailer-rentals/minnesota/roseville', lat: 45.0061, lng: -93.1566 },
  { name: 'Sartell, MN', path: '/trailer-rentals/minnesota/sartell', lat: 45.6211, lng: -94.2069 },
  { name: 'Sauk Centre, MN', path: '/trailer-rentals/minnesota/sauk-centre', lat: 45.7375, lng: -94.9525 },
  { name: 'Sauk Rapids, MN', path: '/trailer-rentals/minnesota/sauk-rapids', lat: 45.5917, lng: -94.1669 },
  { name: 'Savage, MN', path: '/trailer-rentals/minnesota/savage', lat: 44.7794, lng: -93.3369 },
  { name: 'Shakopee, MN', path: '/trailer-rentals/minnesota/shakopee', lat: 44.7983, lng: -93.5269 },
  { name: 'Shoreview, MN', path: '/trailer-rentals/minnesota/shoreview', lat: 45.0794, lng: -93.1472 },
  { name: 'Shorewood, MN', path: '/trailer-rentals/minnesota/shorewood', lat: 44.9033, lng: -93.5833 },
  { name: 'South St. Paul, MN', path: '/trailer-rentals/minnesota/south-st-paul', lat: 44.8872, lng: -93.0358 },
  { name: 'Spring Lake Park, MN', path: '/trailer-rentals/minnesota/spring-lake-park', lat: 45.1072, lng: -93.2383 },
  { name: 'St. Anthony, MN', path: '/trailer-rentals/minnesota/st-anthony', lat: 45.0208, lng: -93.2183 },
  { name: 'St. Cloud, MN', path: '/trailer-rentals/minnesota/st-cloud', lat: 45.5608, lng: -94.1622 },
  { name: 'St. Francis, MN', path: '/trailer-rentals/minnesota/st-francis', lat: 45.3908, lng: -93.3658 },
  { name: 'St. Joseph, MN', path: '/trailer-rentals/minnesota/st-joseph', lat: 45.5644, lng: -94.3183 },
  { name: 'St. Louis Park, MN', path: '/trailer-rentals/minnesota/st-louis-park', lat: 44.9483, lng: -93.3483 },
  { name: 'St. Michael, MN', path: '/trailer-rentals/minnesota/st-michael', lat: 45.2094, lng: -93.6658 },
  { name: 'St. Paul, MN', path: '/trailer-rentals/minnesota/st-paul', lat: 44.9537, lng: -93.0900 },
  { name: 'St. Peter, MN', path: '/trailer-rentals/minnesota/st-peter', lat: 44.3233, lng: -93.9583 },
  { name: 'Staples, MN', path: '/trailer-rentals/minnesota/staples', lat: 46.3558, lng: -94.7933 },
  { name: 'Stillwater, MN', path: '/trailer-rentals/minnesota/stillwater', lat: 45.0569, lng: -92.8083 },
  { name: 'Thief River Falls, MN', path: '/trailer-rentals/minnesota/thief-river-falls', lat: 48.1194, lng: -96.1819 },
  { name: 'Tracy, MN', path: '/trailer-rentals/minnesota/tracy', lat: 44.2311, lng: -95.6169 },
  { name: 'Vadnais Heights, MN', path: '/trailer-rentals/minnesota/vadnais-heights', lat: 45.0572, lng: -93.0733 },
  { name: 'Virginia, MN', path: '/trailer-rentals/minnesota/virginia', lat: 47.5233, lng: -92.5369 },
  { name: 'Waconia, MN', path: '/trailer-rentals/minnesota/waconia', lat: 44.8500, lng: -93.7869 },
  { name: 'Waite Park, MN', path: '/trailer-rentals/minnesota/waite-park', lat: 45.5572, lng: -94.2244 },
  { name: 'Waseca, MN', path: '/trailer-rentals/minnesota/waseca', lat: 44.0772, lng: -93.5083 },
  { name: 'Wayzata, MN', path: '/trailer-rentals/minnesota/wayzata', lat: 44.9744, lng: -93.5069 },
  { name: 'West St. Paul, MN', path: '/trailer-rentals/minnesota/west-st-paul', lat: 44.9167, lng: -93.0833 },
  { name: 'White Bear Lake, MN', path: '/trailer-rentals/minnesota/white-bear-lake', lat: 45.0844, lng: -93.0094 },
  { name: 'Willmar, MN', path: '/trailer-rentals/minnesota/willmar', lat: 45.1211, lng: -95.0433 },
  { name: 'Winona, MN', path: '/trailer-rentals/minnesota/winona', lat: 44.0556, lng: -91.6669 },
  { name: 'Woodbury, MN', path: '/trailer-rentals/minnesota/woodbury', lat: 44.9233, lng: -92.9594 },
  { name: 'Worthington, MN', path: '/trailer-rentals/minnesota/worthington', lat: 43.6208, lng: -95.5933 },
  { name: 'Zimmerman, MN', path: '/trailer-rentals/minnesota/zimmerman', lat: 45.4444, lng: -93.5872 },
];

export const mississippiCities:ICity[] = [
  { name: 'Aberdeen, MS', path: '/trailer-rentals/mississippi/aberdeen', lat: 33.8257, lng: -88.5434 },
  { name: 'Amory, MS', path: '/trailer-rentals/mississippi/amory', lat: 33.984, lng: -88.4884 },
  { name: 'Ashland, MS', path: '/trailer-rentals/mississippi/ashland', lat: 34.8237, lng: -89.1748 },
  { name: 'Baldwyn, MS', path: '/trailer-rentals/mississippi/baldwyn', lat: 34.509, lng: -88.6356 },
  { name: 'Batesville, MS', path: '/trailer-rentals/mississippi/batesville', lat: 34.3118, lng: -89.944 },
  { name: 'Bay St. Louis, MS', path: '/trailer-rentals/mississippi/bay-st-louis', lat: 30.3088, lng: -89.3306 },
  { name: 'Bay Springs, MS', path: '/trailer-rentals/mississippi/bay-springs', lat: 31.9752, lng: -89.2851 },
  { name: 'Belzoni, MS', path: '/trailer-rentals/mississippi/belzoni', lat: 33.184, lng: -90.4898 },
  { name: 'Biloxi, MS', path: '/trailer-rentals/mississippi/biloxi', lat: 30.396, lng: -88.8853 },
  { name: 'Booneville, MS', path: '/trailer-rentals/mississippi/booneville', lat: 34.6604, lng: -88.5667 },
  { name: 'Brandon, MS', path: '/trailer-rentals/mississippi/brandon', lat: 32.2732, lng: -89.9859 },
  { name: 'Brookhaven, MS', path: '/trailer-rentals/mississippi/brookhaven', lat: 31.5791, lng: -90.4401 },
  { name: 'Bruce, MS', path: '/trailer-rentals/mississippi/bruce', lat: 33.9926, lng: -89.3484 },
  { name: 'Bude, MS', path: '/trailer-rentals/mississippi/bude', lat: 31.4627, lng: -90.8454 },
  { name: 'Byhalia, MS', path: '/trailer-rentals/mississippi/byhalia', lat: 34.8693, lng: -89.6898 },
  { name: 'Canton, MS', path: '/trailer-rentals/mississippi/canton', lat: 32.6121, lng: -90.0367 },
  { name: 'Carrollton, MS', path: '/trailer-rentals/mississippi/carrollton', lat: 33.5087, lng: -89.9184 },
  { name: 'Carthage, MS', path: '/trailer-rentals/mississippi/carthage', lat: 32.7321, lng: -89.5367 },
  { name: 'Centreville, MS', path: '/trailer-rentals/mississippi/centreville', lat: 31.0899, lng: -91.0662 },
  { name: 'Charleston, MS', path: '/trailer-rentals/mississippi/charleston', lat: 34.0073, lng: -90.0568 },
  { name: 'Clarksdale, MS', path: '/trailer-rentals/mississippi/clarksdale', lat: 34.2001, lng: -90.5709 },
  { name: 'Cleveland, MS', path: '/trailer-rentals/mississippi/cleveland', lat: 33.7448, lng: -90.7248 },
  { name: 'Clinton, MS', path: '/trailer-rentals/mississippi/clinton', lat: 32.3415, lng: -90.3217 },
  { name: 'Columbia, MS', path: '/trailer-rentals/mississippi/columbia', lat: 31.2516, lng: -89.8373 },
  { name: 'Columbus, MS', path: '/trailer-rentals/mississippi/columbus', lat: 33.4957, lng: -88.4273 },
  { name: 'Corinth, MS', path: '/trailer-rentals/mississippi/corinth', lat: 34.9343, lng: -88.522 },
  { name: 'Crystal Springs, MS', path: '/trailer-rentals/mississippi/crystal-springs', lat: 31.9874, lng: -90.3576 },
  { name: 'De Kalb, MS', path: '/trailer-rentals/mississippi/de-kalb', lat: 32.769, lng: -88.6503 },
  { name: 'Decatur, MS', path: '/trailer-rentals/mississippi/decatur', lat: 32.4393, lng: -89.1084 },
  { name: 'Durant, MS', path: '/trailer-rentals/mississippi/durant', lat: 33.0785, lng: -89.8573 },
  { name: 'Ellisville, MS', path: '/trailer-rentals/mississippi/ellisville', lat: 31.6043, lng: -89.2017 },
  { name: 'Eupora, MS', path: '/trailer-rentals/mississippi/eupora', lat: 33.5437, lng: -89.2684 },
  { name: 'Florence, MS', path: '/trailer-rentals/mississippi/florence', lat: 32.1532, lng: -90.1312 },
  { name: 'Flowood, MS', path: '/trailer-rentals/mississippi/flowood', lat: 32.309, lng: -90.1386 },
  { name: 'Forest, MS', path: '/trailer-rentals/mississippi/forest', lat: 32.364, lng: -89.4745 },
  { name: 'Fulton, MS', path: '/trailer-rentals/mississippi/fulton', lat: 34.2665, lng: -88.4017 },
  { name: 'Gautier, MS', path: '/trailer-rentals/mississippi/gautier', lat: 30.3858, lng: -88.6111 },
  { name: 'Gloster, MS', path: '/trailer-rentals/mississippi/gloster', lat: 31.196, lng: -91.0162 },
  { name: 'Greenville, MS', path: '/trailer-rentals/mississippi/greenville', lat: 33.4101, lng: -91.0618 },
  { name: 'Greenwood, MS', path: '/trailer-rentals/mississippi/greenwood', lat: 33.5162, lng: -90.179 },
  { name: 'Grenada, MS', path: '/trailer-rentals/mississippi/grenada', lat: 33.769, lng: -89.8085 },
  { name: 'Gulfport, MS', path: '/trailer-rentals/mississippi/gulfport', lat: 30.3674, lng: -89.0928 },
  { name: 'Hattiesburg, MS', path: '/trailer-rentals/mississippi/hattiesburg', lat: 31.3271, lng: -89.2903 },
  { name: 'Hazlehurst, MS', path: '/trailer-rentals/mississippi/hazlehurst', lat: 31.861, lng: -90.3962 },
  { name: 'Holly Springs, MS', path: '/trailer-rentals/mississippi/holly-springs', lat: 34.767, lng: -89.4484 },
  { name: 'Horn Lake, MS', path: '/trailer-rentals/mississippi/horn-lake', lat: 34.9553, lng: -90.034 },
  { name: 'Houston, MS', path: '/trailer-rentals/mississippi/houston', lat: 33.8987, lng: -88.9992 },
  { name: 'Indianola, MS', path: '/trailer-rentals/mississippi/indianola', lat: 33.4501, lng: -90.6554 },
  { name: 'Inverness, MS', path: '/trailer-rentals/mississippi/inverness', lat: 33.3537, lng: -90.5901 },
  { name: 'Iuka, MS', path: '/trailer-rentals/mississippi/iuka', lat: 34.8115, lng: -88.1934 },
  { name: 'Jackson, MS', path: '/trailer-rentals/mississippi/jackson', lat: 32.2988, lng: -90.1848 },
  { name: 'Jonestown, MS', path: '/trailer-rentals/mississippi/jonestown', lat: 34.3201, lng: -90.4534 },
  { name: 'Kosciusko, MS', path: '/trailer-rentals/mississippi/kosciusko', lat: 33.0576, lng: -89.5873 },
  { name: 'Laurel, MS', path: '/trailer-rentals/mississippi/laurel', lat: 31.6948, lng: -89.1306 },
  { name: 'Leakesville, MS', path: '/trailer-rentals/mississippi/leakesville', lat: 31.1535, lng: -88.5553 },
  { name: 'Leland, MS', path: '/trailer-rentals/mississippi/leland', lat: 33.404, lng: -90.8973 },
  { name: 'Lexington, MS', path: '/trailer-rentals/mississippi/lexington', lat: 33.114, lng: -90.0528 },
  { name: 'Long Beach, MS', path: '/trailer-rentals/mississippi/long-beach', lat: 30.3502, lng: -89.1528 },
  { name: 'Louisville, MS', path: '/trailer-rentals/mississippi/louisville', lat: 33.1232, lng: -89.0553 },
  { name: 'Lucedale, MS', path: '/trailer-rentals/mississippi/lucedale', lat: 30.9257, lng: -88.5917 },
  { name: 'Lumberton, MS', path: '/trailer-rentals/mississippi/lumberton', lat: 31.006, lng: -89.4528 },
  { name: 'Madison, MS', path: '/trailer-rentals/mississippi/madison', lat: 32.461, lng: -90.1154 },
  { name: 'Magee, MS', path: '/trailer-rentals/mississippi/magee', lat: 31.8732, lng: -89.7334 },
  { name: 'Magnolia, MS', path: '/trailer-rentals/mississippi/magnolia', lat: 31.1427, lng: -90.4573 },
  { name: 'Mantachie, MS', path: '/trailer-rentals/mississippi/mantachie', lat: 34.3273, lng: -88.4917 },
  { name: 'Marion, MS', path: '/trailer-rentals/mississippi/marion', lat: 32.409, lng: -88.6528 },
  { name: 'Marks, MS', path: '/trailer-rentals/mississippi/marks', lat: 34.251, lng: -90.2728 },
  { name: 'McComb, MS', path: '/trailer-rentals/mississippi/mccomb', lat: 31.2435, lng: -90.4534 },
  { name: 'Mendenhall, MS', path: '/trailer-rentals/mississippi/mendenhall', lat: 31.961, lng: -89.8703 },
  { name: 'Meridian, MS', path: '/trailer-rentals/mississippi/meridian', lat: 32.3643, lng: -88.7037 },
  { name: 'Monticello, MS', path: '/trailer-rentals/mississippi/monticello', lat: 31.5532, lng: -90.1117 },
  { name: 'Morton, MS', path: '/trailer-rentals/mississippi/morton', lat: 32.3532, lng: -89.6545 },
  { name: 'Moss Point, MS', path: '/trailer-rentals/mississippi/moss-point', lat: 30.411, lng: -88.5345 },
  { name: 'Natchez, MS', path: '/trailer-rentals/mississippi/natchez', lat: 31.5604, lng: -91.4032 },
  { name: 'New Albany, MS', path: '/trailer-rentals/mississippi/new-albany', lat: 34.494, lng: -89.0245 },
  { name: 'Newton, MS', path: '/trailer-rentals/mississippi/newton', lat: 32.321, lng: -89.1654 },
  { name: 'Ocean Springs, MS', path: '/trailer-rentals/mississippi/ocean-springs', lat: 30.411, lng: -88.8273 },
  { name: 'Okolona, MS', path: '/trailer-rentals/mississippi/okolona', lat: 34.0028, lng: -88.7528 },
  { name: 'Olive Branch, MS', path: '/trailer-rentals/mississippi/olive-branch', lat: 34.9618, lng: -89.8295 },
  { name: 'Oxford, MS', path: '/trailer-rentals/mississippi/oxford', lat: 34.3665, lng: -89.5192 },
  { name: 'Pascagoula, MS', path: '/trailer-rentals/mississippi/pascagoula', lat: 30.3654, lng: -88.5561 },
  { name: 'Pass Christian, MS', path: '/trailer-rentals/mississippi/pass-christian', lat: 30.3152, lng: -89.2473 },
  { name: 'Pearl, MS', path: '/trailer-rentals/mississippi/pearl', lat: 32.274, lng: -90.1328 },
  { name: 'Petal, MS', path: '/trailer-rentals/mississippi/petal', lat: 31.346, lng: -89.2603 },
  { name: 'Philadelphia, MS', path: '/trailer-rentals/mississippi/philadelphia', lat: 32.771, lng: -89.1162 },
  { name: 'Picayune, MS', path: '/trailer-rentals/mississippi/picayune', lat: 30.5254, lng: -89.6795 },
  { name: 'Pontotoc, MS', path: '/trailer-rentals/mississippi/pontotoc', lat: 34.2473, lng: -88.9984 },
  { name: 'Port Gibson, MS', path: '/trailer-rentals/mississippi/port-gibson', lat: 31.961, lng: -90.9834 },
  { name: 'Prentiss, MS', path: '/trailer-rentals/mississippi/prentiss', lat: 31.5987, lng: -89.8654 },
  { name: 'Purvis, MS', path: '/trailer-rentals/mississippi/purvis', lat: 31.1427, lng: -89.4073 },
  { name: 'Quitman, MS', path: '/trailer-rentals/mississippi/quitman', lat: 32.0387, lng: -88.7228 },
  { name: 'Raymond, MS', path: '/trailer-rentals/mississippi/raymond', lat: 32.259, lng: -90.4228 },
  { name: 'Richland, MS', path: '/trailer-rentals/mississippi/richland', lat: 32.2332, lng: -90.1584 },
  { name: 'Ridgeland, MS', path: '/trailer-rentals/mississippi/ridgeland', lat: 32.4285, lng: -90.1328 },
  { name: 'Ripley, MS', path: '/trailer-rentals/mississippi/ripley', lat: 34.7265, lng: -88.9445 },
  { name: 'Rolling Fork, MS', path: '/trailer-rentals/mississippi/rolling-fork', lat: 32.906, lng: -90.8784 },
  { name: 'Rosedale, MS', path: '/trailer-rentals/mississippi/rosedale', lat: 33.8532, lng: -91.0284 },
  { name: 'Ruleville, MS', path: '/trailer-rentals/mississippi/ruleville', lat: 33.7265, lng: -90.5462 },
  { name: 'Senatobia, MS', path: '/trailer-rentals/mississippi/senatobia', lat: 34.6173, lng: -89.9684 },
  { name: 'Shaw, MS', path: '/trailer-rentals/mississippi/shaw', lat: 33.601, lng: -90.7684 },
  { name: 'Shelby, MS', path: '/trailer-rentals/mississippi/shelby', lat: 33.9501, lng: -90.7662 },
  { name: 'Southaven, MS', path: '/trailer-rentals/mississippi/southaven', lat: 34.9889, lng: -90.0126 },
  { name: 'Starkville, MS', path: '/trailer-rentals/mississippi/starkville', lat: 33.4504, lng: -88.8184 },
  { name: 'Stonewall, MS', path: '/trailer-rentals/mississippi/stonewall', lat: 32.1399, lng: -88.7942 },
  { name: 'Summit, MS', path: '/trailer-rentals/mississippi/summit', lat: 31.2835, lng: -90.4676 },
  { name: 'Tunica, MS', path: '/trailer-rentals/mississippi/tunica', lat: 34.6848, lng: -90.3826 },
  { name: 'Tupelo, MS', path: '/trailer-rentals/mississippi/tupelo', lat: 34.2576, lng: -88.7034 },
  { name: 'Tylertown, MS', path: '/trailer-rentals/mississippi/tylertown', lat: 31.1174, lng: -90.1426 },
  { name: 'Vicksburg, MS', path: '/trailer-rentals/mississippi/vicksburg', lat: 32.3526, lng: -90.8779 },
  { name: 'Walnut, MS', path: '/trailer-rentals/mississippi/walnut', lat: 34.9493, lng: -88.9012 },
  { name: 'Water Valley, MS', path: '/trailer-rentals/mississippi/water-valley', lat: 34.1482, lng: -89.6312 },
  { name: 'Waveland, MS', path: '/trailer-rentals/mississippi/waveland', lat: 30.2866, lng: -89.3767 },
  { name: 'Waynesboro, MS', path: '/trailer-rentals/mississippi/waynesboro', lat: 31.6749, lng: -88.6464 },
  { name: 'West Point, MS', path: '/trailer-rentals/mississippi/west-point', lat: 33.6071, lng: -88.6506 },
  { name: 'Winona, MS', path: '/trailer-rentals/mississippi/winona', lat: 33.4829, lng: -89.7284 },
  { name: 'Woodville, MS', path: '/trailer-rentals/mississippi/woodville', lat: 31.1041, lng: -91.2976 },
  { name: 'Yazoo City, MS', path: '/trailer-rentals/mississippi/yazoo-city', lat: 32.8554, lng: -90.4054 },
];

export const missouriCities: ICity[] = [
  { name: 'Arnold, MO', path: '/trailer-rentals/missouri/arnold', lat: 38.4328, lng: -90.3776 },
  { name: 'Aurora, MO', path: '/trailer-rentals/missouri/aurora', lat: 36.9709, lng: -93.7177 },
  { name: 'Ballwin, MO', path: '/trailer-rentals/missouri/ballwin', lat: 38.5951, lng: -90.5462 },
  { name: 'Belton, MO', path: '/trailer-rentals/missouri/belton', lat: 38.8119, lng: -94.5316 },
  { name: 'Blue Springs, MO', path: '/trailer-rentals/missouri/blue-springs', lat: 39.0169, lng: -94.2816 },
  { name: 'Bolivar, MO', path: '/trailer-rentals/missouri/bolivar', lat: 37.6142, lng: -93.4105 },
  { name: 'Branson, MO', path: '/trailer-rentals/missouri/branson', lat: 36.6437, lng: -93.2185 },
  { name: 'Bridgeton, MO', path: '/trailer-rentals/missouri/bridgeton', lat: 38.7667, lng: -90.4118 },
  { name: 'Cameron, MO', path: '/trailer-rentals/missouri/cameron', lat: 39.7408, lng: -94.2411 },
  { name: 'Cape Girardeau, MO', path: '/trailer-rentals/missouri/cape-girardeau', lat: 37.3059, lng: -89.5181 },
  { name: 'Carl Junction, MO', path: '/trailer-rentals/missouri/carl-junction', lat: 37.1762, lng: -94.5563 },
  { name: 'Chesterfield, MO', path: '/trailer-rentals/missouri/chesterfield', lat: 38.6631, lng: -90.5771 },
  { name: 'Chillicothe, MO', path: '/trailer-rentals/missouri/chillicothe', lat: 39.7953, lng: -93.5524 },
  { name: 'Clayton, MO', path: '/trailer-rentals/missouri/clayton', lat: 38.6426, lng: -90.3237 },
  { name: 'Columbia, MO', path: '/trailer-rentals/missouri/columbia', lat: 38.9517, lng: -92.3341 },
  { name: 'Crestwood, MO', path: '/trailer-rentals/missouri/crestwood', lat: 38.5564, lng: -90.3815 },
  { name: 'Des Peres, MO', path: '/trailer-rentals/missouri/des-peres', lat: 38.6001, lng: -90.4373 },
  { name: 'Dexter, MO', path: '/trailer-rentals/missouri/dexter', lat: 36.7959, lng: -89.9579 },
  { name: 'Ellisville, MO', path: '/trailer-rentals/missouri/ellisville', lat: 38.5931, lng: -90.5879 },
  { name: 'Excelsior Springs, MO', path: '/trailer-rentals/missouri/excelsior-springs', lat: 39.3395, lng: -94.2266 },
  { name: 'Farmington, MO', path: '/trailer-rentals/missouri/farmington', lat: 37.7803, lng: -90.421 },
  { name: 'Ferguson, MO', path: '/trailer-rentals/missouri/ferguson', lat: 38.7442, lng: -90.3054 },
  { name: 'Florissant, MO', path: '/trailer-rentals/missouri/florissant', lat: 38.7892, lng: -90.3226 },
  { name: 'Fulton, MO', path: '/trailer-rentals/missouri/fulton', lat: 38.8467, lng: -91.9474 },
  { name: 'Gladstone, MO', path: '/trailer-rentals/missouri/gladstone', lat: 39.2031, lng: -94.5484 },
  { name: 'Grain Valley, MO', path: '/trailer-rentals/missouri/grain-valley', lat: 39.015, lng: -94.198 },
  { name: 'Grandview, MO', path: '/trailer-rentals/missouri/grandview', lat: 38.885, lng: -94.533 },
  { name: 'Hannibal, MO', path: '/trailer-rentals/missouri/hannibal', lat: 39.7084, lng: -91.3585 },
  { name: 'Harrisonville, MO', path: '/trailer-rentals/missouri/harrisonville', lat: 38.6539, lng: -94.3483 },
  { name: 'Hazelwood, MO', path: '/trailer-rentals/missouri/hazelwood', lat: 38.7714, lng: -90.3701 },
  { name: 'Independence, MO', path: '/trailer-rentals/missouri/independence', lat: 39.0911, lng: -94.4155 },
  { name: 'Jackson, MO', path: '/trailer-rentals/missouri/jackson', lat: 37.3828, lng: -89.6662 },
  { name: 'Jefferson City, MO', path: '/trailer-rentals/missouri/jefferson-city', lat: 38.5767, lng: -92.1735 },
  { name: 'Joplin, MO', path: '/trailer-rentals/missouri/joplin', lat: 37.0842, lng: -94.5133 },
  { name: 'Kansas City, MO', path: '/trailer-rentals/missouri/kansas-city', lat: 39.0997, lng: -94.5786 },
  { name: 'Kirkwood, MO', path: '/trailer-rentals/missouri/kirkwood', lat: 38.5834, lng: -90.4068 },
  { name: 'Ladue, MO', path: '/trailer-rentals/missouri/ladue', lat: 38.6451, lng: -90.3762 },
  { name: 'Lebanon, MO', path: '/trailer-rentals/missouri/lebanon', lat: 37.6803, lng: -92.6638 },
  { name: 'Lee\'s Summit, MO', path: '/trailer-rentals/missouri/lees-summit', lat: 38.9108, lng: -94.3822 },
  { name: 'Liberty, MO', path: '/trailer-rentals/missouri/liberty', lat: 39.2461, lng: -94.4191 },
  { name: 'Manchester, MO', path: '/trailer-rentals/missouri/manchester', lat: 38.5973, lng: -90.5098 },
  { name: 'Maryland Heights, MO', path: '/trailer-rentals/missouri/maryland-heights', lat: 38.7134, lng: -90.4293 },
  { name: 'Maryville, MO', path: '/trailer-rentals/missouri/maryville', lat: 40.3461, lng: -94.8725 },
  { name: 'Mexico, MO', path: '/trailer-rentals/missouri/mexico', lat: 39.1695, lng: -91.8843 },
  { name: 'Moberly, MO', path: '/trailer-rentals/missouri/moberly', lat: 39.4184, lng: -92.4388 },
  { name: 'Monett, MO', path: '/trailer-rentals/missouri/monett', lat: 36.9281, lng: -93.9277 },
  { name: 'Nevada, MO', path: '/trailer-rentals/missouri/nevada', lat: 37.8392, lng: -94.3541 },
  { name: 'Nixa, MO', path: '/trailer-rentals/missouri/nixa', lat: 37.0431, lng: -93.2944 },
  { name: `O'Fallon, MO`, path: '/trailer-rentals/missouri/ofallon', lat: 38.8106, lng: -90.6998 },
  { name: 'Ozark, MO', path: '/trailer-rentals/missouri/ozark', lat: 37.0209, lng: -93.206 },
  { name: 'Park Hills, MO', path: '/trailer-rentals/missouri/park-hills', lat: 37.8453, lng: -90.5157 },
  { name: 'Poplar Bluff, MO', path: '/trailer-rentals/missouri/poplar-bluff', lat: 36.7598, lng: -90.3929 },
  { name: 'Raymore, MO', path: '/trailer-rentals/missouri/raymore', lat: 38.8019, lng: -94.4527 },
  { name: 'Raytown, MO', path: '/trailer-rentals/missouri/raytown', lat: 39.0086, lng: -94.463 },
  { name: 'Republic, MO', path: '/trailer-rentals/missouri/republic', lat: 37.1203, lng: -93.4802 },
  { name: 'Richmond, MO', path: '/trailer-rentals/missouri/richmond', lat: 39.2781, lng: -93.9766 },
  { name: 'Rolla, MO', path: '/trailer-rentals/missouri/rolla', lat: 37.9514, lng: -91.7715 },
  { name: 'Sedalia, MO', path: '/trailer-rentals/missouri/sedalia', lat: 38.7045, lng: -93.2283 },
  { name: 'Sikeston, MO', path: '/trailer-rentals/missouri/sikeston', lat: 36.8767, lng: -89.5879 },
  { name: 'Spanish Lake, MO', path: '/trailer-rentals/missouri/spanish-lake', lat: 38.787, lng: -90.2126 },
  { name: 'Springfield, MO', path: '/trailer-rentals/missouri/springfield', lat: 37.2089, lng: -93.2923 },
  { name: 'St. Ann, MO', path: '/trailer-rentals/missouri/st-ann', lat: 38.7278, lng: -90.3832 },
  { name: 'St. Charles, MO', path: '/trailer-rentals/missouri/st-charles', lat: 38.7881, lng: -90.4974 },
  { name: 'St. Joseph, MO', path: '/trailer-rentals/missouri/st-joseph', lat: 39.7675, lng: -94.8467 },
  { name: 'St. Louis, MO', path: '/trailer-rentals/missouri/st-louis', lat: 38.627, lng: -90.1994 },
  { name: 'St. Peters, MO', path: '/trailer-rentals/missouri/st-peters', lat: 38.8003, lng: -90.6265 },
  { name: 'Sunset Hills, MO', path: '/trailer-rentals/missouri/sunset-hills', lat: 38.5381, lng: -90.4079 },
  { name: 'Town and Country, MO', path: '/trailer-rentals/missouri/town-and-country', lat: 38.6123, lng: -90.4635 },
  { name: 'Troy, MO', path: '/trailer-rentals/missouri/troy', lat: 38.9792, lng: -90.9801 },
  { name: 'Union, MO', path: '/trailer-rentals/missouri/union', lat: 38.4503, lng: -91.0085 },
  { name: 'University City, MO', path: '/trailer-rentals/missouri/university-city', lat: 38.6684, lng: -90.3326 },
  { name: 'Warrensburg, MO', path: '/trailer-rentals/missouri/warrensburg', lat: 38.7628, lng: -93.736 },
  { name: 'Warrenton, MO', path: '/trailer-rentals/missouri/warrenton', lat: 38.8112, lng: -91.1396 },
  { name: 'Washington, MO', path: '/trailer-rentals/missouri/washington', lat: 38.5584, lng: -91.0129 },
  { name: 'Webb City, MO', path: '/trailer-rentals/missouri/webb-city', lat: 37.1462, lng: -94.4633 },
  { name: 'Webster Groves, MO', path: '/trailer-rentals/missouri/webster-groves', lat: 38.5926, lng: -90.3576 },
  { name: 'Wentzville, MO', path: '/trailer-rentals/missouri/wentzville', lat: 38.8114, lng: -90.8529 },
  { name: 'West Plains, MO', path: '/trailer-rentals/missouri/west-plains', lat: 36.7281, lng: -91.8524 },
  { name: 'Wildwood, MO', path: '/trailer-rentals/missouri/wildwood', lat: 38.5828, lng: -90.6626 },
];

export const montanaCities: ICity[] = [
  { name: 'Anaconda, MT', path: '/trailer-rentals/montana/anaconda', lat: 46.1285, lng: -112.9428 },
  { name: 'Belgrade, MT', path: '/trailer-rentals/montana/belgrade', lat: 45.7766, lng: -111.1773 },
  { name: 'Big Timber, MT', path: '/trailer-rentals/montana/big-timber', lat: 45.8341, lng: -109.9538 },
  { name: 'Billings, MT', path: '/trailer-rentals/montana/billings', lat: 45.7833, lng: -108.5007 },
  { name: 'Bozeman, MT', path: '/trailer-rentals/montana/bozeman', lat: 45.6760, lng: -111.0429 },
  { name: 'Butte, MT', path: '/trailer-rentals/montana/butte', lat: 46.0038, lng: -112.5347 },
  { name: 'Choteau, MT', path: '/trailer-rentals/montana/choteau', lat: 47.8127, lng: -112.1831 },
  { name: 'Colstrip, MT', path: '/trailer-rentals/montana/colstrip', lat: 45.8883, lng: -106.6328 },
  { name: 'Columbia Falls, MT', path: '/trailer-rentals/montana/columbia-falls', lat: 48.3708, lng: -114.1808 },
  { name: 'Conrad, MT', path: '/trailer-rentals/montana/conrad', lat: 48.1750, lng: -111.9478 },
  { name: 'Cut Bank, MT', path: '/trailer-rentals/montana/cut-bank', lat: 48.6333, lng: -112.3300 },
  { name: 'Deer Lodge, MT', path: '/trailer-rentals/montana/deer-lodge', lat: 46.3983, lng: -112.7342 },
  { name: 'Dillon, MT', path: '/trailer-rentals/montana/dillon', lat: 45.2163, lng: -112.6375 },
  { name: 'East Helena, MT', path: '/trailer-rentals/montana/east-helena', lat: 46.5877, lng: -111.9153 },
  { name: 'Ekalaka, MT', path: '/trailer-rentals/montana/ekalaka', lat: 45.8883, lng: -104.5508 },
  { name: 'Ennis, MT', path: '/trailer-rentals/montana/ennis', lat: 45.3488, lng: -111.7319 },
  { name: 'Eureka, MT', path: '/trailer-rentals/montana/eureka', lat: 48.8808, lng: -115.0483 },
  { name: 'Forsyth, MT', path: '/trailer-rentals/montana/forsyth', lat: 46.2666, lng: -106.6778 },
  { name: 'Fort Benton, MT', path: '/trailer-rentals/montana/fort-benton', lat: 47.8191, lng: -110.6677 },
  { name: 'Glasgow, MT', path: '/trailer-rentals/montana/glasgow', lat: 48.1972, lng: -106.6361 },
  { name: 'Glendive, MT', path: '/trailer-rentals/montana/glendive', lat: 47.1053, lng: -104.7122 },
  { name: 'Great Falls, MT', path: '/trailer-rentals/montana/great-falls', lat: 47.5052, lng: -111.3008 },
  { name: 'Hamilton, MT', path: '/trailer-rentals/montana/hamilton', lat: 46.2471, lng: -114.1595 },
  { name: 'Hardin, MT', path: '/trailer-rentals/montana/hardin', lat: 45.7311, lng: -107.6123 },
  { name: 'Harlowton, MT', path: '/trailer-rentals/montana/harlowton', lat: 46.4333, lng: -109.8357 },
  { name: 'Havre, MT', path: '/trailer-rentals/montana/havre', lat: 48.5492, lng: -109.6841 },
  { name: 'Helena, MT', path: '/trailer-rentals/montana/helena', lat: 46.5884, lng: -112.0245 },
  { name: 'Kalispell, MT', path: '/trailer-rentals/montana/kalispell', lat: 48.1917, lng: -114.3161 },
  { name: 'Laurel, MT', path: '/trailer-rentals/montana/laurel', lat: 45.6694, lng: -108.7729 },
  { name: 'Lewistown, MT', path: '/trailer-rentals/montana/lewistown', lat: 47.0625, lng: -109.4286 },
  { name: 'Libby, MT', path: '/trailer-rentals/montana/libby', lat: 48.3883, lng: -115.5550 },
  { name: 'Livingston, MT', path: '/trailer-rentals/montana/livingston', lat: 45.6614, lng: -110.5610 },
  { name: 'Malta, MT', path: '/trailer-rentals/montana/malta', lat: 48.3597, lng: -107.8745 },
  { name: 'Miles City, MT', path: '/trailer-rentals/montana/miles-city', lat: 46.4083, lng: -105.8406 },
  { name: 'Missoula, MT', path: '/trailer-rentals/montana/missoula', lat: 46.8721, lng: -113.9940 },
  { name: 'Plentywood, MT', path: '/trailer-rentals/montana/plentywood', lat: 48.7733, lng: -104.5583 },
  { name: 'Polson, MT', path: '/trailer-rentals/montana/polson', lat: 47.6883, lng: -114.1633 },
  { name: 'Red Lodge, MT', path: '/trailer-rentals/montana/red-lodge', lat: 45.1850, lng: -109.2460 },
  { name: 'Roundup, MT', path: '/trailer-rentals/montana/roundup', lat: 46.4450, lng: -108.5418 },
  { name: 'Scobey, MT', path: '/trailer-rentals/montana/scobey', lat: 48.7925, lng: -105.4194 },
  { name: 'Shelby, MT', path: '/trailer-rentals/montana/shelby', lat: 48.5050, lng: -111.8561 },
  { name: 'Sidney, MT', path: '/trailer-rentals/montana/sidney', lat: 47.7167, lng: -104.1561 },
  { name: 'Stevensville, MT', path: '/trailer-rentals/montana/stevensville', lat: 46.5091, lng: -114.0937 },
  { name: 'Thompson Falls, MT', path: '/trailer-rentals/montana/thompson-falls', lat: 47.5958, lng: -115.3483 },
  { name: 'Three Forks, MT', path: '/trailer-rentals/montana/three-forks', lat: 45.8927, lng: -111.5522 },
  { name: 'Townsend, MT', path: '/trailer-rentals/montana/townsend', lat: 46.3191, lng: -111.5161 },
  { name: 'Troy, MT', path: '/trailer-rentals/montana/troy', lat: 48.4625, lng: -115.8894 },
  { name: 'Valier, MT', path: '/trailer-rentals/montana/valier', lat: 48.3061, lng: -112.2528 },
  { name: 'Virginia City, MT', path: '/trailer-rentals/montana/virginia-city', lat: 45.2941, lng: -111.9411 },
  { name: 'West Yellowstone, MT', path: '/trailer-rentals/montana/west-yellowstone', lat: 44.6628, lng: -111.1042 },
  { name: 'Whitefish, MT', path: '/trailer-rentals/montana/whitefish', lat: 48.4108, lng: -114.3350 },
  { name: 'Whitehall, MT', path: '/trailer-rentals/montana/whitehall', lat: 45.8708, lng: -112.0972 },
  { name: 'Wolf Point, MT', path: '/trailer-rentals/montana/wolf-point', lat: 48.0908, lng: -105.6428 },
];

export const nebraskaCities: ICity[] = [
  { name: 'Albion, NE', path: '/trailer-rentals/nebraska/albion', lat: 41.6903, lng: -98.0037 },
  { name: 'Alliance, NE', path: '/trailer-rentals/nebraska/alliance', lat: 42.1011, lng: -102.8727 },
  { name: 'Alma, NE', path: '/trailer-rentals/nebraska/alma', lat: 40.1028, lng: -99.3623 },
  { name: 'Ashland, NE', path: '/trailer-rentals/nebraska/ashland', lat: 41.0392, lng: -96.3686 },
  { name: 'Auburn, NE', path: '/trailer-rentals/nebraska/auburn', lat: 40.3928, lng: -95.8408 },
  { name: 'Aurora, NE', path: '/trailer-rentals/nebraska/aurora', lat: 40.8667, lng: -98.0045 },
  { name: 'Bassett, NE', path: '/trailer-rentals/nebraska/bassett', lat: 42.5833, lng: -99.5337 },
  { name: 'Beatrice, NE', path: '/trailer-rentals/nebraska/beatrice', lat: 40.265, lng: -96.7467 },
  { name: 'Bellevue, NE', path: '/trailer-rentals/nebraska/bellevue', lat: 41.1367, lng: -95.8908 },
  { name: 'Bennington, NE', path: '/trailer-rentals/nebraska/bennington', lat: 41.3703, lng: -96.1561 },
  { name: 'Blair, NE', path: '/trailer-rentals/nebraska/blair', lat: 41.5442, lng: -96.1356 },
  { name: 'Bloomfield, NE', path: '/trailer-rentals/nebraska/bloomfield', lat: 42.5978, lng: -97.6403 },
  { name: 'Broken Bow, NE', path: '/trailer-rentals/nebraska/broken-bow', lat: 41.4042, lng: -99.6395 },
  { name: 'Burwell, NE', path: '/trailer-rentals/nebraska/burwell', lat: 41.7803, lng: -99.1328 },
  { name: 'Cambridge, NE', path: '/trailer-rentals/nebraska/cambridge', lat: 40.2833, lng: -100.1656 },
  { name: 'Central City, NE', path: '/trailer-rentals/nebraska/central-city', lat: 41.115, lng: -98.0028 },
  { name: 'Chadron, NE', path: '/trailer-rentals/nebraska/chadron', lat: 42.8292, lng: -102.9992 },
  { name: 'Chappell, NE', path: '/trailer-rentals/nebraska/chappell', lat: 41.0942, lng: -102.4703 },
  { name: 'Columbus, NE', path: '/trailer-rentals/nebraska/columbus', lat: 41.4297, lng: -97.3689 },
  { name: 'Cozad, NE', path: '/trailer-rentals/nebraska/cozad', lat: 40.8592, lng: -99.9867 },
  { name: 'Crawford, NE', path: '/trailer-rentals/nebraska/crawford', lat: 42.6833, lng: -103.4128 },
  { name: 'Creighton, NE', path: '/trailer-rentals/nebraska/creighton', lat: 42.4667, lng: -97.9167 },
  { name: 'Crete, NE', path: '/trailer-rentals/nebraska/crete', lat: 40.625, lng: -96.9611 },
  { name: 'Dakota City, NE', path: '/trailer-rentals/nebraska/dakota-city', lat: 42.415, lng: -96.4183 },
  { name: 'David City, NE', path: '/trailer-rentals/nebraska/david-city', lat: 41.2528, lng: -97.1303 },
  { name: 'Fairbury, NE', path: '/trailer-rentals/nebraska/fairbury', lat: 40.1403, lng: -97.1792 },
  { name: 'Falls City, NE', path: '/trailer-rentals/nebraska/falls-city', lat: 40.0603, lng: -95.6011 },
  { name: 'Fremont, NE', path: '/trailer-rentals/nebraska/fremont', lat: 41.4333, lng: -96.4989 },
  { name: 'Friend, NE', path: '/trailer-rentals/nebraska/friend', lat: 40.6533, lng: -97.2833 },
  { name: 'Fullerton, NE', path: '/trailer-rentals/nebraska/fullerton', lat: 41.3642, lng: -97.9728 },
  { name: 'Geneva, NE', path: '/trailer-rentals/nebraska/geneva', lat: 40.5267, lng: -97.6011 },
  { name: 'Gering, NE', path: '/trailer-rentals/nebraska/gering', lat: 41.825, lng: -103.6603 },
  { name: 'Gordon, NE', path: '/trailer-rentals/nebraska/gordon', lat: 42.805, lng: -102.2011 },
  { name: 'Gothenburg, NE', path: '/trailer-rentals/nebraska/gothenburg', lat: 40.9292, lng: -100.1603 },
  { name: 'Grand Island, NE', path: '/trailer-rentals/nebraska/grand-island', lat: 40.9219, lng: -98.358 },
  { name: 'Gretna, NE', path: '/trailer-rentals/nebraska/gretna', lat: 41.1403, lng: -96.2392 },
  { name: 'Hartington, NE', path: '/trailer-rentals/nebraska/hartington', lat: 42.6192, lng: -97.2633 },
  { name: 'Hastings, NE', path: '/trailer-rentals/nebraska/hastings', lat: 40.5861, lng: -98.3892 },
  { name: 'Hebron, NE', path: '/trailer-rentals/nebraska/hebron', lat: 40.1667, lng: -97.5867 },
  { name: 'Holdrege, NE', path: '/trailer-rentals/nebraska/holdrege', lat: 40.4392, lng: -99.3711 },
  { name: 'Hyannis, NE', path: '/trailer-rentals/nebraska/hyannis', lat: 42.0003, lng: -101.7642 },
  { name: 'Imperial, NE', path: '/trailer-rentals/nebraska/imperial', lat: 40.5167, lng: -101.6428 },
  { name: 'Kearney, NE', path: '/trailer-rentals/nebraska/kearney', lat: 40.6992, lng: -99.0817 },
  { name: 'Kimball, NE', path: '/trailer-rentals/nebraska/kimball', lat: 41.235, lng: -103.6628 },
  { name: 'La Vista, NE', path: '/trailer-rentals/nebraska/la-vista', lat: 41.1833, lng: -96.0311 },
  { name: 'Lexington, NE', path: '/trailer-rentals/nebraska/lexington', lat: 40.7803, lng: -99.7411 },
  { name: 'Lincoln, NE', path: '/trailer-rentals/nebraska/lincoln', lat: 40.8136, lng: -96.7026 },
  { name: 'Louisville, NE', path: '/trailer-rentals/nebraska/louisville', lat: 40.9967, lng: -96.1628 },
  { name: 'Madison, NE', path: '/trailer-rentals/nebraska/madison', lat: 41.8283, lng: -97.4553 },
  { name: 'McCook, NE', path: '/trailer-rentals/nebraska/mccook', lat: 40.2011, lng: -100.6253 },
  { name: 'Minden, NE', path: '/trailer-rentals/nebraska/minden', lat: 40.4983, lng: -98.9511 },
  { name: 'Nebraska City, NE', path: '/trailer-rentals/nebraska/nebraska-city', lat: 40.6761, lng: -95.8578 },
  { name: 'Neligh, NE', path: '/trailer-rentals/nebraska/neligh', lat: 42.1278, lng: -98.0311 },
  { name: 'Norfolk, NE', path: '/trailer-rentals/nebraska/norfolk', lat: 42.0283, lng: -97.4178 },
  { name: 'North Bend, NE', path: '/trailer-rentals/nebraska/north-bend', lat: 41.4628, lng: -96.7811 },
  { name: 'North Platte, NE', path: '/trailer-rentals/nebraska/north-platte', lat: 41.1403, lng: -100.7603 },
  { name: 'Oakland, NE', path: '/trailer-rentals/nebraska/oakland', lat: 41.835, lng: -96.4653 },
  { name: 'Ogallala, NE', path: '/trailer-rentals/nebraska/ogallala', lat: 41.1283, lng: -101.7192 },
  { name: 'Omaha, NE', path: '/trailer-rentals/nebraska/omaha', lat: 41.2565, lng: -95.9345 },
  { name: 'O\'Neill, NE', path: '/trailer-rentals/nebraska/oneill', lat: 42.4578, lng: -98.6478 },
  { name: 'Ord, NE', path: '/trailer-rentals/nebraska/ord', lat: 41.6033, lng: -98.9261 },
  { name: 'Papillion, NE', path: '/trailer-rentals/nebraska/papillion', lat: 41.1544, lng: -96.0422 },
  { name: 'Pender, NE', path: '/trailer-rentals/nebraska/pender', lat: 42.1103, lng: -96.7078 },
  { name: 'Plattsmouth, NE', path: '/trailer-rentals/nebraska/plattsmouth', lat: 41.0111, lng: -95.8828 },
  { name: 'Ponca, NE', path: '/trailer-rentals/nebraska/ponca', lat: 42.5633, lng: -96.7092 },
  { name: 'Ralston, NE', path: '/trailer-rentals/nebraska/ralston', lat: 41.2028, lng: -96.0492 },
  { name: 'Red Cloud, NE', path: '/trailer-rentals/nebraska/red-cloud', lat: 40.0883, lng: -98.5192 },
  { name: 'Schuyler, NE', path: '/trailer-rentals/nebraska/schuyler', lat: 41.4483, lng: -97.0592 },
  { name: 'Scottsbluff, NE', path: '/trailer-rentals/nebraska/scottsbluff', lat: 41.8667, lng: -103.6678 },
  { name: 'Seward, NE', path: '/trailer-rentals/nebraska/seward', lat: 40.9078, lng: -97.0983 },
  { name: 'Sidney, NE', path: '/trailer-rentals/nebraska/sidney', lat: 41.1392, lng: -102.9783 },
  { name: 'South Sioux City, NE', path: '/trailer-rentals/nebraska/south-sioux-city', lat: 42.4667, lng: -96.4133 },
  { name: 'Springfield, NE', path: '/trailer-rentals/nebraska/springfield', lat: 41.0811, lng: -96.1283 },
  { name: 'St. Paul, NE', path: '/trailer-rentals/nebraska/st-paul', lat: 41.2128, lng: -98.4583 },
  { name: 'Stromsburg, NE', path: '/trailer-rentals/nebraska/stromsburg', lat: 41.115, lng: -97.5953 },
  { name: 'Superior, NE', path: '/trailer-rentals/nebraska/superior', lat: 40.0228, lng: -98.0642 },
  { name: 'Sutherland, NE', path: '/trailer-rentals/nebraska/sutherland', lat: 41.1561, lng: -101.1261 },
  { name: 'Syracuse, NE', path: '/trailer-rentals/nebraska/syracuse', lat: 40.6578, lng: -96.1861 },
  { name: 'Tecumseh, NE', path: '/trailer-rentals/nebraska/tecumseh', lat: 40.3692, lng: -96.1903 },
  { name: 'Tekamah, NE', path: '/trailer-rentals/nebraska/tekamah', lat: 41.7783, lng: -96.2228 },
  { name: 'Valentine, NE', path: '/trailer-rentals/nebraska/valentine', lat: 42.8728, lng: -100.5503 },
  { name: 'Wahoo, NE', path: '/trailer-rentals/nebraska/wahoo', lat: 41.2111, lng: -96.6211 },
  { name: 'Wayne, NE', path: '/trailer-rentals/nebraska/wayne', lat: 42.2303, lng: -97.0178 },
  { name: 'West Point, NE', path: '/trailer-rentals/nebraska/west-point', lat: 41.8392, lng: -96.7111 },
  { name: 'Wilber, NE', path: '/trailer-rentals/nebraska/wilber', lat: 40.4811, lng: -96.9611 },
  { name: 'Wymore, NE', path: '/trailer-rentals/nebraska/wymore', lat: 40.1233, lng: -96.6633 },
  { name: 'York, NE', path: '/trailer-rentals/nebraska/york', lat: 40.8678, lng: -97.5928 },
];

export const newJerseyCities: ICity[] = [
  { name: 'Asbury Park, NJ', path: '/trailer-rentals/new-jersey/asbury-park', lat: 40.2204, lng: -74.0121 },
  { name: 'Atlantic City, NJ', path: '/trailer-rentals/new-jersey/atlantic-city', lat: 39.3643, lng: -74.4229 },
  { name: 'Bayonne, NJ', path: '/trailer-rentals/new-jersey/bayonne', lat: 40.6687, lng: -74.1143 },
  { name: 'Beachwood, NJ', path: '/trailer-rentals/new-jersey/beachwood', lat: 39.9273, lng: -74.1976 },
  { name: 'Belleville, NJ', path: '/trailer-rentals/new-jersey/belleville', lat: 40.7937, lng: -74.1506 },
  { name: 'Bergenfield, NJ', path: '/trailer-rentals/new-jersey/bergenfield', lat: 40.9276, lng: -73.9976 },
  { name: 'Bloomfield, NJ', path: '/trailer-rentals/new-jersey/bloomfield', lat: 40.8068, lng: -74.1854 },
  { name: 'Bridgeton, NJ', path: '/trailer-rentals/new-jersey/bridgeton', lat: 39.4273, lng: -75.2341 },
  { name: 'Burlington, NJ', path: '/trailer-rentals/new-jersey/burlington', lat: 40.0712, lng: -74.8649 },
  { name: 'Caldwell, NJ', path: '/trailer-rentals/new-jersey/caldwell', lat: 40.8395, lng: -74.2765 },
  { name: 'Camden, NJ', path: '/trailer-rentals/new-jersey/camden', lat: 39.9259, lng: -75.1196 },
  { name: 'Carteret, NJ', path: '/trailer-rentals/new-jersey/carteret', lat: 40.5773, lng: -74.2282 },
  { name: 'Cherry Hill, NJ', path: '/trailer-rentals/new-jersey/cherry-hill', lat: 39.9268, lng: -75.0246 },
  { name: 'Clark, NJ', path: '/trailer-rentals/new-jersey/clark', lat: 40.6207, lng: -74.3118 },
  { name: 'Clifton, NJ', path: '/trailer-rentals/new-jersey/clifton', lat: 40.8584, lng: -74.1638 },
  { name: 'Collingswood, NJ', path: '/trailer-rentals/new-jersey/collingswood', lat: 39.9182, lng: -75.0718 },
  { name: 'Cranford, NJ', path: '/trailer-rentals/new-jersey/cranford', lat: 40.6584, lng: -74.3046 },
  { name: 'Dover, NJ', path: '/trailer-rentals/new-jersey/dover', lat: 40.8837, lng: -74.5624 },
  { name: 'Dumont, NJ', path: '/trailer-rentals/new-jersey/dumont', lat: 40.9407, lng: -73.9968 },
  { name: 'East Brunswick, NJ', path: '/trailer-rentals/new-jersey/east-brunswick', lat: 40.4279, lng: -74.4153 },
  { name: 'East Orange, NJ', path: '/trailer-rentals/new-jersey/east-orange', lat: 40.7673, lng: -74.2049 },
  { name: 'East Rutherford, NJ', path: '/trailer-rentals/new-jersey/east-rutherford', lat: 40.8334, lng: -74.0971 },
  { name: 'Edison, NJ', path: '/trailer-rentals/new-jersey/edison', lat: 40.5187, lng: -74.4121 },
  { name: 'Elizabeth, NJ', path: '/trailer-rentals/new-jersey/elizabeth', lat: 40.6639, lng: -74.2107 },
  { name: 'Englewood, NJ', path: '/trailer-rentals/new-jersey/englewood', lat: 40.8929, lng: -73.9726 },
  { name: 'Ewing, NJ', path: '/trailer-rentals/new-jersey/ewing', lat: 40.2698, lng: -74.7999 },
  { name: 'Fair Lawn, NJ', path: '/trailer-rentals/new-jersey/fair-lawn', lat: 40.9404, lng: -74.1318 },
  { name: 'Fort Lee, NJ', path: '/trailer-rentals/new-jersey/fort-lee', lat: 40.8509, lng: -73.9701 },
  { name: 'Garfield, NJ', path: '/trailer-rentals/new-jersey/garfield', lat: 40.8815, lng: -74.1135 },
  { name: 'Glassboro, NJ', path: '/trailer-rentals/new-jersey/glassboro', lat: 39.7029, lng: -75.1118 },
  { name: 'Gloucester City, NJ', path: '/trailer-rentals/new-jersey/gloucester-city', lat: 39.8915, lng: -75.1171 },
  { name: 'Hackensack, NJ', path: '/trailer-rentals/new-jersey/hackensack', lat: 40.8859, lng: -74.0435 },
  { name: 'Haddonfield, NJ', path: '/trailer-rentals/new-jersey/haddonfield', lat: 39.8915, lng: -75.0371 },
  { name: 'Hawthorne, NJ', path: '/trailer-rentals/new-jersey/hawthorne', lat: 40.9495, lng: -74.1532 },
  { name: 'Hoboken, NJ', path: '/trailer-rentals/new-jersey/hoboken', lat: 40.7433, lng: -74.0324 },
  { name: 'Hopatcong, NJ', path: '/trailer-rentals/new-jersey/hopatcong', lat: 40.9437, lng: -74.6618 },
  { name: 'Irvington, NJ', path: '/trailer-rentals/new-jersey/irvington', lat: 40.7242, lng: -74.2318 },
  { name: 'Jackson, NJ', path: '/trailer-rentals/new-jersey/jackson', lat: 40.1182, lng: -74.3587 },
  { name: 'Jersey City, NJ', path: '/trailer-rentals/new-jersey/jersey-city', lat: 40.7178, lng: -74.0431 },
  { name: 'Kearny, NJ', path: '/trailer-rentals/new-jersey/kearny', lat: 40.7684, lng: -74.1454 },
  { name: 'Lakewood, NJ', path: '/trailer-rentals/new-jersey/lakewood', lat: 40.0821, lng: -74.2097 },
  { name: 'Linden, NJ', path: '/trailer-rentals/new-jersey/linden', lat: 40.6220, lng: -74.2446 },
  { name: 'Little Falls, NJ', path: '/trailer-rentals/new-jersey/little-falls', lat: 40.8762, lng: -74.2054 },
  { name: 'Long Branch, NJ', path: '/trailer-rentals/new-jersey/long-branch', lat: 40.3043, lng: -73.9924 },
  { name: 'Lyndhurst, NJ', path: '/trailer-rentals/new-jersey/lyndhurst', lat: 40.8126, lng: -74.1243 },
  { name: 'Madison, NJ', path: '/trailer-rentals/new-jersey/madison', lat: 40.7598, lng: -74.4174 },
  { name: 'Mahwah, NJ', path: '/trailer-rentals/new-jersey/mahwah', lat: 41.0885, lng: -74.1443 },
  { name: 'Manasquan, NJ', path: '/trailer-rentals/new-jersey/manasquan', lat: 40.1251, lng: -74.0493 },
  { name: 'Maplewood, NJ', path: '/trailer-rentals/new-jersey/maplewood', lat: 40.7312, lng: -74.2713 },
  { name: 'Marlton, NJ', path: '/trailer-rentals/new-jersey/marlton', lat: 39.8915, lng: -74.9218 },
  { name: 'Metuchen, NJ', path: '/trailer-rentals/new-jersey/metuchen', lat: 40.5437, lng: -74.3632 },
  { name: 'Middlesex, NJ', path: '/trailer-rentals/new-jersey/middlesex', lat: 40.5723, lng: -74.4987 },
  { name: 'Millville, NJ', path: '/trailer-rentals/new-jersey/millville', lat: 39.4021, lng: -75.0393 },
  { name: 'Montclair, NJ', path: '/trailer-rentals/new-jersey/montclair', lat: 40.8259, lng: -74.2097 },
  { name: 'Montvale, NJ', path: '/trailer-rentals/new-jersey/montvale', lat: 41.0465, lng: -74.0218 },
  { name: 'Morristown, NJ', path: '/trailer-rentals/new-jersey/morristown', lat: 40.7968, lng: -74.4815 },
  { name: 'Mount Laurel, NJ', path: '/trailer-rentals/new-jersey/mount-laurel', lat: 39.9340, lng: -74.8915 },
  { name: 'New Brunswick, NJ', path: '/trailer-rentals/new-jersey/new-brunswick', lat: 40.4862, lng: -74.4518 },
  { name: 'New Milford, NJ', path: '/trailer-rentals/new-jersey/new-milford', lat: 40.9354, lng: -74.0187 },
  { name: 'Newark, NJ', path: '/trailer-rentals/new-jersey/newark', lat: 40.7357, lng: -74.1724 },
  { name: 'North Arlington, NJ', path: '/trailer-rentals/new-jersey/north-arlington', lat: 40.7884, lng: -74.1324 },
  { name: 'North Bergen, NJ', path: '/trailer-rentals/new-jersey/north-bergen', lat: 40.8043, lng: -74.0124 },
  { name: 'Nutley, NJ', path: '/trailer-rentals/new-jersey/nutley', lat: 40.8223, lng: -74.1593 },
  { name: 'Old Bridge, NJ', path: '/trailer-rentals/new-jersey/old-bridge', lat: 40.3993, lng: -74.3418 },
  { name: 'Orange, NJ', path: '/trailer-rentals/new-jersey/orange', lat: 40.7707, lng: -74.2324 },
  { name: 'Paramus, NJ', path: '/trailer-rentals/new-jersey/paramus', lat: 40.9445, lng: -74.0754 },
  { name: 'Parsippany, NJ', path: '/trailer-rentals/new-jersey/parsippany', lat: 40.8579, lng: -74.4254 },
  { name: 'Passaic, NJ', path: '/trailer-rentals/new-jersey/passaic', lat: 40.8568, lng: -74.1287 },
  { name: 'Paterson, NJ', path: '/trailer-rentals/new-jersey/paterson', lat: 40.9168, lng: -74.1718 },
  { name: 'Pennsauken, NJ', path: '/trailer-rentals/new-jersey/pennsauken', lat: 39.9568, lng: -75.0571 },
  { name: 'Perth Amboy, NJ', path: '/trailer-rentals/new-jersey/perth-amboy', lat: 40.5068, lng: -74.2654 },
  { name: 'Piscataway, NJ', path: '/trailer-rentals/new-jersey/piscataway', lat: 40.4993, lng: -74.3993 },
  { name: 'Plainfield, NJ', path: '/trailer-rentals/new-jersey/plainfield', lat: 40.6337, lng: -74.4071 },
  { name: 'Pleasantville, NJ', path: '/trailer-rentals/new-jersey/pleasantville', lat: 39.3895, lng: -74.5243 },
  { name: 'Rahway, NJ', path: '/trailer-rentals/new-jersey/rahway', lat: 40.6082, lng: -74.2776 },
  { name: 'Ramsey, NJ', path: '/trailer-rentals/new-jersey/ramsey', lat: 41.0576, lng: -74.1418 },
  { name: 'Ridgewood, NJ', path: '/trailer-rentals/new-jersey/ridgewood', lat: 40.9793, lng: -74.1168 },
  { name: 'Roselle, NJ', path: '/trailer-rentals/new-jersey/roselle', lat: 40.6526, lng: -74.2632 },
  { name: 'Rutherford, NJ', path: '/trailer-rentals/new-jersey/rutherford', lat: 40.8265, lng: -74.1068 },
  { name: 'Sayreville, NJ', path: '/trailer-rentals/new-jersey/sayreville', lat: 40.4593, lng: -74.3607 },
  { name: 'Scotch Plains, NJ', path: '/trailer-rentals/new-jersey/scotch-plains', lat: 40.6395, lng: -74.3668 },
  { name: 'Secaucus, NJ', path: '/trailer-rentals/new-jersey/secaucus', lat: 40.7895, lng: -74.0568 },
  { name: 'Somerset, NJ', path: '/trailer-rentals/new-jersey/somerset', lat: 40.4973, lng: -74.4887 },
  { name: 'Summit, NJ', path: '/trailer-rentals/new-jersey/summit', lat: 40.7143, lng: -74.3643 },
  { name: 'Teaneck, NJ', path: '/trailer-rentals/new-jersey/teaneck', lat: 40.8887, lng: -74.0118 },
  { name: 'Toms River, NJ', path: '/trailer-rentals/new-jersey/toms-river', lat: 39.9537, lng: -74.1976 },
  { name: 'Trenton, NJ', path: '/trailer-rentals/new-jersey/trenton', lat: 40.2171, lng: -74.7429 },
  { name: 'Union City, NJ', path: '/trailer-rentals/new-jersey/union-city', lat: 40.7795, lng: -74.0234 },
  { name: 'Vineland, NJ', path: '/trailer-rentals/new-jersey/vineland', lat: 39.4862, lng: -75.0254 },
  { name: 'Wayne, NJ', path: '/trailer-rentals/new-jersey/wayne', lat: 40.9482, lng: -74.2454 },
  { name: 'Westfield, NJ', path: '/trailer-rentals/new-jersey/westfield', lat: 40.6587, lng: -74.3476 },
];

export const newMexicoCities: ICity[] = [
  { name: 'Alamogordo, NM', path: '/trailer-rentals/new-mexico/alamogordo', lat: 32.8995, lng: -105.9603 },
  { name: 'Albuquerque, NM', path: '/trailer-rentals/new-mexico/albuquerque', lat: 35.0844, lng: -106.6504, active: true, showNewVersion: true  },
  { name: 'Angel Fire, NM', path: '/trailer-rentals/new-mexico/angel-fire', lat: 36.3934, lng: -105.2858 },
  { name: 'Artesia, NM', path: '/trailer-rentals/new-mexico/artesia', lat: 32.8423, lng: -104.4036 },
  { name: 'Aztec, NM', path: '/trailer-rentals/new-mexico/aztec', lat: 36.8222, lng: -107.9925 },
  { name: 'Bayard, NM', path: '/trailer-rentals/new-mexico/bayard', lat: 32.7598, lng: -108.1337 },
  { name: 'Belen, NM', path: '/trailer-rentals/new-mexico/belen', lat: 34.6629, lng: -106.7763 },
  { name: 'Bernalillo, NM', path: '/trailer-rentals/new-mexico/bernalillo', lat: 35.3000, lng: -106.5511 },
  { name: 'Bloomfield, NM', path: '/trailer-rentals/new-mexico/bloomfield', lat: 36.7111, lng: -107.9845 },
  { name: 'Bosque Farms, NM', path: '/trailer-rentals/new-mexico/bosque-farms', lat: 34.8559, lng: -106.7017 },
  { name: 'Capitan, NM', path: '/trailer-rentals/new-mexico/capitan', lat: 33.5412, lng: -105.5700 },
  { name: 'Carlsbad, NM', path: '/trailer-rentals/new-mexico/carlsbad', lat: 32.4207, lng: -104.2288 },
  { name: 'Carrizozo, NM', path: '/trailer-rentals/new-mexico/carrizozo', lat: 33.6412, lng: -105.8772 },
  { name: 'Chama, NM', path: '/trailer-rentals/new-mexico/chama', lat: 36.9031, lng: -106.5795 },
  { name: 'Cimarron, NM', path: '/trailer-rentals/new-mexico/cimarron', lat: 36.5109, lng: -104.9142 },
  { name: 'Clayton, NM', path: '/trailer-rentals/new-mexico/clayton', lat: 36.4511, lng: -103.1844 },
  { name: 'Cloudcroft, NM', path: '/trailer-rentals/new-mexico/cloudcroft', lat: 32.9534, lng: -105.7436 },
  { name: 'Clovis, NM', path: '/trailer-rentals/new-mexico/clovis', lat: 34.4048, lng: -103.2052 },
  { name: 'Corrales, NM', path: '/trailer-rentals/new-mexico/corrales', lat: 35.2378, lng: -106.6078 },
  { name: 'Cuba, NM', path: '/trailer-rentals/new-mexico/cuba', lat: 36.0195, lng: -106.9628 },
  { name: 'Deming, NM', path: '/trailer-rentals/new-mexico/deming', lat: 32.2687, lng: -107.7586 },
  { name: 'Dexter, NM', path: '/trailer-rentals/new-mexico/dexter', lat: 33.1987, lng: -104.3722 },
  { name: 'Eagle Nest, NM', path: '/trailer-rentals/new-mexico/eagle-nest', lat: 36.5545, lng: -105.2639 },
  { name: 'Edgewood, NM', path: '/trailer-rentals/new-mexico/edgewood', lat: 35.0614, lng: -106.1911 },
  { name: 'Elephant Butte, NM', path: '/trailer-rentals/new-mexico/elephant-butte', lat: 33.1901, lng: -107.2225 },
  { name: 'Elida, NM', path: '/trailer-rentals/new-mexico/elida', lat: 33.9462, lng: -103.6608 },
  { name: 'Espanola, NM', path: '/trailer-rentals/new-mexico/espanola', lat: 35.9917, lng: -106.0815 },
  { name: 'Estancia, NM', path: '/trailer-rentals/new-mexico/estancia', lat: 34.7595, lng: -106.0578 },
  { name: 'Eunice, NM', path: '/trailer-rentals/new-mexico/eunice', lat: 32.4373, lng: -103.1594 },
  { name: 'Farmington, NM', path: '/trailer-rentals/new-mexico/farmington', lat: 36.7281, lng: -108.2187, active: true, showNewVersion: true  },
  { name: 'Floyd, NM', path: '/trailer-rentals/new-mexico/floyd', lat: 34.2156, lng: -103.5947 },
  { name: 'Fort Sumner, NM', path: '/trailer-rentals/new-mexico/fort-sumner', lat: 34.4717, lng: -104.2458 },
  { name: 'Gallup, NM', path: '/trailer-rentals/new-mexico/gallup', lat: 35.5281, lng: -108.7426 },
  { name: 'Grants, NM', path: '/trailer-rentals/new-mexico/grants', lat: 35.1473, lng: -107.8514 },
  { name: 'Hatch, NM', path: '/trailer-rentals/new-mexico/hatch', lat: 32.6648, lng: -107.1536 },
  { name: 'Hobbs, NM', path: '/trailer-rentals/new-mexico/hobbs', lat: 32.7026, lng: -103.1360 },
  { name: 'Hurley, NM', path: '/trailer-rentals/new-mexico/hurley', lat: 32.6998, lng: -108.1273 },
  { name: 'Jal, NM', path: '/trailer-rentals/new-mexico/jal', lat: 32.1143, lng: -103.1924 },
  { name: 'Jemez Springs, NM', path: '/trailer-rentals/new-mexico/jemez-springs', lat: 35.7681, lng: -106.6903 },
  { name: 'Las Cruces, NM', path: '/trailer-rentals/new-mexico/las-cruces', lat: 32.3199, lng: -106.7637 },
  { name: 'Las Vegas, NM', path: '/trailer-rentals/new-mexico/las-vegas', lat: 35.5942, lng: -105.2228 },
  { name: 'Logan, NM', path: '/trailer-rentals/new-mexico/logan', lat: 35.3581, lng: -103.4422 },
  { name: 'Lordsburg, NM', path: '/trailer-rentals/new-mexico/lordsburg', lat: 32.3312, lng: -108.7059 },
  { name: 'Los Alamos, NM', path: '/trailer-rentals/new-mexico/los-alamos', lat: 35.8800, lng: -106.3031 },
  { name: 'Los Lunas, NM', path: '/trailer-rentals/new-mexico/los-lunas', lat: 34.8061, lng: -106.7334 },
  { name: 'Loving, NM', path: '/trailer-rentals/new-mexico/loving', lat: 32.2854, lng: -104.0969 },
  { name: 'Lovington, NM', path: '/trailer-rentals/new-mexico/lovington', lat: 32.9445, lng: -103.3486 },
  { name: 'Magdalena, NM', path: '/trailer-rentals/new-mexico/magdalena', lat: 34.1126, lng: -107.2431 },
  { name: 'Maxwell, NM', path: '/trailer-rentals/new-mexico/maxwell', lat: 36.5386, lng: -104.5403 },
  { name: 'Melrose, NM', path: '/trailer-rentals/new-mexico/melrose', lat: 34.4267, lng: -103.6286 },
  { name: 'Mesilla, NM', path: '/trailer-rentals/new-mexico/mesilla', lat: 32.2726, lng: -106.7986 },
  { name: 'Milan, NM', path: '/trailer-rentals/new-mexico/milan', lat: 35.1745, lng: -107.8895 },
  { name: 'Moriarty, NM', path: '/trailer-rentals/new-mexico/moriarty', lat: 35.0159, lng: -106.0492 },
  { name: 'Mosquero, NM', path: '/trailer-rentals/new-mexico/mosquero', lat: 35.7775, lng: -103.9536 },
  { name: 'Mountainair, NM', path: '/trailer-rentals/new-mexico/mountainair', lat: 34.5203, lng: -106.2411 },
  { name: 'Pecos, NM', path: '/trailer-rentals/new-mexico/pecos', lat: 35.5764, lng: -105.6711 },
  { name: 'Portales, NM', path: '/trailer-rentals/new-mexico/portales', lat: 34.1862, lng: -103.3344 },
  { name: 'Questa, NM', path: '/trailer-rentals/new-mexico/questa', lat: 36.7081, lng: -105.5972 },
  { name: 'Raton, NM', path: '/trailer-rentals/new-mexico/raton', lat: 36.9031, lng: -104.4397 },
  { name: 'Red River, NM', path: '/trailer-rentals/new-mexico/red-river', lat: 36.7081, lng: -105.4064 },
  { name: 'Reserve, NM', path: '/trailer-rentals/new-mexico/reserve', lat: 33.7131, lng: -108.7578 },
  { name: 'Rio Communities, NM', path: '/trailer-rentals/new-mexico/rio-communities', lat: 34.6464, lng: -106.7328 },
  { name: 'Rio Rancho, NM', path: '/trailer-rentals/new-mexico/rio-rancho', lat: 35.2328, lng: -106.6630 },
  { name: 'Roswell, NM', path: '/trailer-rentals/new-mexico/roswell', lat: 33.3943, lng: -104.5230 },
  { name: 'Roy, NM', path: '/trailer-rentals/new-mexico/roy', lat: 35.9453, lng: -104.1930 },
  { name: 'Ruidoso, NM', path: '/trailer-rentals/new-mexico/ruidoso', lat: 33.3317, lng: -105.6730 },
  { name: 'San Jon, NM', path: '/trailer-rentals/new-mexico/san-jon', lat: 35.1095, lng: -103.3316 },
  { name: 'San Ysidro, NM', path: '/trailer-rentals/new-mexico/san-ysidro', lat: 35.5528, lng: -106.7739 },
  { name: 'Santa Clara, NM', path: '/trailer-rentals/new-mexico/santa-clara', lat: 32.7759, lng: -108.1534 },
  { name: 'Santa Fe, NM', path: '/trailer-rentals/new-mexico/santa-fe', lat: 35.6869, lng: -105.9378 },
  { name: 'Santa Rosa, NM', path: '/trailer-rentals/new-mexico/santa-rosa', lat: 34.9381, lng: -104.6825 },
  { name: 'Shiprock, NM', path: '/trailer-rentals/new-mexico/shiprock', lat: 36.7850, lng: -108.6870 },
  { name: 'Silver City, NM', path: '/trailer-rentals/new-mexico/silver-city', lat: 32.7701, lng: -108.2803 },
  { name: 'Socorro, NM', path: '/trailer-rentals/new-mexico/socorro', lat: 34.0584, lng: -106.8914 },
  { name: 'Springer, NM', path: '/trailer-rentals/new-mexico/springer', lat: 36.3631, lng: -104.5958 },
  { name: 'Sunland Park, NM', path: '/trailer-rentals/new-mexico/sunland-park', lat: 31.8140, lng: -106.5794 },
  { name: 'Taos, NM', path: '/trailer-rentals/new-mexico/taos', lat: 36.4072, lng: -105.5731 },
  { name: 'Texico, NM', path: '/trailer-rentals/new-mexico/texico', lat: 34.3873, lng: -103.0480 },
  { name: 'T or C (Truth or Consequences), NM', path: '/trailer-rentals/new-mexico/truth-or-consequences', lat: 33.1284, lng: -107.2528 },
  { name: 'Tucumcari, NM', path: '/trailer-rentals/new-mexico/tucumcari', lat: 35.1717, lng: -103.7249 },
  { name: 'Tularosa, NM', path: '/trailer-rentals/new-mexico/tularosa', lat: 33.0762, lng: -106.0186 },
  { name: 'Vaughn, NM', path: '/trailer-rentals/new-mexico/vaughn', lat: 34.6045, lng: -105.2078 },
  { name: 'Wagon Mound, NM', path: '/trailer-rentals/new-mexico/wagon-mound', lat: 36.0053, lng: -104.7064 },
  { name: 'Willard, NM', path: '/trailer-rentals/new-mexico/willard', lat: 34.5959, lng: -106.0278 },
  { name: 'Williamsburg, NM', path: '/trailer-rentals/new-mexico/williamsburg', lat: 33.1223, lng: -107.2906 },
  { name: 'Zuni Pueblo, NM', path: '/trailer-rentals/new-mexico/zuni-pueblo', lat: 35.0681, lng: -108.8481 },
];

export const newYorkCities: ICity[] = [
  { name: 'Albany, NY', path: '/trailer-rentals/new-york/albany', lat: 42.6526, lng: -73.7562 },
  { name: 'Amsterdam, NY', path: '/trailer-rentals/new-york/amsterdam', lat: 42.9387, lng: -74.1882 },
  { name: 'Auburn, NY', path: '/trailer-rentals/new-york/auburn', lat: 42.9317, lng: -76.5661 },
  { name: 'Batavia, NY', path: '/trailer-rentals/new-york/batavia', lat: 42.9981, lng: -78.1875 },
  { name: 'Beacon, NY', path: '/trailer-rentals/new-york/beacon', lat: 41.5048, lng: -73.9696 },
  { name: 'Binghamton, NY', path: '/trailer-rentals/new-york/binghamton', lat: 42.0987, lng: -75.9180 },
  { name: 'Buffalo, NY', path: '/trailer-rentals/new-york/buffalo', lat: 42.8864, lng: -78.8784 },
  { name: 'Canandaigua, NY', path: '/trailer-rentals/new-york/canandaigua', lat: 42.8875, lng: -77.2817 },
  { name: 'Cohoes, NY', path: '/trailer-rentals/new-york/cohoes', lat: 42.7745, lng: -73.7001 },
  { name: 'Corning, NY', path: '/trailer-rentals/new-york/corning', lat: 42.1429, lng: -77.0547 },
  { name: 'Cortland, NY', path: '/trailer-rentals/new-york/cortland', lat: 42.6012, lng: -76.1805 },
  { name: 'Dunkirk, NY', path: '/trailer-rentals/new-york/dunkirk', lat: 42.4795, lng: -79.3339 },
  { name: 'Elmira, NY', path: '/trailer-rentals/new-york/elmira', lat: 42.0898, lng: -76.8077 },
  { name: 'Fulton, NY', path: '/trailer-rentals/new-york/fulton', lat: 43.3220, lng: -76.4177 },
  { name: 'Geneva, NY', path: '/trailer-rentals/new-york/geneva', lat: 42.8687, lng: -76.9772 },
  { name: 'Glen Cove, NY', path: '/trailer-rentals/new-york/glen-cove', lat: 40.8623, lng: -73.6337 },
  { name: 'Glens Falls, NY', path: '/trailer-rentals/new-york/glens-falls', lat: 43.3095, lng: -73.6440 },
  { name: 'Gloversville, NY', path: '/trailer-rentals/new-york/gloversville', lat: 43.0520, lng: -74.3435 },
  { name: 'Hornell, NY', path: '/trailer-rentals/new-york/hornell', lat: 42.3270, lng: -77.6611 },
  { name: 'Hudson, NY', path: '/trailer-rentals/new-york/hudson', lat: 42.2529, lng: -73.7900 },
  { name: 'Ithaca, NY', path: '/trailer-rentals/new-york/ithaca', lat: 42.4439, lng: -76.5019 },
  { name: 'Jamestown, NY', path: '/trailer-rentals/new-york/jamestown', lat: 42.0970, lng: -79.2353 },
  { name: 'Johnstown, NY', path: '/trailer-rentals/new-york/johnstown', lat: 43.0067, lng: -74.3671 },
  { name: 'Kingston, NY', path: '/trailer-rentals/new-york/kingston', lat: 41.9270, lng: -73.9974 },
  { name: 'Lackawanna, NY', path: '/trailer-rentals/new-york/lackawanna', lat: 42.8259, lng: -78.8234 },
  { name: 'Little Falls, NY', path: '/trailer-rentals/new-york/little-falls', lat: 43.0434, lng: -74.8596 },
  { name: 'Lockport, NY', path: '/trailer-rentals/new-york/lockport', lat: 43.1706, lng: -78.6903 },
  { name: 'Long Beach, NY', path: '/trailer-rentals/new-york/long-beach', lat: 40.5884, lng: -73.6579 },
  { name: 'Massena, NY', path: '/trailer-rentals/new-york/massena', lat: 44.9287, lng: -74.8919 },
  { name: 'Mechanicville, NY', path: '/trailer-rentals/new-york/mechanicville', lat: 42.9020, lng: -73.6871 },
  { name: 'Middletown, NY', path: '/trailer-rentals/new-york/middletown', lat: 41.4459, lng: -74.4229 },
  { name: 'Mount Vernon, NY', path: '/trailer-rentals/new-york/mount-vernon', lat: 40.9126, lng: -73.8371 },
  { name: 'New Rochelle, NY', path: '/trailer-rentals/new-york/new-rochelle', lat: 40.9115, lng: -73.7824 },
  { name: 'New York City, NY', path: '/trailer-rentals/new-york/new-york-city', lat: 40.7128, lng: -74.0060 },
  { name: 'Newburgh, NY', path: '/trailer-rentals/new-york/newburgh', lat: 41.5034, lng: -74.0104 },
  { name: 'Niagara Falls, NY', path: '/trailer-rentals/new-york/niagara-falls', lat: 43.0962, lng: -79.0377 },
  { name: 'North Tonawanda, NY', path: '/trailer-rentals/new-york/north-tonawanda', lat: 43.0387, lng: -78.8642 },
  { name: 'Norwich, NY', path: '/trailer-rentals/new-york/norwich', lat: 42.5312, lng: -75.5232 },
  { name: 'Ogdensburg, NY', path: '/trailer-rentals/new-york/ogdensburg', lat: 44.6948, lng: -75.4863 },
  { name: 'Olean, NY', path: '/trailer-rentals/new-york/olean', lat: 42.0834, lng: -78.4303 },
  { name: 'Oneida, NY', path: '/trailer-rentals/new-york/oneida', lat: 43.0928, lng: -75.6513 },
  { name: 'Oneonta, NY', path: '/trailer-rentals/new-york/oneonta', lat: 42.4529, lng: -75.0638 },
  { name: 'Oswego, NY', path: '/trailer-rentals/new-york/oswego', lat: 43.4553, lng: -76.5105 },
  { name: 'Peekskill, NY', path: '/trailer-rentals/new-york/peekskill', lat: 41.2901, lng: -73.9204 },
  { name: 'Plattsburgh, NY', path: '/trailer-rentals/new-york/plattsburgh', lat: 44.6995, lng: -73.4529 },
  { name: 'Port Jervis, NY', path: '/trailer-rentals/new-york/port-jervis', lat: 41.3759, lng: -74.6921 },
  { name: 'Poughkeepsie, NY', path: '/trailer-rentals/new-york/poughkeepsie', lat: 41.7004, lng: -73.9210 },
  { name: 'Rensselaer, NY', path: '/trailer-rentals/new-york/rensselaer', lat: 42.6420, lng: -73.7429 },
  { name: 'Rochester, NY', path: '/trailer-rentals/new-york/rochester', lat: 43.1566, lng: -77.6088 },
  { name: 'Rome, NY', path: '/trailer-rentals/new-york/rome', lat: 43.2128, lng: -75.4557 },
  { name: 'Salamanca, NY', path: '/trailer-rentals/new-york/salamanca', lat: 42.1573, lng: -78.7150 },
  { name: 'Saratoga Springs, NY', path: '/trailer-rentals/new-york/saratoga-springs', lat: 43.0831, lng: -73.7846 },
  { name: 'Schenectady, NY', path: '/trailer-rentals/new-york/schenectady', lat: 42.8142, lng: -73.9396 },
  { name: 'Sherrill, NY', path: '/trailer-rentals/new-york/sherrill', lat: 43.0720, lng: -75.5988 },
  { name: 'Syracuse, NY', path: '/trailer-rentals/new-york/syracuse', lat: 43.0481, lng: -76.1474 },
  { name: 'Tonawanda, NY', path: '/trailer-rentals/new-york/tonawanda', lat: 43.0203, lng: -78.8803 },
  { name: 'Troy, NY', path: '/trailer-rentals/new-york/troy', lat: 42.7284, lng: -73.6918 },
  { name: 'Utica, NY', path: '/trailer-rentals/new-york/utica', lat: 43.1009, lng: -75.2327 },
  { name: 'Watertown, NY', path: '/trailer-rentals/new-york/watertown', lat: 43.9748, lng: -75.9108 },
  { name: 'Watervliet, NY', path: '/trailer-rentals/new-york/watervliet', lat: 42.7306, lng: -73.7087 },
  { name: 'White Plains, NY', path: '/trailer-rentals/new-york/white-plains', lat: 41.0330, lng: -73.7629 },
  { name: 'Yonkers, NY', path: '/trailer-rentals/new-york/yonkers', lat: 40.9312, lng: -73.8988 },
  { name: 'Amherst, NY', path: '/trailer-rentals/new-york/amherst', lat: 42.9784, lng: -78.7998 },
  { name: 'Bay Shore, NY', path: '/trailer-rentals/new-york/bay-shore', lat: 40.7251, lng: -73.2454 },
  { name: 'Bethpage, NY', path: '/trailer-rentals/new-york/bethpage', lat: 40.7443, lng: -73.4826 },
  { name: 'Brentwood, NY', path: '/trailer-rentals/new-york/brentwood', lat: 40.7812, lng: -73.2462 },
  { name: 'Brockport, NY', path: '/trailer-rentals/new-york/brockport', lat: 43.2134, lng: -77.9397 },
  { name: 'Centereach, NY', path: '/trailer-rentals/new-york/centereach', lat: 40.8582, lng: -73.0998 },
  { name: 'Central Islip, NY', path: '/trailer-rentals/new-york/central-islip', lat: 40.7907, lng: -73.2018 },
  { name: 'Cheektowaga, NY', path: '/trailer-rentals/new-york/cheektowaga', lat: 42.9034, lng: -78.7548 },
  { name: 'Commack, NY', path: '/trailer-rentals/new-york/commack', lat: 40.8420, lng: -73.2929 },
  { name: 'Deer Park, NY', path: '/trailer-rentals/new-york/deer-park', lat: 40.7615, lng: -73.3293 },
  { name: 'East Meadow, NY', path: '/trailer-rentals/new-york/east-meadow', lat: 40.7137, lng: -73.5580 },
  { name: 'East Northport, NY', path: '/trailer-rentals/new-york/east-northport', lat: 40.8762, lng: -73.3248 },
  { name: 'East Patchogue, NY', path: '/trailer-rentals/new-york/east-patchogue', lat: 40.7670, lng: -72.9962 },
  { name: 'East Setauket, NY', path: '/trailer-rentals/new-york/east-setauket', lat: 40.9304, lng: -73.1062 },
  { name: 'Elmont, NY', path: '/trailer-rentals/new-york/elmont', lat: 40.7007, lng: -73.7126 },
  { name: 'Freeport, NY', path: '/trailer-rentals/new-york/freeport', lat: 40.6576, lng: -73.5832 },
  { name: 'Greece, NY', path: '/trailer-rentals/new-york/greece', lat: 43.2098, lng: -77.6933 },
  { name: 'Hempstead, NY', path: '/trailer-rentals/new-york/hempstead', lat: 40.7062, lng: -73.6187 },
  { name: 'Irondequoit, NY', path: '/trailer-rentals/new-york/irondequoit', lat: 43.2130, lng: -77.5801 },
  { name: 'Levittown, NY', path: '/trailer-rentals/new-york/levittown', lat: 40.7259, lng: -73.5143 },
  { name: 'Mastic, NY', path: '/trailer-rentals/new-york/mastic', lat: 40.8020, lng: -72.8426 },
  { name: 'Merrick, NY', path: '/trailer-rentals/new-york/merrick', lat: 40.6629, lng: -73.5535 },
  { name: 'Nanuet, NY', path: '/trailer-rentals/new-york/nanuet', lat: 41.0887, lng: -74.0132 },
];

export const northCarolinaCities:ICity[] = [
  { name: 'Albemarle, NC', path: '/trailer-rentals/north-carolina/albemarle', lat: 35.3504, lng: -80.2006 },
  { name: 'Apex, NC', path: '/trailer-rentals/north-carolina/apex', lat: 35.7327, lng: -78.8503 },
  { name: 'Asheboro, NC', path: '/trailer-rentals/north-carolina/asheboro', lat: 35.7079, lng: -79.8136 },
  { name: 'Asheville, NC', path: '/trailer-rentals/north-carolina/asheville', lat: 35.5951, lng: -82.5515 },
  { name: 'Belmont, NC', path: '/trailer-rentals/north-carolina/belmont', lat: 35.2421, lng: -81.0370 },
  { name: 'Bessemer City, NC', path: '/trailer-rentals/north-carolina/bessemer-city', lat: 35.2843, lng: -81.2823 },
  { name: 'Boone, NC', path: '/trailer-rentals/north-carolina/boone', lat: 36.2168, lng: -81.6746 },
  { name: 'Brevard, NC', path: '/trailer-rentals/north-carolina/brevard', lat: 35.2337, lng: -82.7343 },
  { name: 'Burlington, NC', path: '/trailer-rentals/north-carolina/burlington', lat: 36.0957, lng: -79.4378 },
  { name: 'Cary, NC', path: '/trailer-rentals/north-carolina/cary', lat: 35.7915, lng: -78.7811 },
  { name: 'Chapel Hill, NC', path: '/trailer-rentals/north-carolina/chapel-hill', lat: 35.9132, lng: -79.0558 },
  { name: 'Charlotte, NC', path: '/trailer-rentals/north-carolina/charlotte', lat: 35.2271, lng: -80.8431 },
  { name: 'Clayton, NC', path: '/trailer-rentals/north-carolina/clayton', lat: 35.6507, lng: -78.4564 },
  { name: 'Clemmons, NC', path: '/trailer-rentals/north-carolina/clemmons', lat: 36.0215, lng: -80.3828 },
  { name: 'Clinton, NC', path: '/trailer-rentals/north-carolina/clinton', lat: 34.9977, lng: -78.3233 },
  { name: 'Concord, NC', path: '/trailer-rentals/north-carolina/concord', lat: 35.4088, lng: -80.5795 },
  { name: 'Cornelius, NC', path: '/trailer-rentals/north-carolina/cornelius', lat: 35.4868, lng: -80.8601 },
  { name: 'Davidson, NC', path: '/trailer-rentals/north-carolina/davidson', lat: 35.4993, lng: -80.8487 },
  { name: 'Dunn, NC', path: '/trailer-rentals/north-carolina/dunn', lat: 35.3060, lng: -78.6089 },
  { name: 'Durham, NC', path: '/trailer-rentals/north-carolina/durham', lat: 35.9940, lng: -78.8986 },
  { name: 'Edenton, NC', path: '/trailer-rentals/north-carolina/edenton', lat: 36.0571, lng: -76.6077 },
  { name: 'Elizabeth City, NC', path: '/trailer-rentals/north-carolina/elizabeth-city', lat: 36.2946, lng: -76.2510 },
  { name: 'Elkin, NC', path: '/trailer-rentals/north-carolina/elkin', lat: 36.2443, lng: -80.8487 },
  { name: 'Fayetteville, NC', path: '/trailer-rentals/north-carolina/fayetteville', lat: 35.0527, lng: -78.8784 },
  { name: 'Forest City, NC', path: '/trailer-rentals/north-carolina/forest-city', lat: 35.3340, lng: -81.8651 },
  { name: 'Fuquay-Varina, NC', path: '/trailer-rentals/north-carolina/fuquay-varina', lat: 35.5843, lng: -78.8000 },
  { name: 'Garner, NC', path: '/trailer-rentals/north-carolina/garner', lat: 35.7113, lng: -78.6142 },
  { name: 'Gastonia, NC', path: '/trailer-rentals/north-carolina/gastonia', lat: 35.2621, lng: -81.1873 },
  { name: 'Goldsboro, NC', path: '/trailer-rentals/north-carolina/goldsboro', lat: 35.3849, lng: -77.9928 },
  { name: 'Greensboro, NC', path: '/trailer-rentals/north-carolina/greensboro', lat: 36.0726, lng: -79.7920 },
  { name: 'Greenville, NC', path: '/trailer-rentals/north-carolina/greenville', lat: 35.6127, lng: -77.3664 },
  { name: 'Henderson, NC', path: '/trailer-rentals/north-carolina/henderson', lat: 36.3296, lng: -78.3992 },
  { name: 'Hendersonville, NC', path: '/trailer-rentals/north-carolina/hendersonville', lat: 35.3187, lng: -82.4609 },
  { name: 'Hickory, NC', path: '/trailer-rentals/north-carolina/hickory', lat: 35.7332, lng: -81.3412 },
  { name: 'High Point, NC', path: '/trailer-rentals/north-carolina/high-point', lat: 35.9557, lng: -80.0053 },
  { name: 'Hillsborough, NC', path: '/trailer-rentals/north-carolina/hillsborough', lat: 36.0754, lng: -79.0997 },
  { name: 'Holly Springs, NC', path: '/trailer-rentals/north-carolina/holly-springs', lat: 35.6513, lng: -78.8336 },
  { name: 'Hope Mills, NC', path: '/trailer-rentals/north-carolina/hope-mills', lat: 34.9704, lng: -78.9453 },
  { name: 'Huntersville, NC', path: '/trailer-rentals/north-carolina/huntersville', lat: 35.4107, lng: -80.8428 },
  { name: 'Jacksonville, NC', path: '/trailer-rentals/north-carolina/jacksonville', lat: 34.7541, lng: -77.4302 },
  { name: 'Kannapolis, NC', path: '/trailer-rentals/north-carolina/kannapolis', lat: 35.4874, lng: -80.6217 },
  { name: 'Kernersville, NC', path: '/trailer-rentals/north-carolina/kernersville', lat: 36.1199, lng: -80.0737 },
  { name: 'Kill Devil Hills, NC', path: '/trailer-rentals/north-carolina/kill-devil-hills', lat: 36.0307, lng: -75.6760 },
  { name: 'Kings Mountain, NC', path: '/trailer-rentals/north-carolina/kings-mountain', lat: 35.2451, lng: -81.3412 },
  { name: 'Kinston, NC', path: '/trailer-rentals/north-carolina/kinston', lat: 35.2627, lng: -77.5816 },
  { name: 'Laurinburg, NC', path: '/trailer-rentals/north-carolina/laurinburg', lat: 34.7740, lng: -79.4628 },
  { name: 'Lenoir, NC', path: '/trailer-rentals/north-carolina/lenoir', lat: 35.9140, lng: -81.5389 },
  { name: 'Lewisville, NC', path: '/trailer-rentals/north-carolina/lewisville', lat: 36.0971, lng: -80.4164 },
  { name: 'Lexington, NC', path: '/trailer-rentals/north-carolina/lexington', lat: 35.8240, lng: -80.2534 },
  { name: 'Lincolnton, NC', path: '/trailer-rentals/north-carolina/lincolnton', lat: 35.4732, lng: -81.2543 },
  { name: 'Lumberton, NC', path: '/trailer-rentals/north-carolina/lumberton', lat: 34.6182, lng: -79.0086 },
  { name: 'Matthews, NC', path: '/trailer-rentals/north-carolina/matthews', lat: 35.1168, lng: -80.7237 },
  { name: 'Mint Hill, NC', path: '/trailer-rentals/north-carolina/mint-hill', lat: 35.1796, lng: -80.6470 },
  { name: 'Monroe, NC', path: '/trailer-rentals/north-carolina/monroe', lat: 34.9854, lng: -80.5495 },
  { name: 'Mooresville, NC', path: '/trailer-rentals/north-carolina/mooresville', lat: 35.5849, lng: -80.8101 },
  { name: 'Morehead City, NC', path: '/trailer-rentals/north-carolina/morehead-city', lat: 34.7229, lng: -76.7260 },
  { name: 'Morganton, NC', path: '/trailer-rentals/north-carolina/morganton', lat: 35.7454, lng: -81.6848 },
  { name: 'Mount Airy, NC', path: '/trailer-rentals/north-carolina/mount-airy', lat: 36.4993, lng: -80.6073 },
  { name: 'Mount Holly, NC', path: '/trailer-rentals/north-carolina/mount-holly', lat: 35.2987, lng: -81.0151 },
  { name: 'New Bern, NC', path: '/trailer-rentals/north-carolina/new-bern', lat: 35.1085, lng: -77.0441 },
  { name: 'Newton, NC', path: '/trailer-rentals/north-carolina/newton', lat: 35.6693, lng: -81.2217 },
  { name: 'Pinehurst, NC', path: '/trailer-rentals/north-carolina/pinehurst', lat: 35.1954, lng: -79.4695 },
  { name: 'Raleigh, NC', path: '/trailer-rentals/north-carolina/raleigh', lat: 35.7796, lng: -78.6382 },
  { name: 'Reidsville, NC', path: '/trailer-rentals/north-carolina/reidsville', lat: 36.3549, lng: -79.6645 },
  { name: 'Roanoke Rapids, NC', path: '/trailer-rentals/north-carolina/roanoke-rapids', lat: 36.4615, lng: -77.6544 },
  { name: 'Rockingham, NC', path: '/trailer-rentals/north-carolina/rockingham', lat: 34.9393, lng: -79.7731 },
  { name: 'Rocky Mount, NC', path: '/trailer-rentals/north-carolina/rocky-mount', lat: 35.9382, lng: -77.7905 },
  { name: 'Rolesville, NC', path: '/trailer-rentals/north-carolina/rolesville', lat: 35.9232, lng: -78.4578 },
  { name: 'Roxboro, NC', path: '/trailer-rentals/north-carolina/roxboro', lat: 36.3938, lng: -78.9828 },
  { name: 'Salisbury, NC', path: '/trailer-rentals/north-carolina/salisbury', lat: 35.6709, lng: -80.4742 },
  { name: 'Sanford, NC', path: '/trailer-rentals/north-carolina/sanford', lat: 35.4799, lng: -79.1803 },
  { name: 'Shelby, NC', path: '/trailer-rentals/north-carolina/shelby', lat: 35.2924, lng: -81.5356 },
  { name: 'Smithfield, NC', path: '/trailer-rentals/north-carolina/smithfield', lat: 35.5085, lng: -78.3395 },
  { name: 'Southern Pines, NC', path: '/trailer-rentals/north-carolina/southern-pines', lat: 35.1740, lng: -79.3923 },
  { name: 'Statesville, NC', path: '/trailer-rentals/north-carolina/statesville', lat: 35.7826, lng: -80.8873 },
  { name: 'Tarboro, NC', path: '/trailer-rentals/north-carolina/tarboro', lat: 35.8965, lng: -77.5350 },
  { name: 'Thomasville, NC', path: '/trailer-rentals/north-carolina/thomasville', lat: 35.8826, lng: -80.0810 },
  { name: 'Wake Forest, NC', path: '/trailer-rentals/north-carolina/wake-forest', lat: 35.9799, lng: -78.5097 },
  { name: 'Washington, NC', path: '/trailer-rentals/north-carolina/washington', lat: 35.5468, lng: -77.0526 },
  { name: 'Waynesville, NC', path: '/trailer-rentals/north-carolina/waynesville', lat: 35.4887, lng: -82.9887 },
  { name: 'Weddington, NC', path: '/trailer-rentals/north-carolina/weddington', lat: 35.0229, lng: -80.7334 },
  { name: 'Wendell, NC', path: '/trailer-rentals/north-carolina/wendell', lat: 35.7804, lng: -78.3697 },
  { name: 'Wilson, NC', path: '/trailer-rentals/north-carolina/wilson', lat: 35.7213, lng: -77.9155 },
  { name: 'Winston-Salem, NC', path: '/trailer-rentals/north-carolina/winston-salem', lat: 36.0999, lng: -80.2442 },
];

export const northDakotaCities:ICity[] = [
  { name: 'Beulah, ND', path: '/trailer-rentals/north-dakota/beulah', lat: 47.2633, lng: -101.7771 },
  { name: 'Bismarck, ND', path: '/trailer-rentals/north-dakota/bismarck', lat: 46.8083, lng: -100.7837 },
  { name: 'Bottineau, ND', path: '/trailer-rentals/north-dakota/bottineau', lat: 48.8283, lng: -100.4451 },
  { name: 'Bowman, ND', path: '/trailer-rentals/north-dakota/bowman', lat: 46.1833, lng: -103.3949 },
  { name: 'Burlington, ND', path: '/trailer-rentals/north-dakota/burlington', lat: 48.2742, lng: -101.4182 },
  { name: 'Carrington, ND', path: '/trailer-rentals/north-dakota/carrington', lat: 47.4497, lng: -99.1265 },
  { name: 'Casselton, ND', path: '/trailer-rentals/north-dakota/casselton', lat: 46.9003, lng: -97.2115 },
  { name: 'Cavalier, ND', path: '/trailer-rentals/north-dakota/cavalier', lat: 48.7925, lng: -97.6226 },
  { name: 'Cooperstown, ND', path: '/trailer-rentals/north-dakota/cooperstown', lat: 47.4442, lng: -98.1234 },
  { name: 'Crosby, ND', path: '/trailer-rentals/north-dakota/crosby', lat: 48.9142, lng: -103.2946 },
  { name: 'Devils Lake, ND', path: '/trailer-rentals/north-dakota/devils-lake', lat: 48.1128, lng: -98.8651 },
  { name: 'Dickinson, ND', path: '/trailer-rentals/north-dakota/dickinson', lat: 46.8792, lng: -102.7896 },
  { name: 'Drayton, ND', path: '/trailer-rentals/north-dakota/drayton', lat: 48.5678, lng: -97.1812 },
  { name: 'Dunseith, ND', path: '/trailer-rentals/north-dakota/dunseith', lat: 48.8142, lng: -100.0601 },
  { name: 'Ellendale, ND', path: '/trailer-rentals/north-dakota/ellendale', lat: 46.0033, lng: -98.5265 },
  { name: 'Fargo, ND', path: '/trailer-rentals/north-dakota/fargo', lat: 46.8772, lng: -96.7898 },
  { name: 'Garrison, ND', path: '/trailer-rentals/north-dakota/garrison', lat: 47.6533, lng: -101.4171 },
  { name: 'Grafton, ND', path: '/trailer-rentals/north-dakota/grafton', lat: 48.4178, lng: -97.4101 },
  { name: 'Grand Forks, ND', path: '/trailer-rentals/north-dakota/grand-forks', lat: 47.9253, lng: -97.0329 },
  { name: 'Hankinson, ND', path: '/trailer-rentals/north-dakota/hankinson', lat: 46.0733, lng: -96.9012 },
  { name: 'Harvey, ND', path: '/trailer-rentals/north-dakota/harvey', lat: 47.7833, lng: -99.9335 },
  { name: 'Hatton, ND', path: '/trailer-rentals/north-dakota/hatton', lat: 47.6361, lng: -97.4545 },
  { name: 'Hazen, ND', path: '/trailer-rentals/north-dakota/hazen', lat: 47.2942, lng: -101.6232 },
  { name: 'Hebron, ND', path: '/trailer-rentals/north-dakota/hebron', lat: 46.9042, lng: -102.0451 },
  { name: 'Hillsboro, ND', path: '/trailer-rentals/north-dakota/hillsboro', lat: 47.4028, lng: -97.0623 },
  { name: 'Hope, ND', path: '/trailer-rentals/north-dakota/hope', lat: 47.3242, lng: -97.7182 },
  { name: 'Horace, ND', path: '/trailer-rentals/north-dakota/horace', lat: 46.7583, lng: -96.9045 },
  { name: 'Jamestown, ND', path: '/trailer-rentals/north-dakota/jamestown', lat: 46.9106, lng: -98.7084 },
  { name: 'Kenmare, ND', path: '/trailer-rentals/north-dakota/kenmare', lat: 48.6733, lng: -102.0782 },
  { name: 'Killdeer, ND', path: '/trailer-rentals/north-dakota/killdeer', lat: 47.3703, lng: -102.7551 },
  { name: 'Kindred, ND', path: '/trailer-rentals/north-dakota/kindred', lat: 46.6483, lng: -97.0145 },
  { name: 'Kulm, ND', path: '/trailer-rentals/north-dakota/kulm', lat: 46.3011, lng: -98.9495 },
  { name: 'Lakota, ND', path: '/trailer-rentals/north-dakota/lakota', lat: 48.0411, lng: -98.3434 },
  { name: 'Langdon, ND', path: '/trailer-rentals/north-dakota/langdon', lat: 48.7628, lng: -98.3695 },
  { name: 'Larimore, ND', path: '/trailer-rentals/north-dakota/larimore', lat: 47.9078, lng: -97.6273 },
  { name: 'Linton, ND', path: '/trailer-rentals/north-dakota/linton', lat: 46.2678, lng: -100.2329 },
  { name: 'Lisbon, ND', path: '/trailer-rentals/north-dakota/lisbon', lat: 46.4411, lng: -97.6823 },
  { name: 'Mandan, ND', path: '/trailer-rentals/north-dakota/mandan', lat: 46.8267, lng: -100.8895 },
  { name: 'Mayville, ND', path: '/trailer-rentals/north-dakota/mayville', lat: 47.5003, lng: -97.3245 },
  { name: 'Medora, ND', path: '/trailer-rentals/north-dakota/medora', lat: 46.9133, lng: -103.5246 },
  { name: 'Milnor, ND', path: '/trailer-rentals/north-dakota/milnor', lat: 46.2583, lng: -97.4551 },
  { name: 'Minnewaukan, ND', path: '/trailer-rentals/north-dakota/minnewaukan', lat: 48.0733, lng: -99.2501 },
  { name: 'Minot, ND', path: '/trailer-rentals/north-dakota/minot', lat: 48.2325, lng: -101.2963 },
  { name: 'Minto, ND', path: '/trailer-rentals/north-dakota/minto', lat: 48.2925, lng: -97.3762 },
  { name: 'Mohall, ND', path: '/trailer-rentals/north-dakota/mohall', lat: 48.7633, lng: -101.5095 },
  { name: 'Napoleon, ND', path: '/trailer-rentals/north-dakota/napoleon', lat: 46.5033, lng: -99.7695 },
  { name: 'New Rockford, ND', path: '/trailer-rentals/north-dakota/new-rockford', lat: 47.6792, lng: -99.1345 },
  { name: 'New Salem, ND', path: '/trailer-rentals/north-dakota/new-salem', lat: 46.8433, lng: -101.4134 },
  { name: 'Northwood, ND', path: '/trailer-rentals/north-dakota/northwood', lat: 47.7353, lng: -97.5701 },
  { name: 'Oakes, ND', path: '/trailer-rentals/north-dakota/oakes', lat: 46.1383, lng: -98.0912 },
  { name: 'Park River, ND', path: '/trailer-rentals/north-dakota/park-river', lat: 48.3978, lng: -97.7445 },
  { name: 'Parshall, ND', path: '/trailer-rentals/north-dakota/parshall', lat: 47.9542, lng: -102.1251 },
  { name: 'Pembina, ND', path: '/trailer-rentals/north-dakota/pembina', lat: 48.9667, lng: -97.2473 },
  { name: 'Portland, ND', path: '/trailer-rentals/north-dakota/portland', lat: 47.5003, lng: -97.3712 },
  { name: 'Ray, ND', path: '/trailer-rentals/north-dakota/ray', lat: 48.3425, lng: -103.1629 },
  { name: 'Regent, ND', path: '/trailer-rentals/north-dakota/regent', lat: 46.4211, lng: -102.5562 },
  { name: 'Richardton, ND', path: '/trailer-rentals/north-dakota/richardton', lat: 46.8883, lng: -102.3162 },
  { name: 'Rolla, ND', path: '/trailer-rentals/north-dakota/rolla', lat: 48.8578, lng: -99.6151 },
  { name: 'Rugby, ND', path: '/trailer-rentals/north-dakota/rugby', lat: 48.3683, lng: -99.9962 },
  { name: 'Scranton, ND', path: '/trailer-rentals/north-dakota/scranton', lat: 46.1511, lng: -103.1445 },
  { name: 'Stanley, ND', path: '/trailer-rentals/north-dakota/stanley', lat: 48.3167, lng: -102.3884 },
  { name: 'Tioga, ND', path: '/trailer-rentals/north-dakota/tioga', lat: 48.3983, lng: -102.9395 },
  { name: 'Valley City, ND', path: '/trailer-rentals/north-dakota/valley-city', lat: 46.9233, lng: -98.0034 },
  { name: 'Velva, ND', path: '/trailer-rentals/north-dakota/velva', lat: 48.0578, lng: -100.9284 },
  { name: 'Wahpeton, ND', path: '/trailer-rentals/north-dakota/wahpeton', lat: 46.2653, lng: -96.6051 },
  { name: 'Walhalla, ND', path: '/trailer-rentals/north-dakota/walhalla', lat: 48.9225, lng: -97.9173 },
  { name: 'Washburn, ND', path: '/trailer-rentals/north-dakota/washburn', lat: 47.2892, lng: -101.0284 },
  { name: 'West Fargo, ND', path: '/trailer-rentals/north-dakota/west-fargo', lat: 46.8742, lng: -96.9001 },
  { name: 'Williston, ND', path: '/trailer-rentals/north-dakota/williston', lat: 48.1467, lng: -103.6179 },
  { name: 'Wishek, ND', path: '/trailer-rentals/north-dakota/wishek', lat: 46.2578, lng: -99.5562 },
  { name: 'Ashley, ND', path: '/trailer-rentals/north-dakota/ashley', lat: 46.0342, lng: -99.3712 },
  { name: 'Balta, ND', path: '/trailer-rentals/north-dakota/balta', lat: 48.3178, lng: -99.8445 },
  { name: 'Binford, ND', path: '/trailer-rentals/north-dakota/binford', lat: 47.5611, lng: -98.3451 },
  { name: 'Buffalo, ND', path: '/trailer-rentals/north-dakota/buffalo', lat: 46.9278, lng: -97.5512 },
  { name: 'Buxton, ND', path: '/trailer-rentals/north-dakota/buxton', lat: 47.6003, lng: -97.1012 },
  { name: 'Carpio, ND', path: '/trailer-rentals/north-dakota/carpio', lat: 48.4453, lng: -101.7112 },
  { name: 'Cando, ND', path: '/trailer-rentals/north-dakota/cando', lat: 48.4878, lng: -99.2051 },
  { name: 'Colfax, ND', path: '/trailer-rentals/north-dakota/colfax', lat: 46.4711, lng: -96.8751 },
  { name: 'Fairmount, ND', path: '/trailer-rentals/north-dakota/fairmount', lat: 46.0542, lng: -96.6023 },
  { name: 'Fordville, ND', path: '/trailer-rentals/north-dakota/fordville', lat: 48.2183, lng: -97.7962 },
  { name: 'Glen Ullin, ND', path: '/trailer-rentals/north-dakota/glen-ullin', lat: 46.8153, lng: -101.8295 },
  { name: 'Gwinner, ND', path: '/trailer-rentals/north-dakota/gwinner', lat: 46.2253, lng: -97.6601 },
  { name: 'Harvey, ND', path: '/trailer-rentals/north-dakota/harvey', lat: 47.7833, lng: -99.9335 },
  { name: 'LaMoure, ND', path: '/trailer-rentals/north-dakota/lamoure', lat: 46.3583, lng: -98.2934 },
  { name: 'Leeds, ND', path: '/trailer-rentals/north-dakota/leeds', lat: 48.2883, lng: -99.4384 },
  { name: 'Lidgerwood, ND', path: '/trailer-rentals/north-dakota/lidgerwood', lat: 46.0733, lng: -97.1451 },
  { name: 'Manvel, ND', path: '/trailer-rentals/north-dakota/manvel', lat: 48.0733, lng: -97.1773 },
  { name: 'Neche, ND', path: '/trailer-rentals/north-dakota/neche', lat: 48.9825, lng: -97.5523 },
  { name: 'Page, ND', path: '/trailer-rentals/north-dakota/page', lat: 47.1583, lng: -97.5734 },
  { name: 'Rolette, ND', path: '/trailer-rentals/north-dakota/rolette', lat: 48.6625, lng: -99.8423 },
  { name: 'Steele, ND', path: '/trailer-rentals/north-dakota/steele', lat: 46.8553, lng: -99.9173 },
  { name: 'Turtle Lake, ND', path: '/trailer-rentals/north-dakota/turtle-lake', lat: 47.5203, lng: -100.8912 },
  { name: 'Underwood, ND', path: '/trailer-rentals/north-dakota/underwood', lat: 47.4483, lng: -101.1384 },
];

export const oregonCities: ICity[] = [
  { name: 'Albany, OR', path: '/trailer-rentals/oregon/albany', lat: 44.6368, lng: -123.1059 },
  { name: 'Amity, OR', path: '/trailer-rentals/oregon/amity', lat: 45.1151, lng: -123.2073 },
  { name: 'Ashland, OR', path: '/trailer-rentals/oregon/ashland', lat: 42.1946, lng: -122.7095 },
  { name: 'Astoria, OR', path: '/trailer-rentals/oregon/astoria', lat: 46.1879, lng: -123.8313 },
  { name: 'Athena, OR', path: '/trailer-rentals/oregon/athena', lat: 45.8132, lng: -118.4877 },
  { name: 'Aumsville, OR', path: '/trailer-rentals/oregon/aumsville', lat: 44.8407, lng: -122.8679 },
  { name: 'Baker City, OR', path: '/trailer-rentals/oregon/baker-city', lat: 44.7749, lng: -117.8344 },
  { name: 'Bandon, OR', path: '/trailer-rentals/oregon/bandon', lat: 43.1181, lng: -124.4084 },
  { name: 'Beaverton, OR', path: '/trailer-rentals/oregon/beaverton', lat: 45.4871, lng: -122.8037 },
  { name: 'Bend, OR', path: '/trailer-rentals/oregon/bend', lat: 44.0582, lng: -121.3153 },
  { name: 'Boardman, OR', path: '/trailer-rentals/oregon/boardman', lat: 45.8393, lng: -119.7003 },
  { name: 'Brookings, OR', path: '/trailer-rentals/oregon/brookings', lat: 42.0526, lng: -124.2833 },
  { name: 'Burns, OR', path: '/trailer-rentals/oregon/burns', lat: 43.5865, lng: -119.0543 },
  { name: 'Canby, OR', path: '/trailer-rentals/oregon/canby', lat: 45.2621, lng: -122.6929 },
  { name: 'Cannon Beach, OR', path: '/trailer-rentals/oregon/cannon-beach', lat: 45.8918, lng: -123.9615 },
  { name: 'Carlton, OR', path: '/trailer-rentals/oregon/carlton', lat: 45.2943, lng: -123.1765 },
  { name: 'Central Point, OR', path: '/trailer-rentals/oregon/central-point', lat: 42.3751, lng: -122.9164 },
  { name: 'Clatskanie, OR', path: '/trailer-rentals/oregon/clatskanie', lat: 46.1015, lng: -123.2065 },
  { name: 'Coburg, OR', path: '/trailer-rentals/oregon/coburg', lat: 44.1387, lng: -123.0648 },
  { name: 'Columbia City, OR', path: '/trailer-rentals/oregon/columbia-city', lat: 45.8915, lng: -122.8126 },
  { name: 'Coos Bay, OR', path: '/trailer-rentals/oregon/coos-bay', lat: 43.3665, lng: -124.2179 },
  { name: 'Cornelius, OR', path: '/trailer-rentals/oregon/cornelius', lat: 45.5193, lng: -123.0576 },
  { name: 'Corvallis, OR', path: '/trailer-rentals/oregon/corvallis', lat: 44.5646, lng: -123.2620 },
  { name: 'Cottage Grove, OR', path: '/trailer-rentals/oregon/cottage-grove', lat: 43.7976, lng: -123.0595 },
  { name: 'Creswell, OR', path: '/trailer-rentals/oregon/creswell', lat: 43.9176, lng: -123.0226 },
  { name: 'Dallas, OR', path: '/trailer-rentals/oregon/dallas', lat: 44.9198, lng: -123.3162 },
  { name: 'Dayton, OR', path: '/trailer-rentals/oregon/dayton', lat: 45.2198, lng: -123.0754 },
  { name: 'Depoe Bay, OR', path: '/trailer-rentals/oregon/depoe-bay', lat: 44.8087, lng: -124.0601 },
  { name: 'Eagle Point, OR', path: '/trailer-rentals/oregon/eagle-point', lat: 42.4721, lng: -122.8028 },
  { name: 'Echo, OR', path: '/trailer-rentals/oregon/echo', lat: 45.7421, lng: -119.1956 },
  { name: 'Enterprise, OR', path: '/trailer-rentals/oregon/enterprise', lat: 45.4265, lng: -117.2787 },
  { name: 'Estacada, OR', path: '/trailer-rentals/oregon/estacada', lat: 45.2901, lng: -122.3345 },
  { name: 'Eugene, OR', path: '/trailer-rentals/oregon/eugene', lat: 44.0521, lng: -123.0868 },
  { name: 'Fairview, OR', path: '/trailer-rentals/oregon/fairview', lat: 45.5407, lng: -122.4295 },
  { name: 'Florence, OR', path: '/trailer-rentals/oregon/florence', lat: 43.9826, lng: -124.0998 },
  { name: 'Forest Grove, OR', path: '/trailer-rentals/oregon/forest-grove', lat: 45.5193, lng: -123.1107 },
  { name: 'Garibaldi, OR', path: '/trailer-rentals/oregon/garibaldi', lat: 45.5584, lng: -123.9115 },
  { name: 'Gearhart, OR', path: '/trailer-rentals/oregon/gearhart', lat: 46.0245, lng: -123.9215 },
  { name: 'Gladstone, OR', path: '/trailer-rentals/oregon/gladstone', lat: 45.3807, lng: -122.5945 },
  { name: 'Grants Pass, OR', path: '/trailer-rentals/oregon/grants-pass', lat: 42.4390, lng: -123.3284 },
  { name: 'Gresham, OR', path: '/trailer-rentals/oregon/gresham', lat: 45.5001, lng: -122.4302 },
  { name: 'Halsey, OR', path: '/trailer-rentals/oregon/halsey', lat: 44.3843, lng: -123.1095 },
  { name: 'Happy Valley, OR', path: '/trailer-rentals/oregon/happy-valley', lat: 45.4468, lng: -122.5309 },
  { name: 'Harrisburg, OR', path: '/trailer-rentals/oregon/harrisburg', lat: 44.2735, lng: -123.1704 },
  { name: 'Hermiston, OR', path: '/trailer-rentals/oregon/hermiston', lat: 45.8404, lng: -119.2895 },
  { name: 'Hillsboro, OR', path: '/trailer-rentals/oregon/hillsboro', lat: 45.5229, lng: -122.9898 },
  { name: 'Hood River, OR', path: '/trailer-rentals/oregon/hood-river', lat: 45.7054, lng: -121.5215 },
  { name: 'Independence, OR', path: '/trailer-rentals/oregon/independence', lat: 44.8518, lng: -123.1865 },
  { name: 'Jacksonville, OR', path: '/trailer-rentals/oregon/jacksonville', lat: 42.3135, lng: -122.9665 },
  { name: 'Jefferson, OR', path: '/trailer-rentals/oregon/jefferson', lat: 44.7171, lng: -123.0065 },
  { name: 'John Day, OR', path: '/trailer-rentals/oregon/john-day', lat: 44.4154, lng: -118.9537 },
  { name: 'Joseph, OR', path: '/trailer-rentals/oregon/joseph', lat: 45.3543, lng: -117.2295 },
  { name: 'Keizer, OR', path: '/trailer-rentals/oregon/keizer', lat: 44.9901, lng: -123.0262 },
  { name: 'Klamath Falls, OR', path: '/trailer-rentals/oregon/klamath-falls', lat: 42.2249, lng: -121.7817 },
  { name: 'La Grande, OR', path: '/trailer-rentals/oregon/la-grande', lat: 45.3245, lng: -118.0877 },
  { name: 'Lake Oswego, OR', path: '/trailer-rentals/oregon/lake-oswego', lat: 45.4207, lng: -122.6706 },
  { name: 'Lakeside, OR', path: '/trailer-rentals/oregon/lakeside', lat: 43.5783, lng: -124.1737 },
  { name: 'Lebanon, OR', path: '/trailer-rentals/oregon/lebanon', lat: 44.5368, lng: -122.9070 },
  { name: 'Lincoln City, OR', path: '/trailer-rentals/oregon/lincoln-city', lat: 44.9582, lng: -124.0179 },
  { name: 'Madras, OR', path: '/trailer-rentals/oregon/madras', lat: 44.6338, lng: -121.1295 },
  { name: 'Manzanita, OR', path: '/trailer-rentals/oregon/manzanita', lat: 45.7187, lng: -123.9357 },
  { name: 'McMinnville, OR', path: '/trailer-rentals/oregon/mcminnville', lat: 45.2101, lng: -123.1987 },
  { name: 'Medford, OR', path: '/trailer-rentals/oregon/medford', lat: 42.3265, lng: -122.8756 },
  { name: 'Milton-Freewater, OR', path: '/trailer-rentals/oregon/milton-freewater', lat: 45.9321, lng: -118.3877 },
  { name: 'Molalla, OR', path: '/trailer-rentals/oregon/molalla', lat: 45.1471, lng: -122.5779 },
  { name: 'Monmouth, OR', path: '/trailer-rentals/oregon/monmouth', lat: 44.8488, lng: -123.2326 },
  { name: 'Myrtle Creek, OR', path: '/trailer-rentals/oregon/myrtle-creek', lat: 43.0207, lng: -123.2837 },
  { name: 'Newberg, OR', path: '/trailer-rentals/oregon/newberg', lat: 45.3001, lng: -122.9737 },
  { name: 'Newport, OR', path: '/trailer-rentals/oregon/newport', lat: 44.6368, lng: -124.0535 },
  { name: 'North Bend, OR', path: '/trailer-rentals/oregon/north-bend', lat: 43.4068, lng: -124.2245 },
  { name: 'North Plains, OR', path: '/trailer-rentals/oregon/north-plains', lat: 45.5971, lng: -123.0576 },
  { name: 'Ontario, OR', path: '/trailer-rentals/oregon/ontario', lat: 44.0266, lng: -116.9629 },
  { name: 'Oregon City, OR', path: '/trailer-rentals/oregon/oregon-city', lat: 45.3573, lng: -122.6068 },
  { name: 'Pendleton, OR', path: '/trailer-rentals/oregon/pendleton', lat: 45.6721, lng: -118.7887 },
  { name: 'Philomath, OR', path: '/trailer-rentals/oregon/philomath', lat: 44.5407, lng: -123.3679 },
  { name: 'Phoenix, OR', path: '/trailer-rentals/oregon/phoenix', lat: 42.2751, lng: -122.8187 },
  { name: 'Port Orford, OR', path: '/trailer-rentals/oregon/port-orford', lat: 42.7468, lng: -124.4979 },
  { name: 'Portland, OR', path: '/trailer-rentals/oregon/portland', lat: 45.5051, lng: -122.6750 },
  { name: 'Prineville, OR', path: '/trailer-rentals/oregon/prineville', lat: 44.2998, lng: -120.8345 },
  { name: 'Rainier, OR', path: '/trailer-rentals/oregon/rainier', lat: 46.0898, lng: -122.9387 },
  { name: 'Redmond, OR', path: '/trailer-rentals/oregon/redmond', lat: 44.2726, lng: -121.1739 },
  { name: 'Reedsport, OR', path: '/trailer-rentals/oregon/reedsport', lat: 43.7026, lng: -124.0965 },
  { name: 'Rockaway Beach, OR', path: '/trailer-rentals/oregon/rockaway-beach', lat: 45.6118, lng: -123.9429 },
  { name: 'Roseburg, OR', path: '/trailer-rentals/oregon/roseburg', lat: 43.2165, lng: -123.3417 },
  { name: 'Sandy, OR', path: '/trailer-rentals/oregon/sandy', lat: 45.3971, lng: -122.2615 },
  { name: 'Scappoose, OR', path: '/trailer-rentals/oregon/scappoose', lat: 45.7548, lng: -122.8779 },
  { name: 'Seaside, OR', path: '/trailer-rentals/oregon/seaside', lat: 45.9938, lng: -123.9229 },
  { name: 'Sherwood, OR', path: '/trailer-rentals/oregon/sherwood', lat: 45.3568, lng: -122.8407 },
  { name: 'Silverton, OR', path: '/trailer-rentals/oregon/silverton', lat: 45.0051, lng: -122.7837 },
  { name: 'Springfield, OR', path: '/trailer-rentals/oregon/springfield', lat: 44.0462, lng: -123.0220 },
  { name: 'St. Helens, OR', path: '/trailer-rentals/oregon/st-helens', lat: 45.8598, lng: -122.8165 },
  { name: 'Stayton, OR', path: '/trailer-rentals/oregon/stayton', lat: 44.8007, lng: -122.7945 },
  { name: 'Sweet Home, OR', path: '/trailer-rentals/oregon/sweet-home', lat: 44.3971, lng: -122.7357 },
  { name: 'Talent, OR', path: '/trailer-rentals/oregon/talent', lat: 42.2451, lng: -122.7887 },
  { name: 'The Dalles, OR', path: '/trailer-rentals/oregon/the-dalles', lat: 45.5946, lng: -121.1787 },
  { name: 'Tigard, OR', path: '/trailer-rentals/oregon/tigard', lat: 45.4312, lng: -122.7715 },
  { name: 'Tillamook, OR', path: '/trailer-rentals/oregon/tillamook', lat: 45.4562, lng: -123.8427 },
  { name: 'Toledo, OR', path: '/trailer-rentals/oregon/toledo', lat: 44.6218, lng: -123.9387 },
  { name: 'Troutdale, OR', path: '/trailer-rentals/oregon/troutdale', lat: 45.5398, lng: -122.3879 },
  { name: 'Tualatin, OR', path: '/trailer-rentals/oregon/tualatin', lat: 45.3845, lng: -122.7637 },
  { name: 'Turner, OR', path: '/trailer-rentals/oregon/turner', lat: 44.8493, lng: -122.9534 },
  { name: 'Umatilla, OR', path: '/trailer-rentals/oregon/umatilla', lat: 45.9171, lng: -119.3428 },
  { name: 'Union, OR', path: '/trailer-rentals/oregon/union', lat: 45.2082, lng: -117.8657 },
  { name: 'Vale, OR', path: '/trailer-rentals/oregon/vale', lat: 43.9821, lng: -117.2437 },
  { name: 'Veneta, OR', path: '/trailer-rentals/oregon/veneta', lat: 44.0485, lng: -123.3512 },
  { name: 'Waldport, OR', path: '/trailer-rentals/oregon/waldport', lat: 44.4265, lng: -124.0679 },
  { name: 'Warrenton, OR', path: '/trailer-rentals/oregon/warrenton', lat: 46.1668, lng: -123.9243 },
  { name: 'West Linn, OR', path: '/trailer-rentals/oregon/west-linn', lat: 45.3654, lng: -122.6126 },
  { name: 'Weston, OR', path: '/trailer-rentals/oregon/weston', lat: 45.8151, lng: -118.4244 },
  { name: 'Wilsonville, OR', path: '/trailer-rentals/oregon/wilsonville', lat: 45.2991, lng: -122.7737 },
  { name: 'Woodburn, OR', path: '/trailer-rentals/oregon/woodburn', lat: 45.1434, lng: -122.8559 },
  { name: 'Yachats, OR', path: '/trailer-rentals/oregon/yachats', lat: 44.3118, lng: -124.1054 },
  { name: 'Yamhill, OR', path: '/trailer-rentals/oregon/yamhill', lat: 45.3387, lng: -123.1865 },
];

export const oklahomaCities:ICity[] = [
  { name: 'Ada, OK', path: '/trailer-rentals/oklahoma/ada', lat: 34.7745, lng: -96.6783 },
  { name: 'Alva, OK', path: '/trailer-rentals/oklahoma/alva', lat: 36.8056, lng: -98.6665 },
  { name: 'Anadarko, OK', path: '/trailer-rentals/oklahoma/anadarko', lat: 35.0728, lng: -98.2431 },
  { name: 'Antlers, OK', path: '/trailer-rentals/oklahoma/antlers', lat: 34.2312, lng: -95.6208 },
  { name: 'Ardmore, OK', path: '/trailer-rentals/oklahoma/ardmore', lat: 34.1743, lng: -97.1436 },
  { name: 'Atoka, OK', path: '/trailer-rentals/oklahoma/atoka', lat: 34.3856, lng: -96.1283 },
  { name: 'Bartlesville, OK', path: '/trailer-rentals/oklahoma/bartlesville', lat: 36.7473, lng: -95.9808 },
  { name: 'Bethany, OK', path: '/trailer-rentals/oklahoma/bethany', lat: 35.5184, lng: -97.6325 },
  { name: 'Bixby, OK', path: '/trailer-rentals/oklahoma/bixby', lat: 35.9420, lng: -95.8830 },
  { name: 'Blackwell, OK', path: '/trailer-rentals/oklahoma/blackwell', lat: 36.8042, lng: -97.2828 },
  { name: 'Blanchard, OK', path: '/trailer-rentals/oklahoma/blanchard', lat: 35.1373, lng: -97.6581 },
  { name: 'Broken Arrow, OK', path: '/trailer-rentals/oklahoma/broken-arrow', lat: 36.0609, lng: -95.7975 },
  { name: 'Cache, OK', path: '/trailer-rentals/oklahoma/cache', lat: 34.6298, lng: -98.6287 },
  { name: 'Catoosa, OK', path: '/trailer-rentals/oklahoma/catoosa', lat: 36.1884, lng: -95.7455 },
  { name: 'Chandler, OK', path: '/trailer-rentals/oklahoma/chandler', lat: 35.7012, lng: -96.8803 },
  { name: 'Checotah, OK', path: '/trailer-rentals/oklahoma/checotah', lat: 35.4701, lng: -95.5233 },
  { name: 'Chickasha, OK', path: '/trailer-rentals/oklahoma/chickasha', lat: 35.0526, lng: -97.9364 },
  { name: 'Chelsea, OK', path: '/trailer-rentals/oklahoma/chelsea', lat: 36.535648, lng: -95.432472, active: true, showNewVersion: true },
  { name: 'Choctaw, OK', path: '/trailer-rentals/oklahoma/choctaw', lat: 35.4973, lng: -97.2686 },
  { name: 'Claremore, OK', path: '/trailer-rentals/oklahoma/claremore', lat: 36.3126, lng: -95.6161 },
  { name: 'Cleveland, OK', path: '/trailer-rentals/oklahoma/cleveland', lat: 36.3112, lng: -96.4645 },
  { name: 'Clinton, OK', path: '/trailer-rentals/oklahoma/clinton', lat: 35.5156, lng: -98.9673 },
  { name: 'Coalgate, OK', path: '/trailer-rentals/oklahoma/coalgate', lat: 34.5334, lng: -96.2178 },
  { name: 'Collinsville, OK', path: '/trailer-rentals/oklahoma/collinsville', lat: 36.3645, lng: -95.8378 },
  { name: 'Comanche, OK', path: '/trailer-rentals/oklahoma/comanche', lat: 34.3623, lng: -97.9681 },
  { name: 'Cordell, OK', path: '/trailer-rentals/oklahoma/cordell', lat: 35.2973, lng: -98.9895 },
  { name: 'Coweta, OK', path: '/trailer-rentals/oklahoma/coweta', lat: 35.9512, lng: -95.6508 },
  { name: 'Cushing, OK', path: '/trailer-rentals/oklahoma/cushing', lat: 35.9851, lng: -96.7667 },
  { name: 'Del City, OK', path: '/trailer-rentals/oklahoma/del-city', lat: 35.4420, lng: -97.4409 },
  { name: 'Dewey, OK', path: '/trailer-rentals/oklahoma/dewey', lat: 36.7959, lng: -95.9355 },
  { name: 'Drumright, OK', path: '/trailer-rentals/oklahoma/drumright', lat: 36.0892, lng: -96.6017 },
  { name: 'Duncan, OK', path: '/trailer-rentals/oklahoma/duncan', lat: 34.5023, lng: -97.9578 },
  { name: 'Durant, OK', path: '/trailer-rentals/oklahoma/durant', lat: 33.9937, lng: -96.3700 },
  { name: 'Edmond, OK', path: '/trailer-rentals/oklahoma/edmond', lat: 35.6528, lng: -97.4781 },
  { name: 'Elk City, OK', path: '/trailer-rentals/oklahoma/elk-city', lat: 35.4114, lng: -99.4045 },
  { name: 'El Reno, OK', path: '/trailer-rentals/oklahoma/el-reno', lat: 35.5323, lng: -97.9550 },
  { name: 'Enid, OK', path: '/trailer-rentals/oklahoma/enid', lat: 36.3956, lng: -97.8784 },
  { name: 'Eufaula, OK', path: '/trailer-rentals/oklahoma/eufaula', lat: 35.2887, lng: -95.5825 },
  { name: 'Fairview, OK', path: '/trailer-rentals/oklahoma/fairview', lat: 36.2695, lng: -98.4787 },
  { name: 'Fort Gibson, OK', path: '/trailer-rentals/oklahoma/fort-gibson', lat: 35.7987, lng: -95.2508 },
  { name: 'Frederick, OK', path: '/trailer-rentals/oklahoma/frederick', lat: 34.3923, lng: -99.0187 },
  { name: 'Geary, OK', path: '/trailer-rentals/oklahoma/geary', lat: 35.6287, lng: -98.3178 },
  { name: 'Glenpool, OK', path: '/trailer-rentals/oklahoma/glenpool', lat: 35.9551, lng: -96.0084 },
  { name: 'Goodwell, OK', path: '/trailer-rentals/oklahoma/goodwell', lat: 36.5956, lng: -101.6362 },
  { name: 'Grove, OK', path: '/trailer-rentals/oklahoma/grove', lat: 36.5934, lng: -94.7694 },
  { name: 'Guthrie, OK', path: '/trailer-rentals/oklahoma/guthrie', lat: 35.8781, lng: -97.4253 },
  { name: 'Guymon, OK', path: '/trailer-rentals/oklahoma/guymon', lat: 36.6828, lng: -101.4815 },
  { name: 'Harrah, OK', path: '/trailer-rentals/oklahoma/harrah', lat: 35.4851, lng: -97.1639 },
  { name: 'Heavener, OK', path: '/trailer-rentals/oklahoma/heavener', lat: 34.8884, lng: -94.5983 },
  { name: 'Henryetta, OK', path: '/trailer-rentals/oklahoma/henryetta', lat: 35.4395, lng: -95.9808 },
  { name: 'Holdenville, OK', path: '/trailer-rentals/oklahoma/holdenville', lat: 35.0806, lng: -96.3992 },
  { name: 'Hominy, OK', path: '/trailer-rentals/oklahoma/hominy', lat: 36.4156, lng: -96.3953 },
  { name: 'Hugo, OK', path: '/trailer-rentals/oklahoma/hugo', lat: 34.0101, lng: -95.5125 },
  { name: 'Idabel, OK', path: '/trailer-rentals/oklahoma/idabel', lat: 33.8951, lng: -94.8266 },
  { name: 'Jenks, OK', path: '/trailer-rentals/oklahoma/jenks', lat: 36.0229, lng: -95.9683 },
  { name: 'Jones, OK', path: '/trailer-rentals/oklahoma/jones', lat: 35.5651, lng: -97.2864 },
  { name: 'Kingfisher, OK', path: '/trailer-rentals/oklahoma/kingfisher', lat: 35.8612, lng: -97.9314 },
  { name: 'Kingston, OK', path: '/trailer-rentals/oklahoma/kingston', lat: 34.1912, lng: -96.7192 },
  { name: 'Lawton, OK', path: '/trailer-rentals/oklahoma/lawton', lat: 34.6036, lng: -98.3959 },
  { name: 'Lindsay, OK', path: '/trailer-rentals/oklahoma/lindsay', lat: 34.8362, lng: -97.6028 },
  { name: 'Lone Grove, OK', path: '/trailer-rentals/oklahoma/lone-grove', lat: 34.1748, lng: -97.2628 },
  { name: 'Madill, OK', path: '/trailer-rentals/oklahoma/madill', lat: 34.0906, lng: -96.7708 },
  { name: 'Mangum, OK', path: '/trailer-rentals/oklahoma/mangum', lat: 34.8723, lng: -99.5048 },
  { name: 'Mannford, OK', path: '/trailer-rentals/oklahoma/mannford', lat: 36.1334, lng: -96.3542 },
  { name: 'Marietta, OK', path: '/trailer-rentals/oklahoma/marietta', lat: 33.9373, lng: -97.1153 },
  { name: 'Marlow, OK', path: '/trailer-rentals/oklahoma/marlow', lat: 34.6434, lng: -97.9587 },
  { name: 'McAlester, OK', path: '/trailer-rentals/oklahoma/mcalester', lat: 34.9334, lng: -95.7697 },
  { name: 'Miami, OK', path: '/trailer-rentals/oklahoma/miami', lat: 36.8745, lng: -94.8775 },
  { name: 'Midwest City, OK', path: '/trailer-rentals/oklahoma/midwest-city', lat: 35.4495, lng: -97.3967 },
  { name: 'Moore, OK', path: '/trailer-rentals/oklahoma/moore', lat: 35.3395, lng: -97.4867 },
  { name: 'Muldrow, OK', path: '/trailer-rentals/oklahoma/muldrow', lat: 35.4062, lng: -94.5983 },
  { name: 'Muskogee, OK', path: '/trailer-rentals/oklahoma/muskogee', lat: 35.7479, lng: -95.3697 },
  { name: 'Mustang, OK', path: '/trailer-rentals/oklahoma/mustang', lat: 35.3845, lng: -97.7245 },
  { name: 'Newcastle, OK', path: '/trailer-rentals/oklahoma/newcastle', lat: 35.2473, lng: -97.5992 },
  { name: 'Newkirk, OK', path: '/trailer-rentals/oklahoma/newkirk', lat: 36.8828, lng: -97.0534 },
  { name: 'Nichols Hills, OK', path: '/trailer-rentals/oklahoma/nichols-hills', lat: 35.5445, lng: -97.5484 },
  { name: 'Nicoma Park, OK', path: '/trailer-rentals/oklahoma/nicoma-park', lat: 35.4934, lng: -97.3278 },
  { name: 'Noble, OK', path: '/trailer-rentals/oklahoma/noble', lat: 35.1395, lng: -97.3942 },
  { name: 'Norman, OK', path: '/trailer-rentals/oklahoma/norman', lat: 35.2226, lng: -97.4395 },
  { name: 'Nowata, OK', path: '/trailer-rentals/oklahoma/nowata', lat: 36.7987, lng: -95.6384 },
  { name: 'Oilton, OK', path: '/trailer-rentals/oklahoma/oilton', lat: 36.0845, lng: -96.5842 },
  { name: 'Okemah, OK', path: '/trailer-rentals/oklahoma/okemah', lat: 35.4323, lng: -96.3053 },
  { name: 'Oklahoma City, OK', path: '/trailer-rentals/oklahoma/oklahoma-city', lat: 35.4676, lng: -97.5164, active: true, showNewVersion: true  },
  { name: 'Okmulgee, OK', path: '/trailer-rentals/oklahoma/okmulgee', lat: 35.6234, lng: -95.9511 },
  { name: 'Owasso, OK', path: '/trailer-rentals/oklahoma/owasso', lat: 36.2884, lng: -95.8198 },
  { name: 'Pawhuska, OK', path: '/trailer-rentals/oklahoma/pawhuska', lat: 36.6678, lng: -96.3378 },
  { name: 'Pauls Valley, OK', path: '/trailer-rentals/oklahoma/pauls-valley', lat: 34.7401, lng: -97.2228 },
  { name: 'Pawnee, OK', path: '/trailer-rentals/oklahoma/pawnee', lat: 36.3384, lng: -96.8034 },
  { name: 'Perkins, OK', path: '/trailer-rentals/oklahoma/perkins', lat: 35.9723, lng: -97.0334 },
  { name: 'Perry, OK', path: '/trailer-rentals/oklahoma/perry', lat: 36.2895, lng: -97.2884 },
  { name: 'Piedmont, OK', path: '/trailer-rentals/oklahoma/piedmont', lat: 35.6412, lng: -97.7467 },
  { name: 'Ponca City, OK', path: '/trailer-rentals/oklahoma/ponca-city', lat: 36.7060, lng: -97.0856 },
  { name: 'Poteau, OK', path: '/trailer-rentals/oklahoma/poteau', lat: 35.0534, lng: -94.6233 },
  { name: 'Pryor Creek, OK', path: '/trailer-rentals/oklahoma/pryor-creek', lat: 36.3084, lng: -95.3161 },
  { name: 'Purcell, OK', path: '/trailer-rentals/oklahoma/purcell', lat: 35.0134, lng: -97.3617 },
  { name: 'Quinton, OK', path: '/trailer-rentals/oklahoma/quinton', lat: 35.1212, lng: -95.3808 },
  { name: 'Sallisaw, OK', path: '/trailer-rentals/oklahoma/sallisaw', lat: 35.4601, lng: -94.7878 },
  { name: 'Sand Springs, OK', path: '/trailer-rentals/oklahoma/sand-springs', lat: 36.1398, lng: -96.1089 },
  { name: 'Sapulpa, OK', path: '/trailer-rentals/oklahoma/sapulpa', lat: 35.9987, lng: -96.1142 },
  { name: 'Sayre, OK', path: '/trailer-rentals/oklahoma/sayre', lat: 35.2909, lng: -99.6345 },
  { name: 'Seminole, OK', path: '/trailer-rentals/oklahoma/seminole', lat: 35.2245, lng: -96.6706 },
  { name: 'Shawnee, OK', path: '/trailer-rentals/oklahoma/shawnee', lat: 35.3273, lng: -96.9253 },
  { name: 'Skiatook, OK', path: '/trailer-rentals/oklahoma/skiatook', lat: 36.3687, lng: -96.0011 },
  { name: 'Slaughterville, OK', path: '/trailer-rentals/oklahoma/slaughterville', lat: 35.0973, lng: -97.3261 },
  { name: 'Spencer, OK', path: '/trailer-rentals/oklahoma/spencer', lat: 35.5223, lng: -97.3700 },
  { name: 'Sperry, OK', path: '/trailer-rentals/oklahoma/sperry', lat: 36.2976, lng: -95.9908 },
  { name: 'Stillwater, OK', path: '/trailer-rentals/oklahoma/stillwater', lat: 36.1156, lng: -97.0584 },
  { name: 'Stilwell, OK', path: '/trailer-rentals/oklahoma/stilwell', lat: 35.8145, lng: -94.6283 },
  { name: 'Stroud, OK', path: '/trailer-rentals/oklahoma/stroud', lat: 35.7487, lng: -96.6586 },
  { name: 'Sulphur, OK', path: '/trailer-rentals/oklahoma/sulphur', lat: 34.5070, lng: -96.9686 },
  { name: 'Tahlequah, OK', path: '/trailer-rentals/oklahoma/tahlequah', lat: 35.9154, lng: -94.9690 },
  { name: 'Tecumseh, OK', path: '/trailer-rentals/oklahoma/tecumseh', lat: 35.2573, lng: -96.9361 },
  { name: 'The Village, OK', path: '/trailer-rentals/oklahoma/the-village', lat: 35.5601, lng: -97.5523 },
  { name: 'Thomas, OK', path: '/trailer-rentals/oklahoma/thomas', lat: 35.7464, lng: -98.7487 },
  { name: 'Tishomingo, OK', path: '/trailer-rentals/oklahoma/tishomingo', lat: 34.2362, lng: -96.6786 },
  { name: 'Tonkawa, OK', path: '/trailer-rentals/oklahoma/tonkawa', lat: 36.6784, lng: -97.3106 },
  { name: 'Tulsa, OK', path: '/trailer-rentals/oklahoma/tulsa', lat: 36.1539, lng: -95.9928 },
  { name: 'Tuttle, OK', path: '/trailer-rentals/oklahoma/tuttle', lat: 35.2917, lng: -97.8128 },
  { name: 'Union City, OK', path: '/trailer-rentals/oklahoma/union-city', lat: 35.4067, lng: -97.9103 },
  { name: 'Verdigris, OK', path: '/trailer-rentals/oklahoma/verdigris', lat: 36.2426, lng: -95.6922 },
  { name: 'Vinita, OK', path: '/trailer-rentals/oklahoma/vinita', lat: 36.6384, lng: -95.1547 },
  { name: 'Wagoner, OK', path: '/trailer-rentals/oklahoma/wagoner', lat: 35.9595, lng: -95.3697 },
  { name: 'Walters, OK', path: '/trailer-rentals/oklahoma/walters', lat: 34.3598, lng: -98.3073 },
  { name: 'Warr Acres, OK', path: '/trailer-rentals/oklahoma/warr-acres', lat: 35.5223, lng: -97.6181 },
  { name: 'Watonga, OK', path: '/trailer-rentals/oklahoma/watonga', lat: 35.8445, lng: -98.4112 },
  { name: 'Weatherford, OK', path: '/trailer-rentals/oklahoma/weatherford', lat: 35.5267, lng: -98.7073 },
  { name: 'Wewoka, OK', path: '/trailer-rentals/oklahoma/wewoka', lat: 35.1584, lng: -96.4936 },
  { name: 'Wilburton, OK', path: '/trailer-rentals/oklahoma/wilburton', lat: 34.9184, lng: -95.3097 },
  { name: 'Woodward, OK', path: '/trailer-rentals/oklahoma/woodward', lat: 36.4334, lng: -99.3904 },
  { name: 'Yale, OK', path: '/trailer-rentals/oklahoma/yale', lat: 36.1145, lng: -96.6978 },
  { name: 'Yukon, OK', path: '/trailer-rentals/oklahoma/yukon', lat: 35.5067, lng: -97.7625, active: true, showNewVersion: true  },
];

export const westernPennsylvaniaCities:ICity [] = [
  { name: 'Aliquippa, PA', path: '/trailer-rentals/pennsylvania/aliquippa', lat: 40.6151, lng: -80.2545 },
  { name: 'Ambridge, PA', path: '/trailer-rentals/pennsylvania/ambridge', lat: 40.5892, lng: -80.2253 },
  { name: 'Beaver Falls, PA', path: '/trailer-rentals/pennsylvania/beaver-falls', lat: 40.7528, lng: -80.3195 },
  { name: 'Beaver, PA', path: '/trailer-rentals/pennsylvania/beaver', lat: 40.6953, lng: -80.3045 },
  { name: 'Butler, PA', path: '/trailer-rentals/pennsylvania/butler', lat: 40.8612, lng: -79.8953 },
  { name: 'Clairton, PA', path: '/trailer-rentals/pennsylvania/clairton', lat: 40.292, lng: -79.8878 },
  { name: 'Clarion, PA', path: '/trailer-rentals/pennsylvania/clarion', lat: 41.214, lng: -79.3853 },
  { name: 'Connellsville, PA', path: '/trailer-rentals/pennsylvania/connellsville', lat: 40.0167, lng: -79.5895 },
  { name: 'Corry, PA', path: '/trailer-rentals/pennsylvania/corry', lat: 41.9248, lng: -79.6406 },
  { name: 'Crafton, PA', path: '/trailer-rentals/pennsylvania/crafton', lat: 40.4342, lng: -80.0673 },
  { name: 'Cranberry Township, PA', path: '/trailer-rentals/pennsylvania/cranberry-township', lat: 40.6845, lng: -80.107 },
  { name: 'Derry, PA', path: '/trailer-rentals/pennsylvania/derry', lat: 40.3337, lng: -79.3014 },
  { name: 'Edinboro, PA', path: '/trailer-rentals/pennsylvania/edinboro', lat: 41.8753, lng: -80.1314 },
  { name: 'Elizabeth, PA', path: '/trailer-rentals/pennsylvania/elizabeth', lat: 40.2715, lng: -79.8895 },
  { name: 'Ellwood City, PA', path: '/trailer-rentals/pennsylvania/ellwood-city', lat: 40.8617, lng: -80.2867 },
  { name: 'Erie, PA', path: '/trailer-rentals/pennsylvania/erie', lat: 42.1292, lng: -80.0851 },
  { name: 'Franklin, PA', path: '/trailer-rentals/pennsylvania/franklin', lat: 41.3987, lng: -79.8314 },
  { name: 'Greensburg, PA', path: '/trailer-rentals/pennsylvania/greensburg', lat: 40.3015, lng: -79.5389 },
  { name: 'Grove City, PA', path: '/trailer-rentals/pennsylvania/grove-city', lat: 41.1573, lng: -80.0884 },
  { name: 'Hermitage, PA', path: '/trailer-rentals/pennsylvania/hermitage', lat: 41.2337, lng: -80.4487 },
  { name: 'Indiana, PA', path: '/trailer-rentals/pennsylvania/indiana', lat: 40.6215, lng: -79.1525 },
  { name: 'Jeannette, PA', path: '/trailer-rentals/pennsylvania/jeannette', lat: 40.3281, lng: -79.6153 },
  { name: 'Kittanning, PA', path: '/trailer-rentals/pennsylvania/kittanning', lat: 40.8201, lng: -79.5214 },
  { name: 'Latrobe, PA', path: '/trailer-rentals/pennsylvania/latrobe', lat: 40.3212, lng: -79.3795 },
  { name: 'Leechburg, PA', path: '/trailer-rentals/pennsylvania/leechburg', lat: 40.6281, lng: -79.6031 },
  { name: 'Meadville, PA', path: '/trailer-rentals/pennsylvania/meadville', lat: 41.6414, lng: -80.1514 },
  { name: 'Monaca, PA', path: '/trailer-rentals/pennsylvania/monaca', lat: 40.6873, lng: -80.2717 },
  { name: 'Monessen, PA', path: '/trailer-rentals/pennsylvania/monessen', lat: 40.1515, lng: -79.8823 },
  { name: 'Monongahela, PA', path: '/trailer-rentals/pennsylvania/monongahela', lat: 40.2001, lng: -79.9267 },
  { name: 'New Castle, PA', path: '/trailer-rentals/pennsylvania/new-castle', lat: 41.0037, lng: -80.347 },
  { name: 'New Kensington, PA', path: '/trailer-rentals/pennsylvania/new-kensington', lat: 40.5695, lng: -79.7642 },
  { name: 'North East, PA', path: '/trailer-rentals/pennsylvania/north-east', lat: 42.2134, lng: -79.8348 },
  { name: 'Oil City, PA', path: '/trailer-rentals/pennsylvania/oil-city', lat: 41.4337, lng: -79.7067 },
  { name: 'Pittsburgh, PA', path: '/trailer-rentals/pennsylvania/pittsburgh', lat: 40.4406, lng: -79.9959 },
  { name: 'Sharon, PA', path: '/trailer-rentals/pennsylvania/sharon', lat: 41.2337, lng: -80.4934 },
  { name: 'Somerset, PA', path: '/trailer-rentals/pennsylvania/somerset', lat: 40.0087, lng: -79.0806 },
  { name: 'Tionesta, PA', path: '/trailer-rentals/pennsylvania/tionesta', lat: 41.4951, lng: -79.4534 },
  { name: 'Titusville, PA', path: '/trailer-rentals/pennsylvania/titusville', lat: 41.6267, lng: -79.6745 },
  { name: 'Uniontown, PA', path: '/trailer-rentals/pennsylvania/uniontown', lat: 39.9001, lng: -79.7164 },
  { name: 'Warren, PA', path: '/trailer-rentals/pennsylvania/warren', lat: 41.8431, lng: -79.145 },
  { name: 'Washington, PA', path: '/trailer-rentals/pennsylvania/washington', lat: 40.1739, lng: -80.2462 },
  { name: 'Waterford, PA', path: '/trailer-rentals/pennsylvania/waterford', lat: 41.9395, lng: -79.9848 },
  { name: 'Waynesburg, PA', path: '/trailer-rentals/pennsylvania/waynesburg', lat: 39.8967, lng: -80.1795 },
  { name: 'West Mifflin, PA', path: '/trailer-rentals/pennsylvania/west-mifflin', lat: 40.3634, lng: -79.8664 }
];

export const centralPennsylvaniaCities:ICity [] = [
  { name: 'Altoona, PA', path: '/trailer-rentals/pennsylvania/altoona', lat: 40.5187, lng: -78.3947 },
  { name: 'Bellefonte, PA', path: '/trailer-rentals/pennsylvania/bellefonte', lat: 40.9134, lng: -77.7789 },
  { name: 'Carlisle, PA', path: '/trailer-rentals/pennsylvania/carlisle', lat: 40.201, lng: -77.2003 },
  { name: 'Chambersburg, PA', path: '/trailer-rentals/pennsylvania/chambersburg', lat: 39.9376, lng: -77.6611 },
  { name: 'Columbia, PA', path: '/trailer-rentals/pennsylvania/columbia', lat: 40.0337, lng: -76.5041 },
  { name: 'Danville, PA', path: '/trailer-rentals/pennsylvania/danville', lat: 40.9634, lng: -76.6127 },
  { name: 'Ebensburg, PA', path: '/trailer-rentals/pennsylvania/ebensburg', lat: 40.4856, lng: -78.7245 },
  { name: 'Elizabethtown, PA', path: '/trailer-rentals/pennsylvania/elizabethtown', lat: 40.1529, lng: -76.6027 },
  { name: 'Gettysburg, PA', path: '/trailer-rentals/pennsylvania/gettysburg', lat: 39.8309, lng: -77.2311 },
  { name: 'Hanover, PA', path: '/trailer-rentals/pennsylvania/hanover', lat: 39.8007, lng: -76.983 },
  { name: 'Harrisburg, PA', path: '/trailer-rentals/pennsylvania/harrisburg', lat: 40.2732, lng: -76.8867 },
  { name: 'Hollidaysburg, PA', path: '/trailer-rentals/pennsylvania/hollidaysburg', lat: 40.427, lng: -78.3886 },
  { name: 'Johnstown, PA', path: '/trailer-rentals/pennsylvania/johnstown', lat: 40.3267, lng: -78.9219 },
  { name: 'Lewisburg, PA', path: '/trailer-rentals/pennsylvania/lewisburg', lat: 40.9645, lng: -76.8847 },
  { name: 'Lewistown, PA', path: '/trailer-rentals/pennsylvania/lewistown', lat: 40.5995, lng: -77.5711 },
  { name: 'Mechanicsburg, PA', path: '/trailer-rentals/pennsylvania/mechanicsburg', lat: 40.2143, lng: -77.0086 },
  { name: 'Middletown, PA', path: '/trailer-rentals/pennsylvania/middletown', lat: 40.1998, lng: -76.7311 },
  { name: 'Northumberland, PA', path: '/trailer-rentals/pennsylvania/northumberland', lat: 40.8915, lng: -76.7977 },
  { name: 'Palmyra, PA', path: '/trailer-rentals/pennsylvania/palmyra', lat: 40.3087, lng: -76.5933 },
  { name: 'Punxsutawney, PA', path: '/trailer-rentals/pennsylvania/punxsutawney', lat: 40.9434, lng: -78.9706 },
  { name: 'Red Lion, PA', path: '/trailer-rentals/pennsylvania/red-lion', lat: 39.9001, lng: -76.6058 },
  { name: 'Selinsgrove, PA', path: '/trailer-rentals/pennsylvania/selinsgrove', lat: 40.7984, lng: -76.8625 },
  { name: 'Shamokin, PA', path: '/trailer-rentals/pennsylvania/shamokin', lat: 40.7884, lng: -76.5583 },
  { name: 'State College, PA', path: '/trailer-rentals/pennsylvania/state-college', lat: 40.7934, lng: -77.86 },
  { name: 'Sunbury, PA', path: '/trailer-rentals/pennsylvania/sunbury', lat: 40.8626, lng: -76.7944 },
  { name: 'York, PA', path: '/trailer-rentals/pennsylvania/york', lat: 39.9626, lng: -76.7277 }
];

export const easternPennsylvaniaCities:ICity [] = [
  { name: 'Abington, PA', path: '/trailer-rentals/pennsylvania/abington', lat: 40.1204, lng: -75.118 },
  { name: 'Allentown, PA', path: '/trailer-rentals/pennsylvania/allentown', lat: 40.6084, lng: -75.4902 },
  { name: 'Ardmore, PA', path: '/trailer-rentals/pennsylvania/ardmore', lat: 40.0065, lng: -75.2848 },
  { name: 'Aston, PA', path: '/trailer-rentals/pennsylvania/aston', lat: 39.8626, lng: -75.4238 },
  { name: 'Bethlehem, PA', path: '/trailer-rentals/pennsylvania/bethlehem', lat: 40.6259, lng: -75.3705 },
  { name: 'Bristol, PA', path: '/trailer-rentals/pennsylvania/bristol', lat: 40.1007, lng: -74.8513 },
  { name: 'Chester, PA', path: '/trailer-rentals/pennsylvania/chester', lat: 39.8496, lng: -75.3557 },
  { name: 'Coatesville, PA', path: '/trailer-rentals/pennsylvania/coatesville', lat: 39.9837, lng: -75.8233 },
  { name: 'Darby, PA', path: '/trailer-rentals/pennsylvania/darby', lat: 39.9184, lng: -75.2582 },
  { name: 'Downingtown, PA', path: '/trailer-rentals/pennsylvania/downingtown', lat: 40.0065, lng: -75.7033 },
  { name: 'Doylestown, PA', path: '/trailer-rentals/pennsylvania/doylestown', lat: 40.3101, lng: -75.1305 },
  { name: 'East Stroudsburg, PA', path: '/trailer-rentals/pennsylvania/east-stroudsburg', lat: 40.9995, lng: -75.1813 },
  { name: 'Easton, PA', path: '/trailer-rentals/pennsylvania/easton', lat: 40.6884, lng: -75.2207 },
  { name: 'Ephrata, PA', path: '/trailer-rentals/pennsylvania/ephrata', lat: 40.1798, lng: -76.178 },
  { name: 'Garnet Valley, PA', path: '/trailer-rentals/pennsylvania/garnet-valley', lat: 39.8007, lng: -75.3513, active: true, showNewVersion: true },
  { name: 'Hazleton, PA', path: '/trailer-rentals/pennsylvania/hazleton', lat: 40.9584, lng: -75.9747 },
  { name: 'Jim Thorpe, PA', path: '/trailer-rentals/pennsylvania/jim-thorpe', lat: 40.8756, lng: -75.7324 },
  { name: 'Kutztown, PA', path: '/trailer-rentals/pennsylvania/kutztown', lat: 40.5173, lng: -75.7777 },
  { name: 'Lansdale, PA', path: '/trailer-rentals/pennsylvania/lansdale', lat: 40.2415, lng: -75.2838 },
  { name: 'Lebanon, PA', path: '/trailer-rentals/pennsylvania/lebanon', lat: 40.3409, lng: -76.4113 },
  { name: 'Lititz, PA', path: '/trailer-rentals/pennsylvania/lititz', lat: 40.1573, lng: -76.3077 },
  { name: 'Malvern, PA', path: '/trailer-rentals/pennsylvania/malvern', lat: 40.0362, lng: -75.513 },
  { name: 'Montgomeryville, PA', path: '/trailer-rentals/pennsylvania/montgomeryville', lat: 40.2473, lng: -75.2477 },
  { name: 'Norristown, PA', path: '/trailer-rentals/pennsylvania/norristown', lat: 40.1215, lng: -75.3399 },
  { name: 'Oxford, PA', path: '/trailer-rentals/pennsylvania/oxford', lat: 39.7851, lng: -75.9783 },
  { name: 'Philadelphia, PA', path: '/trailer-rentals/pennsylvania/philadelphia', lat: 39.9526, lng: -75.1652, active: true, showNewVersion: true },
  { name: 'Phoenixville, PA', path: '/trailer-rentals/pennsylvania/phoenixville', lat: 40.1304, lng: -75.5141 },
  { name: 'Pottstown, PA', path: '/trailer-rentals/pennsylvania/pottstown', lat: 40.2454, lng: -75.6496 },
  { name: 'Pottsville, PA', path: '/trailer-rentals/pennsylvania/pottsville', lat: 40.6856, lng: -76.1955 },
  { name: 'Quakertown, PA', path: '/trailer-rentals/pennsylvania/quakertown', lat: 40.4418, lng: -75.3413 },
  { name: 'Reading, PA', path: '/trailer-rentals/pennsylvania/reading', lat: 40.3356, lng: -75.9269 },
  { name: 'Stroudsburg, PA', path: '/trailer-rentals/pennsylvania/stroudsburg', lat: 40.9865, lng: -75.1946 },
  { name: 'Swarthmore, PA', path: '/trailer-rentals/pennsylvania/swarthmore', lat: 39.9026, lng: -75.3513 },
  { name: 'West Chester, PA', path: '/trailer-rentals/pennsylvania/west-chester', lat: 39.9607, lng: -75.6055 },
  { name: 'Wyomissing, PA', path: '/trailer-rentals/pennsylvania/wyomissing', lat: 40.3295, lng: -75.9658 }
];

export const northeasternPennsylvaniaCities:ICity [] = [
  { name: 'Archbald, PA', path: '/trailer-rentals/pennsylvania/archbald', lat: 41.4948, lng: -75.5366 },
  { name: 'Athens, PA', path: '/trailer-rentals/pennsylvania/athens', lat: 41.9576, lng: -76.5183 },
  { name: 'Bloomsburg, PA', path: '/trailer-rentals/pennsylvania/bloomsburg', lat: 41.0037, lng: -76.4541 },
  { name: 'Bradford, PA', path: '/trailer-rentals/pennsylvania/bradford', lat: 41.9551, lng: -78.6436 },
  { name: 'Carbondale, PA', path: '/trailer-rentals/pennsylvania/carbondale', lat: 41.5737, lng: -75.5016 },
  { name: 'Clearfield, PA', path: '/trailer-rentals/pennsylvania/clearfield', lat: 41.0278, lng: -78.4395 },
  { name: 'Coudersport, PA', path: '/trailer-rentals/pennsylvania/coudersport', lat: 41.7748, lng: -78.0208 },
  { name: 'Dickson City, PA', path: '/trailer-rentals/pennsylvania/dickson-city', lat: 41.4667, lng: -75.6271 },
  { name: 'DuBois, PA', path: '/trailer-rentals/pennsylvania/dubois', lat: 41.1195, lng: -78.7603 },
  { name: 'Dunmore, PA', path: '/trailer-rentals/pennsylvania/dunmore', lat: 41.4198, lng: -75.6321 },
  { name: 'Lock Haven, PA', path: '/trailer-rentals/pennsylvania/lock-haven', lat: 41.137, lng: -77.4469 },
  { name: 'Muncy, PA', path: '/trailer-rentals/pennsylvania/muncy', lat: 41.2056, lng: -76.7852 },
  { name: 'Nanticoke, PA', path: '/trailer-rentals/pennsylvania/nanticoke', lat: 41.2056, lng: -76.0041 },
  { name: 'Pittston, PA', path: '/trailer-rentals/pennsylvania/pittston', lat: 41.3251, lng: -75.7891 },
  { name: 'Ridgway, PA', path: '/trailer-rentals/pennsylvania/ridgway', lat: 41.4192, lng: -78.7286 },
  { name: 'Scranton, PA', path: '/trailer-rentals/pennsylvania/scranton', lat: 41.4089, lng: -75.6624 },
  { name: 'St. Marys, PA', path: '/trailer-rentals/pennsylvania/st-marys', lat: 41.4278, lng: -78.5606 },
  { name: 'Towanda, PA', path: '/trailer-rentals/pennsylvania/towanda', lat: 41.767, lng: -76.4427 },
  { name: 'Wellsboro, PA', path: '/trailer-rentals/pennsylvania/wellsboro', lat: 41.7487, lng: -77.3 },
  { name: 'Wilkes-Barre, PA', path: '/trailer-rentals/pennsylvania/wilkes-barre', lat: 41.2459, lng: -75.8813 },
  { name: 'Williamsport, PA', path: '/trailer-rentals/pennsylvania/williamsport', lat: 41.2412, lng: -77.0011 }
];

export const rhodeIslandCities:ICity[] = [
  { name: 'Ashaway, RI', path: '/trailer-rentals/rhode-island/ashaway', lat: 41.4237, lng: -71.7895 },
  { name: 'Barrington, RI', path: '/trailer-rentals/rhode-island/barrington', lat: 41.7401, lng: -71.3081 },
  { name: 'Bonnet Shores, RI', path: '/trailer-rentals/rhode-island/bonnet-shores', lat: 41.5026, lng: -71.4512 },
  { name: 'Bradford, RI', path: '/trailer-rentals/rhode-island/bradford', lat: 41.4012, lng: -71.7428 },
  { name: 'Bristol, RI', path: '/trailer-rentals/rhode-island/bristol', lat: 41.6770, lng: -71.2662 },
  { name: 'Burrillville, RI', path: '/trailer-rentals/rhode-island/burrillville', lat: 41.9665, lng: -71.6795 },
  { name: 'Carolina, RI', path: '/trailer-rentals/rhode-island/carolina', lat: 41.4576, lng: -71.6534 },
  { name: 'Central Falls, RI', path: '/trailer-rentals/rhode-island/central-falls', lat: 41.8901, lng: -71.3923 },
  { name: 'Charlestown, RI', path: '/trailer-rentals/rhode-island/charlestown', lat: 41.3834, lng: -71.6401 },
  { name: 'Chepachet, RI', path: '/trailer-rentals/rhode-island/chepachet', lat: 41.9137, lng: -71.6717 },
  { name: 'Clayville, RI', path: '/trailer-rentals/rhode-island/clayville', lat: 41.7720, lng: -71.6734 },
  { name: 'Coventry, RI', path: '/trailer-rentals/rhode-island/coventry', lat: 41.7001, lng: -71.6551 },
  { name: 'Cranston, RI', path: '/trailer-rentals/rhode-island/cranston', lat: 41.7798, lng: -71.4373 },
  { name: 'Cumberland, RI', path: '/trailer-rentals/rhode-island/cumberland', lat: 41.9665, lng: -71.4282 },
  { name: 'East Greenwich, RI', path: '/trailer-rentals/rhode-island/east-greenwich', lat: 41.6340, lng: -71.4712 },
  { name: 'East Providence, RI', path: '/trailer-rentals/rhode-island/east-providence', lat: 41.8137, lng: -71.3701 },
  { name: 'Edgewood, RI', path: '/trailer-rentals/rhode-island/edgewood', lat: 41.7751, lng: -71.3984 },
  { name: 'Exeter, RI', path: '/trailer-rentals/rhode-island/exeter', lat: 41.5751, lng: -71.5351 },
  { name: 'Foster, RI', path: '/trailer-rentals/rhode-island/foster', lat: 41.8537, lng: -71.7573 },
  { name: 'Glocester, RI', path: '/trailer-rentals/rhode-island/glocester', lat: 41.8970, lng: -71.6701 },
  { name: 'Greene, RI', path: '/trailer-rentals/rhode-island/greene', lat: 41.7034, lng: -71.7201 },
  { name: 'Greenville, RI', path: '/trailer-rentals/rhode-island/greenville', lat: 41.8712, lng: -71.5523 },
  { name: 'Harmony, RI', path: '/trailer-rentals/rhode-island/harmony', lat: 41.8884, lng: -71.6084 },
  { name: 'Harrisville, RI', path: '/trailer-rentals/rhode-island/harrisville', lat: 41.9684, lng: -71.6801 },
  { name: 'Hope, RI', path: '/trailer-rentals/rhode-island/hope', lat: 41.7462, lng: -71.5834 },
  { name: 'Hope Valley, RI', path: '/trailer-rentals/rhode-island/hope-valley', lat: 41.5062, lng: -71.7162 },
  { name: 'Hopkinton, RI', path: '/trailer-rentals/rhode-island/hopkinton', lat: 41.4512, lng: -71.7784 },
  { name: 'Jamestown, RI', path: '/trailer-rentals/rhode-island/jamestown', lat: 41.4962, lng: -71.3673 },
  { name: 'Johnston, RI', path: '/trailer-rentals/rhode-island/johnston', lat: 41.8201, lng: -71.4951 },
  { name: 'Kingston, RI', path: '/trailer-rentals/rhode-island/kingston', lat: 41.4801, lng: -71.5234 },
  { name: 'Lincoln, RI', path: '/trailer-rentals/rhode-island/lincoln', lat: 41.9051, lng: -71.4123 },
  { name: 'Little Compton, RI', path: '/trailer-rentals/rhode-island/little-compton', lat: 41.5101, lng: -71.1712 },
  { name: 'Manville, RI', path: '/trailer-rentals/rhode-island/manville', lat: 41.9612, lng: -71.4745 },
  { name: 'Melville, RI', path: '/trailer-rentals/rhode-island/melville', lat: 41.5484, lng: -71.2923 },
  { name: 'Middletown, RI', path: '/trailer-rentals/rhode-island/middletown', lat: 41.5451, lng: -71.2912 },
  { name: 'Narragansett, RI', path: '/trailer-rentals/rhode-island/narragansett', lat: 41.4501, lng: -71.4495 },
  { name: 'New Shoreham, RI', path: '/trailer-rentals/rhode-island/new-shoreham', lat: 41.1684, lng: -71.5584 },
  { name: 'Newport, RI', path: '/trailer-rentals/rhode-island/newport', lat: 41.4901, lng: -71.3123 },
  { name: 'North Kingstown, RI', path: '/trailer-rentals/rhode-island/north-kingstown', lat: 41.5762, lng: -71.4512 },
  { name: 'North Providence, RI', path: '/trailer-rentals/rhode-island/north-providence', lat: 41.8537, lng: -71.4662 },
  { name: 'North Scituate, RI', path: '/trailer-rentals/rhode-island/north-scituate', lat: 41.8312, lng: -71.6245 },
  { name: 'North Smithfield, RI', path: '/trailer-rentals/rhode-island/north-smithfield', lat: 41.9662, lng: -71.5523 },
  { name: 'Pascoag, RI', path: '/trailer-rentals/rhode-island/pascoag', lat: 41.9551, lng: -71.7023 },
  { name: 'Pawtucket, RI', path: '/trailer-rentals/rhode-island/pawtucket', lat: 41.8784, lng: -71.3823 },
  { name: 'Portsmouth, RI', path: '/trailer-rentals/rhode-island/portsmouth', lat: 41.6023, lng: -71.2501 },
  { name: 'Providence, RI', path: '/trailer-rentals/rhode-island/providence', lat: 41.8236, lng: -71.4222 },
  { name: 'Richmond, RI', path: '/trailer-rentals/rhode-island/richmond', lat: 41.5051, lng: -71.6673 },
  { name: 'Riverside, RI', path: '/trailer-rentals/rhode-island/riverside', lat: 41.7695, lng: -71.3551 },
  { name: 'Rumford, RI', path: '/trailer-rentals/rhode-island/rumford', lat: 41.8523, lng: -71.3534 },
  { name: 'Saunderstown, RI', path: '/trailer-rentals/rhode-island/saunderstown', lat: 41.5137, lng: -71.4512 },
  { name: 'Scituate, RI', path: '/trailer-rentals/rhode-island/scituate', lat: 41.8334, lng: -71.6245 },
  { name: 'Smithfield, RI', path: '/trailer-rentals/rhode-island/smithfield', lat: 41.9223, lng: -71.5495 },
  { name: 'South Kingstown, RI', path: '/trailer-rentals/rhode-island/south-kingstown', lat: 41.4562, lng: -71.5234 },
  { name: 'Tiverton, RI', path: '/trailer-rentals/rhode-island/tiverton', lat: 41.6251, lng: -71.2084 },
  { name: 'Valley Falls, RI', path: '/trailer-rentals/rhode-island/valley-falls', lat: 41.9051, lng: -71.3923 },
  { name: 'Wakefield, RI', path: '/trailer-rentals/rhode-island/wakefield', lat: 41.4401, lng: -71.4995 },
  { name: 'Warren, RI', path: '/trailer-rentals/rhode-island/warren', lat: 41.7301, lng: -71.2834 },
  { name: 'Warwick, RI', path: '/trailer-rentals/rhode-island/warwick', lat: 41.7001, lng: -71.4162 },
  { name: 'Watch Hill, RI', path: '/trailer-rentals/rhode-island/watch-hill', lat: 41.3084, lng: -71.8584 },
  { name: 'West Greenwich, RI', path: '/trailer-rentals/rhode-island/west-greenwich', lat: 41.6301, lng: -71.6601 },
  { name: 'West Kingston, RI', path: '/trailer-rentals/rhode-island/west-kingston', lat: 41.4801, lng: -71.5634 },
  { name: 'West Warwick, RI', path: '/trailer-rentals/rhode-island/west-warwick', lat: 41.6962, lng: -71.5184 },
  { name: 'Westerly, RI', path: '/trailer-rentals/rhode-island/westerly', lat: 41.3770, lng: -71.8273 },
  { name: 'Wood River Junction, RI', path: '/trailer-rentals/rhode-island/wood-river-junction', lat: 41.4512, lng: -71.6834 },
  { name: 'Woonsocket, RI', path: '/trailer-rentals/rhode-island/woonsocket', lat: 42.0026, lng: -71.5145 },
  { name: 'Adamsville, RI', path: '/trailer-rentals/rhode-island/adamsville', lat: 41.5523, lng: -71.1445 },
  { name: 'Arcadia, RI', path: '/trailer-rentals/rhode-island/arcadia', lat: 41.5734, lng: -71.7162 },
  { name: 'Escoheag, RI', path: '/trailer-rentals/rhode-island/escoheag', lat: 41.5734, lng: -71.7162 },
  { name: 'Fiskeville, RI', path: '/trailer-rentals/rhode-island/fiskeville', lat: 41.7362, lng: -71.5584 },
  { name: 'Georgiaville, RI', path: '/trailer-rentals/rhode-island/georgiaville', lat: 41.8901, lng: -71.5523 },
  { name: 'Harris, RI', path: '/trailer-rentals/rhode-island/harris', lat: 41.7001, lng: -71.5834 },
  { name: 'Mapleville, RI', path: '/trailer-rentals/rhode-island/mapleville', lat: 41.9512, lng: -71.6545 },
  { name: 'Misquamicut, RI', path: '/trailer-rentals/rhode-island/misquamicut', lat: 41.3201, lng: -71.8273 },
  { name: 'Mohegan, RI', path: '/trailer-rentals/rhode-island/mohegan', lat: 41.5734, lng: -71.7162 },
  { name: 'Mount Pleasant, RI', path: '/trailer-rentals/rhode-island/mount-pleasant', lat: 41.8236, lng: -71.4222 },
  { name: 'Oakland, RI', path: '/trailer-rentals/rhode-island/oakland', lat: 41.9512, lng: -71.6545 },
  { name: 'Olneyville, RI', path: '/trailer-rentals/rhode-island/olneyville', lat: 41.8236, lng: -71.4222 },
  { name: 'Pontiac, RI', path: '/trailer-rentals/rhode-island/pontiac', lat: 41.7001, lng: -71.4162 },
  { name: 'Potter Hill, RI', path: '/trailer-rentals/rhode-island/potter-hill', lat: 41.3770, lng: -71.8273 },
  { name: 'Quonochontaug, RI', path: '/trailer-rentals/rhode-island/quonochontaug', lat: 41.3201, lng: -71.8273 },
  { name: 'Rockville, RI', path: '/trailer-rentals/rhode-island/rockville', lat: 41.5734, lng: -71.7162 },
  { name: 'Rumford, RI', path: '/trailer-rentals/rhode-island/rumford', lat: 41.8523, lng: -71.3534 },
  { name: 'Saylesville, RI', path: '/trailer-rentals/rhode-island/saylesville', lat: 41.9051, lng: -71.4123 },
];

export const southCarolinaCities: ICity[] = [
  { name: 'Abbeville, SC', path: '/trailer-rentals/south-carolina/abbeville', lat: 34.1780, lng: -82.3790 },
  { name: 'Aiken, SC', path: '/trailer-rentals/south-carolina/aiken', lat: 33.5604, lng: -81.7196 },
  { name: 'Allendale, SC', path: '/trailer-rentals/south-carolina/allendale', lat: 33.0071, lng: -81.3084 },
  { name: 'Anderson, SC', path: '/trailer-rentals/south-carolina/anderson', lat: 34.5034, lng: -82.6501 },
  { name: 'Andrews, SC', path: '/trailer-rentals/south-carolina/andrews', lat: 33.4518, lng: -79.5606 },
  { name: 'Arcadia Lakes, SC', path: '/trailer-rentals/south-carolina/arcadia-lakes', lat: 34.0482, lng: -80.9645 },
  { name: 'Atlantic Beach, SC', path: '/trailer-rentals/south-carolina/atlantic-beach', lat: 33.8021, lng: -78.7153 },
  { name: 'Aynor, SC', path: '/trailer-rentals/south-carolina/aynor', lat: 33.9918, lng: -79.2014 },
  { name: 'Bamberg, SC', path: '/trailer-rentals/south-carolina/bamberg', lat: 33.2974, lng: -81.0348 },
  { name: 'Barnwell, SC', path: '/trailer-rentals/south-carolina/barnwell', lat: 33.2443, lng: -81.3584 },
  { name: 'Batesburg-Leesville, SC', path: '/trailer-rentals/south-carolina/batesburg-leesville', lat: 33.9085, lng: -81.5384 },
  { name: 'Beaufort, SC', path: '/trailer-rentals/south-carolina/beaufort', lat: 32.4316, lng: -80.6698 },
  { name: 'Belton, SC', path: '/trailer-rentals/south-carolina/belton', lat: 34.5234, lng: -82.4943 },
  { name: 'Bennettsville, SC', path: '/trailer-rentals/south-carolina/bennettsville', lat: 34.6177, lng: -79.6845 },
  { name: 'Bishopville, SC', path: '/trailer-rentals/south-carolina/bishopville', lat: 34.2185, lng: -80.2481 },
  { name: 'Blacksburg, SC', path: '/trailer-rentals/south-carolina/blacksburg', lat: 35.1212, lng: -81.5151 },
  { name: 'Blackville, SC', path: '/trailer-rentals/south-carolina/blackville', lat: 33.3574, lng: -81.2737 },
  { name: 'Blythewood, SC', path: '/trailer-rentals/south-carolina/blythewood', lat: 34.2143, lng: -80.9737 },
  { name: 'Bluffton, SC', path: '/trailer-rentals/south-carolina/bluffton', lat: 32.2371, lng: -80.8604 },
  { name: 'Bowman, SC', path: '/trailer-rentals/south-carolina/bowman', lat: 33.3485, lng: -80.6837 },
  { name: 'Camden, SC', path: '/trailer-rentals/south-carolina/camden', lat: 34.2460, lng: -80.6070 },
  { name: 'Cayce, SC', path: '/trailer-rentals/south-carolina/cayce', lat: 33.9651, lng: -81.0734 },
  { name: 'Central, SC', path: '/trailer-rentals/south-carolina/central', lat: 34.7240, lng: -82.7812 },
  { name: 'Charleston, SC', path: '/trailer-rentals/south-carolina/charleston', lat: 32.7765, lng: -79.9311 },
  { name: 'Cheraw, SC', path: '/trailer-rentals/south-carolina/cheraw', lat: 34.6977, lng: -79.8834 },
  { name: 'Chesnee, SC', path: '/trailer-rentals/south-carolina/chesnee', lat: 35.1487, lng: -81.8601 },
  { name: 'Chester, SC', path: '/trailer-rentals/south-carolina/chester', lat: 34.7040, lng: -81.2140 },
  { name: 'Clemson, SC', path: '/trailer-rentals/south-carolina/clemson', lat: 34.6834, lng: -82.8374 },
  { name: 'Clinton, SC', path: '/trailer-rentals/south-carolina/clinton', lat: 34.4726, lng: -81.8801 },
  { name: 'Clover, SC', path: '/trailer-rentals/south-carolina/clover', lat: 35.1118, lng: -81.2262 },
  { name: 'Columbia, SC', path: '/trailer-rentals/south-carolina/columbia', lat: 34.0007, lng: -81.0348, active: true, showNewVersion: true},
  { name: 'Conway, SC', path: '/trailer-rentals/south-carolina/conway', lat: 33.8360, lng: -79.0478 },
  { name: 'Cowpens, SC', path: '/trailer-rentals/south-carolina/cowpens', lat: 35.0168, lng: -81.8034 },
  { name: 'Darlington, SC', path: '/trailer-rentals/south-carolina/darlington', lat: 34.2999, lng: -79.8764 },
  { name: 'Denmark, SC', path: '/trailer-rentals/south-carolina/denmark', lat: 33.3268, lng: -81.1420 },
  { name: 'Dillon, SC', path: '/trailer-rentals/south-carolina/dillon', lat: 34.4165, lng: -79.3712 },
  { name: 'Donalds, SC', path: '/trailer-rentals/south-carolina/donalds', lat: 34.3726, lng: -82.3487 },
  { name: 'Duncan, SC', path: '/trailer-rentals/south-carolina/duncan', lat: 34.9371, lng: -82.1373 },
  { name: 'Easley, SC', path: '/trailer-rentals/south-carolina/easley', lat: 34.8298, lng: -82.6015 },
  { name: 'Edgefield, SC', path: '/trailer-rentals/south-carolina/edgefield', lat: 33.7890, lng: -81.9293 },
  { name: 'Edisto Beach, SC', path: '/trailer-rentals/south-carolina/edisto-beach', lat: 32.4821, lng: -80.3248 },
  { name: 'Estill, SC', path: '/trailer-rentals/south-carolina/estill', lat: 32.7543, lng: -81.2412 },
  { name: 'Fairfax, SC', path: '/trailer-rentals/south-carolina/fairfax', lat: 32.9593, lng: -81.2365 },
  { name: 'Florence, SC', path: '/trailer-rentals/south-carolina/florence', lat: 34.1954, lng: -79.7626 },
  { name: 'Folly Beach, SC', path: '/trailer-rentals/south-carolina/folly-beach', lat: 32.6554, lng: -79.9406 },
  { name: 'Forest Acres, SC', path: '/trailer-rentals/south-carolina/forest-acres', lat: 34.0193, lng: -80.9773 },
  { name: 'Fort Lawn, SC', path: '/trailer-rentals/south-carolina/fort-lawn', lat: 34.6993, lng: -80.8973 },
  { name: 'Fountain Inn, SC', path: '/trailer-rentals/south-carolina/fountain-inn', lat: 34.6876, lng: -82.1951 },
  { name: 'Gaffney, SC', path: '/trailer-rentals/south-carolina/gaffney', lat: 35.0718, lng: -81.6498 },
  { name: 'Gaston, SC', path: '/trailer-rentals/south-carolina/gaston', lat: 33.8171, lng: -81.1037 },
  { name: 'Georgetown, SC', path: '/trailer-rentals/south-carolina/georgetown', lat: 33.3768, lng: -79.2945 },
  { name: 'Gloverville, SC', path: '/trailer-rentals/south-carolina/gloverville', lat: 33.5271, lng: -81.8223 },
  { name: 'Goose Creek, SC', path: '/trailer-rentals/south-carolina/goose-creek', lat: 32.9810, lng: -80.0326 },
  { name: 'Great Falls, SC', path: '/trailer-rentals/south-carolina/great-falls', lat: 34.5704, lng: -80.9023 },
  { name: 'Greenville, SC', path: '/trailer-rentals/south-carolina/greenville', lat: 34.8526, lng: -82.3940 },
  { name: 'Greenwood, SC', path: '/trailer-rentals/south-carolina/greenwood', lat: 34.1954, lng: -82.1618 },
  { name: 'Greer, SC', path: '/trailer-rentals/south-carolina/greer', lat: 34.9387, lng: -82.2271 },
  { name: 'Hampton, SC', path: '/trailer-rentals/south-carolina/hampton', lat: 32.8671, lng: -81.1087 },
  { name: 'Hanahan, SC', path: '/trailer-rentals/south-carolina/hanahan', lat: 32.9185, lng: -80.0223 },
  { name: 'Hardeeville, SC', path: '/trailer-rentals/south-carolina/hardeeville', lat: 32.2871, lng: -81.0801 },
  { name: 'Hartsville, SC', path: '/trailer-rentals/south-carolina/hartsville', lat: 34.3740, lng: -80.0734 },
  { name: 'Hemingway, SC', path: '/trailer-rentals/south-carolina/hemingway', lat: 33.7535, lng: -79.4445 },
  { name: 'Hilton Head Island, SC', path: '/trailer-rentals/south-carolina/hilton-head-island', lat: 32.2163, lng: -80.7526 },
  { name: 'Holly Hill, SC', path: '/trailer-rentals/south-carolina/holly-hill', lat: 33.3235, lng: -80.4112 },
  { name: 'Hollywood, SC', path: '/trailer-rentals/south-carolina/hollywood', lat: 32.7460, lng: -80.2412 },
  { name: 'Honea Path, SC', path: '/trailer-rentals/south-carolina/honea-path', lat: 34.4468, lng: -82.3923 },
  { name: 'Inman, SC', path: '/trailer-rentals/south-carolina/inman', lat: 35.0471, lng: -82.0901 },
  { name: 'Irmo, SC', path: '/trailer-rentals/south-carolina/irmo', lat: 34.0857, lng: -81.1837 },
  { name: 'Isle of Palms, SC', path: '/trailer-rentals/south-carolina/isle-of-palms', lat: 32.7860, lng: -79.7945 },
  { name: 'Iva, SC', path: '/trailer-rentals/south-carolina/iva', lat: 34.3071, lng: -82.6637 },
  { name: 'Johnston, SC', path: '/trailer-rentals/south-carolina/johnston', lat: 33.8326, lng: -81.8001 },
  { name: 'Kingstree, SC', path: '/trailer-rentals/south-carolina/kingstree', lat: 33.6671, lng: -79.8301 },
  { name: 'Lake City, SC', path: '/trailer-rentals/south-carolina/lake-city', lat: 33.8704, lng: -79.7551 },
  { name: 'Lake Wylie, SC', path: '/trailer-rentals/south-carolina/lake-wylie', lat: 35.1040, lng: -81.0401 },
  { name: 'Lancaster, SC', path: '/trailer-rentals/south-carolina/lancaster', lat: 34.7204, lng: -80.7701 },
  { name: 'Landrum, SC', path: '/trailer-rentals/south-carolina/landrum', lat: 35.1751, lng: -82.1895 },
  { name: 'Latta, SC', path: '/trailer-rentals/south-carolina/latta', lat: 34.3368, lng: -79.4301 },
  { name: 'Laurens, SC', path: '/trailer-rentals/south-carolina/laurens', lat: 34.4990, lng: -82.0145 },
  { name: 'Lexington, SC', path: '/trailer-rentals/south-carolina/lexington', lat: 33.9815, lng: -81.2362 },
  { name: 'Liberty, SC', path: '/trailer-rentals/south-carolina/liberty', lat: 34.7887, lng: -82.6923 },
  { name: 'Loris, SC', path: '/trailer-rentals/south-carolina/loris', lat: 34.0568, lng: -78.8901 },
  { name: 'Manning, SC', path: '/trailer-rentals/south-carolina/manning', lat: 33.6951, lng: -80.2101 },
  { name: 'Marion, SC', path: '/trailer-rentals/south-carolina/marion', lat: 34.1787, lng: -79.4001 },
  { name: 'Mauldin, SC', path: '/trailer-rentals/south-carolina/mauldin', lat: 34.7787, lng: -82.3101 },
  { name: 'McBee, SC', path: '/trailer-rentals/south-carolina/mcbee', lat: 34.4671, lng: -80.2501 },
  { name: 'McColl, SC', path: '/trailer-rentals/south-carolina/mccoll', lat: 34.6671, lng: -79.5501 },
  { name: 'Moncks Corner, SC', path: '/trailer-rentals/south-carolina/moncks-corner', lat: 33.1968, lng: -80.0137 },
  { name: 'Mount Pleasant, SC', path: '/trailer-rentals/south-carolina/mount-pleasant', lat: 32.8326, lng: -79.8284 },
  { name: 'Mullins, SC', path: '/trailer-rentals/south-carolina/mullins', lat: 34.2051, lng: -79.2545 },
  { name: 'Myrtle Beach, SC', path: '/trailer-rentals/south-carolina/myrtle-beach', lat: 33.6891, lng: -78.8867 },
  { name: 'Newberry, SC', path: '/trailer-rentals/south-carolina/newberry', lat: 34.2740, lng: -81.6187 },
  { name: 'Nichols, SC', path: '/trailer-rentals/south-carolina/nichols', lat: 34.2290, lng: -79.1487 },
  { name: 'North Augusta, SC', path: '/trailer-rentals/south-carolina/north-augusta', lat: 33.5018, lng: -81.9651 },
  { name: 'North Charleston, SC', path: '/trailer-rentals/south-carolina/north-charleston', lat: 32.8546, lng: -79.9748 },
  { name: 'North Myrtle Beach, SC', path: '/trailer-rentals/south-carolina/north-myrtle-beach', lat: 33.8160, lng: -78.6800 },
  { name: 'Orangeburg, SC', path: '/trailer-rentals/south-carolina/orangeburg', lat: 33.4968, lng: -80.8623 },
  { name: 'Pageland, SC', path: '/trailer-rentals/south-carolina/pageland', lat: 34.7737, lng: -80.3912 },
  { name: 'Pamplico, SC', path: '/trailer-rentals/south-carolina/pamplico', lat: 33.9968, lng: -79.5701 },
  { name: 'Patrick, SC', path: '/trailer-rentals/south-carolina/patrick', lat: 34.5765, lng: -80.0423 },
  { name: 'Pawleys Island, SC', path: '/trailer-rentals/south-carolina/pawleys-island', lat: 33.4332, lng: -79.1214 },
  { name: 'Pendleton, SC', path: '/trailer-rentals/south-carolina/pendleton', lat: 34.6515, lng: -82.7835 },
  { name: 'Pickens, SC', path: '/trailer-rentals/south-carolina/pickens', lat: 34.8837, lng: -82.7074 },
  { name: 'Pine Ridge, SC', path: '/trailer-rentals/south-carolina/pine-ridge', lat: 33.9082, lng: -81.1087 },
  { name: 'Port Royal, SC', path: '/trailer-rentals/south-carolina/port-royal', lat: 32.3791, lng: -80.6923 },
  { name: 'Prosperity, SC', path: '/trailer-rentals/south-carolina/prosperity', lat: 34.2104, lng: -81.5326 },
  { name: 'Ravenel, SC', path: '/trailer-rentals/south-carolina/ravenel', lat: 32.7646, lng: -80.2304 },
  { name: 'Rock Hill, SC', path: '/trailer-rentals/south-carolina/rock-hill', lat: 34.9249, lng: -81.0251 },
  { name: 'Salem, SC', path: '/trailer-rentals/south-carolina/salem', lat: 34.8895, lng: -82.9751 },
  { name: 'Salley, SC', path: '/trailer-rentals/south-carolina/salley', lat: 33.5649, lng: -81.3037 },
  { name: 'Santee, SC', path: '/trailer-rentals/south-carolina/santee', lat: 33.4785, lng: -80.4862 },
  { name: 'Seneca, SC', path: '/trailer-rentals/south-carolina/seneca', lat: 34.6854, lng: -82.9533 },
  { name: 'Simpsonville, SC', path: '/trailer-rentals/south-carolina/simpsonville', lat: 34.7371, lng: -82.2543 },
  { name: 'Six Mile, SC', path: '/trailer-rentals/south-carolina/six-mile', lat: 34.8012, lng: -82.8193 },
  { name: 'Society Hill, SC', path: '/trailer-rentals/south-carolina/society-hill', lat: 34.5143, lng: -79.8598 },
  { name: 'South Congaree, SC', path: '/trailer-rentals/south-carolina/south-congaree', lat: 33.9118, lng: -81.1576 },
  { name: 'Spartanburg, SC', path: '/trailer-rentals/south-carolina/spartanburg', lat: 34.9496, lng: -81.9320 },
  { name: 'Springdale, SC', path: '/trailer-rentals/south-carolina/springdale', lat: 33.9604, lng: -81.1037 },
  { name: 'St. George, SC', path: '/trailer-rentals/south-carolina/st-george', lat: 33.1868, lng: -80.5770 },
  { name: 'St. Matthews, SC', path: '/trailer-rentals/south-carolina/st-matthews', lat: 33.6643, lng: -80.7787 },
  { name: 'St. Stephen, SC', path: '/trailer-rentals/south-carolina/st-stephen', lat: 33.4068, lng: -79.9223 },
  { name: 'Sullivan’s Island, SC', path: '/trailer-rentals/south-carolina/sullivans-island', lat: 32.7635, lng: -79.8362 },
  { name: 'Summerville, SC', path: '/trailer-rentals/south-carolina/summerville', lat: 33.0185, lng: -80.1756 },
  { name: 'Sumter, SC', path: '/trailer-rentals/south-carolina/sumter', lat: 33.9204, lng: -80.3415 },
  { name: 'Surfside Beach, SC', path: '/trailer-rentals/south-carolina/surfside-beach', lat: 33.6060, lng: -78.9736 },
  { name: 'Swansea, SC', path: '/trailer-rentals/south-carolina/swansea', lat: 33.7385, lng: -81.0987 },
  { name: 'Tega Cay, SC', path: '/trailer-rentals/south-carolina/tega-cay', lat: 35.0243, lng: -81.0284 },
  { name: 'Timmonsville, SC', path: '/trailer-rentals/south-carolina/timmonsville', lat: 34.1343, lng: -79.9398 },
  { name: 'Travelers Rest, SC', path: '/trailer-rentals/south-carolina/travelers-rest', lat: 34.9676, lng: -82.4432 },
  { name: 'Union, SC', path: '/trailer-rentals/south-carolina/union', lat: 34.7154, lng: -81.6237 },
  { name: 'Walhalla, SC', path: '/trailer-rentals/south-carolina/walhalla', lat: 34.7648, lng: -83.0646 },
  { name: 'Walterboro, SC', path: '/trailer-rentals/south-carolina/walterboro', lat: 32.9054, lng: -80.6665 },
  { name: 'Ware Shoals, SC', path: '/trailer-rentals/south-carolina/ware-shoals', lat: 34.3965, lng: -82.2462 },
  { name: 'Wellford, SC', path: '/trailer-rentals/south-carolina/wellford', lat: 34.9501, lng: -82.1059 },
  { name: 'West Columbia, SC', path: '/trailer-rentals/south-carolina/west-columbia', lat: 33.9935, lng: -81.0734, active: true, showNewVersion: true},
  { name: 'Westminster, SC', path: '/trailer-rentals/south-carolina/westminster', lat: 34.6648, lng: -83.0968 },
  { name: 'Williamston, SC', path: '/trailer-rentals/south-carolina/williamston', lat: 34.6182, lng: -82.4754 },
  { name: 'Winnsboro, SC', path: '/trailer-rentals/south-carolina/winnsboro', lat: 34.3804, lng: -81.0862 },
  { name: 'Woodruff, SC', path: '/trailer-rentals/south-carolina/woodruff', lat: 34.7393, lng: -82.0376 },
  { name: 'Yemassee, SC', path: '/trailer-rentals/south-carolina/yemassee', lat: 32.6954, lng: -80.8504 },
];

export const southDakotaCities: ICity[] = [
  { name: 'Aberdeen, SD', path: '/trailer-rentals/south-dakota/aberdeen', lat: 45.4647, lng: -98.4865 },
  { name: 'Alcester, SD', path: '/trailer-rentals/south-dakota/alcester', lat: 43.0003, lng: -96.6353 },
  { name: 'Arlington, SD', path: '/trailer-rentals/south-dakota/arlington', lat: 44.3622, lng: -97.1334 },
  { name: 'Avon, SD', path: '/trailer-rentals/south-dakota/avon', lat: 43.0053, lng: -98.0598 },
  { name: 'Baltic, SD', path: '/trailer-rentals/south-dakota/baltic', lat: 43.7611, lng: -96.7381 },
  { name: 'Belle Fourche, SD', path: '/trailer-rentals/south-dakota/belle-fourche', lat: 44.6714, lng: -103.8527 },
  { name: 'Beresford, SD', path: '/trailer-rentals/south-dakota/beresford', lat: 43.0803, lng: -96.7731 },
  { name: 'Big Stone City, SD', path: '/trailer-rentals/south-dakota/big-stone-city', lat: 45.2972, lng: -96.4628 },
  { name: 'Blunt, SD', path: '/trailer-rentals/south-dakota/blunt', lat: 44.5153, lng: -99.9879 },
  { name: 'Bonesteel, SD', path: '/trailer-rentals/south-dakota/bonesteel', lat: 43.0797, lng: -98.9465 },
  { name: 'Bowdle, SD', path: '/trailer-rentals/south-dakota/bowdle', lat: 45.4533, lng: -99.6576 },
  { name: 'Brandon, SD', path: '/trailer-rentals/south-dakota/brandon', lat: 43.5922, lng: -96.5728 },
  { name: 'Bridgewater, SD', path: '/trailer-rentals/south-dakota/bridgewater', lat: 43.5553, lng: -97.5009 },
  { name: 'Britton, SD', path: '/trailer-rentals/south-dakota/britton', lat: 45.7911, lng: -97.7509 },
  { name: 'Brookings, SD', path: '/trailer-rentals/south-dakota/brookings', lat: 44.3114, lng: -96.7984 },
  { name: 'Buffalo, SD', path: '/trailer-rentals/south-dakota/buffalo', lat: 45.5858, lng: -103.5454 },
  { name: 'Burke, SD', path: '/trailer-rentals/south-dakota/burke', lat: 43.1811, lng: -99.2934 },
  { name: 'Canistota, SD', path: '/trailer-rentals/south-dakota/canistota', lat: 43.5961, lng: -97.2928 },
  { name: 'Canton, SD', path: '/trailer-rentals/south-dakota/canton', lat: 43.3003, lng: -96.5959 },
  { name: 'Castlewood, SD', path: '/trailer-rentals/south-dakota/castlewood', lat: 44.7233, lng: -97.0328 },
  { name: 'Centerville, SD', path: '/trailer-rentals/south-dakota/centerville', lat: 43.1161, lng: -96.9615 },
  { name: 'Chamberlain, SD', path: '/trailer-rentals/south-dakota/chamberlain', lat: 43.8011, lng: -99.3309 },
  { name: 'Cheyenne River, SD', path: '/trailer-rentals/south-dakota/cheyenne-river', lat: 44.9803, lng: -101.2515 },
  { name: 'Clark, SD', path: '/trailer-rentals/south-dakota/clark', lat: 44.8772, lng: -97.7315 },
  { name: 'Clear Lake, SD', path: '/trailer-rentals/south-dakota/clear-lake', lat: 44.7553, lng: -96.6828 },
  { name: 'Colman, SD', path: '/trailer-rentals/south-dakota/colman', lat: 44.0111, lng: -96.8159 },
  { name: 'Columbia, SD', path: '/trailer-rentals/south-dakota/columbia', lat: 45.6072, lng: -98.3084 },
  { name: 'Crooks, SD', path: '/trailer-rentals/south-dakota/crooks', lat: 43.6647, lng: -96.8128 },
  { name: 'Custer, SD', path: '/trailer-rentals/south-dakota/custer', lat: 43.7661, lng: -103.5984 },
  { name: 'Dakota Dunes, SD', path: '/trailer-rentals/south-dakota/dakota-dunes', lat: 42.4872, lng: -96.4865 },
  { name: 'Dell Rapids, SD', path: '/trailer-rentals/south-dakota/dell-rapids', lat: 43.8233, lng: -96.7128 },
  { name: 'De Smet, SD', path: '/trailer-rentals/south-dakota/de-smet', lat: 44.3853, lng: -97.5509 },
  { name: 'Eagle Butte, SD', path: '/trailer-rentals/south-dakota/eagle-butte', lat: 44.9953, lng: -101.2328 },
  { name: 'Edgemont, SD', path: '/trailer-rentals/south-dakota/edgemont', lat: 43.2997, lng: -103.8259 },
  { name: 'Elk Point, SD', path: '/trailer-rentals/south-dakota/elk-point', lat: 42.6811, lng: -96.6815 },
  { name: 'Elkton, SD', path: '/trailer-rentals/south-dakota/elkton', lat: 44.2361, lng: -96.4815 },
  { name: 'Estelline, SD', path: '/trailer-rentals/south-dakota/estelline', lat: 44.5733, lng: -96.9015 },
  { name: 'Eureka, SD', path: '/trailer-rentals/south-dakota/eureka', lat: 45.7697, lng: -99.6209 },
  { name: 'Faith, SD', path: '/trailer-rentals/south-dakota/faith', lat: 45.0211, lng: -102.0384 },
  { name: 'Faulkton, SD', path: '/trailer-rentals/south-dakota/faulkton', lat: 45.0347, lng: -99.1284 },
  { name: 'Flandreau, SD', path: '/trailer-rentals/south-dakota/flandreau', lat: 44.0483, lng: -96.5984 },
  { name: 'Fort Pierre, SD', path: '/trailer-rentals/south-dakota/fort-pierre', lat: 44.3583, lng: -100.3734 },
  { name: 'Freeman, SD', path: '/trailer-rentals/south-dakota/freeman', lat: 43.3503, lng: -97.4334 },
  { name: 'Garretson, SD', path: '/trailer-rentals/south-dakota/garretson', lat: 43.7172, lng: -96.5028 },
  { name: 'Geddes, SD', path: '/trailer-rentals/south-dakota/geddes', lat: 43.2553, lng: -98.6984 },
  { name: 'Gettysburg, SD', path: '/trailer-rentals/south-dakota/gettysburg', lat: 45.0103, lng: -99.9509 },
  { name: 'Gregory, SD', path: '/trailer-rentals/south-dakota/gregory', lat: 43.2322, lng: -99.4248 },
  { name: 'Groton, SD', path: '/trailer-rentals/south-dakota/groton', lat: 45.4503, lng: -98.0984 },
  { name: 'Harrisburg, SD', path: '/trailer-rentals/south-dakota/harrisburg', lat: 43.4311, lng: -96.6984 },
  { name: 'Hartford, SD', path: '/trailer-rentals/south-dakota/hartford', lat: 43.6211, lng: -96.9434 },
  { name: 'Hot Springs, SD', path: '/trailer-rentals/south-dakota/hot-springs', lat: 43.4311, lng: -103.4748 },
  { name: 'Huron, SD', path: '/trailer-rentals/south-dakota/huron', lat: 44.3633, lng: -98.2148 },
  { name: 'Ipswich, SD', path: '/trailer-rentals/south-dakota/ipswich', lat: 45.4453, lng: -99.0309 },
  { name: 'Jefferson, SD', path: '/trailer-rentals/south-dakota/jefferson', lat: 42.6047, lng: -96.5584 },
  { name: 'Kadoka, SD', path: '/trailer-rentals/south-dakota/kadoka', lat: 43.8333, lng: -101.5115 },
  { name: 'Kennebec, SD', path: '/trailer-rentals/south-dakota/kennebec', lat: 43.9022, lng: -99.8628 },
  { name: 'Keystone, SD', path: '/trailer-rentals/south-dakota/keystone', lat: 43.8953, lng: -103.4284 },
  { name: 'Kimball, SD', path: '/trailer-rentals/south-dakota/kimball', lat: 43.7461, lng: -98.9576 },
  { name: 'Lake Andes, SD', path: '/trailer-rentals/south-dakota/lake-andes', lat: 43.1572, lng: -98.5384 },
  { name: 'Lead, SD', path: '/trailer-rentals/south-dakota/lead', lat: 44.3503, lng: -103.7659 },
  { name: 'Lemmon, SD', path: '/trailer-rentals/south-dakota/lemmon', lat: 45.9403, lng: -102.1598 },
  { name: 'Lennox, SD', path: '/trailer-rentals/south-dakota/lennox', lat: 43.3533, lng: -96.8984 },
  { name: 'Madison, SD', path: '/trailer-rentals/south-dakota/madison', lat: 44.0061, lng: -97.1134 },
  { name: 'Martin, SD', path: '/trailer-rentals/south-dakota/martin', lat: 43.1722, lng: -101.7315 },
  { name: 'McLaughlin, SD', path: '/trailer-rentals/south-dakota/mclaughlin', lat: 45.8122, lng: -100.8128 },
  { name: 'Menno, SD', path: '/trailer-rentals/south-dakota/menno', lat: 43.2397, lng: -97.5776 },
  { name: 'Milbank, SD', path: '/trailer-rentals/south-dakota/milbank', lat: 45.2222, lng: -96.6359 },
  { name: 'Miller, SD', path: '/trailer-rentals/south-dakota/miller', lat: 44.5197, lng: -98.9879 },
  { name: 'Mission, SD', path: '/trailer-rentals/south-dakota/mission', lat: 43.3053, lng: -100.6628 },
  { name: 'Mitchell, SD', path: '/trailer-rentals/south-dakota/mitchell', lat: 43.7097, lng: -98.0265 },
  { name: 'Mobridge, SD', path: '/trailer-rentals/south-dakota/mobridge', lat: 45.5403, lng: -100.4348 },
  { name: 'North Sioux City, SD', path: '/trailer-rentals/south-dakota/north-sioux-city', lat: 42.5272, lng: -96.4834 },
  { name: 'Parker, SD', path: '/trailer-rentals/south-dakota/parker', lat: 43.3972, lng: -97.1415 },
  { name: 'Parkston, SD', path: '/trailer-rentals/south-dakota/parkston', lat: 43.3961, lng: -97.9865 },
  { name: 'Philip, SD', path: '/trailer-rentals/south-dakota/philip', lat: 44.0397, lng: -101.6634 },
  { name: 'Pierre, SD', path: '/trailer-rentals/south-dakota/pierre', lat: 44.3683, lng: -100.3515 },
  { name: 'Platte, SD', path: '/trailer-rentals/south-dakota/platte', lat: 43.3861, lng: -98.8434 },
  { name: 'Rapid City, SD', path: '/trailer-rentals/south-dakota/rapid-city', lat: 44.0803, lng: -103.2315 },
  { name: 'Redfield, SD', path: '/trailer-rentals/south-dakota/redfield', lat: 44.8772, lng: -98.5184 },
  { name: 'Roscoe, SD', path: '/trailer-rentals/south-dakota/roscoe', lat: 45.4533, lng: -99.3384 },
  { name: 'Salem, SD', path: '/trailer-rentals/south-dakota/salem', lat: 43.7233, lng: -97.3865 },
  { name: 'Scotland, SD', path: '/trailer-rentals/south-dakota/scotland', lat: 43.1472, lng: -97.7159 },
  { name: 'Selby, SD', path: '/trailer-rentals/south-dakota/selby', lat: 45.5061, lng: -100.0315 },
  { name: 'Sioux Falls, SD', path: '/trailer-rentals/south-dakota/sioux-falls', lat: 43.5503, lng: -96.7009 },
  { name: 'Sisseton, SD', path: '/trailer-rentals/south-dakota/sisseton', lat: 45.6647, lng: -97.0515 },
  { name: 'Spearfish, SD', path: '/trailer-rentals/south-dakota/spearfish', lat: 44.4903, lng: -103.8598 },
  { name: 'Springfield, SD', path: '/trailer-rentals/south-dakota/springfield', lat: 42.8533, lng: -97.8984 },
  { name: 'Sturgis, SD', path: '/trailer-rentals/south-dakota/sturgis', lat: 44.4097, lng: -103.5098 },
  { name: 'Tea, SD', path: '/trailer-rentals/south-dakota/tea', lat: 43.4453, lng: -96.8359 },
  { name: 'Timber Lake, SD', path: '/trailer-rentals/south-dakota/timber-lake', lat: 45.4297, lng: -101.0759 },
  { name: 'Tyndall, SD', path: '/trailer-rentals/south-dakota/tyndall', lat: 42.9933, lng: -97.8648 },
  { name: 'Vermillion, SD', path: '/trailer-rentals/south-dakota/vermillion', lat: 42.7797, lng: -96.9298 },
  { name: 'Viborg, SD', path: '/trailer-rentals/south-dakota/viborg', lat: 43.1722, lng: -97.0815 },
  { name: 'Volga, SD', path: '/trailer-rentals/south-dakota/volga', lat: 44.3197, lng: -96.9234 },
  { name: 'Wagner, SD', path: '/trailer-rentals/south-dakota/wagner', lat: 43.0833, lng: -98.2973 },
  { name: 'Wakonda, SD', path: '/trailer-rentals/south-dakota/wakonda', lat: 43.0075, lng: -97.0973 },
  { name: 'Wall, SD', path: '/trailer-rentals/south-dakota/wall', lat: 43.9947, lng: -102.2415 },
  { name: 'Watertown, SD', path: '/trailer-rentals/south-dakota/watertown', lat: 44.8994, lng: -97.1150 },
  { name: 'Webster, SD', path: '/trailer-rentals/south-dakota/webster', lat: 45.3344, lng: -97.5206 },
  { name: 'Wessington Springs, SD', path: '/trailer-rentals/south-dakota/wessington-springs', lat: 44.0808, lng: -98.5695 },
  { name: 'Whitewood, SD', path: '/trailer-rentals/south-dakota/whitewood', lat: 44.4561, lng: -103.6374 },
  { name: 'Winner, SD', path: '/trailer-rentals/south-dakota/winner', lat: 43.3761, lng: -99.8593 },
  { name: 'Woonsocket, SD', path: '/trailer-rentals/south-dakota/woonsocket', lat: 44.0494, lng: -98.2762 },
  { name: 'Yankton, SD', path: '/trailer-rentals/south-dakota/yankton', lat: 42.8711, lng: -97.3973 },
];

export const tennesseeCities:ICity[] = [
  { name: 'Adams, TN', path: '/trailer-rentals/tennessee/adams', lat: 36.5828, lng: -87.0650 },
  { name: 'Alcoa, TN', path: '/trailer-rentals/tennessee/alcoa', lat: 35.7895, lng: -83.9738 },
  { name: 'Alexandria, TN', path: '/trailer-rentals/tennessee/alexandria', lat: 36.0762, lng: -86.0322 },
  { name: 'Algood, TN', path: '/trailer-rentals/tennessee/algood', lat: 36.1956, lng: -85.4491 },
  { name: 'Arlington, TN', path: '/trailer-rentals/tennessee/arlington', lat: 35.2968, lng: -89.6612 },
  { name: 'Ashland City, TN', path: '/trailer-rentals/tennessee/ashland-city', lat: 36.2745, lng: -87.0647 },
  { name: 'Athens, TN', path: '/trailer-rentals/tennessee/athens', lat: 35.4420, lng: -84.5920 },
  { name: 'Atoka, TN', path: '/trailer-rentals/tennessee/atoka', lat: 35.4415, lng: -89.7751 },
  { name: 'Bartlett, TN', path: '/trailer-rentals/tennessee/bartlett', lat: 35.2045, lng: -89.8730 },
  { name: 'Baxter, TN', path: '/trailer-rentals/tennessee/baxter', lat: 36.1545, lng: -85.6353 },
  { name: 'Bell Buckle, TN', path: '/trailer-rentals/tennessee/bell-buckle', lat: 35.5912, lng: -86.3544 },
  { name: 'Benton, TN', path: '/trailer-rentals/tennessee/benton', lat: 35.1745, lng: -84.6533 },
  { name: 'Berry Hill, TN', path: '/trailer-rentals/tennessee/berry-hill', lat: 36.1262, lng: -86.7694 },
  { name: 'Bluff City, TN', path: '/trailer-rentals/tennessee/bluff-city', lat: 36.4743, lng: -82.2732 },
  { name: 'Bolivar, TN', path: '/trailer-rentals/tennessee/bolivar', lat: 35.2565, lng: -88.9873 },
  { name: 'Bristol, TN', path: '/trailer-rentals/tennessee/bristol', lat: 36.5951, lng: -82.1887 },
  { name: 'Brownsville, TN', path: '/trailer-rentals/tennessee/brownsville', lat: 35.5931, lng: -89.2623 },
  { name: 'Camden, TN', path: '/trailer-rentals/tennessee/camden', lat: 36.0584, lng: -88.0978 },
  { name: 'Carthage, TN', path: '/trailer-rentals/tennessee/carthage', lat: 36.2523, lng: -85.9514 },
  { name: 'Celina, TN', path: '/trailer-rentals/tennessee/celina', lat: 36.5481, lng: -85.5044 },
  { name: 'Chapel Hill, TN', path: '/trailer-rentals/tennessee/chapel-hill', lat: 35.6256, lng: -86.6944 },
  { name: 'Chattanooga, TN', path: '/trailer-rentals/tennessee/chattanooga', lat: 35.0456, lng: -85.3097 },
  { name: 'Church Hill, TN', path: '/trailer-rentals/tennessee/church-hill', lat: 36.5220, lng: -82.7132 },
  { name: 'Clarksville, TN', path: '/trailer-rentals/tennessee/clarksville', lat: 36.5298, lng: -87.3595 },
  { name: 'Cleveland, TN', path: '/trailer-rentals/tennessee/cleveland', lat: 35.1595, lng: -84.8766 },
  { name: 'Clifton, TN', path: '/trailer-rentals/tennessee/clifton', lat: 35.3856, lng: -87.9934 },
  { name: 'Collierville, TN', path: '/trailer-rentals/tennessee/collierville', lat: 35.0420, lng: -89.6645 },
  { name: 'Columbia, TN', path: '/trailer-rentals/tennessee/columbia', lat: 35.6151, lng: -87.0353 },
  { name: 'Cookeville, TN', path: '/trailer-rentals/tennessee/cookeville', lat: 36.1628, lng: -85.5016 },
  { name: 'Coopertown, TN', path: '/trailer-rentals/tennessee/coopertown', lat: 36.4223, lng: -86.9489 },
  { name: 'Covington, TN', path: '/trailer-rentals/tennessee/covington', lat: 35.5643, lng: -89.6462 },
  { name: 'Cross Plains, TN', path: '/trailer-rentals/tennessee/cross-plains', lat: 36.5456, lng: -86.6794 },
  { name: 'Crossville, TN', path: '/trailer-rentals/tennessee/crossville', lat: 35.9489, lng: -85.0269 },
  { name: 'Dandridge, TN', path: '/trailer-rentals/tennessee/dandridge', lat: 36.0159, lng: -83.4143 },
  { name: 'Dayton, TN', path: '/trailer-rentals/tennessee/dayton', lat: 35.4937, lng: -85.0127 },
  { name: 'Decatur, TN', path: '/trailer-rentals/tennessee/decatur', lat: 35.5145, lng: -84.7941 },
  { name: 'Dickson, TN', path: '/trailer-rentals/tennessee/dickson', lat: 36.0770, lng: -87.3878 },
  { name: 'Dover, TN', path: '/trailer-rentals/tennessee/dover', lat: 36.4862, lng: -87.8372 },
  { name: 'Dresden, TN', path: '/trailer-rentals/tennessee/dresden', lat: 36.2845, lng: -88.7081 },
  { name: 'Dunlap, TN', path: '/trailer-rentals/tennessee/dunlap', lat: 35.3715, lng: -85.3891 },
  { name: 'Dyersburg, TN', path: '/trailer-rentals/tennessee/dyersburg', lat: 36.0345, lng: -89.3856 },
  { name: 'East Ridge, TN', path: '/trailer-rentals/tennessee/east-ridge', lat: 34.9945, lng: -85.2491 },
  { name: 'Elizabethton, TN', path: '/trailer-rentals/tennessee/elizabethton', lat: 36.3487, lng: -82.2107 },
  { name: 'Erwin, TN', path: '/trailer-rentals/tennessee/erwin', lat: 36.1451, lng: -82.4165 },
  { name: 'Etowah, TN', path: '/trailer-rentals/tennessee/etowah', lat: 35.3256, lng: -84.5244 },
  { name: 'Fairview, TN', path: '/trailer-rentals/tennessee/fairview', lat: 35.9828, lng: -87.1234 },
  { name: 'Fayetteville, TN', path: '/trailer-rentals/tennessee/fayetteville', lat: 35.1523, lng: -86.5703 },
  { name: 'Franklin, TN', path: '/trailer-rentals/tennessee/franklin', lat: 35.9251, lng: -86.8689 },
  { name: 'Gallatin, TN', path: '/trailer-rentals/tennessee/gallatin', lat: 36.3884, lng: -86.4467 },
  { name: 'Germantown, TN', path: '/trailer-rentals/tennessee/germantown', lat: 35.0868, lng: -89.8101 },
  { name: 'Goodlettsville, TN', path: '/trailer-rentals/tennessee/goodlettsville', lat: 36.3231, lng: -86.7133 },
  { name: 'Greenbrier, TN', path: '/trailer-rentals/tennessee/greenbrier', lat: 36.4278, lng: -86.8044 },
  { name: 'Greeneville, TN', path: '/trailer-rentals/tennessee/greeneville', lat: 36.1632, lng: -82.8309 },
  { name: 'Halls, TN', path: '/trailer-rentals/tennessee/halls', lat: 35.8781, lng: -89.3962 },
  { name: 'Harriman, TN', path: '/trailer-rentals/tennessee/harriman', lat: 35.9334, lng: -84.5527 },
  { name: 'Hartsville, TN', path: '/trailer-rentals/tennessee/hartsville', lat: 36.3906, lng: -86.1603 },
  { name: 'Henderson, TN', path: '/trailer-rentals/tennessee/henderson', lat: 35.4395, lng: -88.6417 },
  { name: 'Hendersonville, TN', path: '/trailer-rentals/tennessee/hendersonville', lat: 36.3048, lng: -86.6200 },
  { name: 'Hohenwald, TN', path: '/trailer-rentals/tennessee/hohenwald', lat: 35.5473, lng: -87.5511 },
  { name: 'Humboldt, TN', path: '/trailer-rentals/tennessee/humboldt', lat: 35.8195, lng: -88.9156 },
  { name: 'Jackson, TN', path: '/trailer-rentals/tennessee/jackson', lat: 35.6145, lng: -88.8139 },
  { name: 'Jefferson City, TN', path: '/trailer-rentals/tennessee/jefferson-city', lat: 36.1223, lng: -83.4927 },
  { name: 'Johnson City, TN', path: '/trailer-rentals/tennessee/johnson-city', lat: 36.3134, lng: -82.3535 },
  { name: 'Jonesborough, TN', path: '/trailer-rentals/tennessee/jonesborough', lat: 36.2948, lng: -82.4732 },
  { name: 'Kingsport, TN', path: '/trailer-rentals/tennessee/kingsport', lat: 36.5484, lng: -82.5618 },
  { name: 'Kingston, TN', path: '/trailer-rentals/tennessee/kingston', lat: 35.8806, lng: -84.5127 },
  { name: 'Knoxville, TN', path: '/trailer-rentals/tennessee/knoxville', lat: 35.9606, lng: -83.9207 },
  { name: 'La Vergne, TN', path: '/trailer-rentals/tennessee/la-vergne', lat: 36.0156, lng: -86.5811 },
  { name: 'Lafayette, TN', path: '/trailer-rentals/tennessee/lafayette', lat: 36.5212, lng: -86.0261 },
  { name: 'Lawrenceburg, TN', path: '/trailer-rentals/tennessee/lawrenceburg', lat: 35.2423, lng: -87.3347 },
  { name: 'Lebanon, TN', path: '/trailer-rentals/tennessee/lebanon', lat: 36.2081, lng: -86.2911 },
  { name: 'Lenoir City, TN', path: '/trailer-rentals/tennessee/lenoir-city', lat: 35.7973, lng: -84.2566 },
  { name: 'Lewisburg, TN', path: '/trailer-rentals/tennessee/lewisburg', lat: 35.4495, lng: -86.7889 },
  { name: 'Lexington, TN', path: '/trailer-rentals/tennessee/lexington', lat: 35.6506, lng: -88.3934 },
  { name: 'Livingston, TN', path: '/trailer-rentals/tennessee/livingston', lat: 36.3834, lng: -85.3239 },
  { name: 'Madisonville, TN', path: '/trailer-rentals/tennessee/madisonville', lat: 35.5195, lng: -84.3634 },
  { name: 'Manchester, TN', path: '/trailer-rentals/tennessee/manchester', lat: 35.4812, lng: -86.0889 },
  { name: 'Martin, TN', path: '/trailer-rentals/tennessee/martin', lat: 36.3434, lng: -88.8506 },
  { name: 'Maryville, TN', path: '/trailer-rentals/tennessee/maryville', lat: 35.7565, lng: -83.9705 },
  { name: 'McKenzie, TN', path: '/trailer-rentals/tennessee/mckenzie', lat: 36.1323, lng: -88.5189 },
  { name: 'McMinnville, TN', path: '/trailer-rentals/tennessee/mcminnville', lat: 35.6834, lng: -85.7694 },
  { name: 'Medina, TN', path: '/trailer-rentals/tennessee/medina', lat: 35.8023, lng: -88.7856 },
  { name: 'Memphis, TN', path: '/trailer-rentals/tennessee/memphis', lat: 35.1495, lng: -90.0490 },
  { name: 'Milan, TN', path: '/trailer-rentals/tennessee/milan', lat: 35.9256, lng: -88.7589 },
  { name: 'Millersville, TN', path: '/trailer-rentals/tennessee/millersville', lat: 36.3723, lng: -86.7156 },
  { name: 'Morristown, TN', path: '/trailer-rentals/tennessee/morristown', lat: 36.2134, lng: -83.2941 },
  { name: 'Mount Juliet, TN', path: '/trailer-rentals/tennessee/mount-juliet', lat: 36.2006, lng: -86.5186 },
  { name: 'Mountain City, TN', path: '/trailer-rentals/tennessee/mountain-city', lat: 36.4745, lng: -81.8043 },
  { name: 'Munford, TN', path: '/trailer-rentals/tennessee/munford', lat: 35.4495, lng: -89.8156 },
  { name: 'Murfreesboro, TN', path: '/trailer-rentals/tennessee/murfreesboro', lat: 35.8456, lng: -86.3903 },
  { name: 'Nashville, TN', path: '/trailer-rentals/tennessee/nashville', lat: 36.1627, lng: -86.7816, active: true, showNewVersion: true },
  { name: 'New Johnsonville, TN', path: '/trailer-rentals/tennessee/new-johnsonville', lat: 36.0195, lng: -87.9634 },
  { name: 'New Market, TN', path: '/trailer-rentals/tennessee/new-market', lat: 36.1062, lng: -83.5534 },
  { name: 'Newport, TN', path: '/trailer-rentals/tennessee/newport', lat: 35.9673, lng: -83.1871 },
  { name: 'Nolensville, TN', path: '/trailer-rentals/tennessee/nolensville', lat: 35.9523, lng: -86.6694 },
  { name: 'Oak Ridge, TN', path: '/trailer-rentals/tennessee/oak-ridge', lat: 36.0107, lng: -84.2696 },
  { name: 'Oakland, TN', path: '/trailer-rentals/tennessee/oakland', lat: 35.2284, lng: -89.5156 },
  { name: 'Paris, TN', path: '/trailer-rentals/tennessee/paris', lat: 36.3020, lng: -88.3268 },
  { name: 'Parsons, TN', path: '/trailer-rentals/tennessee/parsons', lat: 35.6498, lng: -88.1267 },
  { name: 'Pegram, TN', path: '/trailer-rentals/tennessee/pegram', lat: 36.1031, lng: -87.0531 },
  { name: 'Pigeon Forge, TN', path: '/trailer-rentals/tennessee/pigeon-forge', lat: 35.7884, lng: -83.5543 },
  { name: 'Pikeville, TN', path: '/trailer-rentals/tennessee/pikeville', lat: 35.6056, lng: -85.1916 },
  { name: 'Portland, TN', path: '/trailer-rentals/tennessee/portland', lat: 36.5873, lng: -86.5167 },
  { name: 'Pulaski, TN', path: '/trailer-rentals/tennessee/pulaski', lat: 35.1998, lng: -87.0300 },
  { name: 'Red Bank, TN', path: '/trailer-rentals/tennessee/red-bank', lat: 35.1120, lng: -85.2941 },
  { name: 'Ridgetop, TN', path: '/trailer-rentals/tennessee/ridgetop', lat: 36.3964, lng: -86.7794 },
  { name: 'Ripley, TN', path: '/trailer-rentals/tennessee/ripley', lat: 35.7451, lng: -89.5298 },
  { name: 'Rockwood, TN', path: '/trailer-rentals/tennessee/rockwood', lat: 35.8659, lng: -84.6844 },
  { name: 'Rogersville, TN', path: '/trailer-rentals/tennessee/rogersville', lat: 36.4079, lng: -83.0057 },
  { name: 'Savannah, TN', path: '/trailer-rentals/tennessee/savannah', lat: 35.2248, lng: -88.2498 },
  { name: 'Sevierville, TN', path: '/trailer-rentals/tennessee/sevierville', lat: 35.8681, lng: -83.5618 },
  { name: 'Shelbyville, TN', path: '/trailer-rentals/tennessee/shelbyville', lat: 35.4834, lng: -86.4603 },
  { name: 'Signal Mountain, TN', path: '/trailer-rentals/tennessee/signal-mountain', lat: 35.1229, lng: -85.3436 },
  { name: 'Smithville, TN', path: '/trailer-rentals/tennessee/smithville', lat: 35.9601, lng: -85.8144 },
  { name: 'Smyrna, TN', path: '/trailer-rentals/tennessee/smyrna', lat: 35.9828, lng: -86.5186 },
  { name: 'Soddy-Daisy, TN', path: '/trailer-rentals/tennessee/soddy-daisy', lat: 35.2573, lng: -85.1830 },
  { name: 'South Fulton, TN', path: '/trailer-rentals/tennessee/south-fulton', lat: 36.4967, lng: -88.8762 },
  { name: 'Sparta, TN', path: '/trailer-rentals/tennessee/sparta', lat: 35.9251, lng: -85.4641 },
  { name: 'Spencer, TN', path: '/trailer-rentals/tennessee/spencer', lat: 35.7434, lng: -85.4650 },
  { name: 'Spring City, TN', path: '/trailer-rentals/tennessee/spring-city', lat: 35.6920, lng: -84.8641 },
  { name: 'Spring Hill, TN', path: '/trailer-rentals/tennessee/spring-hill', lat: 35.7512, lng: -86.9300 },
  { name: 'Springfield, TN', path: '/trailer-rentals/tennessee/springfield', lat: 36.5092, lng: -86.8844 },
  { name: 'Sweetwater, TN', path: '/trailer-rentals/tennessee/sweetwater', lat: 35.6015, lng: -84.4616 },
  { name: 'Tazewell, TN', path: '/trailer-rentals/tennessee/tazewell', lat: 36.4548, lng: -83.5691 },
  { name: 'Tennessee Ridge, TN', path: '/trailer-rentals/tennessee/tennessee-ridge', lat: 36.3212, lng: -87.7731 },
  { name: 'Trenton, TN', path: '/trailer-rentals/tennessee/trenton', lat: 35.9801, lng: -88.9412 },
  { name: 'Tullahoma, TN', path: '/trailer-rentals/tennessee/tullahoma', lat: 35.3620, lng: -86.2094 },
  { name: 'Unicoi, TN', path: '/trailer-rentals/tennessee/unicoi', lat: 36.1979, lng: -82.3493 },
  { name: 'Union City, TN', path: '/trailer-rentals/tennessee/union-city', lat: 36.4242, lng: -89.0579 },
  { name: 'Vonore, TN', path: '/trailer-rentals/tennessee/vonore', lat: 35.5956, lng: -84.2302 },
  { name: 'Wartrace, TN', path: '/trailer-rentals/tennessee/wartrace', lat: 35.5262, lng: -86.3333 },
  { name: 'Waverly, TN', path: '/trailer-rentals/tennessee/waverly', lat: 36.0834, lng: -87.7936 },
  { name: 'Waynesboro, TN', path: '/trailer-rentals/tennessee/waynesboro', lat: 35.3195, lng: -87.7628 },
  { name: 'White Bluff, TN', path: '/trailer-rentals/tennessee/white-bluff', lat: 36.1076, lng: -87.2200 },
  { name: 'White House, TN', path: '/trailer-rentals/tennessee/white-house', lat: 36.4700, lng: -86.6511 },
  { name: 'Winchester, TN', path: '/trailer-rentals/tennessee/winchester', lat: 35.1854, lng: -86.1122 },
  { name: 'Woodbury, TN', path: '/trailer-rentals/tennessee/woodbury', lat: 35.8278, lng: -86.0719 },
];

export const utahCities: ICity[] = [
  { name: 'Alpine, UT', path: '/trailer-rentals/utah/alpine', lat: 40.4533, lng: -111.7770 },
  { name: 'Alta, UT', path: '/trailer-rentals/utah/alta', lat: 40.5883, lng: -111.6374 },
  { name: 'American Fork, UT', path: '/trailer-rentals/utah/american-fork', lat: 40.3769, lng: -111.7958 },
  { name: 'Blanding, UT', path: '/trailer-rentals/utah/blanding', lat: 37.6244, lng: -109.4785 },
  { name: 'Bluffdale, UT', path: '/trailer-rentals/utah/bluffdale', lat: 40.4891, lng: -111.9383 },
  { name: 'Bountiful, UT', path: '/trailer-rentals/utah/bountiful', lat: 40.8894, lng: -111.8808 },
  { name: 'Brigham City, UT', path: '/trailer-rentals/utah/brigham-city', lat: 41.5102, lng: -112.0155 },
  { name: 'Cannonville, UT', path: '/trailer-rentals/utah/cannonville', lat: 37.5675, lng: -112.0513 },
  { name: 'Cedar City, UT', path: '/trailer-rentals/utah/cedar-city', lat: 37.6775, lng: -113.0619 },
  { name: 'Cedar Hills, UT', path: '/trailer-rentals/utah/cedar-hills', lat: 40.4141, lng: -111.7555 },
  { name: 'Centerville, UT', path: '/trailer-rentals/utah/centerville', lat: 40.9183, lng: -111.8722 },
  { name: 'Clearfield, UT', path: '/trailer-rentals/utah/clearfield', lat: 41.1108, lng: -112.0260 },
  { name: 'Clinton, UT', path: '/trailer-rentals/utah/clinton', lat: 41.1394, lng: -112.0508 },
  { name: 'Cottonwood Heights, UT', path: '/trailer-rentals/utah/cottonwood-heights', lat: 40.6197, lng: -111.8100 },
  { name: 'Draper, UT', path: '/trailer-rentals/utah/draper', lat: 40.5247, lng: -111.8638 },
  { name: 'Eagle Mountain, UT', path: '/trailer-rentals/utah/eagle-mountain', lat: 40.3144, lng: -112.0069 },
  { name: 'Elk Ridge, UT', path: '/trailer-rentals/utah/elk-ridge', lat: 40.0177, lng: -111.6766 },
  { name: 'Ephraim, UT', path: '/trailer-rentals/utah/ephraim', lat: 39.3591, lng: -111.5866 },
  { name: 'Enoch, UT', path: '/trailer-rentals/utah/enoch', lat: 37.7730, lng: -113.0291 },
  { name: 'Enterprise, UT', path: '/trailer-rentals/utah/enterprise', lat: 37.5730, lng: -113.7191 },
  { name: 'Fairview, UT', path: '/trailer-rentals/utah/fairview', lat: 39.6283, lng: -111.4391 },
  { name: 'Farmington, UT', path: '/trailer-rentals/utah/farmington', lat: 40.9805, lng: -111.8874 },
  { name: 'Farr West, UT', path: '/trailer-rentals/utah/farr-west', lat: 41.2972, lng: -112.0269 },
  { name: 'Fountain Green, UT', path: '/trailer-rentals/utah/fountain-green', lat: 39.6277, lng: -111.6352 },
  { name: 'Fruit Heights, UT', path: '/trailer-rentals/utah/fruit-heights', lat: 41.0322, lng: -111.9033 },
  { name: 'Garden City, UT', path: '/trailer-rentals/utah/garden-city', lat: 41.9466, lng: -111.3938 },
  { name: 'Garland, UT', path: '/trailer-rentals/utah/garland', lat: 41.7416, lng: -112.1641 },
  { name: 'Grantsville, UT', path: '/trailer-rentals/utah/grantsville', lat: 40.5991, lng: -112.4641 },
  { name: 'Gunnison, UT', path: '/trailer-rentals/utah/gunnison', lat: 39.1552, lng: -111.8183 },
  { name: 'Harrisville, UT', path: '/trailer-rentals/utah/harrisville', lat: 41.3041, lng: -111.9833 },
  { name: 'Heber City, UT', path: '/trailer-rentals/utah/heber-city', lat: 40.5070, lng: -111.4133 },
  { name: 'Herriman, UT', path: '/trailer-rentals/utah/herriman', lat: 40.5147, lng: -112.0328 },
  { name: 'Highland, UT', path: '/trailer-rentals/utah/highland', lat: 40.4186, lng: -111.8044 },
  { name: 'Holladay, UT', path: '/trailer-rentals/utah/holladay', lat: 40.6686, lng: -111.8244 },
  { name: 'Honeyville, UT', path: '/trailer-rentals/utah/honeyville', lat: 41.6383, lng: -112.0872 },
  { name: 'Hooper, UT', path: '/trailer-rentals/utah/hooper', lat: 41.1633, lng: -112.1208 },
  { name: 'Hurricane, UT', path: '/trailer-rentals/utah/hurricane', lat: 37.1753, lng: -113.2894 },
  { name: 'Hyrum, UT', path: '/trailer-rentals/utah/hyrum', lat: 41.6341, lng: -111.8522 },
  { name: 'Ivins, UT', path: '/trailer-rentals/utah/ivins', lat: 37.1683, lng: -113.6794 },
  { name: 'Kamas, UT', path: '/trailer-rentals/utah/kamas', lat: 40.6433, lng: -111.2744 },
  { name: 'Kanab, UT', path: '/trailer-rentals/utah/kanab', lat: 37.0475, lng: -112.5269 },
  { name: 'Kaysville, UT', path: '/trailer-rentals/utah/kaysville', lat: 41.0353, lng: -111.9383 },
  { name: 'La Verkin, UT', path: '/trailer-rentals/utah/la-verkin', lat: 37.2025, lng: -113.2722 },
  { name: 'Layton, UT', path: '/trailer-rentals/utah/layton', lat: 41.0603, lng: -111.9711 },
  { name: 'Lehi, UT', path: '/trailer-rentals/utah/lehi', lat: 40.3916, lng: -111.8508 },
  { name: 'Lindon, UT', path: '/trailer-rentals/utah/lindon', lat: 40.3433, lng: -111.7208 },
  { name: 'Logan, UT', path: '/trailer-rentals/utah/logan', lat: 41.7361, lng: -111.8338 },
  { name: 'Mapleton, UT', path: '/trailer-rentals/utah/mapleton', lat: 40.1316, lng: -111.5794 },
  { name: 'Magna, UT', path: '/trailer-rentals/utah/magna', lat: 40.7091, lng: -112.1016, active: true, showNewVersion: true },
  { name: 'Midvale, UT', path: '/trailer-rentals/utah/midvale', lat: 40.6116, lng: -111.8994 },
  { name: 'Midway, UT', path: '/trailer-rentals/utah/midway', lat: 40.5125, lng: -111.4744 },
  { name: 'Millcreek, UT', path: '/trailer-rentals/utah/millcreek', lat: 40.6869, lng: -111.8758 },
  { name: 'Moab, UT', path: '/trailer-rentals/utah/moab', lat: 38.5733, lng: -109.5498 },
  { name: 'Mona, UT', path: '/trailer-rentals/utah/mona', lat: 39.8183, lng: -111.8558 },
  { name: 'Monroe, UT', path: '/trailer-rentals/utah/monroe', lat: 38.6253, lng: -112.1208 },
  { name: 'Morgan, UT', path: '/trailer-rentals/utah/morgan', lat: 41.0375, lng: -111.6769 },
  { name: 'Mount Pleasant, UT', path: '/trailer-rentals/utah/mount-pleasant', lat: 39.5453, lng: -111.4558 },
  { name: 'Murray, UT', path: '/trailer-rentals/utah/murray', lat: 40.6669, lng: -111.8872, active: true, showNewVersion: true },
  { name: 'Naples, UT', path: '/trailer-rentals/utah/naples', lat: 40.4269, lng: -109.4983 },
  { name: 'Nephi, UT', path: '/trailer-rentals/utah/nephi', lat: 39.7103, lng: -111.8358 },
  { name: 'North Logan, UT', path: '/trailer-rentals/utah/north-logan', lat: 41.7691, lng: -111.8044 },
  { name: 'North Ogden, UT', path: '/trailer-rentals/utah/north-ogden', lat: 41.3075, lng: -111.9608 },
  { name: 'North Salt Lake, UT', path: '/trailer-rentals/utah/north-salt-lake', lat: 40.8483, lng: -111.9069 },
  { name: 'Ogden, UT', path: '/trailer-rentals/utah/ogden', lat: 41.2230, lng: -111.9738 },
  { name: 'Orem, UT', path: '/trailer-rentals/utah/orem', lat: 40.2969, lng: -111.6947 },
  { name: 'Panguitch, UT', path: '/trailer-rentals/utah/panguitch', lat: 37.8225, lng: -112.4358 },
  { name: 'Park City, UT', path: '/trailer-rentals/utah/park-city', lat: 40.6461, lng: -111.4980 },
  { name: 'Parowan, UT', path: '/trailer-rentals/utah/parowan', lat: 37.8425, lng: -112.8272 },
  { name: 'Payson, UT', path: '/trailer-rentals/utah/payson', lat: 40.0447, lng: -111.7322 },
  { name: 'Plain City, UT', path: '/trailer-rentals/utah/plain-city', lat: 41.2991, lng: -112.0808 },
  { name: 'Pleasant Grove, UT', path: '/trailer-rentals/utah/pleasant-grove', lat: 40.3647, lng: -111.7383 },
  { name: 'Pleasant View, UT', path: '/trailer-rentals/utah/pleasant-view', lat: 41.3141, lng: -111.9911 },
  { name: 'Price, UT', path: '/trailer-rentals/utah/price', lat: 39.5991, lng: -110.8108 },
  { name: 'Providence, UT', path: '/trailer-rentals/utah/providence', lat: 41.7069, lng: -111.8172 },
  { name: 'Provo, UT', path: '/trailer-rentals/utah/provo', lat: 40.2338, lng: -111.6585, active: true, showNewVersion: true  },
  { name: 'Richfield, UT', path: '/trailer-rentals/utah/richfield', lat: 38.7725, lng: -112.0844 },
  { name: 'Riverdale, UT', path: '/trailer-rentals/utah/riverdale', lat: 41.1769, lng: -112.0022 },
  { name: 'Riverton, UT', path: '/trailer-rentals/utah/riverton', lat: 40.5219, lng: -111.9397 },
  { name: 'Roosevelt, UT', path: '/trailer-rentals/utah/roosevelt', lat: 40.2975, lng: -110.0008 },
  { name: 'Roy, UT', path: '/trailer-rentals/utah/roy', lat: 41.1619, lng: -112.0269 },
  { name: 'Salem, UT', path: '/trailer-rentals/utah/salem', lat: 40.0533, lng: -111.6722 },
  { name: 'Salt Lake City, UT', path: '/trailer-rentals/utah/salt-lake-city', lat: 40.7608, lng: -111.8910, active: true, showNewVersion: true },
  { name: 'Sandy, UT', path: '/trailer-rentals/utah/sandy', lat: 40.5649, lng: -111.8380, active: true, showNewVersion: true }, 
  { name: 'Santa Clara, UT', path: '/trailer-rentals/utah/santa-clara', lat: 37.1325, lng: -113.6544 },
  { name: 'Santaquin, UT', path: '/trailer-rentals/utah/santaquin', lat: 39.9753, lng: -111.7858 },
  { name: 'Saratoga Springs, UT', path: '/trailer-rentals/utah/saratoga-springs', lat: 40.3497, lng: -111.9044 },
  { name: 'Smithfield, UT', path: '/trailer-rentals/utah/smithfield', lat: 41.8333, lng: -111.8333 },
  { name: 'South Jordan, UT', path: '/trailer-rentals/utah/south-jordan', lat: 40.5622, lng: -111.9297 },
  { name: 'South Ogden, UT', path: '/trailer-rentals/utah/south-ogden', lat: 41.1919, lng: -111.9711 },
  { name: 'South Salt Lake, UT', path: '/trailer-rentals/utah/south-salt-lake', lat: 40.7075, lng: -111.8883 },
  { name: 'Spanish Fork, UT', path: '/trailer-rentals/utah/spanish-fork', lat: 40.1144, lng: -111.6544 },
  { name: 'Spring City, UT', path: '/trailer-rentals/utah/spring-city', lat: 39.4847, lng: -111.4911 },
  { name: 'Springville, UT', path: '/trailer-rentals/utah/springville', lat: 40.1653, lng: -111.6108 },
  { name: 'St. George, UT', path: '/trailer-rentals/utah/st-george', lat: 37.0965, lng: -113.5684 },
  { name: 'Sunset, UT', path: '/trailer-rentals/utah/sunset', lat: 41.1383, lng: -112.0269 },
  { name: 'Syracuse, UT', path: '/trailer-rentals/utah/syracuse', lat: 41.0894, lng: -112.0641 },
  { name: 'Tooele, UT', path: '/trailer-rentals/utah/tooele', lat: 40.5308, lng: -112.2983 },
  { name: 'Tremonton, UT', path: '/trailer-rentals/utah/tremonton', lat: 41.7119, lng: -112.1658 },
  { name: 'Vernal, UT', path: '/trailer-rentals/utah/vernal', lat: 40.4553, lng: -109.5283 },
  { name: 'Vineyard, UT', path: '/trailer-rentals/utah/vineyard', lat: 40.2970, lng: -111.7547 },
  { name: 'Washington, UT', path: '/trailer-rentals/utah/washington', lat: 37.1305, lng: -113.5083 },
  { name: 'Wellsville, UT', path: '/trailer-rentals/utah/wellsville', lat: 41.6383, lng: -111.9308 },
  { name: 'West Bountiful, UT', path: '/trailer-rentals/utah/west-bountiful', lat: 40.8991, lng: -111.8966 },
  { name: 'West Haven, UT', path: '/trailer-rentals/utah/west-haven', lat: 41.2058, lng: -112.0641 },
  { name: 'West Jordan, UT', path: '/trailer-rentals/utah/west-jordan', lat: 40.6097, lng: -111.9391 },
  { name: 'West Point, UT', path: '/trailer-rentals/utah/west-point', lat: 41.1183, lng: -112.0841 },
  { name: 'West Valley City, UT', path: '/trailer-rentals/utah/west-valley-city', lat: 40.6916, lng: -112.0010 },
  { name: 'Willard, UT', path: '/trailer-rentals/utah/willard', lat: 41.4094, lng: -112.0408 },
  { name: 'Woods Cross, UT', path: '/trailer-rentals/utah/woods-cross', lat: 40.8716, lng: -111.8938 },
];

export const vermontCities:ICity[] = [
  { name: 'Albany, VT', path: '/trailer-rentals/vermont/albany', lat: 44.7284, lng: -72.3707 },
  { name: 'Alburgh, VT', path: '/trailer-rentals/vermont/alburgh', lat: 44.9753, lng: -73.3043 },
  { name: 'Arlington, VT', path: '/trailer-rentals/vermont/arlington', lat: 43.0706, lng: -73.1526 },
  { name: 'Barre, VT', path: '/trailer-rentals/vermont/barre', lat: 44.1970, lng: -72.5028 },
  { name: 'Barton, VT', path: '/trailer-rentals/vermont/barton', lat: 44.7484, lng: -72.1757 },
  { name: 'Bellows Falls, VT', path: '/trailer-rentals/vermont/bellows-falls', lat: 43.1334, lng: -72.4448 },
  { name: 'Bennington, VT', path: '/trailer-rentals/vermont/bennington', lat: 42.8781, lng: -73.1968 },
  { name: 'Bethel, VT', path: '/trailer-rentals/vermont/bethel', lat: 43.8334, lng: -72.6365 },
  { name: 'Bradford, VT', path: '/trailer-rentals/vermont/bradford', lat: 43.9948, lng: -72.1279 },
  { name: 'Brandon, VT', path: '/trailer-rentals/vermont/brandon', lat: 43.7984, lng: -73.0876 },
  { name: 'Brattleboro, VT', path: '/trailer-rentals/vermont/brattleboro', lat: 42.8509, lng: -72.5579 },
  { name: 'Bridgewater, VT', path: '/trailer-rentals/vermont/bridgewater', lat: 43.5865, lng: -72.6182 },
  { name: 'Brighton, VT', path: '/trailer-rentals/vermont/brighton', lat: 44.8153, lng: -71.8834 },
  { name: 'Bristol, VT', path: '/trailer-rentals/vermont/bristol', lat: 44.1334, lng: -73.0798 },
  { name: 'Burke, VT', path: '/trailer-rentals/vermont/burke', lat: 44.5884, lng: -71.9465 },
  { name: 'Burlington, VT', path: '/trailer-rentals/vermont/burlington', lat: 44.4759, lng: -73.2121 },
  { name: 'Cabot, VT', path: '/trailer-rentals/vermont/cabot', lat: 44.4048, lng: -72.3065 },
  { name: 'Castleton, VT', path: '/trailer-rentals/vermont/castleton', lat: 43.6106, lng: -73.1787 },
  { name: 'Chester, VT', path: '/trailer-rentals/vermont/chester', lat: 43.2634, lng: -72.5959 },
  { name: 'Colchester, VT', path: '/trailer-rentals/vermont/colchester', lat: 44.5438, lng: -73.1493 },
  { name: 'Danby, VT', path: '/trailer-rentals/vermont/danby', lat: 43.3434, lng: -73.0015 },
  { name: 'Derby, VT', path: '/trailer-rentals/vermont/derby', lat: 44.9606, lng: -72.1315 },
  { name: 'Dorset, VT', path: '/trailer-rentals/vermont/dorset', lat: 43.2526, lng: -73.0993 },
  { name: 'East Montpelier, VT', path: '/trailer-rentals/vermont/east-montpelier', lat: 44.2706, lng: -72.5015 },
  { name: 'Enosburg Falls, VT', path: '/trailer-rentals/vermont/enosburg-falls', lat: 44.9106, lng: -72.8059 },
  { name: 'Essex, VT', path: '/trailer-rentals/vermont/essex', lat: 44.4906, lng: -73.1115 },
  { name: 'Essex Junction, VT', path: '/trailer-rentals/vermont/essex-junction', lat: 44.4906, lng: -73.1115 },
  { name: 'Fair Haven, VT', path: '/trailer-rentals/vermont/fair-haven', lat: 43.5948, lng: -73.2715 },
  { name: 'Fairfax, VT', path: '/trailer-rentals/vermont/fairfax', lat: 44.7226, lng: -73.0106 },
  { name: 'Fairfield, VT', path: '/trailer-rentals/vermont/fairfield', lat: 44.7984, lng: -72.9376 },
  { name: 'Fairlee, VT', path: '/trailer-rentals/vermont/fairlee', lat: 43.9084, lng: -72.1426 },
  { name: 'Franklin, VT', path: '/trailer-rentals/vermont/franklin', lat: 44.9334, lng: -72.9115 },
  { name: 'Georgia, VT', path: '/trailer-rentals/vermont/georgia', lat: 44.7334, lng: -73.1043 },
  { name: 'Grand Isle, VT', path: '/trailer-rentals/vermont/grand-isle', lat: 44.7165, lng: -73.2959 },
  { name: 'Greensboro, VT', path: '/trailer-rentals/vermont/greensboro', lat: 44.6184, lng: -72.2943 },
  { name: 'Groton, VT', path: '/trailer-rentals/vermont/groton', lat: 44.2284, lng: -72.1987 },
  { name: 'Guilford, VT', path: '/trailer-rentals/vermont/guilford', lat: 42.7834, lng: -72.5806 },
  { name: 'Hardwick, VT', path: '/trailer-rentals/vermont/hardwick', lat: 44.5059, lng: -72.3715 },
  { name: 'Hartford, VT', path: '/trailer-rentals/vermont/hartford', lat: 43.6634, lng: -72.3376 },
  { name: 'Hartland, VT', path: '/trailer-rentals/vermont/hartland', lat: 43.5334, lng: -72.3987 },
  { name: 'Highgate, VT', path: '/trailer-rentals/vermont/highgate', lat: 44.9334, lng: -73.0365 },
  { name: 'Hinesburg, VT', path: '/trailer-rentals/vermont/hinesburg', lat: 44.3334, lng: -73.1093 },
  { name: 'Hyde Park, VT', path: '/trailer-rentals/vermont/hyde-park', lat: 44.5934, lng: -72.6187 },
  { name: 'Jericho, VT', path: '/trailer-rentals/vermont/jericho', lat: 44.5034, lng: -72.9965 },
  { name: 'Johnson, VT', path: '/trailer-rentals/vermont/johnson', lat: 44.6359, lng: -72.6765 },
  { name: 'Killington, VT', path: '/trailer-rentals/vermont/killington', lat: 43.6765, lng: -72.7934 },
  { name: 'Lyndon, VT', path: '/trailer-rentals/vermont/lyndon', lat: 44.5334, lng: -72.0043 },
  { name: 'Manchester, VT', path: '/trailer-rentals/vermont/manchester', lat: 43.1634, lng: -73.0726 },
  { name: 'Marlboro, VT', path: '/trailer-rentals/vermont/marlboro', lat: 42.8509, lng: -72.7259 },
  { name: 'Middlebury, VT', path: '/trailer-rentals/vermont/middlebury', lat: 44.0159, lng: -73.1676 },
  { name: 'Middlesex, VT', path: '/trailer-rentals/vermont/middlesex', lat: 44.2706, lng: -72.6359 },
  { name: 'Milton, VT', path: '/trailer-rentals/vermont/milton', lat: 44.6359, lng: -73.1126 },
  { name: 'Montpelier, VT', path: '/trailer-rentals/vermont/montpelier', lat: 44.2606, lng: -72.5759 },
  { name: 'Morristown, VT', path: '/trailer-rentals/vermont/morristown', lat: 44.5634, lng: -72.6159 },
  { name: 'New Haven, VT', path: '/trailer-rentals/vermont/new-haven', lat: 44.1159, lng: -73.1534 },
  { name: 'Newport, VT', path: '/trailer-rentals/vermont/newport', lat: 44.9376, lng: -72.2059 },
  { name: 'Northfield, VT', path: '/trailer-rentals/vermont/northfield', lat: 44.1509, lng: -72.6565 },
  { name: 'Norwich, VT', path: '/trailer-rentals/vermont/norwich', lat: 43.7159, lng: -72.3076 },
  { name: 'Orleans, VT', path: '/trailer-rentals/vermont/orleans', lat: 44.8159, lng: -72.2034 },
  { name: 'Pawlet, VT', path: '/trailer-rentals/vermont/pawlet', lat: 43.3434, lng: -73.2026 },
  { name: 'Pownal, VT', path: '/trailer-rentals/vermont/pownal', lat: 42.7626, lng: -73.2415 },
  { name: 'Poultney, VT', path: '/trailer-rentals/vermont/poultney', lat: 43.5165, lng: -73.2365 },
  { name: 'Proctor, VT', path: '/trailer-rentals/vermont/proctor', lat: 43.6634, lng: -73.0326 },
  { name: 'Randolph, VT', path: '/trailer-rentals/vermont/randolph', lat: 43.9234, lng: -72.6659 },
  { name: 'Richford, VT', path: '/trailer-rentals/vermont/richford', lat: 44.9959, lng: -72.6706 },
  { name: 'Richmond, VT', path: '/trailer-rentals/vermont/richmond', lat: 44.4034, lng: -72.9965 },
  { name: 'Rochester, VT', path: '/trailer-rentals/vermont/rochester', lat: 43.8759, lng: -72.8087 },
  { name: 'Rutland, VT', path: '/trailer-rentals/vermont/rutland', lat: 43.6106, lng: -72.9726 },
  { name: 'Shelburne, VT', path: '/trailer-rentals/vermont/shelburne', lat: 44.3815, lng: -73.2287 },
  { name: 'South Burlington, VT', path: '/trailer-rentals/vermont/south-burlington', lat: 44.4665, lng: -73.1715 },
  { name: 'Springfield, VT', path: '/trailer-rentals/vermont/springfield', lat: 43.2984, lng: -72.4826 },
  { name: 'St. Albans, VT', path: '/trailer-rentals/vermont/st-albans', lat: 44.8106, lng: -73.0834 },
  { name: 'St. Johnsbury, VT', path: '/trailer-rentals/vermont/st-johnsbury', lat: 44.4198, lng: -72.0159 },
  { name: 'Stowe, VT', path: '/trailer-rentals/vermont/stowe', lat: 44.4659, lng: -72.6876 },
  { name: 'Swanton, VT', path: '/trailer-rentals/vermont/swanton', lat: 44.9184, lng: -73.1226 },
  { name: 'Thetford, VT', path: '/trailer-rentals/vermont/thetford', lat: 43.8284, lng: -72.2315 },
  { name: 'Townshend, VT', path: '/trailer-rentals/vermont/townshend', lat: 43.0484, lng: -72.6687 },
  { name: 'Vergennes, VT', path: '/trailer-rentals/vermont/vergennes', lat: 44.1676, lng: -73.2534 },
  { name: 'Waitsfield, VT', path: '/trailer-rentals/vermont/waitsfield', lat: 44.1915, lng: -72.8243 },
  { name: 'Waterbury, VT', path: '/trailer-rentals/vermont/waterbury', lat: 44.3376, lng: -72.7565 },
  { name: 'Weathersfield, VT', path: '/trailer-rentals/vermont/weathersfield', lat: 43.3834, lng: -72.4826 },
  { name: 'West Rutland, VT', path: '/trailer-rentals/vermont/west-rutland', lat: 43.5948, lng: -73.0376 },
  { name: 'Westminster, VT', path: '/trailer-rentals/vermont/westminster', lat: 43.0734, lng: -72.4487 },
  { name: 'Williston, VT', path: '/trailer-rentals/vermont/williston', lat: 44.4376, lng: -73.0665 },
  { name: 'Wilmington, VT', path: '/trailer-rentals/vermont/wilmington', lat: 42.8706, lng: -72.8715 },
  { name: 'Windsor, VT', path: '/trailer-rentals/vermont/windsor', lat: 43.4834, lng: -72.3843 },
  { name: 'Winooski, VT', path: '/trailer-rentals/vermont/winooski', lat: 44.4915, lng: -73.1859 },
  { name: 'Woodstock, VT', path: '/trailer-rentals/vermont/woodstock', lat: 43.6243, lng: -72.5187 },
];

export const virginiaCities: ICity[] = [
  { name: 'Abingdon, VA', path: '/trailer-rentals/virginia/abingdon', lat: 36.7098, lng: -81.9773 },
  { name: 'Alexandria, VA', path: '/trailer-rentals/virginia/alexandria', lat: 38.8048, lng: -77.0469 },
  { name: 'Altavista, VA', path: '/trailer-rentals/virginia/altavista', lat: 37.1115, lng: -79.2878 },
  { name: 'Amherst, VA', path: '/trailer-rentals/virginia/amherst', lat: 37.5854, lng: -79.0514 },
  { name: 'Annandale, VA', path: '/trailer-rentals/virginia/annandale', lat: 38.8304, lng: -77.1964 },
  { name: 'Appomattox, VA', path: '/trailer-rentals/virginia/appomattox', lat: 37.3576, lng: -78.8253 },
  { name: 'Arlington, VA', path: '/trailer-rentals/virginia/arlington', lat: 38.8799, lng: -77.1068 },
  { name: 'Ashland, VA', path: '/trailer-rentals/virginia/ashland', lat: 37.7590, lng: -77.4794 },
  { name: 'Bedford, VA', path: '/trailer-rentals/virginia/bedford', lat: 37.3343, lng: -79.5231 },
  { name: 'Berryville, VA', path: '/trailer-rentals/virginia/berryville', lat: 39.1512, lng: -77.9828 },
  { name: 'Blacksburg, VA', path: '/trailer-rentals/virginia/blacksburg', lat: 37.2296, lng: -80.4139 },
  { name: 'Bluefield, VA', path: '/trailer-rentals/virginia/bluefield', lat: 37.2523, lng: -81.2748 },
  { name: 'Bristol, VA', path: '/trailer-rentals/virginia/bristol', lat: 36.5951, lng: -82.1887 },
  { name: 'Brookneal, VA', path: '/trailer-rentals/virginia/brookneal', lat: 37.0535, lng: -78.9469 },
  { name: 'Burke, VA', path: '/trailer-rentals/virginia/burke', lat: 38.7934, lng: -77.2717 },
  { name: 'Buena Vista, VA', path: '/trailer-rentals/virginia/buena-vista', lat: 37.7340, lng: -79.3534 },
  { name: 'Charlottesville, VA', path: '/trailer-rentals/virginia/charlottesville', lat: 38.0293, lng: -78.4767 },
  { name: 'Chesapeake, VA', path: '/trailer-rentals/virginia/chesapeake', lat: 36.7682, lng: -76.2875 },
  { name: 'Chincoteague, VA', path: '/trailer-rentals/virginia/chincoteague', lat: 37.9334, lng: -75.3782 },
  { name: 'Christiansburg, VA', path: '/trailer-rentals/virginia/christiansburg', lat: 37.1299, lng: -80.4089 },
  { name: 'Clifton Forge, VA', path: '/trailer-rentals/virginia/clifton-forge', lat: 37.8168, lng: -79.8242 },
  { name: 'Colonial Heights, VA', path: '/trailer-rentals/virginia/colonial-heights', lat: 37.2440, lng: -77.4103 },
  { name: 'Covington, VA', path: '/trailer-rentals/virginia/covington', lat: 37.7937, lng: -79.9937 },
  { name: 'Culpeper, VA', path: '/trailer-rentals/virginia/culpeper', lat: 38.4732, lng: -77.9963 },
  { name: 'Danville, VA', path: '/trailer-rentals/virginia/danville', lat: 36.5859, lng: -79.3950 },
  { name: 'Dayton, VA', path: '/trailer-rentals/virginia/dayton', lat: 38.4168, lng: -78.9395 },
  { name: 'Dumfries, VA', path: '/trailer-rentals/virginia/dumfries', lat: 38.5679, lng: -77.3280 },
  { name: 'Emporia, VA', path: '/trailer-rentals/virginia/emporia', lat: 36.6854, lng: -77.5425 },
  { name: 'Fairfax, VA', path: '/trailer-rentals/virginia/fairfax', lat: 38.8462, lng: -77.3064 },
  { name: 'Falls Church, VA', path: '/trailer-rentals/virginia/falls-church', lat: 38.8823, lng: -77.1711 },
  { name: 'Farmville, VA', path: '/trailer-rentals/virginia/farmville', lat: 37.3021, lng: -78.3919 },
  { name: 'Fincastle, VA', path: '/trailer-rentals/virginia/fincastle', lat: 37.4993, lng: -79.8770 },
  { name: 'Floyd, VA', path: '/trailer-rentals/virginia/floyd', lat: 36.9115, lng: -80.3203 },
  { name: 'Fredericksburg, VA', path: '/trailer-rentals/virginia/fredericksburg', lat: 38.3032, lng: -77.4605 },
  { name: 'Front Royal, VA', path: '/trailer-rentals/virginia/front-royal', lat: 38.9182, lng: -78.1944 },
  { name: 'Galax, VA', path: '/trailer-rentals/virginia/galax', lat: 36.6612, lng: -80.9237 },
  { name: 'Gate City, VA', path: '/trailer-rentals/virginia/gate-city', lat: 36.6412, lng: -82.5782 },
  { name: 'Gloucester, VA', path: '/trailer-rentals/virginia/gloucester', lat: 37.4090, lng: -76.5269 },
  { name: 'Gordonsville, VA', path: '/trailer-rentals/virginia/gordonsville', lat: 38.1368, lng: -78.1878 },
  { name: 'Gretna, VA', path: '/trailer-rentals/virginia/gretna', lat: 36.9571, lng: -79.3647 },
  { name: 'Hampton, VA', path: '/trailer-rentals/virginia/hampton', lat: 37.0299, lng: -76.3452 },
  { name: 'Harrisonburg, VA', path: '/trailer-rentals/virginia/harrisonburg', lat: 38.4496, lng: -78.8689 },
  { name: 'Herndon, VA', path: '/trailer-rentals/virginia/herndon', lat: 38.9696, lng: -77.3861 },
  { name: 'Hopewell, VA', path: '/trailer-rentals/virginia/hopewell', lat: 37.3043, lng: -77.2872 },
  { name: 'Lexington, VA', path: '/trailer-rentals/virginia/lexington', lat: 37.7840, lng: -79.4428 },
  { name: 'Luray, VA', path: '/trailer-rentals/virginia/luray', lat: 38.6643, lng: -78.4597 },
  { name: 'Lynchburg, VA', path: '/trailer-rentals/virginia/lynchburg', lat: 37.4138, lng: -79.1422 },
  { name: 'Manassas, VA', path: '/trailer-rentals/virginia/manassas', lat: 38.7509, lng: -77.4753 },
  { name: 'Marion, VA', path: '/trailer-rentals/virginia/marion', lat: 36.8340, lng: -81.5143 },
  { name: 'Martinsville, VA', path: '/trailer-rentals/virginia/martinsville', lat: 36.6915, lng: -79.8725 },
  { name: 'McLean, VA', path: '/trailer-rentals/virginia/mclean', lat: 38.9339, lng: -77.1773 },
  { name: 'Mechanicsville, VA', path: '/trailer-rentals/virginia/mechanicsville', lat: 37.6088, lng: -77.3733 },
  { name: 'Middleburg, VA', path: '/trailer-rentals/virginia/middleburg', lat: 38.9687, lng: -77.7353 },
  { name: 'Narrows, VA', path: '/trailer-rentals/virginia/narrows', lat: 37.2071, lng: -80.8112 },
  { name: 'Newport News, VA', path: '/trailer-rentals/virginia/newport-news', lat: 37.0871, lng: -76.4730 },
  { name: 'Norfolk, VA', path: '/trailer-rentals/virginia/norfolk', lat: 36.8508, lng: -76.2859 },
  { name: 'Norton, VA', path: '/trailer-rentals/virginia/norton', lat: 36.9337, lng: -82.6299 },
  { name: 'Occoquan, VA', path: '/trailer-rentals/virginia/occoquan', lat: 38.6832, lng: -77.2605 },
  { name: 'Onancock, VA', path: '/trailer-rentals/virginia/onancock', lat: 37.7115, lng: -75.7483 },
  { name: 'Orange, VA', path: '/trailer-rentals/virginia/orange', lat: 38.2457, lng: -78.1108 },
  { name: 'Petersburg, VA', path: '/trailer-rentals/virginia/petersburg', lat: 37.2279, lng: -77.4019 },
  { name: 'Poquoson, VA', path: '/trailer-rentals/virginia/poquoson', lat: 37.1224, lng: -76.3458 },
  { name: 'Portsmouth, VA', path: '/trailer-rentals/virginia/portsmouth', lat: 36.8354, lng: -76.2983 },
  { name: 'Pulaski, VA', path: '/trailer-rentals/virginia/pulaski', lat: 37.0471, lng: -80.7795 },
  { name: 'Radford, VA', path: '/trailer-rentals/virginia/radford', lat: 37.1318, lng: -80.5764 },
  { name: 'Richmond, VA', path: '/trailer-rentals/virginia/richmond', lat: 37.5407, lng: -77.4360 },
  { name: 'Roanoke, VA', path: '/trailer-rentals/virginia/roanoke', lat: 37.2709, lng: -79.9414 },
  { name: 'Rocky Mount, VA', path: '/trailer-rentals/virginia/rocky-mount', lat: 36.9971, lng: -79.8914 },
  { name: 'Salem, VA', path: '/trailer-rentals/virginia/salem', lat: 37.2935, lng: -80.0548 },
  { name: 'South Boston, VA', path: '/trailer-rentals/virginia/south-boston', lat: 36.6985, lng: -78.9011 },
  { name: 'South Hill, VA', path: '/trailer-rentals/virginia/south-hill', lat: 36.7265, lng: -78.1283 },
  { name: 'Springfield, VA', path: '/trailer-rentals/virginia/springfield', lat: 38.7893, lng: -77.1872 },
  { name: 'Stafford, VA', path: '/trailer-rentals/virginia/stafford', lat: 38.4221, lng: -77.4083 },
  { name: 'Staunton, VA', path: '/trailer-rentals/virginia/staunton', lat: 38.1496, lng: -79.0717 },
  { name: 'Strasburg, VA', path: '/trailer-rentals/virginia/strasburg', lat: 38.9887, lng: -78.3583 },
  { name: 'Suffolk, VA', path: '/trailer-rentals/virginia/suffolk', lat: 36.7282, lng: -76.5836 },
  { name: 'Tappahannock, VA', path: '/trailer-rentals/virginia/tappahannock', lat: 37.9254, lng: -76.8597 },
  { name: 'Vienna, VA', path: '/trailer-rentals/virginia/vienna', lat: 38.9012, lng: -77.2653 },
  { name: 'Vinton, VA', path: '/trailer-rentals/virginia/vinton', lat: 37.2801, lng: -79.8967 },
  { name: 'Warrenton, VA', path: '/trailer-rentals/virginia/warrenton', lat: 38.7135, lng: -77.7953 },
  { name: 'Warsaw, VA', path: '/trailer-rentals/virginia/warsaw', lat: 37.9585, lng: -76.7580 },
  { name: 'Waynesboro, VA', path: '/trailer-rentals/virginia/waynesboro', lat: 38.0685, lng: -78.8895 },
  { name: 'Williamsburg, VA', path: '/trailer-rentals/virginia/williamsburg', lat: 37.2707, lng: -76.7075 },
  { name: 'Winchester, VA', path: '/trailer-rentals/virginia/winchester', lat: 39.1857, lng: -78.1633 },
  { name: 'Wise, VA', path: '/trailer-rentals/virginia/wise', lat: 36.9754, lng: -82.5757 },
  { name: 'Woodstock, VA', path: '/trailer-rentals/virginia/woodstock', lat: 38.8815, lng: -78.5050 },
  { name: 'Wytheville, VA', path: '/trailer-rentals/virginia/wytheville', lat: 36.9487, lng: -81.0848 },
  { name: 'Yorktown, VA', path: '/trailer-rentals/virginia/yorktown', lat: 37.2388, lng: -76.5097 },
];

const washingtonCities: ICity[] = [
  { name: 'Aberdeen, WA', path: '/trailer-rentals/washington/aberdeen', lat: 46.9754, lng: -123.8157 },
  { name: 'Airway Heights, WA', path: '/trailer-rentals/washington/airway-heights', lat: 47.6449, lng: -117.5863 },
  { name: 'Anacortes, WA', path: '/trailer-rentals/washington/anacortes', lat: 48.5126, lng: -122.6127 },
  { name: 'Arlington, WA', path: '/trailer-rentals/washington/arlington', lat: 48.1987, lng: -122.1251 },
  { name: 'Auburn, WA', path: '/trailer-rentals/washington/auburn', lat: 47.3073, lng: -122.2285 },
  { name: 'Bainbridge Island, WA', path: '/trailer-rentals/washington/bainbridge-island', lat: 47.6262, lng: -122.5212 },
  { name: 'Battle Ground, WA', path: '/trailer-rentals/washington/battle-ground', lat: 45.7807, lng: -122.5337 },
  { name: 'Bellevue, WA', path: '/trailer-rentals/washington/bellevue', lat: 47.6104, lng: -122.2007 },
  { name: 'Bellingham, WA', path: '/trailer-rentals/washington/bellingham', lat: 48.7491, lng: -122.4781 },
  { name: 'Benton City, WA', path: '/trailer-rentals/washington/benton-city', lat: 46.2635, lng: -119.4886 },
  { name: 'Bonney Lake, WA', path: '/trailer-rentals/washington/bonney-lake', lat: 47.1773, lng: -122.1868 },
  { name: 'Bothell, WA', path: '/trailer-rentals/washington/bothell', lat: 47.7623, lng: -122.2054 },
  { name: 'Bremerton, WA', path: '/trailer-rentals/washington/bremerton', lat: 47.5673, lng: -122.6326 },
  { name: 'Buckley, WA', path: '/trailer-rentals/washington/buckley', lat: 47.1626, lng: -122.0265 },
  { name: 'Burien, WA', path: '/trailer-rentals/washington/burien', lat: 47.4704, lng: -122.3468 },
  { name: 'Camas, WA', path: '/trailer-rentals/washington/camas', lat: 45.5871, lng: -122.3995 },
  { name: 'Carnation, WA', path: '/trailer-rentals/washington/carnation', lat: 47.6487, lng: -121.914 },
  { name: 'Centralia, WA', path: '/trailer-rentals/washington/centralia', lat: 46.7162, lng: -122.9543 },
  { name: 'Chehalis, WA', path: '/trailer-rentals/washington/chehalis', lat: 46.6624, lng: -122.964 },
  { name: 'Cheney, WA', path: '/trailer-rentals/washington/cheney', lat: 47.4874, lng: -117.5758 },
  { name: 'Clarkston, WA', path: '/trailer-rentals/washington/clarkston', lat: 46.4163, lng: -117.0456 },
  { name: 'Cle Elum, WA', path: '/trailer-rentals/washington/cle-elum', lat: 47.1957, lng: -120.9395 },
  { name: 'Clyde Hill, WA', path: '/trailer-rentals/washington/clyde-hill', lat: 47.6304, lng: -122.2201 },
  { name: 'Colfax, WA', path: '/trailer-rentals/washington/colfax', lat: 46.8807, lng: -117.3649 },
  { name: 'College Place, WA', path: '/trailer-rentals/washington/college-place', lat: 46.0493, lng: -118.3883 },
  { name: 'Colville, WA', path: '/trailer-rentals/washington/colville', lat: 48.5441, lng: -117.9002 },
  { name: 'Connell, WA', path: '/trailer-rentals/washington/connell', lat: 46.6632, lng: -118.8616 },
  { name: 'Cosmopolis, WA', path: '/trailer-rentals/washington/cosmopolis', lat: 46.9548, lng: -123.7732 },
  { name: 'Covington, WA', path: '/trailer-rentals/washington/covington', lat: 47.3654, lng: -122.1016 },
  { name: 'Des Moines, WA', path: '/trailer-rentals/washington/des-moines', lat: 47.4018, lng: -122.3243 },
  { name: 'DuPont, WA', path: '/trailer-rentals/washington/dupont', lat: 47.0968, lng: -122.6315 },
  { name: 'Duvall, WA', path: '/trailer-rentals/washington/duvall', lat: 47.7423, lng: -121.9857 },
  { name: 'East Wenatchee, WA', path: '/trailer-rentals/washington/east-wenatchee', lat: 47.4154, lng: -120.2917 },
  { name: 'Eatonville, WA', path: '/trailer-rentals/washington/eatonville', lat: 46.8687, lng: -122.2615 },
  { name: 'Edgewood, WA', path: '/trailer-rentals/washington/edgewood', lat: 47.2507, lng: -122.2937 },
  { name: 'Edmonds, WA', path: '/trailer-rentals/washington/edmonds', lat: 47.8107, lng: -122.3774 },
  { name: 'Ellensburg, WA', path: '/trailer-rentals/washington/ellensburg', lat: 46.9965, lng: -120.5478 },
  { name: 'Elma, WA', path: '/trailer-rentals/washington/elma', lat: 47.0037, lng: -123.4093 },
  { name: 'Enumclaw, WA', path: '/trailer-rentals/washington/enumclaw', lat: 47.2043, lng: -121.9915 },
  { name: 'Ephrata, WA', path: '/trailer-rentals/washington/ephrata', lat: 47.3171, lng: -119.5533 },
  { name: 'Everett, WA', path: '/trailer-rentals/washington/everett', lat: 47.9789, lng: -122.2021, active: true, showNewVersion: true  },
  { name: 'Federal Way, WA', path: '/trailer-rentals/washington/federal-way', lat: 47.3223, lng: -122.3126 },
  { name: 'Ferndale, WA', path: '/trailer-rentals/washington/ferndale', lat: 48.8468, lng: -122.5919 },
  { name: 'Fife, WA', path: '/trailer-rentals/washington/fife', lat: 47.2393, lng: -122.3576 },
  { name: 'Gig Harbor, WA', path: '/trailer-rentals/washington/gig-harbor', lat: 47.3293, lng: -122.5801 },
  { name: 'Goldendale, WA', path: '/trailer-rentals/washington/goldendale', lat: 45.8204, lng: -120.8212 },
  { name: 'Grandview, WA', path: '/trailer-rentals/washington/grandview', lat: 46.2507, lng: -119.9003 },
  { name: 'Hoquiam, WA', path: '/trailer-rentals/washington/hoquiam', lat: 46.9804, lng: -123.8893 },
  { name: 'Issaquah, WA', path: '/trailer-rentals/washington/issaquah', lat: 47.5301, lng: -122.0326 },
  { name: 'Kelso, WA', path: '/trailer-rentals/washington/kelso', lat: 46.1462, lng: -122.9082 },
  { name: 'Kenmore, WA', path: '/trailer-rentals/washington/kenmore', lat: 47.7573, lng: -122.244 },
  { name: 'Kennewick, WA', path: '/trailer-rentals/washington/kennewick', lat: 46.2112, lng: -119.1372 },
  { name: 'Kent, WA', path: '/trailer-rentals/washington/kent', lat: 47.3809, lng: -122.2348 },
  { name: 'Kirkland, WA', path: '/trailer-rentals/washington/kirkland', lat: 47.6815, lng: -122.2087 },
  { name: 'La Center, WA', path: '/trailer-rentals/washington/la-center', lat: 45.8626, lng: -122.6709 },
  { name: 'Lacey, WA', path: '/trailer-rentals/washington/lacey', lat: 47.0343, lng: -122.8232 },
  { name: 'Lake Forest Park, WA', path: '/trailer-rentals/washington/lake-forest-park', lat: 47.7563, lng: -122.2801 },
  { name: 'Lake Stevens, WA', path: '/trailer-rentals/washington/lake-stevens', lat: 48.0154, lng: -122.0637, active: true, showNewVersion: true },
  { name: 'Lakewood, WA', path: '/trailer-rentals/washington/lakewood', lat: 47.1718, lng: -122.5185 },
  { name: 'Long Beach, WA', path: '/trailer-rentals/washington/long-beach', lat: 46.3523, lng: -124.0543 },
  { name: 'Longview, WA', path: '/trailer-rentals/washington/longview', lat: 46.1382, lng: -122.9382 },
  { name: 'Lynden, WA', path: '/trailer-rentals/washington/lynden', lat: 48.9463, lng: -122.4524 },
  { name: 'Lynnwood, WA', path: '/trailer-rentals/washington/lynnwood', lat: 47.8279, lng: -122.305 },
  { name: 'Maple Valley, WA', path: '/trailer-rentals/washington/maple-valley', lat: 47.3663, lng: -122.0443 },
  { name: 'Marysville, WA', path: '/trailer-rentals/washington/marysville', lat: 48.0518, lng: -122.1771 },
  { name: 'Mercer Island, WA', path: '/trailer-rentals/washington/mercer-island', lat: 47.5707, lng: -122.2221 },
  { name: 'Mill Creek, WA', path: '/trailer-rentals/washington/mill-creek', lat: 47.8601, lng: -122.2043 },
  { name: 'Monroe, WA', path: '/trailer-rentals/washington/monroe', lat: 47.8554, lng: -121.9709 },
  { name: 'Montesano, WA', path: '/trailer-rentals/washington/montesano', lat: 46.9804, lng: -123.6027 },
  { name: 'Mount Vernon, WA', path: '/trailer-rentals/washington/mount-vernon', lat: 48.4212, lng: -122.3341 },
  { name: 'Mountlake Terrace, WA', path: '/trailer-rentals/washington/mountlake-terrace', lat: 47.7887, lng: -122.3087 },
  { name: 'Mukilteo, WA', path: '/trailer-rentals/washington/mukilteo', lat: 47.9445, lng: -122.3046 },
  { name: 'Newcastle, WA', path: '/trailer-rentals/washington/newcastle', lat: 47.5307, lng: -122.1715 },
  { name: 'Oak Harbor, WA', path: '/trailer-rentals/washington/oak-harbor', lat: 48.2933, lng: -122.6432 },
  { name: 'Ocean Shores, WA', path: '/trailer-rentals/washington/ocean-shores', lat: 46.9737, lng: -124.1565 },
  { name: 'Olympia, WA', path: '/trailer-rentals/washington/olympia', lat: 47.0379, lng: -122.9007 },
  { name: 'Omak, WA', path: '/trailer-rentals/washington/omak', lat: 48.4107, lng: -119.5278 },
  { name: 'Orting, WA', path: '/trailer-rentals/washington/orting', lat: 47.0973, lng: -122.2043 },
  { name: 'Pasco, WA', path: '/trailer-rentals/washington/pasco', lat: 46.2396, lng: -119.1006 },
  { name: 'Port Angeles, WA', path: '/trailer-rentals/washington/port-angeles', lat: 48.1181, lng: -123.4307 },
  { name: 'Port Orchard, WA', path: '/trailer-rentals/washington/port-orchard', lat: 47.5407, lng: -122.6363 },
  { name: 'Port Townsend, WA', path: '/trailer-rentals/washington/port-townsend', lat: 48.117, lng: -122.7604 },
  { name: 'Poulsbo, WA', path: '/trailer-rentals/washington/poulsbo', lat: 47.7359, lng: -122.6465 },
  { name: 'Pullman, WA', path: '/trailer-rentals/washington/pullman', lat: 46.7313, lng: -117.1796 },
  { name: 'Puyallup, WA', path: '/trailer-rentals/washington/puyallup', lat: 47.1854, lng: -122.2929 },
  { name: 'Quincy, WA', path: '/trailer-rentals/washington/quincy', lat: 47.2343, lng: -119.8528 },
  { name: 'Redmond, WA', path: '/trailer-rentals/washington/redmond', lat: 47.6739, lng: -122.1215 },
  { name: 'Renton, WA', path: '/trailer-rentals/washington/renton', lat: 47.4829, lng: -122.2171 },
  { name: 'Richland, WA', path: '/trailer-rentals/washington/richland', lat: 46.2804, lng: -119.2752 },
  { name: 'Ridgefield, WA', path: '/trailer-rentals/washington/ridgefield', lat: 45.8154, lng: -122.7426 },
  { name: 'Ritzville, WA', path: '/trailer-rentals/washington/ritzville', lat: 47.1271, lng: -118.3808 },
  { name: 'Sammamish, WA', path: '/trailer-rentals/washington/sammamish', lat: 47.6163, lng: -122.0356 },
  { name: 'SeaTac, WA', path: '/trailer-rentals/washington/seatac', lat: 47.4435, lng: -122.3015 },
  { name: 'Seattle, WA', path: '/trailer-rentals/washington/seattle', lat: 47.6062, lng: -122.3321,  active: true, showNewVersion: true },
  { name: 'Sedro-Woolley, WA', path: '/trailer-rentals/washington/sedro-woolley', lat: 48.5033, lng: -122.2365 },
  { name: 'Selah, WA', path: '/trailer-rentals/washington/selah', lat: 46.6548, lng: -120.5306 },
  { name: 'Sequim, WA', path: '/trailer-rentals/washington/sequim', lat: 48.0795, lng: -123.1011 },
  { name: 'Shelton, WA', path: '/trailer-rentals/washington/shelton', lat: 47.2159, lng: -123.1007 },
  { name: 'Shoreline, WA', path: '/trailer-rentals/washington/shoreline', lat: 47.7557, lng: -122.3415 },
  { name: 'Snohomish, WA', path: '/trailer-rentals/washington/snohomish', lat: 47.9129, lng: -122.0982 },
  { name: 'Snoqualmie, WA', path: '/trailer-rentals/washington/snoqualmie', lat: 47.5287, lng: -121.8254 },
  { name: 'Soap Lake, WA', path: '/trailer-rentals/washington/soap-lake', lat: 47.3893, lng: -119.4856 },
  { name: 'South Bend, WA', path: '/trailer-rentals/washington/south-bend', lat: 46.6634, lng: -123.8043 },
  { name: 'Spokane, WA', path: '/trailer-rentals/washington/spokane', lat: 47.6588, lng: -117.4260 },
  { name: 'Spokane Valley, WA', path: '/trailer-rentals/washington/spokane-valley', lat: 47.6732, lng: -117.2394 },
  { name: 'Stanwood, WA', path: '/trailer-rentals/washington/stanwood', lat: 48.2415, lng: -122.3707 },
  { name: 'Steilacoom, WA', path: '/trailer-rentals/washington/steilacoom', lat: 47.1693, lng: -122.6021 },
  { name: 'Sultan, WA', path: '/trailer-rentals/washington/sultan', lat: 47.8626, lng: -121.8162 },
  { name: 'Sumner, WA', path: '/trailer-rentals/washington/sumner', lat: 47.2032, lng: -122.2405 },
  { name: 'Sunnyside, WA', path: '/trailer-rentals/washington/sunnyside', lat: 46.3235, lng: -120.0081 },
  { name: 'Tacoma, WA', path: '/trailer-rentals/washington/tacoma', lat: 47.2529, lng: -122.4443 },
  { name: 'Tumwater, WA', path: '/trailer-rentals/washington/tumwater', lat: 47.0073, lng: -122.9093 },
  { name: 'Tukwila, WA', path: '/trailer-rentals/washington/tukwila', lat: 47.4739, lng: -122.2600 },
  { name: 'Union Gap, WA', path: '/trailer-rentals/washington/union-gap', lat: 46.5571, lng: -120.4756 },
  { name: 'University Place, WA', path: '/trailer-rentals/washington/university-place', lat: 47.2359, lng: -122.5507 },
  { name: 'Vancouver, WA', path: '/trailer-rentals/washington/vancouver', lat: 45.6387, lng: -122.6615 },
  { name: 'Walla Walla, WA', path: '/trailer-rentals/washington/walla-walla', lat: 46.0646, lng: -118.3430 },
  { name: 'Washougal, WA', path: '/trailer-rentals/washington/washougal', lat: 45.5829, lng: -122.3537 },
  { name: 'Wenatchee, WA', path: '/trailer-rentals/washington/wenatchee', lat: 47.4235, lng: -120.3103 },
  { name: 'West Richland, WA', path: '/trailer-rentals/washington/west-richland', lat: 46.3043, lng: -119.3617 },
  { name: 'Woodinville, WA', path: '/trailer-rentals/washington/woodinville', lat: 47.7543, lng: -122.1635 },
  { name: 'Yakima, WA', path: '/trailer-rentals/washington/yakima', lat: 46.6021, lng: -120.5059 },
];

const westVirginiaCities: ICity[] = [
  { name: 'Alderson, WV', path: '/trailer-rentals/west-virginia/alderson', lat: 37.7251, lng: -80.6456 },
  { name: 'Ansted, WV', path: '/trailer-rentals/west-virginia/ansted', lat: 38.1376, lng: -81.1037 },
  { name: 'Barboursville, WV', path: '/trailer-rentals/west-virginia/barboursville', lat: 38.4095, lng: -82.2943 },
  { name: 'Beckley, WV', path: '/trailer-rentals/west-virginia/beckley', lat: 37.7782, lng: -81.1882 },
  { name: 'Belington, WV', path: '/trailer-rentals/west-virginia/belington', lat: 39.0251, lng: -79.9381 },
  { name: 'Benwood, WV', path: '/trailer-rentals/west-virginia/benwood', lat: 40.0123, lng: -80.7356 },
  { name: 'Bethany, WV', path: '/trailer-rentals/west-virginia/bethany', lat: 40.2073, lng: -80.5587 },
  { name: 'Beverly, WV', path: '/trailer-rentals/west-virginia/beverly', lat: 38.8465, lng: -79.8723 },
  { name: 'Bluefield, WV', path: '/trailer-rentals/west-virginia/bluefield', lat: 37.2698, lng: -81.2223 },
  { name: 'Bolivar, WV', path: '/trailer-rentals/west-virginia/bolivar', lat: 39.3254, lng: -77.7483 },
  { name: 'Bridgeport, WV', path: '/trailer-rentals/west-virginia/bridgeport', lat: 39.2865, lng: -80.2562 },
  { name: 'Buckhannon, WV', path: '/trailer-rentals/west-virginia/buckhannon', lat: 38.9937, lng: -80.2329 },
  { name: 'Buffalo, WV', path: '/trailer-rentals/west-virginia/buffalo', lat: 38.6173, lng: -81.9821 },
  { name: 'Camden-on-Gauley, WV', path: '/trailer-rentals/west-virginia/camden-on-gauley', lat: 38.3737, lng: -80.5898 },
  { name: 'Cameron, WV', path: '/trailer-rentals/west-virginia/cameron', lat: 39.8281, lng: -80.5662 },
  { name: 'Charles Town, WV', path: '/trailer-rentals/west-virginia/charles-town', lat: 39.2887, lng: -77.8594 },
  { name: 'Charleston, WV', path: '/trailer-rentals/west-virginia/charleston', lat: 38.3498, lng: -81.6326 },
  { name: 'Chesapeake, WV', path: '/trailer-rentals/west-virginia/chesapeake', lat: 38.2245, lng: -81.5365 },
  { name: 'Chester, WV', path: '/trailer-rentals/west-virginia/chester', lat: 40.6123, lng: -80.5623 },
  { name: 'Clarksburg, WV', path: '/trailer-rentals/west-virginia/clarksburg', lat: 39.2806, lng: -80.3445 },
  { name: 'Clay, WV', path: '/trailer-rentals/west-virginia/clay', lat: 38.4626, lng: -81.0801 },
  { name: 'Davis, WV', path: '/trailer-rentals/west-virginia/davis', lat: 39.1287, lng: -79.4648 },
  { name: 'Dunbar, WV', path: '/trailer-rentals/west-virginia/dunbar', lat: 38.3601, lng: -81.7398 },
  { name: 'Eleanor, WV', path: '/trailer-rentals/west-virginia/eleanor', lat: 38.5354, lng: -81.9318 },
  { name: 'Elizabeth, WV', path: '/trailer-rentals/west-virginia/elizabeth', lat: 39.0623, lng: -81.3948 },
  { name: 'Elkins, WV', path: '/trailer-rentals/west-virginia/elkins', lat: 38.9254, lng: -79.8467 },
  { name: 'Fairmont, WV', path: '/trailer-rentals/west-virginia/fairmont', lat: 39.4851, lng: -80.1426 },
  { name: 'Falling Waters, WV', path: '/trailer-rentals/west-virginia/falling-waters', lat: 39.5526, lng: -77.8864 },
  { name: 'Follansbee, WV', path: '/trailer-rentals/west-virginia/follansbee', lat: 40.3342, lng: -80.5984 },
  { name: 'Fort Ashby, WV', path: '/trailer-rentals/west-virginia/fort-ashby', lat: 39.4973, lng: -78.7639 },
  { name: 'Franklin, WV', path: '/trailer-rentals/west-virginia/franklin', lat: 38.6426, lng: -79.3323 },
  { name: 'Gassaway, WV', path: '/trailer-rentals/west-virginia/gassaway', lat: 38.6693, lng: -80.7701 },
  { name: 'Gilbert, WV', path: '/trailer-rentals/west-virginia/gilbert', lat: 37.6143, lng: -81.8701 },
  { name: 'Glen Dale, WV', path: '/trailer-rentals/west-virginia/glen-dale', lat: 39.9481, lng: -80.7512 },
  { name: 'Grafton, WV', path: '/trailer-rentals/west-virginia/grafton', lat: 39.3401, lng: -80.0184 },
  { name: 'Grantsville, WV', path: '/trailer-rentals/west-virginia/grantsville', lat: 38.9215, lng: -81.0934 },
  { name: 'Hamlin, WV', path: '/trailer-rentals/west-virginia/hamlin', lat: 38.2787, lng: -82.1037 },
  { name: 'Harpers Ferry, WV', path: '/trailer-rentals/west-virginia/harpers-ferry', lat: 39.3254, lng: -77.7389 },
  { name: 'Harrisville, WV', path: '/trailer-rentals/west-virginia/harrisville', lat: 39.2095, lng: -81.0512 },
  { name: 'Hinton, WV', path: '/trailer-rentals/west-virginia/hinton', lat: 37.6743, lng: -80.8898 },
  { name: 'Huntington, WV', path: '/trailer-rentals/west-virginia/huntington', lat: 38.4192, lng: -82.4452 },
  { name: 'Hurricane, WV', path: '/trailer-rentals/west-virginia/hurricane', lat: 38.4326, lng: -82.0193 },
  { name: 'Keyser, WV', path: '/trailer-rentals/west-virginia/keyser', lat: 39.4395, lng: -78.9739 },
  { name: 'Kingwood, WV', path: '/trailer-rentals/west-virginia/kingwood', lat: 39.4726, lng: -79.6898 },
  { name: 'Lewisburg, WV', path: '/trailer-rentals/west-virginia/lewisburg', lat: 37.8015, lng: -80.4454 },
  { name: 'Logan, WV', path: '/trailer-rentals/west-virginia/logan', lat: 37.8487, lng: -81.9937 },
  { name: 'Madison, WV', path: '/trailer-rentals/west-virginia/madison', lat: 38.0676, lng: -81.8198 },
  { name: 'Mannington, WV', path: '/trailer-rentals/west-virginia/mannington', lat: 39.5287, lng: -80.3445 },
  { name: 'Marlinton, WV', path: '/trailer-rentals/west-virginia/marlinton', lat: 38.2237, lng: -80.0934 },
  { name: 'Martinsburg, WV', path: '/trailer-rentals/west-virginia/martinsburg', lat: 39.4562, lng: -77.9639 },
  { name: 'Mason, WV', path: '/trailer-rentals/west-virginia/mason', lat: 39.0201, lng: -82.0301 },
  { name: 'McMechen, WV', path: '/trailer-rentals/west-virginia/mcmechen', lat: 39.9823, lng: -80.7312 },
  { name: 'Milton, WV', path: '/trailer-rentals/west-virginia/milton', lat: 38.4337, lng: -82.1312 },
  { name: 'Moorefield, WV', path: '/trailer-rentals/west-virginia/moorefield', lat: 39.0623, lng: -78.9698 },
  { name: 'Morgantown, WV', path: '/trailer-rentals/west-virginia/morgantown', lat: 39.6295, lng: -79.9559 },
  { name: 'Moundsville, WV', path: '/trailer-rentals/west-virginia/moundsville', lat: 39.9201, lng: -80.7437 },
  { name: 'New Cumberland, WV', path: '/trailer-rentals/west-virginia/new-cumberland', lat: 40.4995, lng: -80.6073 },
  { name: 'New Haven, WV', path: '/trailer-rentals/west-virginia/new-haven', lat: 38.9895, lng: -81.9739 },
  { name: 'New Martinsville, WV', path: '/trailer-rentals/west-virginia/new-martinsville', lat: 39.6573, lng: -80.8573 },
  { name: 'Nitro, WV', path: '/trailer-rentals/west-virginia/nitro', lat: 38.4145, lng: -81.8312 },
  { name: 'North Hills, WV', path: '/trailer-rentals/west-virginia/north-hills', lat: 39.2973, lng: -81.5373 },
  { name: 'Nutter Fort, WV', path: '/trailer-rentals/west-virginia/nutter-fort', lat: 39.2662, lng: -80.3256 },
  { name: 'Oak Hill, WV', path: '/trailer-rentals/west-virginia/oak-hill', lat: 37.9745, lng: -81.1487 },
  { name: 'Parkersburg, WV', path: '/trailer-rentals/west-virginia/parkersburg', lat: 39.2667, lng: -81.5615 },
  { name: 'Parsons, WV', path: '/trailer-rentals/west-virginia/parsons', lat: 39.0965, lng: -79.6798 },
  { name: 'Pennsboro, WV', path: '/trailer-rentals/west-virginia/pennsboro', lat: 39.2837, lng: -80.9701 },
  { name: 'Petersburg, WV', path: '/trailer-rentals/west-virginia/petersburg', lat: 38.9937, lng: -79.1256 },
  { name: 'Philippi, WV', path: '/trailer-rentals/west-virginia/philippi', lat: 39.1523, lng: -80.0437 },
  { name: 'Pineville, WV', path: '/trailer-rentals/west-virginia/pineville', lat: 37.5837, lng: -81.5387 },
  { name: 'Princeton, WV', path: '/trailer-rentals/west-virginia/princeton', lat: 37.3662, lng: -81.0737 },
  { name: 'Ranson, WV', path: '/trailer-rentals/west-virginia/ranson', lat: 39.2962, lng: -77.8598 },
  { name: 'Ravenswood, WV', path: '/trailer-rentals/west-virginia/ravenswood', lat: 38.9487, lng: -81.7612 },
  { name: 'Reedsville, WV', path: '/trailer-rentals/west-virginia/reedsville', lat: 39.5101, lng: -79.8001 },
  { name: 'Richwood, WV', path: '/trailer-rentals/west-virginia/richwood', lat: 38.2237, lng: -80.5345 },
  { name: 'Ripley, WV', path: '/trailer-rentals/west-virginia/ripley', lat: 38.8187, lng: -81.7101 },
  { name: 'Romney, WV', path: '/trailer-rentals/west-virginia/romney', lat: 39.3423, lng: -78.7562 },
  { name: 'Shinnston, WV', path: '/trailer-rentals/west-virginia/shinnston', lat: 39.3945, lng: -80.3001 },
  { name: 'Sistersville, WV', path: '/trailer-rentals/west-virginia/sistersville', lat: 39.5637, lng: -80.9998 },
  { name: 'Smithers, WV', path: '/trailer-rentals/west-virginia/smithers', lat: 38.1762, lng: -81.3045 },
  { name: 'South Charleston, WV', path: '/trailer-rentals/west-virginia/south-charleston', lat: 38.3687, lng: -81.6987 },
  { name: 'Spencer, WV', path: '/trailer-rentals/west-virginia/spencer', lat: 38.8012, lng: -81.3537 },
  { name: 'St. Albans, WV', path: '/trailer-rentals/west-virginia/st-albans', lat: 38.3851, lng: -81.8198 },
  { name: 'St. Marys, WV', path: '/trailer-rentals/west-virginia/st-marys', lat: 39.3962, lng: -81.1973 },
  { name: 'Star City, WV', path: '/trailer-rentals/west-virginia/star-city', lat: 39.6587, lng: -79.9862 },
  { name: 'Summersville, WV', path: '/trailer-rentals/west-virginia/summersville', lat: 38.2812, lng: -80.8523 },
  { name: 'Sutton, WV', path: '/trailer-rentals/west-virginia/sutton', lat: 38.6645, lng: -80.7101 },
  { name: 'Vienna, WV', path: '/trailer-rentals/west-virginia/vienna', lat: 39.3245, lng: -81.5487 },
  { name: 'Wayne, WV', path: '/trailer-rentals/west-virginia/wayne', lat: 38.2212, lng: -82.4412 },
  { name: 'Webster Springs, WV', path: '/trailer-rentals/west-virginia/webster-springs', lat: 38.4795, lng: -80.4098 },
  { name: 'Weirton, WV', path: '/trailer-rentals/west-virginia/weirton', lat: 40.4187, lng: -80.5898 },
  { name: 'Welch, WV', path: '/trailer-rentals/west-virginia/welch', lat: 37.4323, lng: -81.5845 },
  { name: 'Wellsburg, WV', path: '/trailer-rentals/west-virginia/wellsburg', lat: 40.2723, lng: -80.6101 },
  { name: 'West Hamlin, WV', path: '/trailer-rentals/west-virginia/west-hamlin', lat: 38.2862, lng: -82.1901 },
  { name: 'West Union, WV', path: '/trailer-rentals/west-virginia/west-union', lat: 39.2965, lng: -80.7745 },
  { name: 'Weston, WV', path: '/trailer-rentals/west-virginia/weston', lat: 39.0387, lng: -80.4673 },
  { name: 'Wheeling, WV', path: '/trailer-rentals/west-virginia/wheeling', lat: 40.0639, lng: -80.7209 },
  { name: 'White Sulphur Springs, WV', path: '/trailer-rentals/west-virginia/white-sulphur-springs', lat: 37.7937, lng: -80.3037 },
  { name: 'Williamson, WV', path: '/trailer-rentals/west-virginia/williamson', lat: 37.6743, lng: -82.2793 },
];

const wyomingCities: ICity[] = [
  { name: 'Afton, WY', path: '/trailer-rentals/wyoming/afton', lat: 42.7266, lng: -110.9338 },
  { name: 'Alpine, WY', path: '/trailer-rentals/wyoming/alpine', lat: 43.1616, lng: -111.0171 },
  { name: 'Baggs, WY', path: '/trailer-rentals/wyoming/baggs', lat: 41.0375, lng: -107.6556 },
  { name: 'Bar Nunn, WY', path: '/trailer-rentals/wyoming/bar-nunn', lat: 42.9122, lng: -106.3561 },
  { name: 'Basin, WY', path: '/trailer-rentals/wyoming/basin', lat: 44.3791, lng: -108.0451 },
  { name: 'Big Piney, WY', path: '/trailer-rentals/wyoming/big-piney', lat: 42.5391, lng: -110.1168 },
  { name: 'Buffalo, WY', path: '/trailer-rentals/wyoming/buffalo', lat: 44.3483, lng: -106.6989 },
  { name: 'Burlington, WY', path: '/trailer-rentals/wyoming/burlington', lat: 44.4441, lng: -108.4235 },
  { name: 'Casper, WY', path: '/trailer-rentals/wyoming/casper', lat: 42.8501, lng: -106.3252 },
  { name: 'Cheyenne, WY', path: '/trailer-rentals/wyoming/cheyenne', lat: 41.1400, lng: -104.8202 },
  { name: 'Chugwater, WY', path: '/trailer-rentals/wyoming/chugwater', lat: 41.7561, lng: -104.8255 },
  { name: 'Cody, WY', path: '/trailer-rentals/wyoming/cody', lat: 44.5263, lng: -109.0565 },
  { name: 'Cokeville, WY', path: '/trailer-rentals/wyoming/cokeville', lat: 42.0808, lng: -110.9621 },
  { name: 'Cowley, WY', path: '/trailer-rentals/wyoming/cowley', lat: 44.8822, lng: -108.4682 },
  { name: 'Dayton, WY', path: '/trailer-rentals/wyoming/dayton', lat: 44.8711, lng: -107.2637 },
  { name: 'Diamondville, WY', path: '/trailer-rentals/wyoming/diamondville', lat: 41.7758, lng: -110.5377 },
  { name: 'Douglas, WY', path: '/trailer-rentals/wyoming/douglas', lat: 42.7597, lng: -105.3822 },
  { name: 'Dubois, WY', path: '/trailer-rentals/wyoming/dubois', lat: 43.5377, lng: -109.6304 },
  { name: 'East Thermopolis, WY', path: '/trailer-rentals/wyoming/east-thermopolis', lat: 43.6464, lng: -108.2048 },
  { name: 'Edgerton, WY', path: '/trailer-rentals/wyoming/edgerton', lat: 43.4083, lng: -106.2578 },
  { name: 'Evanston, WY', path: '/trailer-rentals/wyoming/evanston', lat: 41.2683, lng: -110.9632 },
  { name: 'Evansville, WY', path: '/trailer-rentals/wyoming/evansville', lat: 42.8591, lng: -106.2678 },
  { name: 'Fort Bridger, WY', path: '/trailer-rentals/wyoming/fort-bridger', lat: 41.3191, lng: -110.3832 },
  { name: 'Fort Laramie, WY', path: '/trailer-rentals/wyoming/fort-laramie', lat: 42.2133, lng: -104.5194 },
  { name: 'Gillette, WY', path: '/trailer-rentals/wyoming/gillette', lat: 44.2911, lng: -105.5022 },
  { name: 'Glenrock, WY', path: '/trailer-rentals/wyoming/glenrock', lat: 42.8611, lng: -105.8606 },
  { name: 'Granger, WY', path: '/trailer-rentals/wyoming/granger', lat: 41.5941, lng: -109.9693 },
  { name: 'Green River, WY', path: '/trailer-rentals/wyoming/green-river', lat: 41.5286, lng: -109.4662 },
  { name: 'Greybull, WY', path: '/trailer-rentals/wyoming/greybull', lat: 44.4908, lng: -108.0587 },
  { name: 'Guernsey, WY', path: '/trailer-rentals/wyoming/guernsey', lat: 42.2672, lng: -104.7391 },
  { name: 'Hanna, WY', path: '/trailer-rentals/wyoming/hanna', lat: 41.8708, lng: -106.5606 },
  { name: 'Hudson, WY', path: '/trailer-rentals/wyoming/hudson', lat: 42.9911, lng: -108.5837 },
  { name: 'Hulett, WY', path: '/trailer-rentals/wyoming/hulett', lat: 44.6822, lng: -104.6016 },
  { name: 'Jackson, WY', path: '/trailer-rentals/wyoming/jackson', lat: 43.4799, lng: -110.7624 },
  { name: 'Jeffrey City, WY', path: '/trailer-rentals/wyoming/jeffrey-city', lat: 42.4791, lng: -107.8328 },
  { name: 'Kemmerer, WY', path: '/trailer-rentals/wyoming/kemmerer', lat: 41.7933, lng: -110.5377 },
  { name: 'Kirby, WY', path: '/trailer-rentals/wyoming/kirby', lat: 43.8041, lng: -108.1779 },
  { name: 'La Barge, WY', path: '/trailer-rentals/wyoming/la-barge', lat: 42.2611, lng: -110.1968 },
  { name: 'Lander, WY', path: '/trailer-rentals/wyoming/lander', lat: 42.8333, lng: -108.7307 },
  { name: 'Laramie, WY', path: '/trailer-rentals/wyoming/laramie', lat: 41.3114, lng: -105.5911 },
  { name: 'Lingle, WY', path: '/trailer-rentals/wyoming/lingle', lat: 42.1358, lng: -104.3466 },
  { name: 'Lost Springs, WY', path: '/trailer-rentals/wyoming/lost-springs', lat: 42.7611, lng: -104.9122 },
  { name: 'Lovell, WY', path: '/trailer-rentals/wyoming/lovell', lat: 44.8372, lng: -108.3906 },
  { name: 'Lusk, WY', path: '/trailer-rentals/wyoming/lusk', lat: 42.7622, lng: -104.4522 },
  { name: 'Lyman, WY', path: '/trailer-rentals/wyoming/lyman', lat: 41.3272, lng: -110.2921 },
  { name: 'Manderson, WY', path: '/trailer-rentals/wyoming/manderson', lat: 44.2683, lng: -107.9606 },
  { name: 'Manville, WY', path: '/trailer-rentals/wyoming/manville', lat: 42.7822, lng: -104.6172 },
  { name: 'Marbleton, WY', path: '/trailer-rentals/wyoming/marbleton', lat: 42.5733, lng: -110.1168 },
  { name: 'Medicine Bow, WY', path: '/trailer-rentals/wyoming/medicine-bow', lat: 41.8991, lng: -106.2028 },
  { name: 'Meeteetse, WY', path: '/trailer-rentals/wyoming/meeteetse', lat: 44.1561, lng: -108.8679 },
  { name: 'Midwest, WY', path: '/trailer-rentals/wyoming/midwest', lat: 43.4083, lng: -106.2761 },
  { name: 'Mills, WY', path: '/trailer-rentals/wyoming/mills', lat: 42.8461, lng: -106.3656 },
  { name: 'Moorcroft, WY', path: '/trailer-rentals/wyoming/moorcroft', lat: 44.2633, lng: -104.9483 },
  { name: 'Mountain View, WY', path: '/trailer-rentals/wyoming/mountain-view', lat: 41.2691, lng: -110.3321 },
  { name: 'New Castle, WY', path: '/trailer-rentals/wyoming/new-castle', lat: 43.8541, lng: -104.6906 },
  { name: 'Opal, WY', path: '/trailer-rentals/wyoming/opal', lat: 41.7672, lng: -110.3083 },
  { name: 'Pavillion, WY', path: '/trailer-rentals/wyoming/pavillion', lat: 43.2458, lng: -108.6929 },
  { name: 'Pine Bluffs, WY', path: '/trailer-rentals/wyoming/pine-bluffs', lat: 41.1811, lng: -104.0683 },
  { name: 'Pinedale, WY', path: '/trailer-rentals/wyoming/pinedale', lat: 42.8661, lng: -109.8607 },
  { name: 'Powell, WY', path: '/trailer-rentals/wyoming/powell', lat: 44.7533, lng: -108.7579 },
  { name: 'Ranchester, WY', path: '/trailer-rentals/wyoming/ranchester', lat: 44.9083, lng: -107.1622 },
  { name: 'Rawlins, WY', path: '/trailer-rentals/wyoming/rawlins', lat: 41.7911, lng: -107.2387 },
  { name: 'Riverside, WY', path: '/trailer-rentals/wyoming/riverside', lat: 41.2083, lng: -106.7794 },
  { name: 'Riverton, WY', path: '/trailer-rentals/wyoming/riverton', lat: 43.0247, lng: -108.3806 },
  { name: 'Rock Springs, WY', path: '/trailer-rentals/wyoming/rock-springs', lat: 41.5875, lng: -109.2028 },
  { name: 'Rolling Hills, WY', path: '/trailer-rentals/wyoming/rolling-hills', lat: 42.8708, lng: -105.7883 },
  { name: 'Saratoga, WY', path: '/trailer-rentals/wyoming/saratoga', lat: 41.4541, lng: -106.8061 },
  { name: 'Sheridan, WY', path: '/trailer-rentals/wyoming/sheridan', lat: 44.7972, lng: -106.9562 },
  { name: 'Shoshoni, WY', path: '/trailer-rentals/wyoming/shoshoni', lat: 43.2358, lng: -108.1106 },
  { name: 'Sinclair, WY', path: '/trailer-rentals/wyoming/sinclair', lat: 41.7783, lng: -107.1256 },
  { name: 'South Greeley, WY', path: '/trailer-rentals/wyoming/south-greeley', lat: 41.0833, lng: -104.8011 },
  { name: 'Star Valley Ranch, WY', path: '/trailer-rentals/wyoming/star-valley-ranch', lat: 42.9733, lng: -110.9644 },
  { name: 'Sundance, WY', path: '/trailer-rentals/wyoming/sundance', lat: 44.4061, lng: -104.3756 },
  { name: 'Superior, WY', path: '/trailer-rentals/wyoming/superior', lat: 41.7608, lng: -108.9718 },
  { name: 'Ten Sleep, WY', path: '/trailer-rentals/wyoming/ten-sleep', lat: 44.0341, lng: -107.4506 },
  { name: 'Thermopolis, WY', path: '/trailer-rentals/wyoming/thermopolis', lat: 43.6464, lng: -108.2123 },
  { name: 'Torrington, WY', path: '/trailer-rentals/wyoming/torrington', lat: 42.0622, lng: -104.1844 },
  { name: 'Upton, WY', path: '/trailer-rentals/wyoming/upton', lat: 44.0991, lng: -104.6256 },
  { name: 'Wheatland, WY', path: '/trailer-rentals/wyoming/wheatland', lat: 42.0541, lng: -104.9522 },
  { name: 'Worland, WY', path: '/trailer-rentals/wyoming/worland', lat: 44.0161, lng: -107.9556 },
  { name: 'Wright, WY', path: '/trailer-rentals/wyoming/wright', lat: 43.7508, lng: -105.4961 },
  { name: 'Yoder, WY', path: '/trailer-rentals/wyoming/yoder', lat: 41.9161, lng: -104.2944 },
];

const delawareCities: ICity[] = [
  { name: 'Ardencroft, DE', path: '/trailer-rentals/delaware/ardencroft', lat: 39.8098, lng: -75.4844 },
  { name: 'Ardentown, DE', path: '/trailer-rentals/delaware/ardentown', lat: 39.8084, lng: -75.4844 },
  { name: 'Arden, DE', path: '/trailer-rentals/delaware/arden', lat: 39.8098, lng: -75.4844 },
  { name: 'Bear, DE', path: '/trailer-rentals/delaware/bear', lat: 39.6295, lng: -75.6588 },
  { name: 'Bellefonte, DE', path: '/trailer-rentals/delaware/bellefonte', lat: 39.7668, lng: -75.5038 },
  { name: 'Bethany Beach, DE', path: '/trailer-rentals/delaware/bethany-beach', lat: 38.5396, lng: -75.0560 },
  { name: 'Bethel, DE', path: '/trailer-rentals/delaware/bethel', lat: 38.5701, lng: -75.6208 },
  { name: 'Blades, DE', path: '/trailer-rentals/delaware/blades', lat: 38.6340, lng: -75.6094 },
  { name: 'Bridgeville, DE', path: '/trailer-rentals/delaware/bridgeville', lat: 38.7426, lng: -75.6044 },
  { name: 'Camden, DE', path: '/trailer-rentals/delaware/camden', lat: 39.1132, lng: -75.5413 },
  { name: 'Cheswold, DE', path: '/trailer-rentals/delaware/cheswold', lat: 39.2168, lng: -75.5866 },
  { name: 'Claymont, DE', path: '/trailer-rentals/delaware/claymont', lat: 39.8001, lng: -75.4594 },
  { name: 'Clayton, DE', path: '/trailer-rentals/delaware/clayton', lat: 39.2901, lng: -75.6344 },
  { name: 'Dagsboro, DE', path: '/trailer-rentals/delaware/dagsboro', lat: 38.5501, lng: -75.2488 },
  { name: 'Delaware City, DE', path: '/trailer-rentals/delaware/delaware-city', lat: 39.5776, lng: -75.5883 },
  { name: 'Dewey Beach, DE', path: '/trailer-rentals/delaware/dewey-beach', lat: 38.6926, lng: -75.0760 },
  { name: 'Dover, DE', path: '/trailer-rentals/delaware/dover', lat: 39.1582, lng: -75.5244 },
  { name: 'Edgemoor, DE', path: '/trailer-rentals/delaware/edgemoor', lat: 39.7584, lng: -75.4994 },
  { name: 'Ellendale, DE', path: '/trailer-rentals/delaware/ellendale', lat: 38.8073, lng: -75.4258 },
  { name: 'Felton, DE', path: '/trailer-rentals/delaware/felton', lat: 39.0098, lng: -75.5777 },
  { name: 'Fenwick Island, DE', path: '/trailer-rentals/delaware/fenwick-island', lat: 38.4601, lng: -75.0510 },
  { name: 'Frankford, DE', path: '/trailer-rentals/delaware/frankford', lat: 38.5198, lng: -75.2344 },
  { name: 'Frederica, DE', path: '/trailer-rentals/delaware/frederica', lat: 39.0084, lng: -75.4658 },
  { name: 'Georgetown, DE', path: '/trailer-rentals/delaware/georgetown', lat: 38.6901, lng: -75.3858 },
  { name: 'Glasgow, DE', path: '/trailer-rentals/delaware/glasgow', lat: 39.6068, lng: -75.7466 },
  { name: 'Greenville, DE', path: '/trailer-rentals/delaware/greenville', lat: 39.7826, lng: -75.5988 },
  { name: 'Greenwood, DE', path: '/trailer-rentals/delaware/greenwood', lat: 38.8073, lng: -75.5916 },
  { name: 'Harrington, DE', path: '/trailer-rentals/delaware/harrington', lat: 38.9234, lng: -75.5777 },
  { name: 'Hartly, DE', path: '/trailer-rentals/delaware/hartly', lat: 39.1618, lng: -75.7133 },
  { name: 'Henlopen Acres, DE', path: '/trailer-rentals/delaware/henlopen-acres', lat: 38.7198, lng: -75.0810 },
  { name: 'Highland Acres, DE', path: '/trailer-rentals/delaware/highland-acres', lat: 39.1334, lng: -75.5244 },
  { name: 'Hockessin, DE', path: '/trailer-rentals/delaware/hockessin', lat: 39.7876, lng: -75.6966 },
  { name: 'Houston, DE', path: '/trailer-rentals/delaware/houston', lat: 38.9226, lng: -75.5116 },
  { name: 'Kent Acres, DE', path: '/trailer-rentals/delaware/kent-acres', lat: 39.1334, lng: -75.5244 },
  { name: 'Laurel, DE', path: '/trailer-rentals/delaware/laurel', lat: 38.5568, lng: -75.5716 },
  { name: 'Leipsic, DE', path: '/trailer-rentals/delaware/leipsic', lat: 39.2451, lng: -75.5188 },
  { name: 'Lewes, DE', path: '/trailer-rentals/delaware/lewes', lat: 38.7743, lng: -75.1393 },
  { name: 'Little Creek, DE', path: '/trailer-rentals/delaware/little-creek', lat: 39.1626, lng: -75.4488 },
  { name: 'Long Neck, DE', path: '/trailer-rentals/delaware/long-neck', lat: 38.6151, lng: -75.1510 },
  { name: 'Magnolia, DE', path: '/trailer-rentals/delaware/magnolia', lat: 39.0718, lng: -75.4758 },
  { name: 'Middletown, DE', path: '/trailer-rentals/delaware/middletown', lat: 39.4498, lng: -75.7166 },
  { name: 'Milford, DE', path: '/trailer-rentals/delaware/milford', lat: 38.9126, lng: -75.4277 },
  { name: 'Millsboro, DE', path: '/trailer-rentals/delaware/millsboro', lat: 38.5918, lng: -75.2916 },
  { name: 'Millville, DE', path: '/trailer-rentals/delaware/millville', lat: 38.5498, lng: -75.1133 },
  { name: 'Milton, DE', path: '/trailer-rentals/delaware/milton', lat: 38.7776, lng: -75.3133 },
  { name: 'New Castle, DE', path: '/trailer-rentals/delaware/new-castle', lat: 39.6626, lng: -75.5666 },
  { name: 'Newark, DE', path: '/trailer-rentals/delaware/newark', lat: 39.6837, lng: -75.7497 },
  { name: 'Newport, DE', path: '/trailer-rentals/delaware/newport', lat: 39.7134, lng: -75.6108 },
  { name: 'North Star, DE', path: '/trailer-rentals/delaware/north-star', lat: 39.7518, lng: -75.7308 },
  { name: 'Ocean View, DE', path: '/trailer-rentals/delaware/ocean-view', lat: 38.5448, lng: -75.0894 },
  { name: 'Odessa, DE', path: '/trailer-rentals/delaware/odessa', lat: 39.4548, lng: -75.6608 },
  { name: 'Pike Creek, DE', path: '/trailer-rentals/delaware/pike-creek', lat: 39.7348, lng: -75.6977 },
  { name: 'Pike Creek Valley, DE', path: '/trailer-rentals/delaware/pike-creek-valley', lat: 39.7348, lng: -75.6977 },
  { name: 'Rehoboth Beach, DE', path: '/trailer-rentals/delaware/rehoboth-beach', lat: 38.7209, lng: -75.0760 },
  { name: 'Riverview, DE', path: '/trailer-rentals/delaware/riverview', lat: 39.0434, lng: -75.5416 },
  { name: 'Rodney Village, DE', path: '/trailer-rentals/delaware/rodney-village', lat: 39.1334, lng: -75.5244 },
  { name: 'Seaford, DE', path: '/trailer-rentals/delaware/seaford', lat: 38.6418, lng: -75.6116 },
  { name: 'Selbyville, DE', path: '/trailer-rentals/delaware/selbyville', lat: 38.4598, lng: -75.2208 },
  { name: 'Slaughter Beach, DE', path: '/trailer-rentals/delaware/slaughter-beach', lat: 38.9251, lng: -75.3116 },
  { name: 'Smyrna, DE', path: '/trailer-rentals/delaware/smyrna', lat: 39.2998, lng: -75.6044 },
  { name: 'South Bethany, DE', path: '/trailer-rentals/delaware/south-bethany', lat: 38.5068, lng: -75.0550 },
  { name: 'Stanton, DE', path: '/trailer-rentals/delaware/stanton', lat: 39.7151, lng: -75.6344 },
  { name: 'Townsend, DE', path: '/trailer-rentals/delaware/townsend', lat: 39.3948, lng: -75.6916 },
  { name: 'Viola, DE', path: '/trailer-rentals/delaware/viola', lat: 39.0418, lng: -75.5716 },
  { name: 'Wilmington, DE', path: '/trailer-rentals/delaware/wilmington', lat: 39.7391, lng: -75.5398, active: true, showNewVersion: true },
  { name: 'Wilmington Manor, DE', path: '/trailer-rentals/delaware/wilmington-manor', lat: 39.6868, lng: -75.5894 },
  { name: 'Woodside, DE', path: '/trailer-rentals/delaware/woodside', lat: 39.0484, lng: -75.5588 },
  { name: 'Wyoming, DE', path: '/trailer-rentals/delaware/wyoming', lat: 39.1184, lng: -75.5588 },
  { name: 'Rising Sun-Lebanon, DE', path: '/trailer-rentals/delaware/rising-sun-lebanon', lat: 39.1184, lng: -75.5588 },
  { name: 'Kenton, DE', path: '/trailer-rentals/delaware/kenton', lat: 39.2218, lng: -75.6694 },
  { name: 'Farmington, DE', path: '/trailer-rentals/delaware/farmington', lat: 38.8726, lng: -75.5808 },
  { name: 'Lincoln, DE', path: '/trailer-rentals/delaware/lincoln', lat: 38.8668, lng: -75.4188 },
  { name: 'Bowers, DE', path: '/trailer-rentals/delaware/bowers', lat: 39.0598, lng: -75.4016 },
  { name: 'Little Heaven, DE', path: '/trailer-rentals/delaware/little-heaven', lat: 39.0568, lng: -75.4488 },
  { name: 'Bethany Forest, DE', path: '/trailer-rentals/delaware/bethany-forest', lat: 38.5396, lng: -75.0560 },
  { name: 'Fenwick Acres, DE', path: '/trailer-rentals/delaware/fenwick-acres', lat: 38.4601, lng: -75.0510 },
  { name: 'West Rehoboth, DE', path: '/trailer-rentals/delaware/west-rehoboth', lat: 38.7209, lng: -75.0760 },
  { name: 'Holly Oak, DE', path: '/trailer-rentals/delaware/holly-oak', lat: 39.8001, lng: -75.4594 },
  { name: 'Yorklyn, DE', path: '/trailer-rentals/delaware/yorklyn', lat: 39.8001, lng: -75.4594 },
];

const northernArizonaCities: ICity[] = [
  { name: 'Camp Verde, AZ', path: '/trailer-rentals/arizona/camp-verde', lat: 34.563635, lng: -111.854317 },
  { name: 'Chinle, AZ', path: '/trailer-rentals/arizona/chinle', lat: 36.154598, lng: -109.553302 },
  { name: 'Cottonwood, AZ', path: '/trailer-rentals/arizona/cottonwood', lat: 34.739188, lng: -112.009879 },
  { name: 'Dewey Humboldt, AZ', path: '/trailer-rentals/arizona/dewey-humboldt', lat: 34.545867, lng: -112.244602 },
  { name: 'Flagstaff, AZ', path: '/trailer-rentals/arizona/flagstaff', lat: 35.198284, lng: -111.651302 },
  { name: 'Fredonia, AZ', path: '/trailer-rentals/arizona/fredonia', lat: 36.948598, lng: -112.527302 },
  { name: 'Holbrook, AZ', path: '/trailer-rentals/arizona/holbrook', lat: 34.902598, lng: -110.158302 },
  { name: 'Jerome, AZ', path: '/trailer-rentals/arizona/jerome', lat: 34.748598, lng: -112.113302 },
  { name: 'Kayenta, AZ', path: '/trailer-rentals/arizona/kayenta', lat: 36.6883, lng: -110.2652 },
  { name: 'Page, AZ', path: '/trailer-rentals/arizona/page', lat: 36.914722, lng: -111.455833 },
  { name: 'Payson, AZ', path: '/trailer-rentals/arizona/payson', lat: 34.230869, lng: -111.325302 },
  { name: 'Prescott, AZ', path: '/trailer-rentals/arizona/prescott', lat: 34.540024, lng: -112.468502 },
  { name: 'Prescott Valley, AZ', path: '/trailer-rentals/arizona/prescott-valley', lat: 34.610024, lng: -112.315502 },
  { name: 'Sedona, AZ', path: '/trailer-rentals/arizona/sedona', lat: 34.869598, lng: -111.761302 },
  { name: 'Show Low, AZ', path: '/trailer-rentals/arizona/show-low', lat: 34.254208, lng: -110.029832 },
  { name: 'Snowflake, AZ', path: '/trailer-rentals/arizona/snowflake', lat: 34.511598, lng: -110.078302 },
  { name: 'Springerville, AZ', path: '/trailer-rentals/arizona/springerville', lat: 34.133598, lng: -109.285302 },
  { name: 'St. Johns, AZ', path: '/trailer-rentals/arizona/st-johns', lat: 34.505598, lng: -109.360302 },
  { name: 'Superior, AZ', path: '/trailer-rentals/arizona/superior', lat: 33.293598, lng: -111.096302 },
  { name: 'Taylor, AZ', path: '/trailer-rentals/arizona/taylor', lat: 34.465598, lng: -110.097302 },
  { name: 'Tuba City, AZ', path: '/trailer-rentals/arizona/tuba-city', lat: 36.1037, lng: -111.2686 },
  { name: 'Williams, AZ', path: '/trailer-rentals/arizona/williams', lat: 35.249598, lng: -112.191302 },
  { name: 'Window Rock, AZ', path: '/trailer-rentals/arizona/window-rock', lat: 35.6676, lng: -109.0784 },
  { name: 'Winslow, AZ', path: '/trailer-rentals/arizona/winslow', lat: 35.024187, lng: -110.697302 },
];

const centralArizonaCities: ICity[] = [
  { name: 'Anthem, AZ', path: '/trailer-rentals/arizona/anthem', lat: 33.863598, lng: -112.135302 },
  { name: 'Apache Junction, AZ', path: '/trailer-rentals/arizona/apache-junction', lat: 33.415048, lng: -111.549577 },
  { name: 'Avondale, AZ', path: '/trailer-rentals/arizona/avondale', lat: 33.435597, lng: -112.349602 },
  { name: 'Buckeye, AZ', path: '/trailer-rentals/arizona/buckeye', lat: 33.370319, lng: -112.583776 },
  { name: 'Carefree, AZ', path: '/trailer-rentals/arizona/carefree', lat: 33.8223, lng: -111.9185 },
  { name: 'Casa Grande, AZ', path: '/trailer-rentals/arizona/casa-grande', lat: 32.8795, lng: -111.7574 },
  { name: 'Cave Creek, AZ', path: '/trailer-rentals/arizona/cave-creek', lat: 33.833379, lng: -111.950704 },
  { name: 'Chandler, AZ', path: '/trailer-rentals/arizona/chandler', lat: 33.306160, lng: -111.841250, active: true, showNewVersion: true },
  { name: 'El Mirage, AZ', path: '/trailer-rentals/arizona/el-mirage', lat: 33.613092, lng: -112.324602 },
  { name: 'Florence, AZ', path: '/trailer-rentals/arizona/florence', lat: 33.031452, lng: -111.387344 },
  { name: 'Fountain Hills, AZ', path: '/trailer-rentals/arizona/fountain-hills', lat: 33.604186, lng: -111.725302 },
  { name: 'Gilbert, AZ', path: '/trailer-rentals/arizona/gilbert', lat: 33.3528, lng: -111.7890 },
  { name: 'Glendale, AZ', path: '/trailer-rentals/arizona/glendale', lat: 33.538652, lng: -112.185986, active: true, showNewVersion: true },
  { name: 'Gold Canyon, AZ', path:'/trailer-rentals/arizona/gold-canyon', lat: 33.3709, lng: -111.4371 },
  { name: 'Goodyear, AZ', path: '/trailer-rentals/arizona/goodyear', lat: 33.435322, lng: -112.358302 },
  { name: 'Guadalupe, AZ', path: '/trailer-rentals/arizona/guadalupe', lat: 33.366759, lng: -111.962302 },
  { name: 'Litchfield Park, AZ', path: '/trailer-rentals/arizona/litchfield-park', lat: 33.493598, lng: -112.357302 },
  { name: 'Maricopa, AZ', path: '/trailer-rentals/arizona/maricopa', lat: 33.0581, lng: -112.0476 },
  { name: 'Mesa, AZ', path: '/trailer-rentals/arizona/mesa', lat: 33.415184, lng: -111.831472, active: true, showNewVersion: true },
  { name: 'Paradise Valley, AZ', path: '/trailer-rentals/arizona/paradise-valley', lat: 33.531157, lng: -111.942602 },
  { name: 'Peoria, AZ', path: '/trailer-rentals/arizona/peoria', lat: 33.580595, lng: -112.237377 },
  { name: 'Phoenix, AZ', path: '/trailer-rentals/arizona/phoenix', lat: 33.448376, lng: -112.074036, active: true, showNewVersion: true },
  { name: 'Queen Creek, AZ', path: '/trailer-rentals/arizona/queen-creek', lat: 33.248598, lng: -111.634302 },
  { name: 'Rio Rico, AZ', path: '/trailer-rentals/arizona/rio-rico', lat: 31.486598, lng: -110.977302 },
  { name: 'San Tan Valley, AZ', path: '/trailer-rentals/arizona/san-tan-valley', lat: 33.166598, lng: -111.572302 },
  { name: 'Scottsdale, AZ', path: '/trailer-rentals/arizona/scottsdale', lat: 33.494170, lng: -111.926052, active: true, showNewVersion: true },
  { name: 'Sun City, AZ', path: '/trailer-rentals/arizona/sun-city', lat: 33.597598, lng: -112.280302 },
  { name: 'Sun City West, AZ', path: '/trailer-rentals/arizona/sun-city-west', lat: 33.661598, lng: -112.356302 },
  { name: 'Surprise, AZ', path: '/trailer-rentals/arizona/surprise', lat: 33.629598, lng: -112.367302 },
  { name: 'Tempe, AZ', path: '/trailer-rentals/arizona/tempe', lat: 33.425510, lng: -111.940005, active: true, showNewVersion: true },
  { name: 'Tolleson, AZ', path: '/trailer-rentals/arizona/tolleson', lat: 33.450598, lng: -112.259302 },
  { name: 'Waddell, AZ', path: '/trailer-rentals/arizona/waddell', lat: 33.566598, lng: -112.437302 },
]

export const southernArizonaCities: ICity[] = [
  { name: 'Ajo, AZ', path: '/trailer-rentals/arizona/ajo', lat: 32.3717, lng: -112.8607 },
  { name: 'Benson, AZ', path: '/trailer-rentals/arizona/benson', lat: 31.967093, lng: -110.294526 },
  { name: 'Bisbee, AZ', path: '/trailer-rentals/arizona/bisbee', lat: 31.448154, lng: -109.928409 },
  { name: 'Catalina, AZ', path: '/trailer-rentals/arizona/catalina', lat: 32.503598, lng: -110.922302 },
  { name: 'Douglas, AZ', path: '/trailer-rentals/arizona/douglas', lat: 31.344547, lng: -109.545339 },
  { name: 'Duncan, AZ', path: '/trailer-rentals/arizona/duncan', lat: 32.726738, lng: -109.097569 },
  { name: 'Eloy, AZ', path: '/trailer-rentals/arizona/eloy', lat: 32.755902, lng: -111.554602 },
  { name: 'Gila Bend, AZ', path: '/trailer-rentals/arizona/gila-bend', lat: 32.953598, lng: -112.718302 },
  { name: 'Green Valley, AZ', path: '/trailer-rentals/arizona/green-valley', lat: 31.854598, lng: -111.002302 },
  { name: 'Huachuca City, AZ', path: '/trailer-rentals/arizona/huachuca-city', lat: 31.626598, lng: -110.333302 },
  { name: 'Mammoth, AZ', path: '/trailer-rentals/arizona/mammoth', lat: 32.722598, lng: -110.642302 },
  { name: 'Marana, AZ', path: '/trailer-rentals/arizona/marana', lat: 32.4364, lng: -111.2224 },
  { name: 'Nogales, AZ', path: '/trailer-rentals/arizona/nogales', lat: 31.340377, lng: -110.934253 },
  { name: 'Oro Valley, AZ', path: '/trailer-rentals/arizona/oro-valley', lat: 32.390907, lng: -110.966488 },
  { name: 'Patagonia, AZ', path: '/trailer-rentals/arizona/patagonia', lat: 31.539598, lng: -110.755302 },
  { name: 'Pima, AZ', path: '/trailer-rentals/arizona/pima', lat: 32.896598, lng: -109.827302 },
  { name: 'Safford, AZ', path: '/trailer-rentals/arizona/safford', lat: 32.833598, lng: -109.707302 },
  { name: 'Sahuarita, AZ', path: '/trailer-rentals/arizona/sahuarita', lat: 31.9576, lng: -110.9823 },
  { name: 'Sells, AZ', path: '/trailer-rentals/arizona/sells', lat: 31.9120, lng: -111.8812 },
  { name: 'Sierra Vista, AZ', path: '/trailer-rentals/arizona/sierra-vista', lat: 31.545500, lng: -110.277302 },
  { name: 'South Tucson, AZ', path: '/trailer-rentals/arizona/south-tucson', lat: 32.194598, lng: -110.968302 },
  { name: 'Thatcher, AZ', path: '/trailer-rentals/arizona/thatcher', lat: 32.849598, lng: -109.759302 },
  { name: 'Tombstone, AZ', path: '/trailer-rentals/arizona/tombstone', lat: 31.715598, lng: -110.067302 },
  { name: 'Tucson, AZ', path: '/trailer-rentals/arizona/tucson', lat: 32.222607, lng: -110.974709 },
  { name: 'Vail, AZ', path: '/trailer-rentals/arizona/vail', lat: 32.103598, lng: -110.716302 },
  { name: 'Willcox, AZ', path: '/trailer-rentals/arizona/willcox', lat: 32.252598, lng: -109.832302 },
];

export const westernArizonaCities: ICity[] = [
  { name: 'Bullhead City, AZ', path: '/trailer-rentals/arizona/bullhead-city', lat: 35.135938, lng: -114.528598 },
  { name: 'Colorado City, AZ', path: '/trailer-rentals/arizona/colorado-city', lat: 36.990819, lng: -112.978267 },
  { name: 'Ehrenberg, AZ', path: '/trailer-rentals/arizona/ehrenberg', lat: 33.6042, lng: -114.5252 },
  { name: 'Fort Mohave, AZ', path: '/trailer-rentals/arizona/fort-mohave', lat: 35.0225, lng: -114.5893 },
  { name: 'Kingman, AZ', path: '/trailer-rentals/arizona/kingman', lat: 35.189443, lng: -114.053006 },
  { name: 'Lake Havasu City, AZ', path: '/trailer-rentals/arizona/lake-havasu-city', lat: 34.483901, lng: -114.322454 },
  { name: 'Parker, AZ', path: '/trailer-rentals/arizona/parker', lat: 34.150598, lng: -114.289302 },
  { name: 'Quartzsite, AZ', path: '/trailer-rentals/arizona/quartzsite', lat: 33.663598, lng: -114.229302 },
  { name: 'Somerton, AZ', path: '/trailer-rentals/arizona/somerton', lat: 32.596598, lng: -114.709302 },
  { name: 'Wellton, AZ', path: '/trailer-rentals/arizona/wellton', lat: 32.671598, lng: -114.145302 },
  { name: 'Wikieup, AZ', path: '/trailer-rentals/arizona/wikieup', lat: 34.7051, lng: -113.6118 },
  { name: 'Yuma, AZ', path: '/trailer-rentals/arizona/yuma', lat: 32.692598, lng: -114.627302 },
  { name: 'Yuma Proving Ground, AZ', path: '/trailer-rentals/arizona/yuma-proving-ground', lat: 32.836598, lng: -114.395302 },
];

const northernOhioCities: ICity[] = [
  { name: 'Ada, OH', path: '/trailer-rentals/ohio/ada', lat: 40.7690, lng: -83.8222 },
  { name: 'Amherst, OH', path: '/trailer-rentals/ohio/amherst', lat: 41.3970, lng: -82.2221 },
  { name: 'Archbold, OH', path: '/trailer-rentals/ohio/archbold', lat: 41.5210, lng: -84.3052 },
  { name: 'Ashtabula, OH', path: '/trailer-rentals/ohio/ashtabula', lat: 41.8651, lng: -80.7898 },
  { name: 'Aurora, OH', path: '/trailer-rentals/ohio/aurora', lat: 41.3170, lng: -81.3454 },
  { name: 'Avon, OH', path: '/trailer-rentals/ohio/avon', lat: 41.4514, lng: -82.0354 },
  { name: 'Avon Lake, OH', path: '/trailer-rentals/ohio/avon-lake', lat: 41.5059, lng: -82.0282 },
  { name: 'Barberton, OH', path: '/trailer-rentals/ohio/barberton', lat: 41.0128, lng: -81.6051 },
  { name: 'Bay Village, OH', path: '/trailer-rentals/ohio/bay-village', lat: 41.4848, lng: -81.9229 },
  { name: 'Beachwood, OH', path: '/trailer-rentals/ohio/beachwood', lat: 41.4645, lng: -81.5084 },
  { name: 'Bellevue, OH', path: '/trailer-rentals/ohio/bellevue', lat: 41.2731, lng: -82.8421 },
  { name: 'Bluffton, OH', path: '/trailer-rentals/ohio/bluffton', lat: 40.8934, lng: -83.8917 },
  { name: 'Bowling Green, OH', path: '/trailer-rentals/ohio/bowling-green', lat: 41.3748, lng: -83.6513 },
  { name: 'Brecksville, OH', path: '/trailer-rentals/ohio/brecksville', lat: 41.3195, lng: -81.6265 },
  { name: 'Broadview Heights, OH', path: '/trailer-rentals/ohio/broadview-heights', lat: 41.3134, lng: -81.6851 },
  { name: 'Brook Park, OH', path: '/trailer-rentals/ohio/brook-park', lat: 41.3987, lng: -81.8332 },
  { name: 'Brooklyn, OH', path: '/trailer-rentals/ohio/brooklyn', lat: 41.4395, lng: -81.7368 },
  { name: 'Brunswick, OH', path: '/trailer-rentals/ohio/brunswick', lat: 41.2381, lng: -81.8418 },
  { name: 'Bryan, OH', path: '/trailer-rentals/ohio/bryan', lat: 41.4748, lng: -84.5521 },
  { name: 'Bucyrus, OH', path: '/trailer-rentals/ohio/bucyrus', lat: 40.8089, lng: -82.9755 },
  { name: 'Clyde, OH', path: '/trailer-rentals/ohio/clyde', lat: 41.3045, lng: -82.9755 },
  { name: 'Conneaut, OH', path: '/trailer-rentals/ohio/conneaut', lat: 41.9476, lng: -80.5545 },
  { name: 'Crestline, OH', path: '/trailer-rentals/ohio/crestline', lat: 40.7878, lng: -82.7365 },
  { name: 'East Cleveland, OH', path: '/trailer-rentals/ohio/east-cleveland', lat: 41.5339, lng: -81.5790 },
  { name: 'East Palestine, OH', path: '/trailer-rentals/ohio/east-palestine', lat: 40.8317, lng: -80.5424 },
  { name: 'Eastlake, OH', path: '/trailer-rentals/ohio/eastlake', lat: 41.6537, lng: -81.4501 },
  { name: 'Elyria, OH', path: '/trailer-rentals/ohio/elyria', lat: 41.3684, lng: -82.1077 },
  { name: 'Englewood, OH', path: '/trailer-rentals/ohio/englewood', lat: 39.8776, lng: -84.3022 },
  { name: 'Euclid, OH', path: '/trailer-rentals/ohio/euclid', lat: 41.5931, lng: -81.5268 },
  { name: 'Fairlawn, OH', path: '/trailer-rentals/ohio/fairlawn', lat: 41.1278, lng: -81.6093 },
  { name: 'Findlay, OH', path: '/trailer-rentals/ohio/findlay', lat: 41.0442, lng: -83.6499 },
  { name: 'Fremont, OH', path: '/trailer-rentals/ohio/fremont', lat: 41.3503, lng: -83.1213 },
  { name: 'Garfield Heights, OH', path: '/trailer-rentals/ohio/garfield-heights', lat: 41.4170, lng: -81.6051 },
  { name: 'Geneva, OH', path: '/trailer-rentals/ohio/geneva', lat: 41.8051, lng: -80.9487 },
  { name: 'Girard, OH', path: '/trailer-rentals/ohio/girard', lat: 41.1534, lng: -80.7004 },
  { name: 'Green, OH', path: '/trailer-rentals/ohio/green', lat: 40.9459, lng: -81.4834 },
  { name: 'Greenville, OH', path: '/trailer-rentals/ohio/greenville', lat: 40.1020, lng: -84.6330 },
  { name: 'Hicksville, OH', path: '/trailer-rentals/ohio/hicksville', lat: 41.2931, lng: -84.7555 },
  { name: 'Hudson, OH', path: '/trailer-rentals/ohio/hudson', lat: 41.2401, lng: -81.4404 },
  { name: 'Independence, OH', path: '/trailer-rentals/ohio/independence', lat: 41.3681, lng: -81.6415 },
  { name: 'Lakewood, OH', path: '/trailer-rentals/ohio/lakewood', lat: 41.4819, lng: -81.7982 },
  { name: 'Lorain, OH', path: '/trailer-rentals/ohio/lorain', lat: 41.4528, lng: -82.1824 },
  { name: 'Mansfield, OH', path: '/trailer-rentals/ohio/mansfield', lat: 40.7584, lng: -82.5154 },
  { name: 'Maple Heights, OH', path: '/trailer-rentals/ohio/maple-heights', lat: 41.4151, lng: -81.5651 },
  { name: 'Medina, OH', path: '/trailer-rentals/ohio/medina', lat: 41.1384, lng: -81.8637 },
  { name: 'Mentor, OH', path: '/trailer-rentals/ohio/mentor', lat: 41.6662, lng: -81.3396 },
  { name: 'Napoleon, OH', path: '/trailer-rentals/ohio/napoleon', lat: 41.3928, lng: -84.1252 },
  { name: 'Norwalk, OH', path: '/trailer-rentals/ohio/norwalk', lat: 41.2428, lng: -82.6157 },
  { name: 'Oberlin, OH', path: '/trailer-rentals/ohio/oberlin', lat: 41.2934, lng: -82.2171 },
  { name: 'Olmsted Falls, OH', path: '/trailer-rentals/ohio/olmsted-falls', lat: 41.3759, lng: -81.9085 },
  { name: 'Ontario, OH', path: '/trailer-rentals/ohio/ontario', lat: 40.7595, lng: -82.6021 },
  { name: 'Parma, OH', path: '/trailer-rentals/ohio/parma', lat: 41.4048, lng: -81.7229 },
  { name: 'Parma Heights, OH', path: '/trailer-rentals/ohio/parma-heights', lat: 41.3901, lng: -81.7593 },
  { name: 'Rocky River, OH', path: '/trailer-rentals/ohio/rocky-river', lat: 41.4759, lng: -81.8396 },
  { name: 'Sandusky, OH', path: '/trailer-rentals/ohio/sandusky', lat: 41.4489, lng: -82.7071 },
  { name: 'Seven Hills, OH', path: '/trailer-rentals/ohio/seven-hills', lat: 41.3878, lng: -81.6765 },
  { name: 'Shelby, OH', path: '/trailer-rentals/ohio/shelby', lat: 40.8812, lng: -82.6615 },
  { name: 'Shreve, OH', path: '/trailer-rentals/ohio/shreve', lat: 40.6814481, lng: -82.0218148, active: true, showNewVersion: true },
  { name: 'Strongsville, OH', path: '/trailer-rentals/ohio/strongsville', lat: 41.3145, lng: -81.8357 },
  { name: 'Sylvania, OH', path: '/trailer-rentals/ohio/sylvania', lat: 41.7184, lng: -83.7121 },
  { name: 'Tallmadge, OH', path: '/trailer-rentals/ohio/tallmadge', lat: 41.1012, lng: -81.4415 },
  { name: 'Vermilion, OH', path: '/trailer-rentals/ohio/vermilion', lat: 41.4212, lng: -82.3644 },
  { name: 'Westlake, OH', path: '/trailer-rentals/ohio/westlake', lat: 41.4559, lng: -81.9171 },
  { name: 'Wooster, OH', path: '/trailer-rentals/ohio/wooster', lat: 40.8059, lng: -81.9365 },
];

const centralOhioCities: ICity[] = [
  { name: 'Athens, OH', path: '/trailer-rentals/ohio/athens', lat: 39.3292, lng: -82.1013 },
  { name: 'Cambridge, OH', path: '/trailer-rentals/ohio/cambridge', lat: 40.0312, lng: -81.5885 },
  { name: 'Canal Winchester, OH', path: '/trailer-rentals/ohio/canal-winchester', lat: 39.8428, lng: -82.8121 },
  { name: 'Carrollton, OH', path: '/trailer-rentals/ohio/carrollton', lat: 40.5723, lng: -81.0857 },
  { name: 'Chillicothe, OH', path: '/trailer-rentals/ohio/chillicothe', lat: 39.3331, lng: -82.9824 },
  { name: 'Circleville, OH', path: '/trailer-rentals/ohio/circleville', lat: 39.6001, lng: -82.9460 },
  { name: 'Columbus, OH', path: '/trailer-rentals/ohio/columbus', lat: 39.9612, lng: -82.9988 },
  { name: 'Delaware, OH', path: '/trailer-rentals/ohio/delaware', lat: 40.2987, lng: -83.0679 },
  { name: 'Dublin, OH', path: '/trailer-rentals/ohio/dublin', lat: 40.0992, lng: -83.1141 },
  { name: 'Gahanna, OH', path: '/trailer-rentals/ohio/gahanna', lat: 40.0192, lng: -82.8796 },
  { name: 'Galion, OH', path: '/trailer-rentals/ohio/galion', lat: 40.7337, lng: -82.7891 },
  { name: 'Granville, OH', path: '/trailer-rentals/ohio/granville', lat: 40.0681, lng: -82.5196 },
  { name: 'Grove City, OH', path: '/trailer-rentals/ohio/grove-city', lat: 39.8815, lng: -83.0920 },
  { name: 'Groveport, OH', path: '/trailer-rentals/ohio/groveport', lat: 39.8651, lng: -82.8885 },
  { name: 'Hilliard, OH', path: '/trailer-rentals/ohio/hilliard', lat: 40.0334, lng: -83.1582 },
  { name: 'Logan, OH', path: '/trailer-rentals/ohio/logan', lat: 39.5401, lng: -82.4074 },
  { name: 'London, OH', path: '/trailer-rentals/ohio/london', lat: 39.8867, lng: -83.4485 },
  { name: 'Marion, OH', path: '/trailer-rentals/ohio/marion', lat: 40.5887, lng: -83.1285 },
  { name: 'Marysville, OH', path: '/trailer-rentals/ohio/marysville', lat: 40.2364, lng: -83.3671 },
  { name: 'Mount Gilead, OH', path: '/trailer-rentals/ohio/mount-gilead', lat: 40.5459, lng: -82.8273 },
  { name: 'Mount Vernon, OH', path: '/trailer-rentals/ohio/mount-vernon', lat: 40.3934, lng: -82.4852 },
  { name: 'Newark, OH', path: '/trailer-rentals/ohio/newark', lat: 40.0581, lng: -82.4013 },
  { name: 'Pataskala, OH', path: '/trailer-rentals/ohio/pataskala', lat: 39.9951, lng: -82.6746 },
  { name: 'Powell, OH', path: '/trailer-rentals/ohio/powell', lat: 40.1578, lng: -83.0755 },
  { name: 'Reynoldsburg, OH', path: '/trailer-rentals/ohio/reynoldsburg', lat: 39.9548, lng: -82.8121 },
  { name: 'Upper Arlington, OH', path: '/trailer-rentals/ohio/upper-arlington', lat: 40.0245, lng: -83.0621 },
  { name: 'Washington Court House, OH', path: '/trailer-rentals/ohio/washington-court-house', lat: 39.5367, lng: -83.4396 },
  { name: 'Worthington, OH', path: '/trailer-rentals/ohio/worthington', lat: 40.0934, lng: -83.0171 },
];

const southernOhioCities: ICity[] = [
  { name: 'Alliance, OH', path: '/trailer-rentals/ohio/alliance', lat: 40.9153, lng: -81.1059 },
  { name: 'Amelia, OH', path: '/trailer-rentals/ohio/amelia', lat: 39.0284, lng: -84.2177 },
  { name:'Batavia, OH', path: '/trailer-rentals/ohio/batavia', lat: 39.0779, lng: -84.1767 },
  { name: 'Beavercreek, OH', path: '/trailer-rentals/ohio/beavercreek', lat: 39.7092, lng: -84.0633 },
  { name: 'Bellbrook, OH', path: '/trailer-rentals/ohio/bellbrook', lat: 39.6351, lng: -84.0705 },
  { name: 'Belmont, OH', path: '/trailer-rentals/ohio/belmont', lat: 40.0201, lng: -81.0426 },
  { name: 'Belpre, OH', path: '/trailer-rentals/ohio/belpre', lat: 39.2731, lng: -81.5721 },
  { name: 'Blue Ash, OH', path: '/trailer-rentals/ohio/blue-ash', lat: 39.2320, lng: -84.3783 },
  { name: 'Cincinnati, OH', path: '/trailer-rentals/ohio/cincinnati', lat: 39.1031, lng: -84.5120 },
  { name: 'Dayton, OH', path: '/trailer-rentals/ohio/dayton', lat: 39.7589, lng: -84.1916 },
  { name: 'Defiance, OH', path: '/trailer-rentals/ohio/defiance', lat: 41.2842, lng: -84.3558 },
  { name: 'Delphos, OH', path: '/trailer-rentals/ohio/delphos', lat: 40.8434, lng: -84.3411 },
  { name: 'Dover, OH', path: '/trailer-rentals/ohio/dover', lat: 40.5203, lng: -81.4743 },
  { name: 'East Liverpool, OH', path: '/trailer-rentals/ohio/east-liverpool', lat: 40.6184, lng: -80.5773 },
  { name: 'Eaton, OH', path: '/trailer-rentals/ohio/eaton', lat: 39.7442, lng: -84.6366 },
  { name: 'Fairborn, OH', path: '/trailer-rentals/ohio/fairborn', lat: 39.8209, lng: -84.0194 },
  { name: 'Fairfield, OH', path: '/trailer-rentals/ohio/fairfield', lat: 39.3459, lng: -84.5605 },
  { name: 'Forest Park, OH', path: '/trailer-rentals/ohio/forest-park', lat: 39.2901, lng: -84.5227 },
  { name: 'Fostoria, OH', path: '/trailer-rentals/ohio/fostoria', lat: 41.1567, lng: -83.4166 },
  { name: 'Franklin, OH', path: '/trailer-rentals/ohio/franklin', lat: 39.5581, lng: -84.3041 },
  { name: 'Hamilton, OH', path: '/trailer-rentals/ohio/hamilton', lat: 39.3995, lng: -84.5613 },
  { name: 'Harrison, OH', path: '/trailer-rentals/ohio/harrison', lat: 39.2628, lng: -84.7913 },
  { name: 'Hillsboro, OH', path: '/trailer-rentals/ohio/hillsboro', lat: 39.2028, lng: -83.6116 },
  { name: 'Ironton, OH', path: '/trailer-rentals/ohio/ironton', lat: 38.5365, lng: -82.6821 },
  { name: 'Jackson, OH', path: '/trailer-rentals/ohio/jackson', lat: 39.0528, lng: -82.6360 },
  { name: 'Kent, OH', path: '/trailer-rentals/ohio/kent', lat: 41.1537, lng: -81.3579 },
  { name: 'Kettering, OH', path: '/trailer-rentals/ohio/kettering', lat: 39.6895, lng: -84.1688 },
  { name: 'Lebanon, OH', path: '/trailer-rentals/ohio/lebanon', lat: 39.4353, lng: -84.2020 },
  { name: 'Loveland, OH', path: '/trailer-rentals/ohio/loveland', lat: 39.2689, lng: -84.2633 },
  { name: 'Mason, OH', path: '/trailer-rentals/ohio/mason', lat: 39.3601, lng: -84.3099 },
  { name: 'Middletown, OH', path: '/trailer-rentals/ohio/middletown', lat: 39.5151, lng: -84.3983 },
  { name: 'Milford, OH', path: '/trailer-rentals/ohio/milford', lat: 39.1753, lng: -84.2944 },
  { name: 'Monroe, OH', path: '/trailer-rentals/ohio/monroe', lat: 39.4401, lng: -84.3627 },
  { name: 'Montgomery, OH', path: '/trailer-rentals/ohio/montgomery', lat: 39.2289, lng: -84.3541 },
  { name: 'Miamisburg, OH', path: '/trailer-rentals/ohio/miamisburg', lat: 39.6428, lng: -84.2866 },
  { name: 'Mount Healthy, OH', path: '/trailer-rentals/ohio/mount-healthy', lat: 39.2339, lng: -84.5477 },
  { name: 'New Albany, OH', path: '/trailer-rentals/ohio/new-albany', lat: 40.0812, lng: -82.8088 },
  { name: 'New Carlisle, OH', path: '/trailer-rentals/ohio/new-carlisle', lat: 39.9378, lng: -84.0255 },
  { name: 'New Lexington, OH', path: '/trailer-rentals/ohio/new-lexington', lat: 39.7131, lng: -82.2071 },
  { name: 'New Philadelphia, OH', path: '/trailer-rentals/ohio/new-philadelphia', lat: 40.4898, lng: -81.4457 },
  { name: 'North Canton, OH', path: '/trailer-rentals/ohio/north-canton', lat: 40.8759, lng: -81.4023 },
  { name: 'Oxford, OH', path: '/trailer-rentals/ohio/oxford', lat: 39.5078, lng: -84.7455 },
  { name: 'Painesville, OH', path: '/trailer-rentals/ohio/painesville', lat: 41.7245, lng: -81.2457 },
  { name: 'Pickerington, OH', path: '/trailer-rentals/ohio/pickerington', lat: 39.8845, lng: -82.7535 },
  { name: 'Portsmouth, OH', path: '/trailer-rentals/ohio/portsmouth', lat: 38.7317, lng: -82.9977 },
  { name: 'Ravenna, OH', path: '/trailer-rentals/ohio/ravenna', lat: 41.1578, lng: -81.2427 },
  { name: 'Reading, OH', path: '/trailer-rentals/ohio/reading', lat: 39.2234, lng: -84.4427 },
  { name: 'Richmond Heights, OH', path: '/trailer-rentals/ohio/richmond-heights', lat: 41.5528, lng: -81.5101 },
  { name: 'Riverside, OH', path: '/trailer-rentals/ohio/riverside', lat: 39.7795, lng: -84.1247 },
  { name: 'Salem, OH', path: '/trailer-rentals/ohio/salem', lat: 40.9001, lng: -80.8565 },
  { name: 'Shaker Heights, OH', path: '/trailer-rentals/ohio/shaker-heights', lat: 41.4734, lng: -81.5371 },
  { name: 'Sidney, OH', path: '/trailer-rentals/ohio/sidney', lat: 40.2845, lng: -84.1555 },
  { name: 'South Euclid, OH', path: '/trailer-rentals/ohio/south-euclid', lat: 41.5234, lng: -81.5185 },
  { name: 'Springfield, OH', path: '/trailer-rentals/ohio/springfield', lat: 39.9245, lng: -83.8088 },
  { name: 'Steubenville, OH', path: '/trailer-rentals/ohio/steubenville', lat: 40.3695, lng: -80.6338 },
  { name: 'Stow, OH', path: '/trailer-rentals/ohio/stow', lat: 41.1595, lng: -81.4404 },
  { name: 'Streetsboro, OH', path: '/trailer-rentals/ohio/streetsboro', lat: 41.2395, lng: -81.3457 },
  { name: 'Troy, OH', path: '/trailer-rentals/ohio/troy', lat: 40.0395, lng: -84.2038 },
  { name: 'Twinsburg, OH', path: '/trailer-rentals/ohio/twinsburg', lat: 41.3128, lng: -81.4404 },
  { name: 'Urbana, OH', path: '/trailer-rentals/ohio/urbana', lat: 40.1084, lng: -83.7521 },
  { name: 'Van Wert, OH', path: '/trailer-rentals/ohio/van-wert', lat: 40.8695, lng: -84.5844 },
  { name: 'Wadsworth, OH', path: '/trailer-rentals/ohio/wadsworth', lat: 41.0259, lng: -81.7293 },
  { name: 'Warren, OH', path: '/trailer-rentals/ohio/warren', lat: 41.2378, lng: -80.8185 },
  { name: 'Warrensville Heights, OH', path: '/trailer-rentals/ohio/warrensville-heights', lat: 41.4384, lng: -81.5365 },
  { name: 'Waverly, OH', path: '/trailer-rentals/ohio/waverly', lat: 39.1259, lng: -82.9857 },
  { name: 'West Carrollton, OH', path: '/trailer-rentals/ohio/west-carrollton', lat: 39.6728, lng: -84.2521 },
  { name: 'West Chester, OH', path: '/trailer-rentals/ohio/west-chester', lat: 39.3321, lng: -84.4173 },
  { name: 'Wilmington, OH', path: '/trailer-rentals/ohio/wilmington', lat: 39.4459, lng: -83.8285 },
  { name: 'Xenia, OH', path: '/trailer-rentals/ohio/xenia', lat: 39.6845, lng: -83.9293 },
  { name: 'Yellow Springs, OH', path: '/trailer-rentals/ohio/yellow-springs', lat: 39.8067, lng: -83.8865 },
  { name: 'Youngstown, OH', path: '/trailer-rentals/ohio/youngstown', lat: 41.0998, lng: -80.6495 },
  { name: 'Zanesville, OH', path: '/trailer-rentals/ohio/zanesville', lat: 39.9403, lng: -82.0132 },
];

const northernWisconsinCities: ICity[] = [
  { name: 'Ashland, WI', path: '/trailer-rentals/wisconsin/ashland', lat: 46.5927, lng: -90.8833 },
  { name: 'Antigo, WI', path: '/trailer-rentals/wisconsin/antigo', lat: 45.1402, lng: -89.1523 },
  { name: 'Bayfield, WI', path: '/trailer-rentals/wisconsin/bayfield', lat: 46.8108, lng: -90.8184 },
  { name: 'Eagle River, WI', path: '/trailer-rentals/wisconsin/eagle-river', lat: 45.9172, lng: -89.2487 },
  { name: 'Hayward, WI', path: '/trailer-rentals/wisconsin/hayward', lat: 46.0130, lng: -91.4846 },
  { name: 'Holmen, WI', path: '/trailer-rentals/wisconsin/holmen', lat: 43.9750, lng: -91.2572 },
  { name: 'Hudson, WI', path: '/trailer-rentals/wisconsin/hudson', lat: 44.9747, lng: -92.7569 },
  { name: 'Merrill, WI', path: '/trailer-rentals/wisconsin/merrill', lat: 45.1805, lng: -89.6837 },
  { name: 'Park Falls, WI', path: '/trailer-rentals/wisconsin/park-falls', lat: 45.9347, lng: -90.4459 },
  { name: 'Rhinelander, WI', path: '/trailer-rentals/wisconsin/rhinelander', lat: 45.6366, lng: -89.4123 },
  { name: 'Rice Lake, WI', path: '/trailer-rentals/wisconsin/rice-lake', lat: 45.5066, lng: -91.7382 },
  { name: 'Spooner, WI', path: '/trailer-rentals/wisconsin/spooner', lat: 45.8225, lng: -91.8795 },
  { name: 'Superior, WI', path: '/trailer-rentals/wisconsin/superior', lat: 46.7208, lng: -92.1041 },
  { name: 'Tomah, WI', path: '/trailer-rentals/wisconsin/tomah', lat: 45.4697, lng: -89.7285 },
  { name: 'Washburn, WI', path: '/trailer-rentals/wisconsin/washburn', lat: 46.6772, lng: -90.8866 },
  { name: 'Wautoma, WI', path: '/trailer-rentals/wisconsin/wautoma', lat: 44.0675, lng: -89.3032 },
  { name: 'Woodruff, WI', path: '/trailer-rentals/wisconsin/woodruff', lat: 45.8925, lng: -89.7305 },
];

const centralWisconsinCities: ICity[] = [
  { name: 'Appleton, WI', path: '/trailer-rentals/wisconsin/appleton', lat: 44.2619, lng: -88.4154 },
  { name: 'Baraboo, WI', path: '/trailer-rentals/wisconsin/baraboo', lat: 43.4715, lng: -89.7446 },
  { name: 'Beaver Dam, WI', path: '/trailer-rentals/wisconsin/beaver-dam', lat: 43.4578, lng: -88.8373 },
  { name: 'Berlin, WI', path: '/trailer-rentals/wisconsin/berlin', lat: 43.9683, lng: -88.9437 },
  { name: 'Black River Falls, WI', path: '/trailer-rentals/wisconsin/black-river-falls', lat: 44.2941, lng: -90.8415 },
  { name: 'Chippewa Falls, WI', path: '/trailer-rentals/wisconsin/chippewa-falls', lat: 44.9369, lng: -91.3924 },
  { name: 'Colby, WI', path: '/trailer-rentals/wisconsin/colby', lat: 44.9050, lng: -90.3157 },
  { name: 'De Pere, WI', path: '/trailer-rentals/wisconsin/de-pere', lat: 44.4483, lng: -88.0604 },
  { name: 'Eau Claire, WI', path: '/trailer-rentals/wisconsin/eau-claire', lat: 44.8113, lng: -91.4985 },
  { name: 'Elkhorn, WI', path: '/trailer-rentals/wisconsin/elkhorn', lat: 42.6728, lng: -88.5443 },
  { name: 'Fitchburg, WI', path: '/trailer-rentals/wisconsin/fitchburg', lat: 43.0067, lng: -89.4243 },
  { name: 'Fond du Lac, WI', path: '/trailer-rentals/wisconsin/fond-du-lac', lat: 43.7734, lng: -88.4468 },
  { name: 'Fort Atkinson, WI', path: '/trailer-rentals/wisconsin/fort-atkinson', lat: 42.9281, lng: -88.8373 },
  { name: 'Green Bay, WI', path: '/trailer-rentals/wisconsin/green-bay', lat: 44.5192, lng: -88.0198 },
  { name: 'Kaukauna, WI', path: '/trailer-rentals/wisconsin/kaukauna', lat: 44.2629, lng: -88.3154 },
  { name: 'Kewaskum, WI', path: '/trailer-rentals/wisconsin/kewaskum', lat: 43.5211, lng: -88.2287 },
  { name: 'La Crosse, WI', path: '/trailer-rentals/wisconsin/la-crosse', lat: 43.8014, lng: -91.2396 },
  { name: 'Little Chute, WI', path: '/trailer-rentals/wisconsin/little-chute', lat: 44.2792, lng: -88.3195 },
  { name: 'Manitowoc, WI', path: '/trailer-rentals/wisconsin/manitowoc', lat: 44.0886, lng: -87.6576 },
  { name: 'Marinette, WI', path: '/trailer-rentals/wisconsin/marinette', lat: 45.0994, lng: -87.6307 },
  { name: 'Marshfield, WI', path: '/trailer-rentals/wisconsin/marshfield', lat: 44.6686, lng: -90.1718 },
  { name: 'Medford, WI', path: '/trailer-rentals/wisconsin/medford', lat: 45.1383, lng: -90.3496 },
  { name: 'Menasha, WI', path: '/trailer-rentals/wisconsin/menasha', lat: 44.2025, lng: -88.4462 },
  { name: 'Menomonee Falls, WI', path: '/trailer-rentals/wisconsin/menomonee-falls', lat: 43.1789, lng: -88.1173 },
  { name: 'Menomonie, WI', path: '/trailer-rentals/wisconsin/menomonie', lat: 44.8755, lng: -91.9194 },
  { name: 'Merrillan, WI', path: '/trailer-rentals/wisconsin/merrillan', lat: 44.4097, lng: -90.7696 },
  { name: 'Middleton, WI', path: '/trailer-rentals/wisconsin/middleton', lat: 43.0972, lng: -89.5043 },
  { name: 'Mosinee, WI', path: '/trailer-rentals/wisconsin/mosinee', lat: 44.7930, lng: -89.7036 },
  { name: 'Nekoosa, WI', path: '/trailer-rentals/wisconsin/nekoosa', lat: 44.3128, lng: -89.8986 },
  { name: 'Oshkosh, WI', path: '/trailer-rentals/wisconsin/oshkosh', lat: 44.0247, lng: -88.5426 },
  { name: 'Platteville, WI', path: '/trailer-rentals/wisconsin/platteville', lat: 42.7347, lng: -90.4787 },
  { name: 'Plymouth, WI', path: '/trailer-rentals/wisconsin/plymouth', lat: 43.7489, lng: -87.9773 },
  { name: 'Portage, WI', path: '/trailer-rentals/wisconsin/portage', lat: 43.5397, lng: -89.4626 },
  { name: 'Reedsburg, WI', path: '/trailer-rentals/wisconsin/reedsburg', lat: 43.5325, lng: -90.0026 },
  { name: 'Ripon, WI', path: '/trailer-rentals/wisconsin/ripon', lat: 43.8422, lng: -88.8387 },
  { name: 'River Falls, WI', path: '/trailer-rentals/wisconsin/river-falls', lat: 44.8611, lng: -92.6232 },
  { name: 'Sheboygan, WI', path: '/trailer-rentals/wisconsin/sheboygan', lat: 43.7508, lng: -87.7145 },
  { name: 'Stevens Point, WI', path: '/trailer-rentals/wisconsin/stevens-point', lat: 44.5236, lng: -89.5746 },
  { name: 'Stoughton, WI', path: '/trailer-rentals/wisconsin/stoughton', lat: 42.9167, lng: -89.2173 },
  { name: 'Sun Prairie, WI', path: '/trailer-rentals/wisconsin/sun-prairie', lat: 43.1836, lng: -89.2137 },
  { name: 'Wisconsin Rapids, WI', path: '/trailer-rentals/wisconsin/wisconsin-rapids', lat: 44.3836, lng: -89.8173 },
];

const southernWisconsinCities: ICity[] = [
  { name: 'Allouez, WI', path: '/trailer-rentals/wisconsin/allouez', lat: 44.4912, lng: -88.0217 },
  { name: 'Beloit, WI', path: '/trailer-rentals/wisconsin/beloit', lat: 42.5083, lng: -89.0318 },
  { name: 'Brookfield, WI', path: '/trailer-rentals/wisconsin/brookfield', lat: 43.0606, lng: -88.1065 },
  { name: 'Burlington, WI', path: '/trailer-rentals/wisconsin/burlington', lat: 42.6781, lng: -88.2762 },
  { name: 'Cedarburg, WI', path: '/trailer-rentals/wisconsin/cedarburg', lat: 43.2967, lng: -87.9873 },
  { name: 'Cottage Grove, WI', path: '/trailer-rentals/wisconsin/cottage-grove', lat: 43.0736, lng: -89.1837 },
  { name: 'Cudahy, WI', path: '/trailer-rentals/wisconsin/cudahy', lat: 42.9597, lng: -87.8615 },
  { name: 'Delafield, WI', path: '/trailer-rentals/wisconsin/delafield', lat: 43.0608, lng: -88.4037 },
  { name: 'Delavan, WI', path: '/trailer-rentals/wisconsin/delavan', lat: 42.6331, lng: -88.6437 },
  { name: 'Evansville, WI', path: '/trailer-rentals/wisconsin/evansville', lat: 42.7786, lng: -89.3015 },
  { name: 'Franklin, WI', path: '/trailer-rentals/wisconsin/franklin', lat: 42.8886, lng: -88.0384 },
  { name: 'Glendale, WI', path: '/trailer-rentals/wisconsin/glendale', lat: 43.1342, lng: -87.9245 },
  { name: 'Greendale, WI', path: '/trailer-rentals/wisconsin/greendale', lat: 42.9408, lng: -87.9951 },
  { name: 'Greenfield, WI', path: '/trailer-rentals/wisconsin/greenfield', lat: 42.9614, lng: -88.0126 },
  { name: 'Hartford, WI', path: '/trailer-rentals/wisconsin/hartford', lat: 43.3189, lng: -88.3787 },
  { name: 'Janesville, WI', path: '/trailer-rentals/wisconsin/janesville', lat: 42.6828, lng: -89.0187 },
  { name: 'Jefferson, WI', path: '/trailer-rentals/wisconsin/jefferson', lat: 43.0056, lng: -88.8073 },
  { name: 'Kenosha, WI', path: '/trailer-rentals/wisconsin/kenosha', lat: 42.5847, lng: -87.8212 },
  { name: 'Lake Geneva, WI', path: '/trailer-rentals/wisconsin/lake-geneva', lat: 42.5917, lng: -88.4334 },
  { name: 'Lancaster, WI', path: '/trailer-rentals/wisconsin/lancaster', lat: 42.8467, lng: -90.7087 },
  { name: 'Madison, WI', path: '/trailer-rentals/wisconsin/madison', lat: 43.0731, lng: -89.4012 },
  { name: 'Mequon, WI', path: '/trailer-rentals/wisconsin/mequon', lat: 43.2364, lng: -87.9845 },
  { name: 'Milwaukee, WI', path: '/trailer-rentals/wisconsin/milwaukee', lat: 43.0389, lng: -87.9065 },
  { name: 'Monroe, WI', path: '/trailer-rentals/wisconsin/monroe', lat: 42.6011, lng: -89.6387 },
  { name: 'Mount Horeb, WI', path: '/trailer-rentals/wisconsin/mount-horeb', lat: 43.0086, lng: -89.7387 },
  { name: 'Muskego, WI', path: '/trailer-rentals/wisconsin/muskego', lat: 42.9058, lng: -88.1373 },
  { name: 'Neenah, WI', path: '/trailer-rentals/wisconsin/neenah', lat: 44.1858, lng: -88.4626 },
  { name: 'New Berlin, WI', path: '/trailer-rentals/wisconsin/new-berlin', lat: 42.9764, lng: -88.1084 },
  { name: 'New Glarus, WI', path: '/trailer-rentals/wisconsin/new-glarus', lat: 42.8153, lng: -89.6337 },
  { name: 'New London, WI', path: '/trailer-rentals/wisconsin/new-london', lat: 44.3922, lng: -88.7393 },
  { name: 'Oak Creek, WI', path: '/trailer-rentals/wisconsin/oak-creek', lat: 42.8859, lng: -87.8631 },
  { name: 'Oconomowoc, WI', path: '/trailer-rentals/wisconsin/oconomowoc', lat: 43.1117, lng: -88.4993 },
  { name: 'Onalaska, WI', path: '/trailer-rentals/wisconsin/onalaska', lat: 43.8841, lng: -91.2357 },
  { name: 'Pewaukee, WI', path: '/trailer-rentals/wisconsin/pewaukee', lat: 43.0808, lng: -88.2615 },
  { name: 'Port Washington, WI', path: '/trailer-rentals/wisconsin/port-washington', lat: 43.3878, lng: -87.8754 },
  { name: 'Racine, WI', path: '/trailer-rentals/wisconsin/racine', lat: 42.7261, lng: -87.7829 },
  { name: 'South Milwaukee, WI', path: '/trailer-rentals/wisconsin/south-milwaukee', lat: 42.9103, lng: -87.8601 },
  { name: 'Sparta, WI', path: '/trailer-rentals/wisconsin/sparta', lat: 43.9447, lng: -90.8123 },
  { name: 'Sussex, WI', path: '/trailer-rentals/wisconsin/sussex', lat: 43.1336, lng: -88.2226 },
  { name: 'Two Rivers, WI', path: '/trailer-rentals/wisconsin/two-rivers', lat: 44.1536, lng: -87.5695 },
  { name: 'Union Grove, WI', path: '/trailer-rentals/wisconsin/union-grove', lat: 42.6881, lng: -88.0515 },
  { name: 'Verona, WI', path: '/trailer-rentals/wisconsin/verona', lat: 42.9908, lng: -89.5337 },
  { name: 'Waubeka, WI', path: '/trailer-rentals/wisconsin/waubeka', lat: 43.4917, lng: -87.9837 },
  { name: 'Watertown, WI', path: '/trailer-rentals/wisconsin/watertown', lat: 43.1947, lng: -88.7243 },
  { name: 'Waukesha, WI', path: '/trailer-rentals/wisconsin/waukesha', lat: 43.0117, lng: -88.2315 },
  { name: 'Waunakee, WI', path: '/trailer-rentals/wisconsin/waunakee', lat: 43.1917, lng: -89.4554 },
  { name: 'Waupun, WI', path: '/trailer-rentals/wisconsin/waupun', lat: 43.6336, lng: -88.7315 },
  { name: 'Wausau, WI', path: '/trailer-rentals/wisconsin/wausau', lat: 44.9591, lng: -89.6301 },
  { name: 'West Allis, WI', path: '/trailer-rentals/wisconsin/west-allis', lat: 43.0167, lng: -88.0073 },
  { name: 'West Bend, WI', path: '/trailer-rentals/wisconsin/west-bend', lat: 43.4253, lng: -88.1837 },
  { name: 'Whitewater, WI', path: '/trailer-rentals/wisconsin/whitewater', lat: 42.8336, lng: -88.7326 },
];

export const serviceStates: IState[] = [

  { name: 'Alabama', fullName: 'Alabama,  US', cities: alabamaCities, path: '/trailer-rentals/alabama' },
  {
    name: 'Arizona',
    fullName: 'Arizona,  US',
    regions: [
      {
        name: 'Northern Arizona',
        cities: northernArizonaCities,
      },
      {
        name: "Central Arizona",
        cities: centralArizonaCities,
      },
      {
        name: 'Southern Arizona',
        cities: southernArizonaCities,
      },
      {
        name: 'Western Arizona',
        cities: westernArizonaCities
      }
    ],
    path: '/trailer-rentals/arizona'
  },
  { name: 'Arkansas', fullName: 'Arkansas,  US', cities: arkansasCities, path: '/trailer-rentals/arkansas' },
  { name: 'California', fullName: 'California,  US', cities: californiaCities, path: '/trailer-rentals/california' },
  {
    name: 'Colorado',
    fullName: 'Colorado,  US',
    regions: [
      {
        name: 'Northern Colorado',
        cities: northernColoradoCities,
      },
      {
        name: "Central Colorado",
        cities: centralColoradoCities,
      },
      {
        name: 'Western Colorado',
        cities: westernColoradoCities,
      },
      {
        name: 'Southern Colorado',
        cities: southernColoradoCities,
      },
    ],
    path: '/trailer-rentals/colorado',
  },
  { name: 'Connecticut', fullName: 'Connecticut,  US', cities: connecticutCities, path: '/trailer-rentals/connecticut' },
  { name: 'Florida', fullName: 'Florida,  US', cities: floridaCities, path: '/trailer-rentals/florida' },
  { name: 'Georgia', fullName: 'Georgia,  US', cities: georgiaCities, path: '/trailer-rentals/georgia' },
  { name: 'Idaho', fullName: 'Idaho,  US', cities: idahoCities, path: '/trailer-rentals/idaho' },
  { name: 'Illinois', fullName: 'Illinois,  US', cities: illinoisCities, path: '/trailer-rentals/illinois' },
  { name: 'Indiana', fullName: 'Indiana,  US', cities: indianaCities, path: '/trailer-rentals/indiana' },
  { name: 'Iowa', fullName: 'Iowa,  US', cities: iowaCities, path: '/trailer-rentals/iowa' },
  { name: 'Kansas', fullName: 'Kansas,  US', cities: kansasCities, path: '/trailer-rentals/kansas' },
  { name: 'Kentucky', fullName: 'Kentucky,  US', cities: kentuckyCities, path: '/trailer-rentals/kentucky' },
  { name: 'Louisiana', fullName: 'Louisiana,  US', cities: louisianaCities, path: '/trailer-rentals/louisiana' },
  { name: 'Maine', fullName: 'Maine,  US', cities: maineCities, path: '/trailer-rentals/maine' },
  { name: 'Maryland', fullName: 'Maryland,  US', cities: marylandCities, path: '/trailer-rentals/maryland' },
  { name: 'Massachusetts', fullName: 'Massachusetts,  US', cities: massachusettsCities, path: '/trailer-rentals/massachusetts' },
  { name: 'Michigan', fullName: 'Michigan,  US', cities: michiganCities, path: '/trailer-rentals/michigan' },
  { name: 'Minnesota', fullName: 'Minnesota,  US', cities: minnesotaCities, path: '/trailer-rentals/minnesota' },
  { name: 'Mississippi', fullName: 'Mississippi,  US', cities: mississippiCities, path: '/trailer-rentals/mississippi' },
  { name: 'Missouri', fullName: 'Missouri,  US', cities: missouriCities, path: '/trailer-rentals/missouri' },
  { name: 'Montana', fullName: 'Montana,  US', cities: montanaCities, path: '/trailer-rentals/montana' },
  { name: 'Nebraska', fullName: 'Nebraska,  US', cities: nebraskaCities, path: '/trailer-rentals/nebraska' },
  { name: 'Nevada', fullName: 'Nevada,  US', cities: nevadaCities, path: '/trailer-rentals/nevada' },
  { name: 'New Hampshire', fullName: 'New Hampshire,  US', cities: newHampshireCities, path: '/trailer-rentals/new-hampshire' },
  { name: 'New Jersey', fullName: 'New Jersey,  US', cities: newJerseyCities, path: '/trailer-rentals/new-jersey' },
  { name: 'New Mexico', fullName: 'New Mexico,  US', cities: newMexicoCities, path: '/trailer-rentals/new-mexico' },
  { name: 'New York', fullName: 'New York,  US', cities: newYorkCities, path: '/trailer-rentals/new-york' },
  { name: 'North Carolina', fullName: 'North Carolina,  US', cities: northCarolinaCities, path: '/trailer-rentals/north-carolina' },
  { name: 'North Dakota', fullName: 'North Dakota,  US', cities: northDakotaCities, path: '/trailer-rentals/north-dakota' },
  {
    name: 'Ohio',
    fullName: 'Ohio,  US',
    regions: [
      {
        name: 'Northern Ohio',
        cities: northernOhioCities,
      },
      {
        name: 'Central Ohio',
        cities: centralOhioCities,
      },
      {
        name: 'Southern Ohio',
        cities: southernOhioCities
      }
    ],
    path: '/trailer-rentals/ohio'
  },
  { name: 'Oklahoma', fullName: 'Oklahoma,  US', cities: oklahomaCities, path: '/trailer-rentals/oklahoma' },
  { name: 'Oregon', fullName: 'Oregon,  US', cities: oregonCities, path: '/trailer-rentals/oregon' },
  {
    name: 'Pennsylvania',
    fullName: 'Pennsylvania,  US',
    regions: [
      {
        name: 'Northern Pennsylvania',
        cities: northeasternPennsylvaniaCities,
      },
      {
        name: "Central Pennsylvania",
        cities: centralPennsylvaniaCities,
      },
      {
        name: 'Western Pennsylvania',
        cities: westernPennsylvaniaCities,
      },
      {
        name: 'Eastern Pennsylvania',
        cities: easternPennsylvaniaCities,
      },
    ],
    path: '/trailer-rentals/pennsylvania',
  },
  { name: 'Rhode Island', fullName: 'Rhode Island,  US', cities: rhodeIslandCities, path: '/trailer-rentals/rhode-island' },
  { name: 'South Carolina', fullName: 'South Carolina,  US', cities: southCarolinaCities, path: '/trailer-rentals/south-carolina' },
  { name: 'South Dakota', fullName: 'South Dakota,  US', cities: southDakotaCities, path: '/trailer-rentals/south-dakota' },
  { name: 'Tennessee', fullName: 'Tennessee,  US', cities: tennesseeCities, path: '/trailer-rentals/tennessee' },
  {
    name: 'Texas',
    fullName: 'Texas,  US',
    regions: [
      {
        name: 'Northern Texas',
        cities: northernTexasCities,
      },
      {
        name: 'Central Texas',
        cities: centralTexasCities,
      },
      {
        name: 'Eastern Texas',
        cities: easternTexasCities,
      },
      {
        name: 'Southern Texas',
        cities: southernTexasCities,
      }
    ],
    path: '/trailer-rentals/texas'
  },
  { name: 'Utah', fullName: 'Utah,  US', cities: utahCities, path: '/trailer-rentals/utah' },
  { name: 'Vermont', fullName: 'Vermont,  US', cities: vermontCities, path: '/trailer-rentals/vermont' },
  { name: 'Virginia', fullName: 'Virginia,  US', cities: virginiaCities, path: '/trailer-rentals/virginia' },
  { name: 'Washington', fullName: 'Washington,  US', cities: washingtonCities, path: '/trailer-rentals/washington' },
  { name: 'West Virginia', fullName: 'West Virginia, US', cities: westVirginiaCities, path: '/trailer-rentals/west-virginia' },
  {
    name: 'Wisconsin',
    fullName: 'Wisconsin,  US',
    regions: [
      {
        name: 'Northern Wisconsin',
        cities: northernWisconsinCities,
      },
      {
        name: 'Central Wisconsin',
        cities: centralWisconsinCities,
      },
      {
        name: 'Southern Wisconsin',
        cities: southernWisconsinCities
      }
    ],
    path: '/trailer-rentals/wisconsin'
  },
  { name: 'Wyoming', fullName: 'Wyoming,  US', cities: wyomingCities, path: '/trailer-rentals/wyoming' },
  { name: 'Delaware', fullName: 'Delaware,  US', cities: delawareCities, path: '/trailer-rentals/delaware' },
];

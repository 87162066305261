import './index.css?ver=1';
import { Fragment, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import type { ReactElement } from 'react';
import type { IRouteItemProp } from 'core/interfaces/base';
import SettingProvider from './core/context/SettingContext';
import './App.css?ver=1';
import { getRoutes } from 'core/routes';
import { addTrackingCode } from "./core/constants/scriptFunc";
import { AuthProvider } from './core/context/AuthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { HelmetProvider, HelmetServerState } from 'react-helmet-async';
import { Spinner } from 'components/spinner/spinner';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { DiscountDialog } from 'components';

const ErrorPage = lazy(() => import('pages/ErrorPage/ErrorPage'));

const queryClient = new QueryClient()
const helmetContext: { helmet?: HelmetServerState;  prioritizeSeoTags: true} = { prioritizeSeoTags: true };

export function App() {
  useEffect(() => {
    addTrackingCode(process.env.REACT_APP_ENV)
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
        <Router>
          <AuthProvider>
            <SettingProvider>
              <Fragment>
                <DiscountDialog />
                <Routes>
                  {
                    getRoutes().map((item:IRouteItemProp):ReactElement => (
                      <Route
                        path={item.path}
                        key={item.id}
                        element={(
                          <Suspense fallback={<Spinner />}>
                            {item.element}
                          </Suspense>
                        )}
                      />
                    ))
                  }
                  <Route path="*" element={<ErrorPage message=' The page you are looking for could not be found.' />} />
                </Routes>
              </Fragment>
            </SettingProvider>
          </AuthProvider>
        </Router>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

import { setFilterDate, setJustFilterDates } from "core/store/slices/settingSlice";
import store from "core/store/store";
import dayjs from "dayjs";

export interface FilterDates {
  pickupDate?: Date | string;
  pickupTime?: string;
  returnDate?: Date | string;
  returnTime?: string;
  isQuoteForm?: boolean;
  updateRentalDates?: (pickupDate: Date | string, returnDate: Date | string) => void;
};

export const stringifyFilterDate = (date: Date) => dayjs(date).format('MM/DD/YYYY');

export const dayjsifyFilterDate = (date: Date | string) => date instanceof Date ? dayjs(date) : dayjs(date, 'MM/DD/YYYY');

export const isPickupAfterReturn = (
  pickupDate: Date | string,
  returnDate: Date | string,
) => dayjsifyFilterDate(pickupDate).isAfter(dayjsifyFilterDate(returnDate).endOf('day'))

export const isDateBeforeToday = (
  pickupDate: Date | string,
) => dayjsifyFilterDate(pickupDate).endOf('day').isBefore(dayjs().startOf('day'))

export const setFilterDates = ({ pickupDate = '', returnDate = '', isQuoteForm = false, updateRentalDates, ...rest }: FilterDates) => {
  if(isQuoteForm && updateRentalDates) {
    updateRentalDates(
      typeof pickupDate === 'string' ? pickupDate : stringifyFilterDate(pickupDate),
      typeof returnDate === 'string' ? returnDate : stringifyFilterDate(returnDate),
    );
  } else {
    store.dispatch(
      setFilterDate({
        pickupDate: typeof pickupDate === 'string' ? pickupDate : stringifyFilterDate(pickupDate),
        returnDate: typeof returnDate === 'string' ? returnDate : stringifyFilterDate(returnDate),
        ...rest,
      })
    );
  }

};

export const justSetFilterDates = ({ pickupDate = '', returnDate = '', isQuoteForm = false, updateRentalDates }: Pick<FilterDates, 'pickupDate' | 'returnDate' | 'isQuoteForm' | 'updateRentalDates'>) => {
  const retDate = pickupDate && returnDate && isPickupAfterReturn(pickupDate, returnDate) ? '' : returnDate;
  if(isQuoteForm && updateRentalDates) {    updateRentalDates(
      typeof pickupDate === 'string' ? pickupDate : stringifyFilterDate(pickupDate),
      typeof retDate === 'string' ? retDate : stringifyFilterDate(retDate),
    );
  } else {
  store.dispatch(
    setJustFilterDates({
      pickupDate: typeof pickupDate === 'string' ? pickupDate : stringifyFilterDate(pickupDate),
      returnDate: typeof retDate === 'string' ? retDate : stringifyFilterDate(retDate),
    })
  );
  }
};

import { completeGuidetoRentingTrailerPlainData } from './CompleteGuidetoRentingTrailer/completeGuidetoRentingTrailerPlainData';
import { growTrailerRentalBusinessProvenTipsPlainData } from './GrowTrailerRentalBusinessProvenTips/growTrailerRentalBusinessProvenTipsPlainData';
import { guideForRoutineMaintenancePlainData } from './GuideForRoutineMaintenance/guideForRoutineMaintenancePlainData';
import { howToDraftTrailerRentalAgreementPlainData } from './HowToDraftTrailerRentalAgreement/howToDraftTrailerRentalAgreementPlainData';
import { howToLoadTrailerProStepByStepPlainData } from './HowToLoadTrailerProStepByStep/howToLoadTrailerProStepByStepPlainData';
import { inspectingTrailerBeforeTowGuidePlainData } from './InspectingTrailerBeforeTowGuide/inspectingTrailerBeforeTowGuidePlainData';
import { learningHowToBackupTrailerProPlainData } from './LearningHowToBackupTrailerPro/learningHowToBackupTrailerProPlainData';
import { offRoadTrailerGuideToBuyingAdventurePlainData } from './OffRoadTrailerGuideToBuyingAdventure/offRoadTrailerGuideToBuyingAdventurePlainData';
import { preparingForLongDistanceTravelPlainData } from './PreparingForLongDistanceTravel/preparingForLongDistanceTravelPlainData';
import { topTipsProtectYourTrailerRentalBusinessPlainData } from './TopTipsProtectYourTrailerRentalBusiness/topTipsProtectYourTrailerRentalBusinessPlainData';
import { towingATrailerSafelyPlainData } from './TowingATrailerSafely/towingATrailerSafelyPlainData';
import { trailerForHomeProjectsPlainData } from './TrailerForHomeProjects/trailerForHomeProjectsPlainData';
import { vehicleTowingCapacityExplainedRentalTipsPlainData } from './VehicleTowingCapacityExplainedRentalTips/vehicleTowingCapacityExplainedRentalTipsPlainData';

export const blogSitemapObject = {
  completeGuidetoRentingTrailerPlainData,
  growTrailerRentalBusinessProvenTipsPlainData,
  guideForRoutineMaintenancePlainData,
  howToDraftTrailerRentalAgreementPlainData,
  howToLoadTrailerProStepByStepPlainData,
  inspectingTrailerBeforeTowGuidePlainData,
  learningHowToBackupTrailerProPlainData,
  offRoadTrailerGuideToBuyingAdventurePlainData,
  preparingForLongDistanceTravelPlainData,
  topTipsProtectYourTrailerRentalBusinessPlainData,
  towingATrailerSafelyPlainData,
  trailerForHomeProjectsPlainData,
  vehicleTowingCapacityExplainedRentalTipsPlainData,
};

export const blogSitemap = [
  completeGuidetoRentingTrailerPlainData,
  growTrailerRentalBusinessProvenTipsPlainData,
  guideForRoutineMaintenancePlainData,
  howToDraftTrailerRentalAgreementPlainData,
  howToLoadTrailerProStepByStepPlainData,
  inspectingTrailerBeforeTowGuidePlainData,
  learningHowToBackupTrailerProPlainData,
  offRoadTrailerGuideToBuyingAdventurePlainData,
  preparingForLongDistanceTravelPlainData,
  topTipsProtectYourTrailerRentalBusinessPlainData,
  towingATrailerSafelyPlainData,
  trailerForHomeProjectsPlainData,
  vehicleTowingCapacityExplainedRentalTipsPlainData,
].map(({ link }) => ({
  path: link,
  changefreq: 'weekly',
  priority: '0.5',
}));

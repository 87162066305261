import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useGetSpentNeededTimeStatus,
  useIsBrowserTabActive,
  useOpennedTabIds,
  useQuoteSubmittedStatus,
  useSearchHasResult,
  useVisitedPathnames,
} from 'hooks';
import { SettingContext } from 'core/context/SettingContext';
import { DiscountDialogArgs } from './types';
import {
  discountModalNotShownRecently,
  getGetQuoteSubmitted,
  getSearchHasResult,
  getVisitedPathnamesCount,
  setDiscountModalShownAt,
} from 'utils';

export const useDiscountDialog = ({
  minVisitingTimeSec = 45,
  excludedPathnames = ['/checkout'],
  openDelaySec = 5,
}: DiscountDialogArgs = {}) => {
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const modalTimeoutIdRef = useRef<NodeJS.Timeout>(undefined);

  const { pathname } = useLocation();

  useOpennedTabIds();
  useVisitedPathnames();
  useQuoteSubmittedStatus();
  useSearchHasResult();

  const isBrowserTabActive = useIsBrowserTabActive();
  const spentTimeStatus = useGetSpentNeededTimeStatus(minVisitingTimeSec);

  const {
    quoteDialogIsOpen,
    searchDialogIsOpen,
    locationDialogIsOpen,
    equipmentDialogIsOpen,
    loginDialogIsOpen,
    productCalendarDialogIsOpen,
  } = useContext(SettingContext);

  const pathShouldBeExcluded = excludedPathnames.includes(pathname);
  const aDialogIsOpen = quoteDialogIsOpen ||
    searchDialogIsOpen ||
    locationDialogIsOpen ||
    equipmentDialogIsOpen ||
    loginDialogIsOpen ||
    productCalendarDialogIsOpen;

  useEffect(() => {
    const pathnameCount = getVisitedPathnamesCount();
    const modalNotShownRecently = discountModalNotShownRecently();
    const searchHasResult = getSearchHasResult();

    if (
      aDialogIsOpen
      || !searchHasResult
      || pathShouldBeExcluded
    ) {
      clearTimeout(modalTimeoutIdRef.current);
      return;
    }

    if (
      spentTimeStatus
      && isBrowserTabActive
      && pathnameCount > 1
      && modalNotShownRecently
    ) {
      modalTimeoutIdRef.current = setTimeout(() => {
        if (getGetQuoteSubmitted()) { return; }

        setShowDiscountModal(true);
        setDiscountModalShownAt();
      }, openDelaySec * 1000);
    }

    return () => {
      clearTimeout(modalTimeoutIdRef.current);
    }
  }, [
    isBrowserTabActive,
    spentTimeStatus,
    aDialogIsOpen,
    pathname,
    openDelaySec,
    pathShouldBeExcluded,
  ]);

  return showDiscountModal;
};

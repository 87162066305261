import { lazy } from 'react';
import type { IRouteItemProp } from './interfaces/base';
import { serviceStates } from '../core/data/city';

import { blogSitemapObject } from '../pages/Blog/blogSitemap';

const HomePage = lazy(() => import('pages/HomePage'));
const MapPage = lazy(() => import('pages/Map'));
const TrailersLocationState = lazy(() => import('pages/ServiceArea/state'));
const Trailers = lazy(() => import('pages/LandingPage/trailers'));
const AllProducts = lazy(() => import('pages/AllProducts'));
const FlatbedTrailers = lazy(() => import('pages/LandingPage/trailers/flatbed'));
const EnclosedTrailers = lazy(() => import('pages/LandingPage/trailers/enclosed'));
const EquipmentTrailers = lazy(() => import('pages/LandingPage/trailers/equipment'));
const RollOffDumpTrailers = lazy(() => import('pages/LandingPage/trailers/rollOfDump'));
const GooseneckTrailers = lazy(() => import('pages/LandingPage/trailers/gooseneck'));
const UtilityTrailers = lazy(() => import('pages/LandingPage/trailers/utility'));
const TiltTrailers = lazy(() => import('pages/LandingPage/trailers/tilt'));
const DumpTrailers = lazy(() => import('pages/LandingPage/trailers/dump'));
const Results = lazy(() => import('pages/ProductDetail/Results'));
const ProductDetail = lazy(() => import('pages/ProductDetail'));
const ServiceArea = lazy(() => import('pages/ServiceArea'));
const Blog = lazy(() => import('pages/Blog'));
const TrailerForHomeProjects = lazy(() => import('pages/Blog/TrailerForHomeProjects/TrailerForHomeProjects'));
const CompleteGuidetoRentingTrailer = lazy(() => import('pages/Blog/CompleteGuidetoRentingTrailer/CompleteGuidetoRentingTrailer'));
const GuideForRoutineMaintenance = lazy(() => import('pages/Blog/GuideForRoutineMaintenance/GuideForRoutineMaintenance'));
const TowingATrailerSafely = lazy(() => import('pages/Blog/TowingATrailerSafely/TowingATrailerSafely'));
const PreparingForLongDistanceTravel = lazy(() => import('pages/Blog/PreparingForLongDistanceTravel/PreparingForLongDistanceTravel'));
const LearningHowToBackupTrailerPro = lazy(() => import('pages/Blog/LearningHowToBackupTrailerPro/LearningHowToBackupTrailerPro'));
const HowToDraftTrailerRentalAgreement = lazy(() => import('pages/Blog/HowToDraftTrailerRentalAgreement/HowToDraftTrailerRentalAgreement'));
const HowToLoadTrailerProStepByStep = lazy(() => import('pages/Blog/HowToLoadTrailerProStepByStep/HowToLoadTrailerProStepByStep'));
const InspectingTrailerBeforeTowGuide = lazy(() => import('pages/Blog/InspectingTrailerBeforeTowGuide/InspectingTrailerBeforeTowGuide'));
const GuideToRentingDumpTrailer = lazy(() => import('pages/Blog/GuideToRentingDumpTrailer/GuideToRentingDumpTrailer'));
const HowToMaximizeTheLifespanOfYourUtilityTrailer = lazy(() => import('pages/Blog/HowToMaximizeTheLifespanOfYourUtilityTrailer/HowToMaximizeTheLifespanOfYourUtilityTrailer'));
const GrowTrailerRentalBusinessProvenTips = lazy(() => import('pages/Blog/GrowTrailerRentalBusinessProvenTips/GrowTrailerRentalBusinessProvenTips'));
const TopTipsProtectYourTrailerRentalBusiness = lazy(() => import('pages/Blog/TopTipsProtectYourTrailerRentalBusiness/TopTipsProtectYourTrailerRentalBusiness'));
const VehicleTowingCapacityExplainedRentalTips = lazy(() => import('pages/Blog/VehicleTowingCapacityExplainedRentalTips/VehicleTowingCapacityExplainedRentalTips'));
const OffRoadTrailerGuideToBuyingAdventure = lazy(() => import('pages/Blog/OffRoadTrailerGuideToBuyingAdventure/OffRoadTrailerGuideToBuyingAdventure'));
const IndustrySolution = lazy(() => import('pages/IndustrySolution'));
const Checkout = lazy(() => import('pages/CheckOut'));
const Promotion = lazy(() => import('pages/Promotion'));
const Confirmation = lazy(() => import('pages/CheckOut/confirm/Confirm'));
const ConfirmPage = lazy(() => import('pages/CheckOut/confirmPage'));
const PrivacyPolicy = lazy(() => import('pages/privacyPage'));
const TermsPolicy = lazy(() => import('pages/termPage'));
const ContactUS = lazy(() => import('pages/ContactUs'));
const FAQPage = lazy(() => import('pages/faq'));
const PartnerPage = lazy(() => import('pages/partner'));
const PartnerRegistrationPage = lazy(() => import('pages/PartnerRegistration'))

export const getRoutes = () => {
  const routeItems: IRouteItemProp[] = [
  {
    path: '/',
    element: <HomePage />,
    id: 'home',
    sitemap: {
      changefreq: 'weekly',
      priority: '1.0',
    },
  },
  {
    path: '/trailer-rentals/map',
    element: <MapPage />,
    id: 'map',
  },
  {
    path: '/trailer-rentals/locations',
    element: <ServiceArea />,
    id: 'servicearea',
    sitemap: {
      changefreq: 'weekly',
      priority: '0.8',
    },
  },
  {
    path: '/trailer-rentals',
    element: <Trailers />,
    id: 'trailers',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.7',
    },
  },
  {
    path: '/trailer-rentals/results',
    element: <AllProducts />,
    id: 'product',
    sitemap: {
      changefreq: 'daily',
      priority: '0.9',
    },
  },
  {
    path: '/trailer-rentals/flatbed-trailers',
    element: <FlatbedTrailers />,
    id: 'flatbed-trailers',
  },
  {
    path: '/trailer-rentals/enclosed-trailers',
    element: <EnclosedTrailers />,
    id: 'enclosed-trailers',
  },
  {
    path: '/trailer-rentals/equipment-trailers',
    element: <EquipmentTrailers />,
    id: 'equipment-trailers',
  },
  {
    path: '/trailer-rentals/roll-off-dump-trailers',
    element: <RollOffDumpTrailers />,
    id: 'roll-off-dump-trailers',
  },
  {
    path: '/trailer-rentals/gooseneck-trailers',
    element: <GooseneckTrailers />,
    id: 'gooseneck-trailers',
  },
  {
    path: '/trailer-rentals/utility-trailers',
    element: <UtilityTrailers />,
    id: 'utility-trailers',
  },
  {
    path: '/trailer-rentals/tilt-trailers',
    element: <TiltTrailers />,
    id: 'tilt-trailers',
  },
  {
    path: '/trailer-rentals/dump-trailers',
    element: <DumpTrailers />,
    id: 'dump-trailers',
  },
  {
    path: '/:category/results/:assetId',
    element: <Results />,
    id: 'equip',
  },
  {
    path: '/trailer-rentals/:subcategory/:assetId',
    element: <ProductDetail />,
    id: 'proddetail',
  },
  {
    path: '/rental-resources',
    element: <Blog />,
    id: 'rental-resources',
    sitemap: {
      changefreq: 'weekly',
      priority: '0.5',
    },
  },
  {
    element: <TrailerForHomeProjects />,
    path: blogSitemapObject.trailerForHomeProjectsPlainData.link,
    id: 'TrailerForHomeProjects',
  },
  {
    element: <CompleteGuidetoRentingTrailer />,
    path: blogSitemapObject.completeGuidetoRentingTrailerPlainData.link,
    id: 'CompleteGuidetoRentingTrailer',
  },
  {
    element: <GuideForRoutineMaintenance />,
    path: blogSitemapObject.guideForRoutineMaintenancePlainData.link,
    id: 'GuideForRoutineMaintenance',
  },
  {
    element: <TowingATrailerSafely />,
    path: blogSitemapObject.towingATrailerSafelyPlainData.link,
    id: 'TowingATrailerSafely',
  },
  {
    element: <PreparingForLongDistanceTravel />,
    path: blogSitemapObject.preparingForLongDistanceTravelPlainData.link,
    id: 'PreparingForLongDistanceTravel',
  },
  {
    element: <LearningHowToBackupTrailerPro />,
    path: blogSitemapObject.learningHowToBackupTrailerProPlainData.link,
    id: 'LearningHowToBackupTrailerPro',
  },
  {
    element: <HowToDraftTrailerRentalAgreement />,
    path: blogSitemapObject.howToDraftTrailerRentalAgreementPlainData.link,
    id: 'HowToDraftTrailerRentalAgreement'
  },
  {
    element: <HowToLoadTrailerProStepByStep />,
    path: blogSitemapObject.howToLoadTrailerProStepByStepPlainData.link,
    id: 'HowToLoadTrailerProStepByStep'
  },
  {
    element: <InspectingTrailerBeforeTowGuide />,
    path: blogSitemapObject.inspectingTrailerBeforeTowGuidePlainData.link,
    id: 'InspectingTrailerBeforeTowGuide'
  },
  {
    element: <GuideToRentingDumpTrailer />,
    path: '/rental-resources/trailer-renters/guide-to-renting-dump-trailer',
    id: 'GuideToRentingDumpTrailer',
  },
  {
    element: <HowToMaximizeTheLifespanOfYourUtilityTrailer />,
    path: '/rental-resources/trailer-owners/how-to-maximize-the-lifespan-of-your-utility-trailer',
    id: 'HowToMaximizeTheLifespanOfYourUtilityTrailer',
  },
  {
    element: <GrowTrailerRentalBusinessProvenTips />,
    path: blogSitemapObject.growTrailerRentalBusinessProvenTipsPlainData.link,
    id: 'GrowTrailerRentalBusinessProvenTips',
  },
  {
    element: <TopTipsProtectYourTrailerRentalBusiness />,
    path: blogSitemapObject.topTipsProtectYourTrailerRentalBusinessPlainData.link,
    id: 'TopTipsProtectYourTrailerRentalBusiness',
  },
  {
    element: <VehicleTowingCapacityExplainedRentalTips />,
    path: blogSitemapObject.vehicleTowingCapacityExplainedRentalTipsPlainData.link,
    id: 'VehicleTowingCapacityExplainedRentalTips',
  },
  {
    element: <OffRoadTrailerGuideToBuyingAdventure />,
    path: blogSitemapObject.offRoadTrailerGuideToBuyingAdventurePlainData.link,
    id: 'OffRoadTrailerGuideToBuyingAdventure',
  },
  {
    path: '/industry-solution',
    element: <IndustrySolution />,
    id: 'industry-solution',
  },
  {
    path: '/checkout',
    element: <Checkout />,
    id: 'checkout',
  },
  {
    path: '/promotion',
    element: <Promotion />,
    id: 'promotion',
  },
  {
    path: "/confirmation/:confirmId",
    element: <Confirmation />,
    id: 'confirmation',
  },
  {
    path: '/confirmation',
    element: <ConfirmPage />,
    id: 'confirmationPage',
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
    id: 'privacy-policy',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/terms-service',
    element: <TermsPolicy />,
    id: 'terms-service',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/contact-us',
    element: <ContactUS />,
    id: 'contact-us',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/faq',
    element: <FAQPage />,
    id: 'faq',
    sitemap: {
      changefreq: 'monthly',
      priority: '0.5',
    },
  },
  {
    path: '/partner/:identifier',
    element: <PartnerPage />,
    id: 'partner',
  },
  {
    path : '/partner-registration',
    element: <PartnerRegistrationPage />,
    id: 'partner-registration',
  }
];

  serviceStates.forEach((state) => {
    // Original city+category path
    routeItems.push({
      path: `${state?.path}/:city?/:category?`,
      element: <TrailersLocationState />,
      id: `trailers-${state?.name}`,
      sitemap: {
        changefreq: 'monthly',
        priority: '0.5',
        path: state?.path,
      },
    });
    
    // Long-tail keyword variations
    const longTailPaths = [
      // Price-focused paths
      `${state?.path}/:city/affordable-:categorySlug-trailer-rentals`,
      `${state?.path}/:city/:categorySlug-trailer-rental-prices`,
      `${state?.path}/:city/cheap-:categorySlug-trailer-rentals`,
      
      // Location-focused paths
      `${state?.path}/:city/:categorySlug-trailer-rentals-near-me`,
      `${state?.path}/:city/:categorySlug-trailer-rentals-nearby`,
      
      // Purpose-focused paths
      `${state?.path}/:city/:categorySlug-trailer-rentals-for-moving`,
      `${state?.path}/:city/:categorySlug-trailer-rentals-for-construction`,
      `${state?.path}/:city/:categorySlug-trailer-rentals-for-landscaping`,
      
      // Feature-focused paths
      `${state?.path}/:city/rent-:categorySlug-trailer`,
      `${state?.path}/:city/best-:categorySlug-trailer-rentals`,
    ];
    
    // Add all long-tail variations
    longTailPaths.forEach((path, index) => {
      routeItems.push({
        path,
        element: <TrailersLocationState />,
        id: `trailers-${state?.name}-longtail-${index}`,
        sitemap: {
          changefreq: 'monthly',
          priority: '0.6', // Slightly higher priority for long-tail keywords
          path: state?.path,
        },
      });
    });
  });

  return routeItems;
}
